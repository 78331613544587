.checkout-stepper {
}

.checkout-stepper .step img {
  width: 24px;
  height: 24px;
  object-fit: contain;
  margin-right: 6px;
}

.checkout-stepper .step .label {
  font-family: Yellix-SemiBold;
  font-size: 15px;
}

.checkout-stepper .step .divider {
  border-top-width: 1px;
  border-top-style: dashed;
  margin-left: 25px;
  margin-right: 15px;
}

.checkout-stepper .step.active .label {
  color: var(--color-text);
}

.checkout-stepper .step.inactive .label {
  color: var(--color-gray7);
}

.checkout-stepper .step.active .divider {
  border-top-color: var(--color-cyan2);
}

.checkout-stepper .step.inactive .divider {
  border-top-color: var(--color-gray7);
}

@media (max-width: 768px) {
  .checkout-stepper .step {
    flex: none;
    width: 100%;
    justify-content: flex-start;
  }
  .checkout-stepper .step .divider{
    display: none;
  }
}