.checkout-cart-view {
  padding: 0px;
}

.checkout-cart-view .items {
  border-radius: 12px;
  margin-top: 20px;
  padding: 20px;
  width: 100%;
  background-color: var(--color-gray9);
}

.checkout-cart-view .h2 {
  font-size: 32px !important;
  font-family: normal;
  font-family: Yellix-Bold, serif;
  color: var(--color-text);
  margin: 0px;
  text-align: center;
}

.checkout-cart-view .cart-btns {
  width: 100%;
  margin-top: 20px;
}

.checkout-cart-view .cart-btns p {
  font-size: 15px;
  font-family: Yellix-Medium, serif;
  color: var(--color-text);
  margin-top: 0;
  margin-bottom: 3px;
}

.checkout-cart-view h2 {
  font-size: 22px;
  font-family: Yellix-SemiBold, serif;
  color: var(--color-text);
  margin: 0;
}

.checkout-cart-view a {
  font-size: 16px;
  font-family: Yellix-SemiBold, serif;
  color: var(--color-cyan2);
  margin: 0;
}

.checkout-cart-view .divider {
  height: 1px;
  width: 100%;
  background-color: var(--color-gray6);
  margin-bottom: 20px;
}

.checkout-cart-view .subject-title {
  font-size: 16px;
  font-family: Yellix-SemiBold, serif;
  color: var(--color-text);
  margin: 10px 0px;
}

.checkout-cart-view .vendor-logo-view .round-icon-btn{
  width: 50px;
  height: 50px;
  border-radius: 12px;
  background-color: white;
}

.checkout-cart-view .vendor-logo-view img {
  width: 48px;
  height: 48px;
  object-fit: contain;
  border-radius: 10px;
}

.checkout-cart-view .vendor-logo-view .logo-text {
  font-size: 20px;
  font-family: Yellix-Bold;
  color: var(--color-text);
  margin-left: 14px;
}