.chat-hist-screen {
  flex: 1;
  background-color: white;
  width: 100%;
  height: calc(100vh - 160px);
}

.chat-hist-screen .scrollview {
  flex: 1;
  width: 100%;
  background-color: white;
  overflow-y: auto;
  padding-left: 20px;
  padding-right: 20px;
}

.chat-hist-screen .search-cntainer {
  padding: 10px 20px;
  margin-top: 15px;
  padding-top: 0;
}
