.earn-invitation-history {
  width: 100%;
}

.earn-invitation-history .scroll-view {
  width: 100%;
}


.earn-invitation-history .header-view {
  width: 100%;
  margin: 15px 0px;
}

.earn-invitation-history .title{
  text-align: center;
  font-size: 20px;
  font-family: Yellix-Bold, serif;
  color: var(--color-text);
}

.earn-invitation-history .round-icon-btn {
  width: 33px;
  height: 33px;
  border-radius: 8px;
  background-color: white;
}

.earn-invitation-history .operation-tab {
  width: 100%;
  margin-top: 0px;
  border: 0 solid #e9e9f7;
  border-bottom-width: 1px;
}
