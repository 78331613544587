.cartview-btn .anchor {
    position: absolute;
    bottom: -6px;
    right: 40px;
    width: 10px;
    height: 16px;
    background-color: var(--color-cyan2);
    transform: rotate(45deg);
}

.cartview-btn .btn-container {
    width: 100%;
    height: 50px;
    padding-left: 20px;
    padding-right: 20px;
    border-radius: 12px;
    background-color: var(--color-cyan2);
}

.cartview-btn .desc-text {
    font-size: 10px;
    font-family: Yellix-Medium, serif;
    color: white;
}

.cartview-btn .total-price {
    margin-right: 8px;
    font-size: 18px;
    font-family: Yellix-SemiBold, serif;
    color: white;
}

.cartview-btn .badge {
    padding: 3px;
    border-radius: 20px;
    background-color: white;
    min-width: 20px;
}

.cartview-btn .badge-text {
    font-size: 14px;
    line-height: 16px;
    font-family: Yellix-SemiBold, serif;
    color: var(--color-cyan2);
}