.earninvite-snapfooders-screen {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.earninvite-snapfooders-screen .scrollview {
  flex: 1;
  width: 100%;
  background-color: white;
  margin-top: 20px;
}

.earninvite-snapfooders-screen .searchview {
  width: 100%;
  margin-top: 15px;
}

.earninvite-snapfooders-screen .header-view {
  width: 100%;
  margin: 15px 0px;
}

.earninvite-snapfooders-screen .title{
  text-align: center;
  font-size: 20px;
  font-family: Yellix-Bold, serif;
  color: var(--color-text);
}

.earninvite-snapfooders-screen .round-icon-btn {
  width: 33px;
  height: 33px;
  border-radius: 8px;
  background-color: white;
}