.orders-filter h1 {
  font-size: 22px;
  font-family: Yellix-ExtraBold, serif;
  color: var(--color-text);
}

.orders-filter .list-btn {
  background-color: var(--color-gray8) !important;
  color: var(--color-text);
  height: 48px;
  border-radius: 10px;
  margin-bottom: 15px;
}

.orders-filter .list-btn span {
  font-size: 16px;
  font-family: Yellix-Bold, serif;
  color: var(--color-text);
}

.orders-filter .round-icon-btn {
  display: none !important;
}

@media (max-width: 767px) {
  .orders-filter .search-input {
    height: 48px !important;
  }

  .orders-filter h1 {
    visibility: hidden;
    height: 0;
  }

  .orders-filter .round-icon-btn {
    display: flex !important;
    border-color : var(--color-gray5)
  }

  .orders-filter .filter-body {
    display: none !important;
  }
}