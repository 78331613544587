.view-vendors .header-bg {
  width: 100%;
  height: 180px;
  margin-top: -80px;
  background-image: url('../../assets/svgs/blog/blog_header_bg.svg');
  background-repeat: no-repeat;
  background-size: cover;
}

.view-vendors .header-bg h4 {
  font-family: Yellix-Bold, serif;
  color: white;
  font-weight: normal;
  margin: 0;
  text-align: center;
}

.view-vendors .main {
  padding-left: 104px;
  padding-right: 104px;
}

.view-vendors .main .vendors-body {
  width: 100%;
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;
  background-color: white;
}

.view-vendors .search-view {
  margin-top: -50px;
  padding-left: 104px;
  padding-right: 104px;
  padding-bottom: 20px;
}

.view-vendors .main .vendors-body .featured-block-title {
  color: var(--color-text);
  font-family: Yellix-Bold, serif;
  font-size: 26px;
  margin-bottom: 20px;
}

@media (max-width: 1400px) {
  .view-vendors .vendor-item-wrap {
    width: 50% !important;
    max-width: 50% !important;
    flex: none !important;
  }

  .view-vendors .search-view {
    padding-left: 40px;
    padding-right: 40px;
  }

  .view-vendors .main {
    padding-left: 40px;
    padding-right: 40px;
  }
}

@media (max-width: 992px) {
  .view-vendors .header-bg {
    height: 180px;
  }

  .view-vendors .vendor-item-wrap {
    width: 100% !important;
    max-width: 100% !important;
    flex: none !important;
  }

  .view-vendors .search-view {
    padding-left: 20px;
    padding-right: 20px;
  }

  .view-vendors .main {
    padding-left: 20px;
    padding-right: 20px;
  }

  .view-vendors .featured-vendor-list {
    flex-wrap: nowrap !important;
    overflow-x: auto;
  }
}

.view-vendors .featured-mobile-view  {
  display: none;
}

@media screen and (max-width: 576px) {
  .header-bg {
    padding: 0px 15% 0px 15%;
  }
  .view-vendors .header-bg h1 {
    padding-bottom: 20px;
  }

  .view-vendors .main .vendors-body .featured-block-title {
    font-size: 22px;
  }

  .view-vendors .featured-vendor-list {
    display: none !important;
  }

  .view-vendors .featured-mobile-view {
    display: inherit;
  }
}