.food-details-screen {
    padding: 0px;
}

.food-details-screen .MuiDialog-container {
    width: 100%;
    max-width: calc(100% - 64px);
    padding-top: 80px;
}

.food-details-screen .MuiDialog-paper {
    width: 100%;
    margin: 0 !important;
    border-radius: 12px;
}

.food-details-screen .header {
    position: absolute;
    top: 20px;
    left: 0px;
    right: 0px;
    height: 50px;
    width: 100%;
    align-items: flex-end;
}

.food-details-screen .subject-title {
    margin-top: 24px;
    margin-bottom: 12px;
    font-size: 16px;
    font-family: Yellix-SemiBold, serif;
    color: var(--color-text);
}

.food-details-screen .info-view {
    width: 100%;
    align-items: flex-start;
    padding-bottom: 20px;
    background-color: white;
    border-bottom-color: var(--color-gray9);
    border-bottom-width: 1px;
    border-bottom-style: solid;
}

.food-details-screen .info-view h2 {
    font-size: 18px;
    font-family: Yellix-Bold, serif;
    color: var(--color-text);
    margin: 0;
}

.food-details-screen .info-view p {
    font-size: 14px;
    font-family: Yellix-Medium, serif;
    color: var(--color-gray7);
    text-align: left;
    line-height: 14px;
    margin-top: 8px;
    margin-bottom: 9px;
}

.food-details-screen .option-item {
    height: 40px;
    width: 100%;
}

.food-details-screen .option-item p {
    flex: 1;
    font-size: 16px;
    font-family: Yellix-Medium, serif;
    color: var(--color-text);
}

.food-details-screen .cart-btns {
    width: 100%;
}

@media (max-width: 576px) {
    .food-details-screen .MuiDialog-container {
        max-width: calc(100% - 20px);
    }

    .food-details-screen .MuiDialog-paper {
        border-radius: 6px;
        max-height: calc(100% - 20px);
    }

    .food-details-screen .header {
        top: 10px;
    }

    .food-close-btn {
        margin-left: 10px;
    }
    .social-stripe {
        margin-right: 10px;
    }
}