
/* add card modal */
.vendor-filter-modal {
    width: 676px;
}

.vendor-filter-modal .MuiDialog-paper {
    border-radius: 16px;
    width: 560px;
    max-width: calc(100vw - 32px) !important;
}

.vendor-filter-modal .content {
    background-color: white;
    padding: 26px 0px;
    overflow: hidden;
}

.vendor-filter-modal .content h1.title {
    font-size: 26px;
    font-family: Yellix-Bold, serif;
    color: var(--color-text);
    margin: 0px;
    text-align: center;
}

.vendor-filter-modal .content img.close-btn {
    width: 40px;
    height: 40px;
    object-fit: contain;
    position: absolute;
    left: 15px;
    top: 0;
    cursor: pointer;
}

.vendor-filter-modal .save-btn {
    width: 100%;
    background-color: var(--color-cyan2) !important;
    color: white;
}

.vendor-filter-modal {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100vh;
}

.vendor-filter-modal  .header {
    height: 55px;
    display: flex;
    align-items: flex-end;
    padding-left: 20px;
    padding-right: 20px;
}

.vendor-filter-modal .scroll_view {
    flex: 1;
    width: 100%;
    background-color: white;
    margin-top: 10px;
    overflow-y: auto;
}


.vendor-filter-modal .list-btn {
    background-color: var(--color-gray8) !important;
    color: var(--color-text);
    height: 48px;
    border-radius: 10px;
    margin-bottom: 15px;
    margin-top: 15px;
}

.vendor-filter-modal .MuiList-root {
    padding-bottom: 0 !important;
    padding-top: 0 !important;
}

.vendor-filter-modal .MuiAccordion-root {
    box-shadow: none;
    margin: 0 !important;
}

.vendor-filter-modal .list-btn span,
.vendor-filter-modal .MuiAccordionSummary-content span {
    font-size: 16px;
    font-family: Yellix-Bold, serif;
    color: var(--color-text);
}

.vendor-filter-modal .MuiAccordion-root,
.vendor-filter-modal .MuiCollapse-root {
    background-color: var(--color-gray8);
    border-radius: 10px;
}

.vendor-filter-modal .MuiAccordion-root::before {
    opacity: 0;
}

.vendor-filter-modal .sort-btn {
    margin-bottom: 20px;
}

.vendor-filter-modal .sort-btn span {
    font-size: 14px;
    font-family: Yellix-SemiBold, serif;
    color: var(--color-text);
}

.vendor-filter-modal .MuiSlider-valueLabel {
    border-radius: 20px;
    background-color: var(--color-cyan2);
    color: white;
    font-size: 11px;
    padding: 2px 10px;
    font-family: Yellix-Medium, serif;
}

.vendor-filter-modal .MuiSlider-root {
    width: calc(100% - 40px);
}

.vendor-filter-modal .MuiSlider-rail {
    color: var(--color-gray7);
}

.vendor-filter-modal .MuiSlider-track {
    color: var(--color-cyan2);
}

.vendor-filter-modal .MuiSlider-thumb {
    color: var(--color-cyan2);
    width: 16px;
    height: 16px;
}