.our-app-intro {
    width: 100%;
    height: 100vh;
    margin-top: 36px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    background-image: url('/src/assets/images/app-intro.png');
    /* background-color: #f9f9f9; */
    clip-path: polygon(0 0, 100% 0%, 100% 100%, 0 91%);
}

.our-app-intro .content {
    max-width: 1216px;
    width: 100%;
    margin: auto;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}

.our-app-intro .content .craving-something {
    font-family: 'Yellix-Bold';
    font-size: 32px;
    color: var(--color-text);
    margin: 0;
    display: flex;
    flex-direction: column;
    margin-bottom: 100px;
}

.our-app-intro .content .craving-something span {
    font-family: 'Yellix-Bold';
    color: #222222;
}

.our-app-intro .content h4 {
    font-family: Yellix-SemiBold, serif;
    color: var(--color-text);
}

.our-app-intro .content .craving-something {
    color: var(--color-cyan2);
    padding-right: 10px;
}

.our-app-intro .content img {
    width: 240px;
    height: 80px;
    object-fit: contain;
}

@media (max-width: 1200px) {
    .our-app-intro {
        height: 85vh;
    }

    .our-app-intro .content img {
        width: 210px;
        height: 70px;
        object-fit: contain;
    }
}

@media screen and (max-width: 872px) {
    .our-app-intro {
        height: 68vh;
        background-position: 67%;
    }

    .our-app-intro .align-middle {
        flex-direction: column;
        justify-content: flex-start;
    }

    .our-app-intro .content a {
        margin-bottom: 20px;
    }

    .our-app-intro .content img {
        width: 180px;
        height: 60px;
        object-fit: contain;
    }

    .our-app-intro .badge-android {
        margin-left: 0;
    }
}

@media screen and (max-width: 768px) {

    .our-app-intro {
        background-image: url('/src/assets/images/app-intro-mobile.png');
        margin-top: 0;
    }
    .our-app-intro .content {
        width: 100%;
        text-align: left;
        justify-content: flex-start;
    }

    .our-app-intro .content div {
      display: flex;
      width: 100%;
      flex-direction: column;
      align-items: center;
    }

    .our-app-intro .content .craving-something {
        font-size: 19px;
        display: block;
        text-align: center;
    }

    .our-app-intro .content a {
        display: block;
        margin-bottom: 30px;
    }
    .our-app-intro .content img {
        width: 150px;
        height: 50px;
    }
}
