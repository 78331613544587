.view-download-app .food-tabs {
    margin-left: auto;
    margin-right: auto;
    max-width: 1450px;
    display: flex;
    align-items: center;
}

.view-download-app .food-tabs-left {
    flex: 0 0 auto;
    width: 50%;
    height: 600px;
    background-image: url('/src/assets/images/ourapp/3tabs.png');
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
}

.view-download-app .food-tabs-right {
    flex: 0 0 auto;
    width: 50%;
    padding-left: 50px;
}

.view-download-app .food-tabs-right h2 {
    font-family: Yellix-Bold;
    letter-spacing: 0px;
    color: #222222;
    opacity: 1;
    margin-top: 0px;
}

.view-download-app .tab-section {
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 458px;
}

.view-download-app .tab-img {
    margin-right: 32px;
}

.view-download-app .tab-img img {
    width: 83px;
}

.view-download-app .food-tabs-right h5 {
    font-family:  Yellix-Bold;
    letter-spacing: 0px;
    color: #222222;
    opacity: 1;
    margin-top: 0px;
    margin-bottom: 21px;
}

.view-download-app .food-tabs-right p {
    margin: 0px;
    font-family:  Yellix-Medium;
    font-size: 20px;
    letter-spacing: 0px;
    color: #222222;
    opacity: 1;
}

@media screen and (max-width: 1100px) {
    .view-download-app .food-tabs {
        padding-left: 20px;
        padding-right: 20px;
    }

    .view-download-app .food-tabs-right {
        padding-left: 10px;
        padding-right: 10px;
    }

    .view-download-app .food-tabs-left {
        height: 530px;
    }
}

@media screen and (max-width: 830px) {
    .view-download-app .food-tabs-right p {
        font-size: 16px;
    }
}

@media screen and (max-width: 768px) {

    .view-download-app .food-tabs-left,
    .view-download-app .food-tabs-right {
        width: 100%;
    }

    .view-download-app .food-tabs {
        flex-direction: column;
    }

    .view-download-app .food-tabs-left {
        height: 380px;
    }

    .view-download-app .tab-section {
        max-width: none;
        justify-content: start;
    }

    .view-download-app .food-tabs-right h2 {
        margin-top: 25px;
    }
}

@media  screen and (max-width: 576px) {
    .view-download-app .food-tabs-left {
        margin-top: 0;
        height: 280px;
    }
}