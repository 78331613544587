.order-faqs {
  width: 100%;
  padding-top: 20px;
  padding-bottom: 20px;
}

.order-faqs .order-faq .question p {
  font-size: 18px;
}

.order-faqs .order-faq .answer p {
  font-size: 16px;
}
