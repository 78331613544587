.no_promolist_view {
    flex: 1;
    margin-top: 120px;
}

.no_promolist_view .description {
    color: var(--color-text);
    line-height: 24px;
    font-family: Yellix-Medium, serif;
    font-size: 16px;
    text-align: center;
    padding-left: 20px;
    padding-right: 20px;
}