.new-conv-modal .MuiDialog-container {
}

.new-conv-modal .MuiDialog-paper {
    margin: 0 !important;
    border-radius: 30px;
}

.new-conv-modal .content {
}

.new-conv-modal .content h1{
    font-size: 18px;
    font-family: Yellix-Bold, serif;
    color: var(--color-text);
}