.footer,
.footer-dark-img {
  background-image: url('../../assets/svgs/footer/footer_bg.svg');
  background-repeat: no-repeat;
  background-size: cover;
}

.footer {
  /*-webkit-clip-path: polygon(0 0, 100% 15%, 100% 100%, 0 100%);*/
  /*clip-path: polygon(0 0, 100% 15%, 100% 100%, 0 100%);*/
  position: relative;
  padding-bottom: 40px;
}

.footer.landing {
  margin-top: -100px;
}

/*.footer:after {*/
/*  !*-webkit-clip-path: polygon(0 0, 100% 15%, 100% 100%, 0 85%);*!*/
/*  !*clip-path: polygon(0 0, 100% 15%, 100% 100%, 0 85%);*!*/
/*  content: '';*/
/*  position: absolute;*/
/*  left: 0;*/
/*  top: -82%;*/
/*  right: 0;*/
/*  height: 100%;*/
/*  display: block;*/
/*  z-index: 9;*/
/*}*/

.footer > div {
  padding-top: 150px;
  padding-bottom: 40px;
}

.footer img.logo {
  height: 36px;
  width: 100%;
  object-fit: contain;
}

.footer h4.subject {
  font-size: 20px;
  font-family: Yellix-SemiBold, serif;
  color: var(--color-cyan2);
  margin-top: 0 !important;
  margin-bottom: 24px !important;
}

.footer ul {
  padding: 0;
  margin: 0;
}

.footer li {
  list-style: none;
  margin-bottom: 24px;
}

.footer a {
  font-size: 18px;
  font-family: Yellix-Medium, serif;
  color: white !important;
  text-decoration: none;
  background-color: transparent;
}

.footer a :hover {
  color: #0d5bdd;
  text-decoration: underline;
}

.footer .social a {
  margin-right: 24px;
}

.footer .lang-link {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

@media screen and (max-width: 850px)  {
  .footer,
  .footer-dark-img {
    background-color: transparent !important;
  }
}

@media screen and (max-width: 768px) { 

  .footer img.logo {
    margin-bottom: 50px;
  }

  .footer.landing {
    margin-top: -44px;
  }

  .footer h4.subject {
    font-size: 25px;
    margin-top: 19px !important;
  }


.footer > div {
  padding-top: 100px;
}
}