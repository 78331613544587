declined-status.order-stepper {
}

.order-stepper .step img {
  width: 24px;
  height: 24px;
  object-fit: contain;
  margin-right: 6px;
}

.order-stepper .step .name {
  font-family: Yellix-Bold;
  font-size: 18px;
}

.order-stepper .step .desc {
  font-family: Yellix-SemiBold;
  font-size: 13px;
  max-width: 300px;
  margin-top: 4px;
}


.order-stepper .step .divider {
  border-top-width: 1px;
  border-top-style: dashed;
  margin-left: 25px;
  margin-right: 15px;
}

.order-stepper .step.active .name {
  color: #000;
}

.order-stepper .step.inactive .name {
  color: var(--color-gray7);
}

.order-stepper .step.active .desc {
  color: var(--color-text);
}

.order-stepper .step.inactive .desc {
  color: var(--color-gray7);
}

.order-stepper .step.active .divider {
  border-top-color: var(--color-cyan2);
}

.order-stepper .step.inactive .divider {
  border-top-color: var(--color-gray7);
}

.order-stepper .step .divider{
  min-width: 30px;
}

@media (max-width: 900px) {
  .order-stepper {
    flex-direction: column;
  }
  .order-stepper .step {
    width: 100%;
    justify-content: flex-start;
  }
  .order-stepper .step .divider{
    display: none;
  }
}