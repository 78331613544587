.promotion-item {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  background: var(--color-gray8);
  border-radius: 15px;
  margin-bottom: 16px;
  cursor: pointer;
  padding: 12px;
}

.promotion-item .title {
  font-size: 18px;
  font-family: Yellix-Bold, serif;
  color: var(--color-text);
}

.promotion-item .date_limit {
  font-size: 14px;
  font-family: Yellix-SemiBold, serif;
  color: var(--color-red1);
  margin-bottom: 3px;
}

.promotion-item .sub_title {
  font-size: 16px;
  font-family: Yellix-SemiBold, serif;
  color: var(--color-text);
}

.promotion-item .description {
  font-size: 14px;
  font-family: Yellix-Medium, serif;
  color: var(--color-gray7);
  text-overflow: ellipsis;
  word-wrap: break-word;
  overflow: hidden;
  max-height: 2.4em;
  line-height: 1.2em;
  margin-top: 8px;
}

.promotion-item .food-img {
  background-color: white;
  border-width: 1px;
  border-style: solid;
  border-color: var(--color-gray9);
  border-radius: 10px;
  margin-left: 12px;
}


.promotion-item .food-img img {
  width: 56px;
  height: 56px;
  object-fit: cover;
  border-radius: 12px;
}