.view-chat {
  border-top: 1px solid #e9e9f7;
}
.view-chat .tabs-chat {
  display: flex;
}
.view-chat .tabs-chat .lis {
  padding-right: 30px;
  border-right: 1px solid #e9e9f7;
}
.view-chat .tabs-chat .MuiTabs-flexContainer {
  border-top: 0 !important;
}
.view-chat .tabs-chat .MuiTabs-flexContainer {
  flex-direction: column;
}
.view-chat .tabs-chat .lis .MuiButtonBase-root {
  padding: 40px 0px !important;
  font-size: 16px !important;
}
.view-chat .tabs-chat .lis .Mui-selected svg path {
  fill: #50B7ED !important;
}
.view-chat .tabs-chat .lis .Mui-selected:after {
  display: none;
}

.view-chat .left-side {
  border-right: 1px solid #e9e9f7;
  padding-left: 0 !important;
  padding-top: 0 !important;
}