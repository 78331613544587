/* map marker */
.map-marker {
  position: relative;
}

.map-marker .marker-body {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.map-marker img {
  cursor: pointer;
}

.map-marker .popup {
  position: absolute;
  background-color: white;
  text-align: center;
  z-index: 2;
  opacity: 1;
  transition: opacity 0.6s;
  box-shadow: 0 0 17px 2px rgb(2 2 2 / 15%) !important;
}

.map-marker .popup::after {
  content: '';
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -15px;
  border-width: 15px;
  border-style: solid;
  border-color: white transparent transparent transparent;
}

/* end map marker */

/* vendor marker */
.vendor-marker .new_tag {
  position: absolute;
  top: -5px;
  width: 36px;
  font-size: 10px;
  text-align: center;
  font-family: Yellix-Medium, serif;
  background-color: var(--color-red1);
  color: white;
  border-radius: 6px;
  z-index: 1;
}
.vendor-marker .fav_tag {
  position: absolute;
  top: -5px;
  right: -18px;
  background-color: var(--color-cyan2);
  border-radius: 7px;
  z-index: 1;
  width: 15px;
  height: 15px;
}

.vendor-marker .vendor-marker-info {
}

.vendor-marker .vendor-marker-info img {
  width: 38px;
  height: 38px;
  object-fit: contain;
  margin-right: 10px;
}

.vendor-marker .vendor-marker-info p {
  font-size: 14px;
  font-family: Yellix-Bold;
  color: var(--color-text);
  flex-wrap: nowrap;
  overflow-wrap: unset;
  text-align: left;
  margin-top: 0px;
  margin-bottom: 3px;
}

.vendor-marker .vendor-marker-info .rate-item {
  font-size: 12px;
  font-family: Yellix-Medium, serif;
  color: var(--color-gray7);
  margin-top: 4px;
}

.vendor-marker .popup {
  top: -90px;
  border-radius: 8px;
  padding: 12px 18px;
}

.vendor-marker .popup .delivery_range_indicator {
  font-size: 12px;
  font-family: Yellix-Medium, serif;
  margin-top: 4px;
  min-width: 150px;
}

/* end of vendor marker */

/* snapfooder marker */
.snapfooder-marker .user-info img.avatar {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  object-fit: contain;
  margin-right: 10px;
}

.snapfooder-marker .user-info p {
  font-size: 14px;
  font-family: Yellix-Bold, serif;
  color: var(--color-text);
  flex-wrap: nowrap;
  overflow-wrap: unset;
  margin: 0 !important;
}

.snapfooder-marker .popup {
  top: -60px;
  border-radius: 8px;
  padding: 8px 12px;
}

.snapfooder-marker .popup .chat-link {
  border-left-width: 1px;
  border-left-style: solid;
  border-left-color: var(--color-gray5);
  padding-left: 16px;
  cursor: pointer;
}

.snapfooder-marker .user-info .chat-link span {
  font-size: 14px;
  font-family: Yellix-SemiBold, serif;
  color: var(--color-cyan2);
}

.snapfooder-marker .popup img.zodiac {
  width: 12px;
  height: 12px;
  object-fit: contain;
  margin-left: 8px;
  margin-right: 12px;
}

/* end of snapfooder marker */

/* user marker */
.user-marker .popup {
  top: -60px;
  border-radius: 8px;
  width: 75px;
  height: 45px;
}

.user-marker .user-info p {
  font-size: 14px;
  font-family: Yellix-Bold, serif;
  color: var(--color-text);
  flex-wrap: nowrap;
  overflow-wrap: unset;
  margin: 0 !important;
}

/* end user marker */

/* group marker */
.snapfooder-group-marker {
  width: 36px;
  height: 36px;
  background-color: var(--color-primary);
  border-radius: 18px;
  cursor: pointer;
}

.snapfooder-group-marker .marker-text {
  font-size: 16px;
  font-family: Yellix-Bold, serif;
  color: white;
}
/* end group marker */