.wallet-transaction-item {
    width: 100%;
    align-items: flex-start;
    border-radius: 15px;
    background-color: var(--color-gray8);
    padding: 15px;
    cursor: pointer;
    margin-bottom: 12px;
    display: flex;
}

.wallet-transaction-item .pay_method_txt {
    font-size: 15px;
    font-family: Yellix-Medium, serif;
    color: var(--color-gray7);
    margin-top: 4px;
    margin-bottom: 4px;
}

.wallet-transaction-item .price {
    font-size: 17px;
    font-family: Yellix-Bold, serif;
    color: var(--color-text);
    margin: 0 !important;
}

.wallet-transaction-item .title {
    font-size: 17px;
    font-family: Yellix-SemiBold, serif;
    color: var(--color-text);
    margin: 0 !important;
}
