.order-review-item {
    width: 100%;
    border-top-width: 1px;
    border-top-color: var(--color-gray9);
    border-top-style: solid;
}

.order-review-item h3 {
    font-size: 16px;
    font-family: Yellix-SemiBold, serif;
    color: var(--color-text);
    margin-top: 20px;
    margin-bottom: 25px;
}

.order-review-item p {
    font-size: 16px;
    font-family: Yellix-Medium, serif;
    color: var(--color-text);
    margin-top: 0;
    margin-bottom: 6px;
}