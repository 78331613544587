.auth-screen {
  margin-top: 120px;
  width: 552px !important;
  max-width: calc(100vw - 32px) !important;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.auth-screen .logo {
  width: 310px;
  height: 48px;
  object-fit: contain;
}

.auth-screen > div {
  width: 100%;
}

.lang-bar {
  position: absolute;
  right: 40px;
  top: 40px;
}

.auth-form {
  width: 100%;
  padding-top: 36px;
}

.auth-form h1 {
  font-size: 28px;
  font-family: Yellix-ExtraBold;
  text-align: center;
}

.auth-form h3 {
  font-size: 18px;
  font-family: Yellix-SemiBold;
  margin-top: 24px;
  margin-bottom: 31px;
  text-align: center;
  padding-left: 20px;
  padding-right: 20px;
}
