.checkout-screen {
}

.checkout-screen .subject-title {
  font-family: Yellix-SemiBold, serif;
  color: var(--color-text);
}

.checkout-screen .proceed-btn {
  background-color: var(--color-cyan2) !important;
}

.checkout-screen .handover-view .h2 {
  font-size: 32px !important;
  font-family: normal;
  font-family: Yellix-Bold, serif;
  color: var(--color-text);
  margin: 0px;
  text-align: center;
}

.checkout-screen .radio-legal-age {
  font-size: 15px !important;
  font-family: normal;
  font-family: Yellix-Medium, serif;
  color: var(--color-text);
  justify-content: flex-start;
}

.checkout-screen .error_msg_view {
  width: 100%;
  padding: 12px;
  background-color: white;
  justify-content: center;
  align-items: center;
  display: flex;
  border-radius: 12px;
  border-width: 1px;
  border-color: var(--color-red1);
  border-style: dashed;
  font-size: 14px !important;
  font-family: normal;
  font-family: Yellix-SemiBold, serif;
  color: var(--color-red1);
}

.checkout-screen .left-view {
  padding-left: 80px !important;
  padding-right: 40px !important;
}

.checkout-screen .right-view {
  padding-left: 40px !important;
  padding-right: 80px !important;
}

@media (max-width: 1200px) {
  .checkout-screen .left-view {
    padding-left: 40px !important;
    padding-right: 20px !important;
  }

  .checkout-screen .right-view {
    padding-left: 20px !important;
    padding-right: 40px !important;
  }
}

@media (max-width: 992px) {

}

.checkout-screen .hide-md {
  display: none;
}


@media (max-width: 768px) {
  .checkout-screen .left-view {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }

  .checkout-screen .right-view {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }

  .checkout-screen .hide-md {
    display: inherit;
  }
  .checkout-screen .show-md {
    display: none;
  }
}

@media (max-width: 576px) {
  .checkout-screen .left-view {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }

  .checkout-screen .right-view {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
}