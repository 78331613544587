.date-selector {
  width: 100%;
  height: 48px;
}

.date-selector .picker-item {
  height: 100%;
  padding-left: 8px;
  padding-right: 8px;
  background: white;
  border: 1px solid var(--color-gray5);
  border-radius: 12px;
}

.date-selector .picker-item select {
  height: 100%;
  width: 100%;
  border: none;
  outline: none;
  padding-top: 2px;
  font-family: Yellix-Medium, serif;
  font-size: 14px;
}
