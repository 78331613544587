.home-app-features {
  justify-content: space-between !important;
  background-color: var(--color-gray9);
  -webkit-clip-path: polygon(0 12%, 100% 0%, 100% 100%, 0% 100%);
  clip-path: polygon(0 12%, 100% 0%, 100% 100%, 0% 100%);
}

.home-app-features .content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.home-app-features h2 {
  font-family: Yellix-Bold, serif;
  color: var(--color-text);
  margin-top: 20px;
}

.home-app-features h2 span,
.home-app-features h5 span
{
  color: var(--color-cyan2);
}

.home-app-features h5 {
  font-family: Yellix-SemiBold, serif;
  color: var(--color-text);
  margin-top: 40px;
  margin-bottom: 40px;
}

.home-app-features .content .craving-something {
  color: var(--color-cyan2);
  padding-right: 10px;
}


.home-app-features .desktop-only {
  display: flex;
}

.home-app-features .mobile-only {
  display: none;
}

@media (max-width: 1200px) {

}

@media (max-width: 992px) {
  .home-app-features {
    flex-wrap: wrap;
    -webkit-clip-path: polygon(0 2%, 100% 0%, 100% 100%, 0% 100%);
    clip-path: polygon(0 2%, 100% 0%, 100% 100%, 0% 100%);
  }
  .home-app-features .left-side {
    width: 100%;
  }
  .home-app-features .right-side {
    width: 100%;
  }
  .home-app-features h2,
  .home-app-features h5
  {
    width: 100%;
    text-align: center;
  }
}

@media (max-width: 768px) {
  .home-app-features .left-side {
    width: 100%;
  }
  .home-app-features .right-side {
    width: 100%;
    flex-wrap: wrap;
  }
  .home-app-features .right-side > div {
    width: 100%;
  }

  .home-app-features .desktop-only {
    display: none;
  }

  .home-app-features .mobile-only {
    display: flex;
  }
}

@media (max-width: 576px) {
  .home-app-features {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }

  .home-app-features h5 {
    margin-top: 20px;
  }
}