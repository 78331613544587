.shopinfo-view {
}

.shopinfo-view img.vendor-logo {
  width: 80px;
  height: 80px;
  border-width: 1px;
  border-radius: 15px;
  border-style: solid;
  border-color: var(--color-gray9);
  background-color: white;
}

.shopinfo-view div.right {
  margin-left: 8px;
  justify-content: center;
  align-items: flex-start;
}

.shopinfo-view h1 {
  margin-right: 8px;
  font-size: 20px;
  font-family: Yellix-Bold, serif;
  color: white;
  margin-top: 0;
  margin-bottom: 0;
  font-weight: normal;
}

.shopinfo-view h3 {
  margin-top: 4px;
  margin-bottom: 4px;
  font-size: 14px;
  font-family: Yellix-Medium, serif;
  color: white;
  font-weight: normal;
}

.shopinfo-view .open-close-badge {
  border-radius: 3px;
  background-color: var(--color-red1);
  padding: 4px 6px;
  margin-left: 8px;
}

.shopinfo-view .open-close-badge > div {
  width: 7px;
  height: 7px;
  border-radius: 4px;
}
.shopinfo-view .open-close-badge > span {
  font-size: 12px;
  font-family: Yellix-Medium, serif;
  color: white;
  padding-left: 4px;
}

.shopinfo-view .tag-item {
  border-radius: 3px;
  background-color: #7b7462;
  padding: 4px 6px;
  margin-right: 8px;
  font-size: 12px;
  font-family: Yellix-Medium, serif;
  color: white;
}
