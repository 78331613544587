.cart-item {
  width: 100%;
  align-items: flex-start !important;
  margin-bottom: 5px;
  cursor: pointer;
  padding: 10px 0px;
  height: 86px;
}

.cart-item .qty {
  font-size: 14px;
  font-family: Yellix-SemiBold, serif;
  color: var(--color-red1);
  padding: 5px 0px;
  border-radius: 5px;
}

.cart-item .middle-side {
  border-left-width: 1px;
  border-left-style: solid;
  border-left-color: var(--color-gray6);
  padding-left: 15px;
  margin-left: 10px;
  height: 100%;
}

.cart-item h3 {
  font-size: 16px;
  font-family: Yellix-SemiBold, serif;
  color: var(--color-text);
  margin: 0 !important;
}

.cart-item .middle-side p {
  font-size: 15px;
  font-family: Yellix-Medium, serif;
  color: var(--color-gray7);
  margin-top: 9px;
  margin-bottom: 0;
}

.cart-item .right {
  justify-content: space-between;
  align-items: flex-end;
  display: flex;
  flex-direction: column;
  height: 100%;
}
