.courier-screen .apply-form {
  width: 620px !important;
  max-width: calc(100vw - 32px) !important;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;
  border-radius: 16px;
  box-shadow: 0 0 25px rgba(66, 133, 244, 0.1) !important;
  padding: 20px;
}

.courier-screen .apply-form h2 {
  font-size: 18px;
  font-family: Yellix-Bold, serif;
  color: var(--color-text);
}

.courier-screen .apply-form .row {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  padding: 8px 0px;
}

.courier-screen .apply-form .row div.label {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 48px;
  width: 20%;
  font-size: 15px;
  font-family: Yellix-SemiBold, serif;
  color: var(--color-text);
  margin-right: 16px;
}


.courier-screen .apply-form .row div.input-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
}

.courier-screen .apply-form .row div.input-wrapper label {
  margin-right: 50px;
}

.courier-screen .apply-form .row div.input-wrapper .MuiFormControlLabel-label {
  font-size: 14px;
  font-family: Yellix-Medium, serif;
  color: var(--color-text);
}

.courier-screen .apply-form .terms-txt {
  font-size: 13px;
  font-family: Yellix-Medium, serif;
  color: var(--color-gray1);
}
.courier-screen .apply-form .terms-txt a {
  color: var(--color-text);
}

.courier-screen .apply-form .login-txt {
  font-size: 13px;
  font-family: Yellix-Medium, serif;
  color: var(--color-gray1);
}
.courier-screen .apply-form .login-txt a {
  color: var(--color-cyan2);
  text-decoration-line: none;
}

.courier-screen .apply-form .courier-apply-form .auth-input {
  border-width: 1px;
  height: 48px;
}

.courier-screen .apply-form .courier-apply-form .auth-input input {
  font-size: 14px !important;
  font-family: Yellix-SemiBold, serif !important;
  line-height: 20px;
}

.courier-screen .apply-form .auth-btn {
  background-color: var(--color-text) !important;
}

@media (max-width: 576px) {
  .courier-screen .apply-form .row div.label {
    width: 100%;
  }
}

