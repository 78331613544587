/* map marker */
.map-marker {
    position: relative;
}

.map-marker .marker-body {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.map-marker img {
    cursor: pointer;
}

.map-marker .popup {
    position: absolute;
    background-color: white;
    text-align: center;
    z-index: 2;
    opacity: 1;
    transition: opacity 0.6s;
    box-shadow: 0 0 17px 2px rgb(2 2 2 / 15%) !important;
}

.map-marker .popup::after {
    content: '';
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -15px;
    border-width: 15px;
    border-style: solid;
    border-color: white transparent transparent transparent;
}

/* end map marker */

.location-marker img {
    width: 42px;
    height: 42px;
    object-fit: contain;
    cursor: pointer;
}
