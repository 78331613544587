.location-msg-option-modal .MuiDialog-paper {
  margin: 0 !important;
  border-radius: 30px;
}

.location-msg-option-modal .content {
  padding: 20px 20px 30px;
  background-color: white;
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
}

.location-msg-option-modal .content h1 {
  font-size: 18px;
  font-family: Yellix-Bold, serif;
  color: var(--color-text);
  text-align: center;
  margin: 0;
  padding: 5px;
  width: 100%;
}

.location-msg-option-modal .content .btn-text {
  flex: 1;
  margin-left: 8px;
  font-size: 15px;
  font-family: Yellix-Medium, serif;
  color: var(--color-text);
}

.location-msg-option-modal .content .divider {
  width: 100%;
  height: 1px;
  background-color: var(--color-gray9);
}

.location-msg-option-modal .content img.close-btn {
  width: 32px;
  height: 32px;
  object-fit: contain;
  position: absolute;
  left: 0;
  top: 0;
  cursor: pointer;
}

.location-msg-option-modal .content .item-btn {
  border-radius: 8px;
  padding: 15px 16px;
  background-color: var(--color-gray8);
  margin-bottom: 12px;
}

.location-msg-option-modal .content .item-btn img {
  height: 15px;
  width: 15px;
  object-fit: contain;
}
