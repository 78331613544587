.payment-methods {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    background-size: cover;
    background-position: center;
}

.payment-methods .scrollview {
    width: 100%;
    background-color: white;
    overflow-y: auto;
    padding-left: 20px;
    padding-right: 20px;
}
