.invitation-hist-item {
  width: 100%;
  align-items: flex-start;
  border-radius: 15px;
  background-color: var(--color-gray8);
  padding: 16px 12px;
  margin-bottom: 16px;
}

.invitation-hist-item p {
  margin-top: 0;
  margin-bottom: 0;
}

.invitation-hist-item .name {
  font-size: 16px;
  font-family: Yellix-Medium, serif;
  color: var(--color-text);
}

.invitation-hist-item .invite-code {
  font-size: 16px;
  font-family: Yellix-Medium, serif;
  color: var(--color-text);
}

.invitation-hist-item .status {
  font-size: 15px;
  font-family: Yellix-SemiBold, serif;
}

.invitation-hist-item .date_limit {
  font-size: 15px;
  font-family: Yellix-SemiBold, serif;
  color: var(--color-text);
}