/* address map modal */

.get-app-modal .MuiDialog-paper {
  border-radius: 16px;
  width: 460px !important;
  max-width: calc(100vw - 32px) !important;
}

.get-app-modal .content {
  background-color: white;
  padding: 25px;
}

.get-app-modal .content img.close-btn {
  width: 40px;
  height: 40px;
  object-fit: contain;
  cursor: pointer;
}

.get-app-modal h2 {
  font-size: 24px;
  line-height: 32px;
  font-family: Yellix-Bold, serif;
  color: var(--color-text);
  margin: 0;
}

.get-app-modal .content h4{
  font-size: 20px;
  line-height: 28px;
  font-family: Yellix-Bold, serif;
  color: var(--color-text);
  width: 80%;
  text-align: center;
}

.get-app-modal .content p{
  font-size: 16px;
  line-height: 24px;
  font-family: Yellix-Medium, serif;
  color: var(--color-text);
}

.get-app-modal .badge-wrap{
  flex-wrap: wrap;
}

.get-app-modal .badge {
  width: 150px;
  height: 52px;
  background-repeat: no-repeat;
  display: table;
  margin: 0 12px;
  background-size: contain;
  margin-bottom: 10px;
}

.get-app-modal .badge-ios {
  background-image: url('../../../assets/svgs/home/app_store.svg');
}

.get-app-modal .badge-android {
  background-image: url('../../../assets/svgs/home/googleplay.svg');
}