.order-screen {
  margin-top: 120px;
}

.order-screen h2 {
  font-family: Yellix-ExtraBold, serif !important;
  color: var(--color-text);
}

.order-screen .tab-wrapper {
  width: 100%;
  justify-content: center !important;
  align-items: center !important;
  margin-left: 0px !important;
  margin-right: 0px !important;
  padding-top: 20px;
  padding-bottom: 20px;
  border-top-width: 1px;
  border-top-style: solid;
  border-top-color: var(--color-gray6);
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: var(--color-gray6);
}

@media (max-width: 1350px) {
  .order-screen .order-item-wrap {
    width: 50% !important;
    max-width: 50% !important;
    flex: none !important;
  }
}

@media (max-width: 992px) {
  .order-screen {
    margin-top: 100px;
  }

  .order-screen .order-item-wrap {
    width: 100% !important;
    max-width: 100% !important;
    flex: none !important;
  }
}
