.courier-screen {
  position: relative;
  z-index: 0;
}

.courier-screen section.main {
  background-image: url('../../assets/images/courier/courier-bg.png');
  position: relative;
  background-repeat: no-repeat !important;
  background-position: bottom !important;
  background-size: cover !important;
}

.courier-screen section.main .title-view {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  height: 100%;
}

.courier-screen section.main h1 {
  font-family: Yellix-ExtraBold, serif;
  color: var(--color-text);
}

.courier-screen section.main h4 {
  font-family: Yellix-SemiBold, serif;
  color: var(--color-text);
  margin: 0;
}

.faq-items {
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 8px;
  border: 1px red solid;
}

@media (min-width: 1440px) {
  .courier-screen section.main h1 {
    margin-top: -500px;
  }
}
