.cashback-row .subject-title {
  font-family: Yellix-SemiBold, serif;
  color: var(--color-text);
}
.checkout-screen .cashback-row .balance {
  margin-top: 4px;
  font-family: Yellix-SemiBold;
  color: var(--color-gray7);
}

.checkout-screen .cashback-row input,
.checkout-screen .rider-tip-view input {
  text-align: center;
}
