.view-vendor {
  position: relative;
}

.view-vendor .overlay-dark:after {
  height: 420px;
  z-index: 0;
  opacity: 0.2;
  background-color: black;
}

.view-vendor .vendor-bg {
  width: 100%;
  height: 420px;
  margin-top: 80px;
  padding-top: 18px;
  padding-bottom: 8px;
  display: flex;
  align-items: flex-end;
  background-repeat: no-repeat;
  background-size: cover;
}

.view-vendor .action-menu .past-order-link {
  background-color: var(--color-red1);
  text-decoration-line: none;
  color: white;
  border-radius: 6px;
  height: 32px;
  text-align: center;
  font-size: 16px;
  font-family: Yellix-Medium, serif;
  line-height: 20px;
  margin-right: 12px;
  padding: 4px 6px;
}

.view-vendor .order-methods-tabs {
  border-width: 1px;
  border-color: var(--color-gray6);
  border-style: solid;
  border-radius: 12px;
}

.view-vendor .order-methods-tabs .tab-btn-active {
  background-color: var(--color-text) !important;
  color: white;
}

.view-vendor .sticky-header {
  position: fixed;
  top: 80px;
  left: 0;
  z-index: 2;
}

.view-vendor .categ-list {
  width: 100%;
  background-color: white;
}

.view-vendor .cartbtn-view {
  position: fixed;
  bottom: 18px;
  left: 0;
  z-index: 1;
}

.view-vendor .cartbtn-view .cartview-btn {
  width: 576px;
  max-width: calc(100% - 40px);
}

.view-vendor .vendor-hscroll-menu .active-cat-item,
.view-vendor .vendor-hscroll-menu .inactive-cat-item
{
  font-size: 20px;
}

@media (max-width: 576px) {
  .view-vendor .common-tabs .tab-btn {
    font-size: 18px !important;
  }

  .view-vendor .categ-list {
    padding-left: 12px !important;
    padding-right: 12px !important;
  }

  /*.view-vendor .overlay-dark:after {*/
  /*  height: 340px;*/
  /*}*/

  /*.view-vendor .vendor-bg {*/
  /*  height: 340px;*/
  /*}*/
}