.messages-header {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 28px;
  background-color: #fffffff8;
  border: 0 solid #e9e9f7;
  border-bottom-width: 1px;
  z-index: 2;
}

.messages-header .popup-btn {
  padding-left: 5px;
  padding-right: 5px;
  padding-bottom: 12px;
  border-bottom-color: var(--color-gray9);
  border-bottom-style: solid;
  border-bottom-width: 1px;
}

.messages-header-popup-btn-text {
  flex: 1;
  margin-left: 10px;
  font-size: 16px;
  font-family: Yellix-SemiBold, serif;
  color: var(--color-text);
}

.messages-header .name {
  margin-right: 7px;
  margin-bottom: 5px;
  font-size: 18px;
  font-family: Yellix-Bold, serif;
  color: var(--color-text);
}

.messages-header .desc {
  font-size: 14px;
  font-family: Yellix-SemiBold, serif;
  color: var(--color-red1);
}

.messages-header .avatar {
  background-color: white;
  width: 42px;
  height: 42px;
  object-fit: cover;
  border-radius: 30px;
  margin-right: 8px;
}

.messages-header .message-header-menu {
  width: 42px;
  height: 42px;
}

.messages-header .round-icon-btn {
  display: none;
}

@media (max-width: 1200px) {
  .messages-header .round-icon-btn {
    display: inherit;
    width: 42px;
    height: 42px;
  }
}
