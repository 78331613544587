.earn-invitation-details {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.earn-invitation-details .header-view {
    width: 100%;
    margin: 15px 0px;
}

.earn-invitation-details .title{
    text-align: center;
    font-size: 20px;
    font-family: Yellix-Bold, serif;
    color: var(--color-text);
}

.earn-invitation-details .learnmore {
    font-size: 16px;
    font-family: Yellix-Medium, serif;
    color: var(--color-gray7);
    text-decoration-line: underline;
    text-align: center;
}


.earn-invitation-details .dot {
    width: 7px;
    height: 7px;
    margin-top: 10px;
    margin-right: 8px;
    border-radius: 3.5px;
    background-color: var(--color-cyan2);
}
.earn-invitation-details .tooltip-row {
    display: flex;
    width: 100%;
    margin-top: 12px;
}
.earn-invitation-details .tooltip-txt {
    font-size: 16px;
    font-family: Yellix-Medium, serif;
    color: var(--color-text);
}
