.messages-screen {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: calc(100vh - 100px);
  position: relative;
}

*:not(input,textarea) {
  -webkit-touch-callout:none;
  -webkit-user-select:none;
  -khtml-user-select:none;
  -moz-user-select:none;
  -ms-user-select:none;
  user-select:none;
}

.messages-screen .no-members-txt {
  margin-left: 30px;
  margin-right: 30px;
  margin-top: 30px;
  text-align: center;
  font-size: 18px;
  font-family: Yellix-SemiBold, serif;
  color: var(--color-gray7);
}

.messages-screen .web-scroll-view {
  padding: 0 0px;
}

.messages-screen .web-scroll-view > div {
  width: 100%;
}

.messages-screen .order-chat-default-messages .react-horizontal-scrolling-menu--item div {
  white-space: nowrap;
}

.message-context-menu .menu-item span {
  padding-left: 8px;
  font-size: 14px;
  font-family: Yellix-SemiBold, serif;
  color: var(--color-text);
}
