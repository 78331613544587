.home-app-features-item {
  width: 380px;
  height: 280px;
  max-width: calc(100vw - 32px);
  border-radius: 20px;
  padding: 22px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
  background: white;
}

.home-app-features-item img {
  width: 60px;
  height: 60px;
}

.home-app-features-item .title {
  font-size: 20px;
  font-family: Yellix-SemiBold;
  color: var(--color-text);
}

.home-app-features-item .desc {
  font-size: 16px;
  margin-top: 8px;
  font-family: Yellix-SemiBold;
  color: var(--color-placeholder);
}

.home-new-blocks-title{
  font-size: 30px;
  margin-top: 0!important;
}

.home-new-blocks-desc {
  font-size: 20px;
  margin-top: 20px!important;
  margin-bottom: 20px!important;
}


@media (max-width: 1440px) {
  .home-app-features-item {
    width: 330px;
  }
}

@media (max-width: 1200px) {
  .home-app-features-item {
    width: 280px;
  }
}

@media (max-width: 992px) {

}

@media (max-width: 768px) {
  .home-app-features-item {
    width: 100%;
    max-width: 100%;
  }
}

@media (max-width: 576px) {
  .home-app-features-item {
    height: 280px;
  }

  .home-app-features-item img {
    width: 54px;
    height: 54px;
  }

  .home-app-features-item {
    border-radius: 16px;
    padding: 18px;
  }
  .home-app-features-item .title {
    font-size: 18px;
  }

  .home-app-features-item .desc {
    font-size: 16px;
    margin-top: 8px;
  }

  .home-new-blocks-title {
    font-size: 25px;
  }

  .home-new-blocks-desc {
    font-size: 17px;
    padding-left: 35px;
    padding-right: 35px;
  }
}

@media (max-width: 420px) {
  .home-app-features-item {
    height: 280px;
  }

  .home-app-features-item img {
    width: 100px;
    height: 100px;
  }

  .home-app-features-item {
    border-radius: 16px;
    padding: 15px;
  }
  .home-app-features-item .title {
    font-size: 18px;
  }

  .home-app-features-item .desc {
    font-size: 16px;
    margin-top: 8px;
  }
}

@media (max-width: 360px) {
  .home-app-features-item {
    height: 330px;
  }

}