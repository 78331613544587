.checkout-payment-methods {
}

.checkout-payment-methods h5 {
  font-family: normal;
  font-family: Yellix-Bold, serif;
  color: var(--color-text);
  margin: 0px;
  text-align: center;
}
