
/* add card modal */
.choose-friend-modal {
    width: 676px;
}

.choose-friend-modal .MuiDialog-paper {
    border-radius: 16px;
    width: 560px;
    max-width: calc(100vw - 32px) !important;
}
 

.choose-friend-modal .content {
    background-color: white;
    padding: 26px 18px;
    height: 80vh;
}

.choose-friend-modal .content h1.title {
    flex: 1;
    font-size: 28px;
    font-family: Yellix-Bold, serif;
    color: var(--color-text);
    margin: 0px;
    text-align: center;
}

.choose-friend-modal .content img.close-btn {
    width: 40px;
    height: 40px;
    object-fit: contain;
    cursor: pointer;
}

.choose-friend-modal .save-btn {
    width: 100%;
    background-color: var(--color-cyan2) !important;
    color: white;
}

.choose-friend-modal .row {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 8px 0px;
}

.choose-friend-modal {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100vh;
}

.choose-friend-modal .scrollview {
    flex: 1;
    width: 100%;
    background-color: white;
    overflow-y: auto;
}

.choose-friend-modal .operation-tab {
    height: 62px;
    width: 100%;
    margin-top: 20px;
    border-top-width: 1px;
    border-bottom-Width: 1px;
    border-top-color: #F6F6F9;
    border-top-style: solid;
    border-bottom-color: #F6F6F9;
    border-bottom-style: solid;
}

.choose-friend-modal .operation-tab .common-tabs {
    width: 360px;
}

.choose-friend-modal .searchview {
    width: 100%;
}