.where-heard-feedback-modal {
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
    z-index: 999;
    max-width: calc(100vw - 30px);
    width: 520px;
    position: fixed;
    bottom: 0;
    right: 15px;
    margin: 0 !important;
    box-shadow: 0px 11px 15px -7px rgb(0 0 0 / 20%), 0px 24px 38px 3px rgb(0 0 0 / 14%),
    0px 9px 46px 8px rgb(0 0 0 / 12%);
    background-color: white;
}

.where-heard-feedback-modal .titleview {
    width: 100%;
    align-items: flex-start;
    position: relative;
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
}

.where-heard-feedback-modal .titleview img {
    width: 32px;
    height: 32px;
    object-fit: contain;
}

.where-heard-feedback-modal .content {
    width: 100%;
    border-radius: 16px;
}

.where-heard-feedback-modal .content .content-view {
    min-height: 380px;
    justify-content: flex-start;
}

.where-heard-feedback-modal .content h2 {
    font-size: 16px;
    font-family: Yellix-SemiBold, serif;
    color: var(--color-text);
    margin: 0;
    flex: 1;
    text-align: center;
    padding: 6px 0;
}

.where-heard-feedback-modal .content h3 {
    font-size: 22px;
    line-height: 24px;
    font-family: Yellix-SemiBold, serif;
    color: white;
    margin: 0;
    flex: 1;
}

.where-heard-feedback-modal .scrollview {
    justify-content: flex-start;
    overflow-y: auto;
    padding: 0px 20px;
    margin-bottom: 20px;
}

.where-heard-feedback-modal .section {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 0 8px;
}

.where-heard-feedback-modal .header {
    margin-bottom: 16px;
}

.where-heard-feedback-modal .headerTitle {
    color: var(--color-text);
    font-family: Yellix-Bold, serif;
    font-size: 18px;
}

.where-heard-feedback-modal .sectionContent {
    background-color: var(--color-gray8);
    border-radius: 12px;
    display: flex;
    flex-direction: row;
    margin-top: 16px;
    align-items: center;
    min-height: 56px;
    padding: 8px 20px;
}

.where-heard-feedback-modal .sectionCol {
    display: flex;
    flex-direction: column;
    flex: 1;
}

.where-heard-feedback-modal .sectionTitle {
    margin-bottom: 8px;
    color: var(--color-text);
    font-family: Yellix-Bold, serif;
    font-size: 14px;
}


.where-heard-feedback-modal .sectionRadioTitle {
    color: var(--color-text);
    font-family: Yellix-SemiBold, serif;
    font-size: 14px;
}
