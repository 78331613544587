.business-card-view .react-multiple-carousel__arrow {
  display: none;
}

.business-card-view .react-multi-carousel-list {
  padding-bottom: 16px;
}

.business-card-view .react-multi-carousel-dot button {
  border-width: 0px;
}

.business-card-view .react-multi-carousel-dot--active button {
  background: var(--color-cyan2);
}

@media (max-width: 576px) {
  .business-card-view {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }
}
