.blog-item {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: var(--color-gray8);
  border-radius: 16px;
  margin-bottom: 32px;
  cursor: pointer;
}

.blog-item .img {
  width: 100%;
  height: 120px;
  position: relative;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding-left: 20px;
  padding-right: 6px;
  padding-bottom: 12px;
}

.blog-item .overlay-dark:after {
  opacity: 0.25;
  background-color: black;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
}

.blog-item .tags {
  margin-top: 12px;
}

.blog-item .tags .tag-text {
  font-size: 15px;
  font-family: Yellix-SemiBold, serif;
  color: var(--color-cyan2);
}

.blog-item h3.blog-title {
  color: var(--color-text);
  font-size: 17px;
  font-family: Yellix-SemiBold, serif;
  margin: 0;
}

.blog-item h3.author-txt {
  color: var(--color-cyan2);
  font-size: 15px;
  font-family: Yellix-SemiBold, serif;
  margin: 0;
}

.blog-item h3.date-txt {
  color: var(--color-gray7);
  font-size: 15px;
  font-family: Yellix-SemiBold, serif;
  margin: 0;
}

.blog-item .info {
  display: flex;
  justify-content: space-between;
  padding: 10px 16px 16px;
}
