.checkout-cart-item {
  width: 100%;
  align-items: center;
}

.checkout-cart-item .qty {
  font-size: 14px;
  font-family: Yellix-SemiBold, serif;
  color: var(--color-red1);
  padding: 0px 15px;
  border-radius: 5px;
}

.checkout-cart-item h4 {
  font-size: 16px;
  font-family: Yellix-SemiBold, serif;
  color: var(--color-text);
  margin: 0 !important;
}

.checkout-cart-item h3 {
  font-size: 16px;
  font-family: Yellix-Bold, serif;
  color: var(--color-text);
  margin: 0 !important;
}

.checkout-cart-item .counter {
  margin-left: 20px;
  padding-left: 20px;
  border-left-width: 1px;
  border-left-style: solid;
  border-left-color: var(--color-gray7);
}
