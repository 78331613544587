.delivery-schedule-modal .MuiDialog-paper {
  border-radius: 16px;
  width: 600px;
  max-width: calc(100vw - 32px) !important;
}

.delivery-schedule-modal .content {
  background-color: white;
  padding: 26px 18px;
}

.delivery-schedule-modal .content h4.title {
  font-family: Yellix-Bold, serif;
  color: var(--color-text);
  margin: 0px;
  text-align: center;
}

.delivery-schedule-modal .content .date-input {
  z-index: 2;
}

.delivery-schedule-modal .content .time-input {
  z-index: 1;
}

.delivery-schedule-modal .content img.close-btn {
  width: 40px;
  height: 40px;
  object-fit: contain;
  position: absolute;
  left: 0;
  top: 0;
  cursor: pointer;
}

.delivery-schedule-modal .actions .deliver-btn {
  width: 100%;
  background-color: var(--color-gray6) !important;
  color: var(--color-gray7);
  margin-top: 12px;
}

.delivery-schedule-modal .actions .save-btn {
  width: 100%;
  background-color: var(--color-cyan2) !important;
  color: white;
}
/* end */
