.view-vendors-map .map-view {
  flex: 1;
  height: calc(100vh - 80px);
  position: relative;
}

.view-vendors-map .search-bar {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  padding: 20px 40px;
}
