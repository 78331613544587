.header {
  display: flex;
  justify-content: space-between;
  padding: 18px 32px 8px 32px;
  height: 80px;
}

.header-bottom-border {
  box-shadow: rgb(0 0 0 / 45%) 0px 0px 3px 0px;
}



.app-home-header {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1100;
  width: 100%;
}

.app-sticky-header {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1100;
  width: 100%;
  background-color: white;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: var(--color-gray6);
}

.header .left-side {
  z-index: 1;
}

.header .drawerBtn {
  margin-right: 20px;
  width: 46px;
  min-width: 46px;
}

.header .drawerBtn svg {
  transform: rotateZ(180deg);
}

.header .header-logo {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
}

.header .header-logo > img {
  height: 40px;
  width: 250px;
  object-fit: contain;
}

.login-button {
  width: 46px;
  height: 46px;
  border-radius: 24px;
  background-color: var(--color-text);
  margin-right: 20px;
  z-index: 2;
  font-size: 20px;
}

.lang-container{
}

.header .header-logo > .vendor {
  font-size: 13px;
  font-family: Yellix-Light, serif;
  color: white;
  background-color: var(--color-text);
  padding: 5px 12px;
  border-radius: 8px;
  margin-top: -2px;
}

.header > div.right-side {
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
}

.header .user-info {
  text-decoration: none;
}

.header .user-info img {
  width: 46px;
  height: 46px;
  border-radius: 30px;
  margin-right: 10px;
}

.header .user-info h2 {
  color: var(--color-text);
  font-size: 18px;
  font-family: Yellix-SemiBold, serif;
  margin: 0;
  white-space: nowrap;
}

.header > div.right-side .cart-btn {
  margin-left: 40px;
}

.header > div.right-side .cart-btn img {
  width: 44px;
  height: 46px;
}

.header > div.right-side .cart-btn .cart-badge {
  position: absolute;
  top: -5px;
  right: -5px;
  width: 22px;
  height: 22px;
  border-radius: 11px;
  background-color: var(--color-primary);
  font-size: 10px;
  line-height: 14px;
  font-family: Yellix-SemiBold;
  color: white;
}

.header > div.right-side .divider {
  margin-left: 40px;
  margin-right: 40px;
  height: 36px;
  width: 2px;
  background-color: #efefef;
}
.header > div.right-side .dropdown {
  background-color: transparent;
}

.header .scan-button {
  width: 230px;
  height: 44px;
  border-radius: 30px;
  border-width: 1px;
  border-style: solid;
  border-color: var(--color-primary);
  background-color: white;
  font-size: 17px;
  line-height: 23px;
  font-family: Yellix-SemiBold;
  color: var(--color-primary);
  margin-right: 20px;
  cursor: pointer;
}

.header .scan-button span{
  padding-left: 6px;
}

@media screen and (max-width: 930px) {
  .header .user-info {
    display: none;
  }
}

@media screen and (max-width: 768px) {
  .header .header-logo > img {
    height: 37.5px;
    width: 160px;
    object-fit: contain;
  }

  .header .drawerBtn {
    margin-right: 10px;
    padding-left: 0px;
    padding-right: 0px;
    padding-top: 0px;
    padding-bottom: 0px;
    width: 30px;
    min-width: 30px;
  }

  .header {
    padding: 18px 0px 8px 0px;
  }
}


@media screen and (max-width: 576px) {
  .login-button {
    /* width: 120px; */
    width: 42px;
    height: 42px;
    margin-right: 15px;
    z-index: 2
  }
  .lang-container {
    margin-left: 0px;
  }

  .header > div.right-side .divider {
    margin-left: 0px;
    margin-right: 15px;
    width: 0px;
  }

  .header > div.right-side .cart-btn img {
    width: 40px;
    height: 42px;
  }
}

@media screen and (max-width: 370px) {
  .header .header-logo > img {
    height: 37.5px;
    width: 140px;
    object-fit: contain;
  }

  .login-button {
    /* width: 120px; */
    width: 36px;
    height: 36px;
    margin-right: 6px;
    z-index: 2
  }

  .header > div.right-side .cart-btn {
    margin-left: 10px;
  }

  .header > div.right-side .cart-btn img {
    width: 36px;
    height: 38px;
  }
}

@media screen and (max-width: 330px) {
  .header .header-logo > img {
    height: 37.5px;
    width: 120px;
    object-fit: contain;
  }
}