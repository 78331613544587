.vendor-hscroll-menu {
    width: 100%;
    align-items: flex-start;
}

.vendor-hscroll-menu .hscrollview {
    width: 100%;
    margin-top: 16px;
    padding-bottom: 18px;
    overflow-x: auto;
}

.vendor-hscroll-menu .scrollview_hider {
    width: 100%;
    margin-top: -18px;
    height: 18px;
    background-color: white;
    z-index: 1;
}

.vendor-hscroll-menu .inactive-cat-item {
    font-size: 16px;
    font-family: Yellix-SemiBold, serif;
    color : var(--color-gray7);
    margin-right: 30px;
    white-space: nowrap;
}

.vendor-hscroll-menu .active-cat-item {
    font-size: 16px;
    font-family: Yellix-SemiBold, serif;
    color : var(--color-cyan2);
    margin-right: 30px;
    line-height: 22px;
    border-bottom-width: 1px;
    border-bottom-color: var(--color-cyan2);
    border-bottom-style: solid;
    white-space: nowrap;
}
