.order-item {
  width: 100%;
  align-items: flex-start !important;
  margin-top: 15px;
  cursor: pointer;
  background-color: var(--color-gray8);
  padding: 15px ;
  border-radius: 15px;
}

.order-item .payment_method {
  font-size: 15px;
  line-height: 16px;
  margin-top: 9px;
  font-family: Yellix-Medium, serif;
  color: var(--color-text);
}

.order-item .vendor-title {
  font-size: 17px;
  font-family: Yellix-SemiBold, serif;
  color: var(--color-text);
  padding-right: 16px;
  margin-bottom: 4px;
}

.order-item .price {
  font-size: 18px;
  font-family: Yellix-Bold, serif;
  color: var(--color-text);
  margin-bottom: 6px;
}

.order-item .status {
  font-size: 15px;
  font-family: Yellix-SemiBold, serif;
  color: var(--color-cyan2);
}

.order-item .date {
  font-size: 15px;
  line-height: 16px;
  font-family: Yellix-Medium, serif;
  color: var(--color-text);
  padding-right: 16px;
  margin-top: 10px;
}
