.invite-input {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex: 1;
  padding: 15px 8px;
  border-radius: 12px;
  border: 1px solid var(--color-gray6);
  position: relative;
  max-height: 52px;
}

.invite-input input {
  font-size: 14px !important;
  line-height: 18px !important;
  font-family: Yellix-Medium, serif !important;
  flex: 1;
  padding-top: 6px;
  padding-bottom: 6px;
  padding-left: 10px;

  width: 100%;
  outline: none !important;
  background-color: white !important;
  border-width: 0 !important;
  border-color: white !important;
  color: var(--color-text) !important;
}

.invite-input input::placeholder {
  color: var(--color-gray5);
  opacity: 1; /* Firefox */
}

.invite-input input:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: var(--color-gray5);
}

.invite-input input::-ms-input-placeholder {
  /* Microsoft Edge */
  color: var(--color-gray5);
}

.invite-input .remove {
  font-size: 12px;
  font-family: Yellix-SemiBold, serif;
  color: var(--color-gray7);
  padding-left: 6px;
  padding-right: 6px;
}

.invite-input .code-text {
  margin-right: 8px;
  font-size: 12px;
  font-family: Yellix-SemiBold, serif;
  color: var(--color-text);
}
