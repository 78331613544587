.add-address-screen {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.add-address-screen .header-view {
    width: 100%;
    margin: 15px 0px;
}

.add-address-screen .title{
    text-align: center;
    font-size: 20px;
    font-family: Yellix-Bold, serif;
    color: var(--color-text);
}

.add-address-screen .round-icon-btn {
    width: 33px;
    height: 33px;
    border-radius: 8px;
    background-color: white;
}

.add-address-screen .subject-title {
    font-size: 17px;
    font-family: Yellix-SemiBold, serif;
    color: var(--color-text);
}

.add-address-screen .address-type .text-drop-btn {
    height: 40px;
}

.add-address-screen .list {
    margin-top: 26px;
}

.add-address-screen .list .add-btn {
    background-color: white !important;
    font-size: 18px;
    font-family: Yellix-SemiBold, serif;
    color: var(--color-cyan2);
    margin-bottom: 20px;
    margin-top: 0;
    cursor: pointer;
}

.add-address-screen .actions .save-btn {
    width: 100%;
    background-color: var(--color-cyan2) !important;
    color: white;
}

.add-address-screen .form .row {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 8px 0px;
}

.add-address-screen .form div.label {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 16px;
    font-family: Yellix-SemiBold, serif;
    color: var(--color-text);
    margin-right: 16px;
}

.add-address-screen .form .row div.input-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex: 1;
}

.add-address-screen .form .row div.input-wrapper .text-dropdown {
    width: 100%;
    height: 48px;
}

.add-address-screen .form .row div.input-wrapper .text-dropdown .text-drop-btn {
    height: 48px;
}

.add-address-screen .form .find-btn {
    font-size: 16px;
    font-family: Yellix-SemiBold, serif;
    color: var(--color-cyan2);
    margin-bottom: 40px;
    margin-top: 10px;
    cursor: pointer;
    text-align: center;
}