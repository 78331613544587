.auth-form h3 a {
  color: var(--color-red1);
}

.auth-bottom-link {
  margin-top: 40px;
}

.auth-bottom-link .text {
  font-size: 16px;
  font-family: Yellix-SemiBold;
  color: var(--color-text);
  margin-right: 4px;
}

.auth-bottom-link .btn {
  font-size: 16px;
  font-family: Yellix-SemiBold;
  color: var(--color-cyan2);
  cursor: pointer;
}

.text-btn {
  font-size: 16px;
  font-family: Yellix-SemiBold;
  color: var(--color-text);
  cursor: pointer;
}

.vcode-inputs .auth-input input,
textarea {
  text-align: center;
}

.view-verification .auth-input {
  padding: 0px !important;
  width: 54px;
  margin-right: 16px;
}

@media (max-width: 576px) {
  .view-verification .auth-input {
    margin-right: 8px;
    width: 46px !important;
    height: 46px !important;
  }
}