.dropdown {
  position: relative;
  margin: 0 auto;
  width: 96px;
  height: 46px;
  background: white;
  border: 1px solid var(--color-text);
  border-radius: 12px;
  z-index: 2;
}

.drop-btn {
  padding: 6px 10px;
  cursor: pointer;
  height: 42px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.drop-btn img {
  height: 24px;
  width: 22px;
  object-fit: contain;
}

.drop-btn h1 {
  color: var(--color-text);
  font-family: Yellix-SemiBold, serif;
  font-size: 16px !important;
  padding-left: 7px;
  padding-right: 14px;
  margin: 0 !important;
}

.drop-btn:after {
  content: '';
  transition: all 0.3s;
  border: solid var(--color-text);
  border-width: 0 2px 2px 0;
  float: right;
  margin-top: 8px;
  margin-right: 6px;
  padding: 4px;
  transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg);
}

.dropdown.active .drop-btn:after {
  margin-top: 1px;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}

.dropdown__items {
  position: relative;
  visibility: hidden;
  opacity: 0;
  max-height: 0px;
  transition: max-height 0.6s ease;
  box-shadow: 0 2px 5px 1px rgb(64 60 67 / 16%);
  border-radius: 12px;
  background-color: white;
}

.dropdown.active .dropdown__items {
  visibility: visible;
  border: 1px solid var(--color-text);
  height: auto;
  max-height: 290px;
  opacity: 1;
  transition: max-height 0.7s, opacity 0.6s, visibility 1s ease;
}

.dropdown__item {
  cursor: pointer;
  padding: 10px 18px;
  font-family: Yellix-Medium, serif;
  font-size: 13px;
}

.dropdown__item:not(:last-child) {
  border-bottom: 1px solid var(--color-text);
}

.dropdown__item:hover {
  color: var(--color-text);
  font-family: Yellix-SemiBold, serif;
}

.dropdown .dropdown__items {
  margin-left: 0;
  width: 100%;
}

@media screen and (max-width: 576px) {
  .dropdown .drop-btn:after {
    display: none;
  }

  .dropdown {
    width: 60px;
    height: 42px;
    font-size: 13px;
  }

  .dropdown .dropdown__item {
    padding: 8px 5px;
    word-break: break-all;
  }

   .dropdown .dropdown__items {
    width: 86px;
    margin-left: -26px;
  }

  .drop-btn h1 {
    font-size: 13px !important;
    padding-right: 4px;
  }
}