.wallet-screen {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.wallet-screen .scrollview {
    width: 100%;
    background-color: white;
    padding-left: 20px;
    padding-right: 20px;
}

.wallet-screen .divider {
    width: 100%;
    height: 1px;
    background-color: #F6F6F9;
}

.wallet-screen .subject-title {
    margin-top: 20px;
    margin-bottom: 12px;
    font-size: 18px;
    font-family: Yellix-Bold, serif;
    color: var(--color-text)
}

.wallet-screen .avatar {

}

.wallet-screen .avatar .photoview {
    height: 100px;
    width: 100px;
    border-width: 1px;
    border-color: var(--color-gray9);
    border-style: solid;
    border-radius: 15px;
    background-color: #E8D7D0;
}

.wallet-screen .avatar .photoview img {
    height: 100px;
    width: 100px;
    border-radius: 6px;
}

.wallet-screen .avatar .level {
    padding: 8px 12px;
    border-radius: 20px;
    background-color: #ffda0f
}

.wallet-screen .avatar .level-txt {
    line-height: 13px;
    font-size: 15px;
    font-family: Yellix-SemiBold, serif;
    color: white;
}

.wallet-screen .avatar .name {
    line-height: 13px;
    font-size: 18px;
    font-family: Yellix-Bold, serif;
    color: var(--color-text);
    margin-top: 10px;
    margin-bottom: 6px;
}

.wallet-screen .balance {
    margin-top: 24px;
    margin-bottom: 20px;
    background-color: white;
    border-radius: 15px;
    padding: 23px 18px 23px 11px;
    background-image: linear-gradient(to right, #715EE7, #00D2FF);
}

.wallet-screen .balance .balance-txt {
    margin-bottom: 6px;
    font-size: 17px;
    font-family: Yellix-SemiBold, serif;
    color: white;
}

.wallet-screen .balance .balance-desc {
    line-height: 13px;
    font-size: 14px;
    font-family: Yellix-SemiBold, serif;
    color: #FAFAFCCC;
}

.wallet-screen .balance .balance-price {
    font-size: 40px;
    font-family: Yellix-Bold, serif;
    color: white;
}

.wallet-screen .balance .balance-unit {
    padding-bottom: 5px;
    font-size: 20px;
    font-family: Yellix-Bold, serif;
    color: white;
}