.view-vendors .breadcrumb {
  margin-bottom: 20px;
}

.view-vendors .breadcrumb img {
  width: 20px;
  height: 20px;
  object-fit: contain;
  margin-right: 6px;
}

.view-vendors .breadcrumb a {
  color: var(--color-text);
  font-family: Yellix-SemiBold, serif;
  font-size: 15px;
}

.view-vendors .breadcrumb .address-tag {
  padding: 8px;
  background-color: var(--color-gray9);
  border-radius: 10px;
}


@media screen and (max-width: 420px) {
  .view-vendors .breadcrumb .address-tag {
    margin-top: 10px;
  }
}
