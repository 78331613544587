/* address map modal */

.location-pickup-modal .MuiDialog-paper {
  border-radius: 16px;
  width: 800px;
  max-width: calc(100vw - 32px) !important;
}

.location-pickup-modal .content {
  background-color: white;
  padding: 0px;
}

.location-pickup-modal .map-view {
  width: 100%;
  height: 400px;
  max-height: calc(100vh - 100px) !important;
}

.location-pickup-modal .search-bar {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  padding: 20px;
}

.location-pickup-modal .search-bar .location-input {
  background-color: white;
  height: 46px;
  margin-left: 8px;
}

.location-pickup-modal .search-view p {
  font-size: 10px;
  font-family: Yellix-SemiBold;
  color: var(--color-gray5);
  margin-top: 14px;
  margin-bottom: 0;
}

.location-pickup-modal .search-view h4 {
  font-size: 14px;
  font-family: Yellix-SemiBold;
  color: var(--color-text);
  margin-top: 5px;
  margin-bottom: 0;
}

.location-pickup-modal .save-btn {
  width: 100%;
  max-width: 400px;
  background-color: var(--color-cyan2) !important;
  color: white;
}
/* end*/
