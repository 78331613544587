.partner-screen {
  position: relative;
  z-index: 0;
}

.partner-screen section.main {
  background-image: url('../../assets/svgs/partner/bg.svg');
  position: relative;
}

.partner-screen section.main .title-view {
  display: flex;
  flex-direction: column;
  justify-content: left;
  height: 100%;
}

.partner-screen section.main h1 {
  font-family: Yellix-ExtraBold, serif;
  color: var(--color-text);
}

.partner-screen section.main h4 {
  font-family: Yellix-SemiBold, serif;
  color: var(--color-text);
  margin: 0;
}

@media (min-width: 0px) {
  .partner-screen .MuiGrid-item {
     padding-left: 0px;
  }
}