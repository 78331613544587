.vendor-item {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: var(--color-gray8);
  border-radius: 16px;
  margin-bottom: 32px;
  cursor: pointer;
}

.vendor-item .img {
  width: 100%;
  height: 160px;
  position: relative;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding-left: 20px;
  padding-right: 6px;
  padding-bottom: 12px;
}

.vendor-item .overlay-dark:after {
  opacity: 0.25;
  background-color: black;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
}

.vendor-item .only_snapfood {
  font-size: 14px;
  font-family: Yellix-Medium, serif;
  color: white;
  padding: 4px 9px 5px;
  background-color: var(--color-cyan2);
  border-radius: 8px;
  margin-right: 6px;
}

.vendor-item .buy_get_free {
  width: 80px;
  text-align: center;
  font-size: 12px;
  font-family: Yellix-Medium, serif;
  color: white;
  padding: 4px 6px;
  background-color: var(--color-red1);
  border-radius: 8px;
  margin-right: 6px;
}

.vendor-item .tags {
  flex-wrap: wrap;
  margin-top: 12px;
}

.vendor-item .tags .tag-text {
  font-size: 16px;
  font-family: Yellix-Medium, serif;
  color: white;
  padding: 4px 6px;
  background-color: #aaaaaa44;
  border-radius: 8px;
  margin-right: 6px;
  white-space: nowrap;
}

.vendor-item .info {
  display: flex;
  justify-content: space-between;
  padding: 10px 16px 16px;
}

.vendor-item .info div:first-child {
  margin-right: 8px;
}

.vendor-item .vendor-logo {
  width: 80px;
  height: 80px;
  background-color: white;
  border-radius: 12px;
}

.vendor-item .vendor-logo img {
  width: 80px;
  height: 80px;
  object-fit: contain;
  border-radius: 12px;
}

.vendor-item h3.vendor-title {
  color: var(--color-text);
  font-size: 18px;
  font-family: Yellix-Bold;
  margin: 0;
  padding-top: 4px;
}

.vendor-item .indicator {
  font-size: 16px;
  font-family: Yellix-Medium;
  display: flex;
  align-items: center;
}

.vendor-item .indicator > div {
  width: 10px;
  height: 10px;
  border-radius: 5px;
  margin-right: 5px;
}

.vendor-item h3.liked-txt {
  color: var(--color-red1);
  font-size: 16px;
  font-family: Yellix-Medium;
  margin: 0;
}

.vendor-item .rate-item {
  font-size: 16px;
  font-family: Yellix-Medium;
  color: var(--color-gray7);
}

.vendor-item .price-item img {
  width: 18px;
  height: 18px;
  object-fit: contain;
  margin-right: 4px;
}

.vendor-item .price-item {
  font-size: 16px;
  font-family: Yellix-Medium;
  color: var(--color-gray7);
}

.vendor-item .price-item img {
  width: 14px;
  height: 14px;
  object-fit: contain;
  margin-right: 6px;
}

.vendor-item .fav-btn {
  padding: 4px;
  position: absolute;
  top: 10px;
  right: 10px;
}

.vendor-item img.open-close-img {
  position: absolute;
  top: 6px;
  left: 6px;
  width: 65px;
  height: 45px;
  object-fit: contain;
}

@media (max-width: 576px) {
  .vendor-item {
    margin-bottom: 18px;
  }
}