.location-msg-modal .MuiDialog-paper {
  border-radius: 16px;
  width: 800px;
  max-width: calc(100vw - 32px) !important;
}

.location-msg-modal .content {
  background-color: white;
  padding: 0px;
}

.location-msg-modal .map-view {
  width: 100%;
  height: 460px;
  max-height: calc(100vh - 100px) !important;
}

.location-msg-modal .round-icon-btn {
  position: absolute;
  left: 20px;
  top: 20px;
}