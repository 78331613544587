.home-business-card {
  width: 100%;
  height: 318px;
  background-size: cover;
  background-position: center;
  border-radius: 30px;
  border: 2px solid #d6d5e1;
}

.home-business-card-vendor {
  background-image: url('/src/assets/images/home/rider_card.png');
}

.home-business-card-rider {
  background-image: url('/src/assets/images/home/rider_card.png');
}

.home-business-card-snapfood {
  background-image: url('/src/assets/images/home/snapfood_team_card.png');
}

.home-business-card .card-body {
  width: 60%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
}

.home-business-card .card-body h3 {
  font-family: Yellix-Bold, serif;
  color: var(--color-text);
  margin: 0;
}

.home-business-card .card-body p {
  font-family: Yellix-Bold, serif;
  color: var(--color-text);
  margin-top: 20px;
  margin-bottom: 20px;
}

@media (max-width: 1440px) {
  .home-business-card .card-body {
    width: 70%;
    max-width: 245px;
  }
}

@media (max-width: 992px) {
  .home-business-card {
    height: 260px;
  }
}

@media screen and (max-width: 370px) {
  .home-business-card .card-body {
    width: 70%;
    max-width: 100%;
  }
}