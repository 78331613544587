.past-order-screen {
  position: relative;
}

.past-order-screen .past-order-screen-padding {
  padding-left: 32px !important;
  padding-right: 32px !important;
}

.past-order-screen .overlay-dark:after {
  height: 420px;
  z-index: 0;
  opacity: 0.2;
  background-color: black;
}

.past-order-screen .vendor-bg {
  width: 100%;
  height: 420px;
  padding: 18px 0;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  background-repeat: no-repeat;
  background-size: cover;
}


@media (max-width: 576px) {

  .past-order-screen .overlay-dark:after{
    height: 340px;
  }

  .past-order-screen .vendor-bg{
    height: 340px;
  }
}
