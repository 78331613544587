.invite-option-modal .MuiDialog-container {
}

.invite-option-modal .MuiDialog-paper {
    margin: 0 !important;
    border-radius: 30px;
}

.invite-option-modal .content {
    width: 100%;
}

.invite-option-modal .content h1{
    font-size: 18px;
    font-family: Yellix-Bold, serif;
    color: var(--color-text);
}