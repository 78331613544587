.vendor-filter h1 {
  font-size: 24px;
  font-family: Yellix-ExtraBold, serif;
  color: var(--color-text);
  margin-bottom: 0;
  margin-top: 0;
}

.vendor-filter .list-btn {
  background-color: var(--color-gray8) !important;
  color: var(--color-text);
  height: 48px;
  border-radius: 10px;
  margin-bottom: 15px;
}

.vendor-filter .MuiAccordion-root {
  box-shadow: none;
  margin: 0 !important;
}

.vendor-filter .list-btn span,
.vendor-filter .MuiAccordionSummary-content span {
  font-size: 16px;
  font-family: Yellix-Bold, serif;
  color: var(--color-text);
}

.vendor-filter .MuiAccordion-root,
.vendor-filter .MuiCollapse-root {
  background-color: var(--color-gray8);
  border-radius: 10px;
}

.vendor-filter .MuiAccordion-root::before {
  opacity: 0;
}

.vendor-filter .sort-btn {
  margin-bottom: 20px;
}

.vendor-filter .sort-btn span {
  font-size: 14px;
  font-family: Yellix-SemiBold, serif;
  color: var(--color-text);
}

.vendor-filter .MuiSlider-valueLabel {
  border-radius: 20px;
  background-color: var(--color-cyan2);
  color: white;
  font-size: 11px;
  padding: 2px 10px;
  font-family: Yellix-Medium, serif;
}

.vendor-filter .MuiSlider-root {
  width: calc(100% - 40px);
}

.vendor-filter .MuiSlider-rail {
  color: var(--color-gray7);
}

.vendor-filter .MuiSlider-track {
  color: var(--color-cyan2);
}

.vendor-filter .MuiSlider-thumb {
  color: var(--color-cyan2);
  width: 16px;
  height: 16px;
}

.vendor-filter .map-btn {
  color: var(--color-cyan2) !important;
  border: 1px solid var(--color-cyan2) !important;
  font-family: Yellix-SemiBold, serif;
}

.vendor-filter .filter-header .round-icon-btn {
  display: none;
}

@media (max-width: 576px) {
  .vendor-filter .filter-header .round-icon-btn {
    display: inherit;
  }

  .vendor-filter .filter-body {
    display: none;
  }
}