.food-category {
  height: 110px;
  display: flex;
  justify-content: space-between;
  background: var(--color-gray8);
  border-radius: 16px;
  padding-top: 20px;
  padding-left: 20px;
  margin-bottom: 32px;
  cursor: pointer;
}

.food-category h3 {
  font-size: 18px;
  font-family: Yellix-SemiBold, serif;
  margin: 0 !important;
}

.food-category .img {
  width: 42%;
  height: 100%;
  position: relative;
}

.food-category img {
  height: 75%;
  position: absolute;
  bottom: 0;
  right: 0;
}
