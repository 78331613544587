.vehicletype-btn {
  width: 100%;
  height: 48px;
  background-color: var(--color-text) !important;
  font-size: 16px !important;
  font-family: Yellix-Medium, serif !important;
  border-radius: 12px !important;
  text-transform: none !important;
  box-shadow: none !important;
}

/* switch button */
.vehicle-switch {
  width: 100%;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
  border: 1px solid var(--color-gray5) !important;
}

.vehicle-switch button {
  flex: 1;
  font-size: 14px !important;
  padding: 0 !important;
}

.vehicle-switch button.active {
  background-color: var(--color-text) !important;
  color: white;
}

.vehicle-switch button.inactive {
  background-color: white !important;
  color: var(--color-text);
}

.vehicle-switch button img {
  height: 22px;
  padding-right: 8px;
  object-fit: contain;
}

/* end switch button */
