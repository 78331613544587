.order-summ-view .order-info-view {
  width: 100%;
  align-items: flex-start;
  padding: 16px;
  border-radius: 15px;
  background-color: var(--color-gray8);
}

.order-summ-view h5 {
  font-family: normal;
  font-family: Yellix-Bold, serif;
  color: var(--color-text);
  margin: 0px;
}

.order-summ-view h5 span {
  font-size: 16px;
  font-family: Yellix-Bold, serif;
  color: var(--color-gray7);
}

.order-summ-view .order-cancelled {
  width: 100%;
  text-align: center;
  margin-bottom: 10px;
  font-size: 16px;
  font-family: Yellix-SemiBold, serif;
  color: var(--color-red1);
}

.order-summ-view .order-total {
  font-size: 18px;
  font-family: Yellix-Bold, serif;
  color: var(--color-text);
}

.order-summ-view .subject-title {
  font-size: 18px;
  font-family: Yellix-SemiBold, serif;
  color: var(--color-text);
}

.order-summ-view .order-info-text {
  font-size: 16px;
  line-height: 20px;
  font-family: Yellix-Medium, serif;
  color: var(--color-gray7);
}

.order-summ-view .top-border {
  border-top-width: 1px;
  border-top-style: solid;
  border-top-color: var(--color-gray6);
  padding-top: 12px;
}

.order-summ-view .order-date {
  font-size: 16px;
  line-height: 20px;
  font-family: Yellix-SemiBold, serif;
  color: var(--color-text);
}

.order-summ-view .vendor-address {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 15px;
  background-color: var(--color-gray8);
  padding: 12px 15px;
}

.order-summ-view .vendor-address .vendor-phone {
  font-size: 16px;
  font-family: Yellix-Medium;
  color: var(--color-text);
}

.order-summ-view .info-value-txt {
  font-size: 16px;
  font-family: Yellix-SemiBold, serif;
  color: var(--color-text);
}