.suggested-item {
  width: 100% !important;
  min-width: 100% !important;
  border-radius: 12px;
  border-width: 1px;
  border-style: solid;
  border-color: var(--color-gray6);
  padding: 0 !important;
}

.suggested-item img {
  width: 60px;
  height: 60px;
  border-radius: 4px;
  margin-right: 10px;
}

.suggested-item .info {
  flex: 1;
}

.suggested-item .btn-view {
  width: 100%;
  padding: 10px 0px;
  text-transform: none;
  background-color: var(--color-gray6);
  color: var(--color-text);
  font-family: Yellix-SemiBold;
}
