.view-privacy {
    margin: auto;
    border-top-width: 1px;
    border-top-style: solid;
    border-top-color: var(--color-gray6);
}

.view-privacy br {
    height: 20px;
}
.view-privacy {
    padding-top: 30px;
    padding-left: 20px;
    padding-right: 20px;
}


.view-privacy h1 {
    margin-top: 32px;
    margin-bottom: 32px;
    font: normal normal 800 32px/38px Yellix-Bold;
    letter-spacing: 0px;
    color: #222222;
    opacity: 1;
}

.view-privacy p {
    font: normal normal 600 16px/19px Yellix-SemiBold;
    letter-spacing: 0px;
    color: #222222;
    opacity: 1;
}