.app-sidebar {
  padding-left: 25px;
  padding-right: 25px;
  padding-top: 40px;
  width: 420px;
  max-width: calc(100vw - 32px) !important;
  flex: 1;
}

.app-sidebar ul {
  padding: 0;
}

.app-sidebar .list-btn {
  background-color: var(--color-gray8) !important;
  color: var(--color-text);
  height: 48px;
  border-radius: 10px;
  margin-bottom: 15px;
  margin-top: 15px;
}

.app-sidebar .list-btn span {
  font-size: 16px;
  font-family: Yellix-SemiBold, serif;
  color: var(--color-text);
}

/* .app-sidebar .drawer-body {
  margin-bottom: 30px;
} */

.drawer-apps {
  width: 100%;
  padding-top: 25px;
  padding-bottom: 25px;
  background-color: var(--color-text);
}

.drawer-apps h4 {
  font-size: 16px;
  font-family: Yellix-Bold, serif;
  color: white;
  margin-top: 0;
  margin-bottom: 20px;
  width: 83%;
}

.drawer-apps .badge {
  width: 150px;
  height: 52px;
  background-repeat: no-repeat;
  display: table;
  margin: 0 12px;
  background-size: contain;
  margin-bottom: 10px;
}

.drawer-apps .badge-ios {
  background-image: url('../../assets/svgs/home/app_store.svg');
}

.drawer-apps .badge-android {
  background-image: url('../../assets/svgs/home/googleplay.svg');
}

.app-sidebar .cashback-info {
  background-color: var(--color-text);
  border-radius: 8px;
  color: white;
  font-size: 16px;
  font-family: Yellix-SemiBold, serif;
  padding: 6px 9px;
}

.app-sidebar .user-info {
  margin-bottom: 15px;
}

.app-sidebar .user-info img {
  width: 56px;
  height: 56px;
  border-radius: 28px;
  margin-right: 10px;
}

.app-sidebar .user-info h2 {
  color: var(--color-text);
  font-size: 20px;
  font-family: Yellix-Bold, serif;
  margin: 0;
  max-width: 200px;
  word-break: break-all;
}

.app-sidebar .user-info a {
  color: var(--color-red1);
  font-size: 16px;
  font-family: Yellix-Medium, serif;
  margin-top: 4px;
  text-decoration: none;
}

.app-sidebar .list-btn .new_msg_tag {
  color: var(--color-cyan2);
  font-size: 14px;
  font-family: Yellix-SemiBold, serif;
}
