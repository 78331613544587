.vendor-search-view {
  width: 100%;
  max-width: calc(100vw - 32px) !important;
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: white;
  border-radius: 16px;
  box-shadow: 0 0 25px rgba(66, 133, 244, 0.1) !important;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 0px;
  padding-bottom: 15px;
}

.vendor-search-view .row {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 8px 0;
}

.vendor-search-view .vendor_type_radios {
  justify-content: center;
}

.vendor-search-view .vendor_type_radios label {
  margin-right: 20px;
  min-width: 116px;
}

.vendor-search-view .vendor_type_radios .MuiFormControlLabel-label {
  font-size: 16px;
  font-family: Yellix-Medium, serif;
  color: var(--color-text);
}

.error .location-input {
  border: 1px solid var(--color-red1);
}

.vendor-search-view .app-btn {
  font-size: 18px !important;
}

@media (max-width: 1440px) {

}

@media (max-width: 1200px) {
  .vendor-search-view {
    flex-direction: column;
  }
}

@media (max-width: 576px) {
  .vendor-search-view .location-input input {
    font-size: 16px !important;
  }

  .vendor-search-view .search-input input {
    font-size: 16px !important;
  }
}