.promotion-view {
  background-color: var(--color-text);
  border-radius: 12px;
}

.promotion-view .title {
  font-family: Yellix-SemiBold, serif;
  color: var(--color-white);
}

.promotion-view .desc {
  font-family: Yellix-SemiBold;
  color: var(--color-cyan2);
}
