.partner-screen .features {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;
}

.partner-screen .features h3 {
  font-family: Yellix-ExtraBold, serif;
  color: var(--color-text);
  margin-bottom: 56px;
}

.partner-screen .features .features-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding-left: 20px;
  padding-right: 20px;
  height: 100%;
}

.partner-screen .features .features-item img {
  height: 152px;
  object-fit: contain;
}

.partner-screen .features .features-item h5 {
  font-family: Yellix-Bold, serif;
  color: var(--color-text);
  text-align: center;
}

.partner-screen .features .features-item p {
  font-size: 20px;
  font-family: Yellix-SemiBold, serif;
  color: var(--color-text);
  text-align: center;
  margin: 0 !important;
}

@media (max-width: 576px) {
  .partner-screen .features .features-item p {
    font-size: 16px;
  }
  .partner-screen .features .features-item img {
    height: 118px;
  }
}