.vendor-search-form {
  width: 100%;
  max-width: calc(100vw - 32px) !important;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;
  border-radius: 16px;
  box-shadow: 0 0 25px rgba(66, 133, 244, 0.1) !important;
  padding: 15px 20px;
}

.vendor-search-form .row {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 8px 0;
}


.vendor-search-form-spacer {
  width: 16px;
}

.error .location-input {
  border: 1px solid var(--color-red1);
}

.vendor-search-form .app-btn {
  font-size: 18px !important;
}

@media screen and (max-width: 900px) {
  .vendor-search-form {
    max-width: 100%;
    padding: 10px 15px;
  }

  .vendor-search-form .row {
    flex-direction: column !important;
    display: block !important;
  }

  .vendor-search-form-spacer {
    height: 16px;
  }
}

@media (max-width: 576px) {
  .vendor-search-form .location-input input {
    font-size: 16px !important;
  }

  .vendor-search-form .search-input input {
    font-size: 16px !important;
  }
}