.add-card-screen {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.add-card-screen .scrollview {
    width: 100%;
    background-color: white;
    overflow-y: auto;
    margin-top: 20px;
}


.add-card-screen .save-btn {
    width: 100%;
    background-color: var(--color-cyan2) !important;
    color: white;
}