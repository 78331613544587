
/* add card modal */
.edit-profile-modal {
    width: 676px;
}

.edit-profile-modal .MuiDialog-paper {
    border-radius: 16px;
    width: 560px;
    max-width: calc(100vw - 32px) !important;
}
 

.edit-profile-modal .content {
    background-color: white;
    padding: 26px 26px;
}

.edit-profile-modal .content h4.title {
    font-family: Yellix-Bold, serif;
    color: var(--color-text);
    margin: 0px;
    text-align: center;
}

.edit-profile-modal .content img.close-btn {
    width: 40px;
    height: 40px;
    object-fit: contain;
    position: absolute;
    left: 0;
    top: 0;
    cursor: pointer;
}

.edit-profile-modal .actions .save-btn {
    width: 100%;
    background-color: var(--color-cyan2) !important;
    color: white;
}

.edit-profile-modal .row {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 8px 0px;
}

.edit-profile-modal {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100vh;
}

.edit-profile-modal  .header {
    height: 55px;
    display: flex;
    align-items: flex-end;
    padding-left: 20px;
    padding-right: 20px;
}

.edit-profile-modal .scrollview {
    flex: 1;
    width: 100%;
    background-color: white;
    margin-top: 20px;
}

.edit-profile-modal .save-btn {
    font-size: 18px;
    font-family: Yellix-SemiBold, serif;
    color: var(--color-cyan2);
}

.edit-profile-modal .avatar-view {
    width: 116px;
    height: 116px;
    position: relative;
}

.edit-profile-modal .photo-view {
    width: 100px;
    height: 100px;
    border-width: 1px;
    border-color: var(--color-gray9);
    border-radius: 50px;
    background-color: #E8D7D0;
}

.edit-profile-modal .photo-view img {
    width: 100px;
    height: 100px;
    border-radius: 50px;
    object-fit: cover;
}

.edit-profile-modal .photo-edit {
    width: 24px;
    height: 24px;
    border-radius: 12px;
    background-color: var(--color-cyan2);
    position: absolute;
    top: 10px;
    right: 10px;
}

.edit-profile-modal .birthday-view {
    display: flex;
    border-width: 1px;
    border-color: var(--color-gray5);
    border-style: solid;
    border-radius: 12px;
    height: 50px;
    padding-left: 12px;
    padding-right: 12px;
    margin-bottom: 12px;
}

.edit-profile-modal .birthday-txt {
    font-size: 16px;
    font-family: Yellix-Medium, serif;
    flex: 1;
    margin-left: 4px;
}

.edit-profile-modal .modal-content {
    width: 100%;
    padding: 20px 20px 30px;
    background-color: white;
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
}

.edit-profile-modal .modal-title {
    width: 100%;
    text-align: center;
    font-size: 18px;
    font-family: Yellix-Bold, serif;
    color: var(--color-text);
    margin-bottom: 12px;
}

.datepicker-modal ul.datepicker-scroll li {
    font-size: 16px;
    font-family: Yellix-SemiBold, serif;
    color: var(--color-text);
}

.camera-view {
    position: absolute !important;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    background-color: #000000;
}
