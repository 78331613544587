.courier-screen .need-support {
  background-image: url('../../assets/svgs/courier/support_bg.svg');
  height: 78vh;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  -webkit-clip-path: polygon(0 10%, 100% 0%, 100% 100%, 0 90%);
  clip-path: polygon(0 10%, 100% 0%, 100% 100%, 0 90%);
  position: relative;
  background-repeat: no-repeat !important;
  background-position: bottom !important;
  background-size: cover !important;
}

@media (max-width: 768px) {
  .courier-screen .need-support {
    height: 60vh;
  }
}

.courier-screen .need-support .title-view {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  padding-right: 20%;
}

.courier-screen .need-support h2 {
  font-family: Yellix-ExtraBold, serif;
  color: var(--color-cyan2);
}

.courier-screen .need-support h5 {
  margin-top: 0 !important;
  font-family: Yellix-SemiBold, serif;
  color: var(--color-text);
}
