.cashback-row .order-for-row .subject-title {
  font-family: Yellix-SemiBold, serif;
  color: var(--color-text);
}

.checkout-screen .order-for-row .choosed-friend {
  font-size: 16px;
  font-family: Yellix-SemiBold;
  color: var(--color-text);
  margin-right: 10px;
}

.checkout-screen .order-for-row .choose-btn {
  padding: 8px 15px;
  border-width: 1px;
  border-color: var(--color-cyan2);
  font-size: 15px;
  font-family: Yellix-SemiBold;
  color: var(--color-cyan2);
}
