.vendor-food-list {
  background-color: white;
}

.vendor-food-list .food-list {
  width: 100%;
  background-color: white;
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.vendor-food-list .category {
  font-size: 21px;
  font-family: Yellix-SemiBold, serif;
  color: var(--color-text);
  height: 48px;
  padding-bottom: 12px;
  padding-left: 15px;
}

@media (max-width: 576px) {
  .vendor-food-list .food-list .food-item {
    margin-bottom: 18px !important;
  }

  .vendor-food-list {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}
