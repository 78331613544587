.schedule-tab {
    padding-left: 0;
    padding-right: 0;
    width: 216px;
    height: 40px;
    border-radius: 15px;
    background-color: white;
    border-width: 1px;
    border-style: solid;
    border-color: #E9E9F7;
}

.schedule-tab .btn {
    flex: 1;
    cursor: pointer;
}

.schedule-tab .btn-active {
    height: 40px;
    margin-left: 0;
    margin-right: 0;
    border-radius: 15px;
    background-color: var(--color-text)
}

.schedule-tab .btn-inactive {
    height: 38px;
    margin-left: 0;
    margin-right: 0;
    border-radius: 15px;
    background-color: white;
}

.inactive_txt{
    font-size: 16px;
    color: var(--color-text);
    font-family: Yellix-SemiBold, serif;
}
.active_txt {
    font-size: 16px;
    color: white;
    font-family: Yellix-SemiBold, serif;
}