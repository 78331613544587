.invitation-screen {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  background-size: cover;
  background-position: center;
  border-top: 1px solid #e9e9f7;
}

.invitation-screen .header {
  height: 65px;
  display: flex;
  align-items: flex-end;
  padding-left: 20px;
  padding-right: 20px;
}

.invitation-screen .scrollview {
  flex: 1;
  width: 100%;
  background-color: white;
  overflow-y: auto;
  margin-top: 20px;
  padding-left: 20px;
  padding-right: 20px;
}

.invitation-screen .operation-tab {
  width: 100%;
  margin-top: 0px;
  border: 0 solid #e9e9f7;
  border-bottom-width: 1px;
}

.invitation-screen .list {
  height: calc(100vh - 160px);
}

.invitation-screen .left-side {
  border-right: 1px solid #e9e9f7;
  padding-left: 0 !important;
  padding-top: 0 !important;
}