.order-summ-screen {
  margin-top: 100px;
}

.order-summ-screen .subject-title {
  font-family: Yellix-SemiBold, serif;
  color: var(--color-text);
}

.order-summ-screen .proceed-btn {
  background-color: var(--color-cyan2) !important;
}

.order-summ-screen .lineStyle {
  border: 1px solid #e9e9f7;
  opacity: 1;
  height: 0px;
  width: 100%;
}

.order-summ-screen .back-link {
  font-size: 16px;
  font-family: Yellix-SemiBold, serif;
  color: var(--color-gray7);
  justify-content: flex-start;
  margin-top: 20px;
}

.order-summ-screen .reorder-btn {
  font-size: 16px;
  font-family: Yellix-SemiBold, serif;
  color: var(--color-text);
}

@media (max-width: 1200px) {
  .order-summ-screen .header-view {
    flex: none !important;
    width: 100% !important;
    max-width: 100% !important;
  }
}