.view-promotions {
  margin-top: 80px;
}

.view-promotions h1 {
  font-size: 24px;
  font-family: Yellix-ExtraBold, serif !important;
  color: var(--color-text);
}

.view-promotions .tab-wrapper {
  width: 100%;
  padding-top: 20px;
  padding-bottom: 20px;
  border-top-width: 1px;
  border-top-style: solid;
  border-top-color: var(--color-gray6);
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: var(--color-gray6);
}

.view-promotions .empty-view {
  padding: 60px;
  width: 100%;
}

.view-promotions h2.empty-txt {
  font-size: 16px;
  font-family: Yellix-SemiBold, serif !important;
  color: var(--color-text);
  margin-top: 35px;
}
