.home-featured-cards-block .react-multiple-carousel__arrow {
  display: none;
}

.home-featured-cards-block .react-multi-carousel-list {
  padding-bottom: 16px;
}

.home-featured-cards-block .react-multi-carousel-dot button {
  border-width: 0px;
}

.home-featured-cards-block .react-multi-carousel-dot--active button {
  background: var(--color-cyan2);
}

@media (max-width: 576px) {
  .home-featured-cards-block {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }
}