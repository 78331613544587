.address-item {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: var(--color-gray8);
  border-radius: 16px;
  cursor: pointer;
  padding: 16px;
}

.address-item .address-type {
  font-size: 11px;
  font-family: Yellix-SemiBold, serif;
  color: var(--color-cyan2);
  padding: 3px 8px;
  border-radius: 5px;
  background-color: #23cbd833;
}

.address-item .edit-link {
  font-size: 15px;
  font-family: Yellix-SemiBold, serif;
  color: var(--color-gray7);
  margin: 0;
}

.address-item .delete-link {
  font-size: 15px;
  font-family: Yellix-SemiBold, serif;
  color: var(--color-red1);
  margin: 0;
}

.address-item .makeprimary-link {
  font-size: 15px;
  font-family: Yellix-SemiBold, serif;
  color: var(--color-gray4);
  text-decoration-line: underline;
  margin: 0;
  margin-left: 8px;
}

.address-item .radio {
}

.address-item .info h3 {
  font-size: 15px;
  font-family: Yellix-SemiBold, serif;
  color: var(--color-text);
  margin-top: 7px;
  margin-bottom: 12px;
}

.address-item .info p {
  font-size: 15px;
  font-family: Yellix-Medium, serif;
  color: var(--color-text);
  margin-top: 9px;
  margin-bottom: 0;
}

.address-item .primary-text {
  margin-left: 8px;
  font-size: 15px;
  color: var(--color-red1);
  font-family: Yellix-Bold, serif;
}

.address-item .out_delivery_area_txt {
  margin-top: 4px;
  width: 100%;
  text-align: center;
  font-size: 17px;
  font-family: Yellix-SemiBold, serif;
  color: #f53d3d;
}