.partner-screen .partners {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;
}

.partner-screen .partners h3 {
  font-family: Yellix-Bold, serif;
  color: var(--color-text);
  margin-bottom: 20px;
  text-align: center;
}

.partner-screen .partners h5 {
  font-family: Yellix-SemiBold, serif;
  color: var(--color-text);
  margin-bottom: 20px;
  text-align: center;
}

.partner-screen .partners .partner-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 25px;
}

.partner-screen .partners .partner-item img {
  width: 80px;
  height: 80px;
  object-fit: contain;
  border-radius: 50%;
  border: 1px solid var(--color-gray5);
}

@media (max-width: 576px) {
  .partner-screen .partners .partner-item img {
    width: 56px;
    height: 56px;
  }
}