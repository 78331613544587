.payment-methods-card-view {
  width: 100%;
  max-width: 350px;
  height: 170px;
  padding: 20px;
  background-size: cover;
  border-radius: 20px;
  background-image: url('../../../assets/svgs/profile/card_bg.svg');
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.payment-methods-card-view h3 {
  font-size: 14px;
  color: white;
  font-family: Yellix-SemiBold, serif;
  margin: 0;
}

.payment-methods-card-view h1 {
  font-size: 20px;
  color: white;
  font-family: Yellix-SemiBold, serif;
  margin: 0;
}

.payment-methods-card-view p {
  flex: 1;
  font-size: 14px;
  color: white;
  font-family: Yellix-Medium, serif;
  margin: 0;
}
