.view-blog-details .header-bg {
  width: 100%;
  height: 520px;
  margin-top: -80px;
  background-repeat: no-repeat;
  background-size: cover;
  -webkit-clip-path: polygon(0 0%, 100% 0%, 100% 92%, 0% 100%);
  clip-path: polygon(0 0%, 100% 0%, 100% 92%, 0% 100%);
  position: relative;
}

.view-blog-details .share-btn {
  cursor: pointer;
  position: absolute;
  right: 20px;
  bottom: 12%;
}

.view-blog-details .blog-body .back-link {
  font-size: 18px;
  font-family: Yellix-SemiBold, serif;
  color: #858490;
  margin-top: 32px;
  margin-left: -8px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  text-decoration: none;
}

.view-blog-details .blog-body .back-link svg {
  padding-top: 4px;
}

.view-blog-details .blog-body .title h1 {
  font-family: Yellix-ExtraBold, serif;
  color: var(--color-text);
  margin-top: 16px;
  margin-bottom: 20px;
}

.view-blog-details .blog-body .tag-text {
  font-size: 16px;
  font-family: Yellix-SemiBold, serif;
  color: var(--color-cyan2);
  margin-top: 28px;
}

.view-blog-details .blog-body .author div:first-child {
  font-size: 16px;
  font-family: Yellix-Medium, serif;
  color: var(--color-gray7);
  margin-right: 8px;
}

.view-blog-details .blog-body .author div:last-child {
  font-size: 16px;
  font-family: Yellix-Medium, serif;
  color: var(--color-red1);
}

.view-blog-details .blog-body .date div:first-child {
  font-size: 14px;
  font-family: Yellix-Medium, serif;
  color: var(--color-gray7);
  margin-top: 14px;
}

.view-blog-details .blog-body .content {
  font-size: 16px;
  margin-top: 10px;
  margin-bottom: 30px;
}

.view-blog-details .blog-body .content img {
  object-fit: contain;
  width: 100% !important;
}

.view-blog-details .blog-body .content * {
  max-width: calc(100vw - 40px);
}

.custom-grid {
  display: flex;
  gap: 16px;
  align-items: center;
}

@media (max-width: 768px) {
  .custom-grid {
    flex-direction: column;
    gap: 8px;
  }
}

@media (max-width: 576px) {
  .view-blog-details .header-bg {
    height: 200px;
    background-size: cover;
    clip-path: none;
    -webkit-clip-path: none;
    margin-top: 0;
  }

  .view-blog-details .blog-body .content img {
    height: 100% !important;
  }
}
