.snapfooder-list-screen {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  border-top: 1px solid #e9e9f7;
}

.snapfooder-list-screen .scrollview {
  flex: 1;
  width: 100%;
  background-color: white;
  overflow-y: auto;
  margin-top: 20px;
  padding-left: 20px;
  padding-right: 20px;
}

.snapfooder-list-screen .searchview {
  width: 100%;
  padding-left: 20px;
  padding-right: 20px;
}

.snapfooder-list-screen .list {
  margin-top: 15px;
  height: calc(100vh - 160px);
}

.snapfooder-list-screen .left-side {
  border-right: 1px solid #e9e9f7;
  padding-left: 0 !important;
  padding-top: 0 !important;
}