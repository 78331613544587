.view-profile {
}

.profile {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.user-profile-info {
    display: flex;
}

.user-profile-info-img {
    position: relative;
}

.user-profile-info-img > img {
    width: 150px;
    height: 150px;
    border-radius: 80px;
    object-fit: cover;
}

.user-profile-info-img > div {
    position: absolute;
    top: 5px;
    right: 5px;
}

.user-profile-info > div {
    margin-left: 35px;
}

.user-profile-info > div h3 > img {
    width: 22px;
    height: 22px;
    object-fit: contain;
    margin-left: 5px;
}

.cont-info {
    color: #222222;
    margin-left: 35px;
}

.cont-info h3,
.user-profile-info > div h3 {
    font-family: Yellix-ExtraBold, serif;
    margin-top: 0;
}

.cont-info ul,
.user-profile-info > div ul {
    list-style-type: none;
    padding: 0;
}

.cont-info a,
.user-profile-info > div a {
    font-size: 20px;
    color: #222222;
    display: flex;
    text-decoration: none;
    align-items: center;
    margin: 15px 0;
    font-family: Yellix-SemiBold, serif;
}

.cont-info a > img,
.user-profile-info > div a > img {
    margin-right: 8px;
}

.tabs-main {
    margin-top: 40px;
}

.lis .MuiTabs-flexContainer {
    justify-content: center;
    padding: 30px 0;
    border-top: 1px solid rgba(0, 0, 0, 0.12);
}

.lis .Mui-selected {
    color: #50B7ED !important;
}

.lis .MuiButtonBase-root {
    font-family: Yellix-SemiBold, serif;
    font-size: 18px;
    text-transform: capitalize;
    padding: 15px 75px;
}

/* .lis .Mui-selected:after {
  position: absolute;
  content: '';
  background: #50B7ED;
  width: 15px;
  height: 2px;
  bottom: 24%;
} */
.confirm-indetity {
    max-width: 600px;
    margin: 0 auto;
    text-align: center;
}

.confirm-indetity p {
    color: #222222;
    font-size: 16px;
    font-family: Yellix-SemiBold, serif;
    margin-bottom: 25px;
    margin-top: 40px;
}

.view-profile .balance {
    max-width: 600px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    background: transparent linear-gradient(270deg, #00d2ff 0%, #715ee7 100%) 0% 0% no-repeat padding-box;
    box-shadow: 0px 10px 50px #007a9829;
    border-radius: 25px;
    padding: 22px 24px;
    margin-top: 30px;
}

.balanc {
    margin-left: 15px;
    margin-right: 70px;
}

.balanc h5 {
    color: white;
    font-size: 16px;
    font-family: Yellix-SemiBold, serif;
    margin: 0 0 15px 0;
}

.balanc p {
    color: #fafafccc;
    font-size: 14px;
    font-family: Yellix-Medium, serif;
    margin: 0;
}

.balance > p {
    margin: 0;
    font-size: 40px;
    color: white;
    font-family: Yellix-Bold, serif;
}

.balance > p > span {
    font-size: 20px;
}

.cashback {
    max-width: 600px;
    margin: 0px auto;
    border-top: 1px solid rgba(0, 0, 0, 0.12);
    margin-top: 30px;
    display: flex;
    flex-direction: column;
}

.cashback h2 {
    color: #222222;
    font-family: Yellix-ExtraBold, serif;
    font-size: 32px;
    margin-bottom: 40px;
}

.cashback img {
    margin: 0 auto;
    align-items: center;
}

.cashback p {
    color: #222222;
    font-size: 16px;
    font-family: Yellix-SemiBold, serif;
    margin: 40px auto 25px;
}


.view-profile .MuiTabs-indicator {
    display: none;
}

@media (max-width: 1440px) {
}

@media (max-width: 1200px) {
    .user-profile-info-img > img {
        width: 135px;
        height: 135px;
        border-radius: 22px;
    }

    .user-profile-info > div h3 img {
        width: 22px;
        height: 22px;
        object-fit: contain;
    }

    .lis .MuiTabs-flexContainer {
        padding: 26px 0;
        justify-content: start;
    }

    .lis .MuiButtonBase-root {
        padding: 15px 50px;
    }
}

@media (max-width: 992px) {
    .user-profile-info-img > img {
        width: 120px;
        height: 120px;
        border-radius: 20px;
    }

    .user-profile-info > div h3 img {
        width: 19px;
        height: 19px;
        object-fit: contain;
    }
}

@media (max-width: 768px) {
    .user-profile-info-img > img {
        width: 90px;
        height: 90px;
        border-radius: 18px;
    }

    .user-profile-info > div h3 img {
        width: 14px;
        height: 14px;
        object-fit: contain;
    }

    .profile {
        flex-direction: column;
        justify-content: center;
    }

    .cont-info {
        margin-left: 0px;
        margin-top: 15px;
    }

    .user-profile-info > div {
        margin-left: 0px;
        margin-top: 15px;
    }

    .lis .MuiButtonBase-root {
        padding: 15px 25px;
    }

    .lis .MuiTabs-flexContainer {
        padding: 18px 0;
    }

    .cont-info a,
    .user-profile-info > div a {
        font-size: 16px;
    }
}