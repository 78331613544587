.earn-screen {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.earn-screen .balance .balance-price {
    font-size: 40px;
    font-family: Yellix-Bold, serif;
    color: white;
}

.earn-screen .balance .balance-unit {
    padding-bottom: 5px;
    font-size: 20px;
    font-family: Yellix-Bold, serif;
    color: white;
}
.earn-screen .learnmore {
    font-size: 16px;
    font-family: Yellix-Medium, serif;
    color: var(--color-gray7);
    text-decoration-line: underline;
}

.earn-screen .divider {
    width: 100%;
    height: 1px;
    margin: 16px 0px;
    background-color: var(--color-gray9);
}

.earn-screen .hist-btn {

}

.earn-screen .earn-desc {
    font-size: 18px;
    font-family: Yellix-Medium, serif;
    color: var(--color-text);
    text-align: center;
}

.earn-screen .dot {
    width: 7px;
    height: 7px;
    margin-top: 10px;
    margin-right: 8px;
    border-radius: 3.5px;
    background-color: var(--color-cyan2);
}
.earn-screen .tooltip-row {
    display: flex;
    width: 100%;
    margin-top: 12px;
}
.earn-screen .tooltip-txt {
    font-size: 16px;
    font-family: Yellix-Medium, serif;
    color: var(--color-text);
}
