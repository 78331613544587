.faq-item {
  margin-bottom: 16px;
  box-shadow: none !important;
  border-radius: 12px !important;
  background-color: var(--color-gray8) !important;
}

.faq-item:before {
  height: 0 !important;
}

.faq-item,
Mui-expanded {
  margin-top: 0 !important;
}

.faq-item .answer {
  background-color: white !important;
}

.faq-item .question p {
  font-size: 20px;
  font-family: Yellix-SemiBold, serif;
  margin: 0 !important;
}
.faq-item .answer p {
  font-size: 20px;
  font-family: Yellix-Medium, serif;
  margin: 0 !important;
}

@media (max-width: 768px) {
  .faq-item .question p {
    font-size: 16px;
  }
  .faq-item .answer p {
    font-size: 16px;
  }
}