.home-app-features-carousel .react-multiple-carousel__arrow {
  display: none;
}

.home-app-features-carousel .react-multi-carousel-list {
  padding-bottom: 16px;
}

.home-app-features-carousel .react-multi-carousel-dot button {
  border-width: 0px;
}

.home-app-features-carousel .react-multi-carousel-dot--active button {
  background: var(--color-cyan2);
}

@media (max-width: 576px) {

}