/* Vendors Section */

.vendors {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px;
  padding-top: 59px;
}

.vendors .section-title {
  max-width: 680px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 27px;
}

.vendors .h1-title {
  font-family: 'Yellix-ExtraBold';
  font-style: normal;
  font-weight: 800;
  font-size: 52px;
  line-height: 56px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: -1.04px;
  color: #171717;
  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 0;
}

.vendors .vendor-subtitle {
  font-family: 'Yellix-Medium';
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 28px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #737373;
}

.grid-menu {
  margin-top: 32px;
  max-width: 1216px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  gap: 32px;
  width: 100%;
}

.feature {
  width: 50%;
  display: flex;
  flex-direction: column;
  gap: 32px;
}

.image-container {
  background: #fafafa;
  border-radius: 8px;
  position: relative;
  display: flex;
  justify-content: center;
  width: 100%;
  padding-top: 50px;
  padding-bottom: 37px;
}

.image-container .child-layer {
  background-color: white;
  padding: 28px;
  border-radius: 30px;
}

.image-container .restaurant-image {
  width: 191px;
  height: 191px;
}

.image-container .restaurant-image-mobile {
  display: none;
}

.text-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  width: 100%;
}

.overline {
  width: 100%;
  font-family: 'Yellix-Bold';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #50b7ed;
}

.heading {
  font-family: 'Yellix-ExtraBold';
  font-style: normal;
  font-weight: 800;
  font-size: 22px;
  line-height: 28px;
  letter-spacing: -0.02em;
  color: #171717;
  margin-top: 8px;
}

.description {
  width: 100%;
  font-family: 'Yellix-Regular';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  color: #737373;
  margin-top: 16px;
}

.order-button {
  text-decoration: none;
  font-family: 'Yellix-Bold';
  font-size: 16px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 20px;
  gap: 8px;
  height: 48px;
  background: #50b7ed;
  border-radius: 6px;
  border: none;
  color: white;
  cursor: pointer;
  margin-top: 32px;
}

.order-button:hover {
  background-color: #4094c7;
}

.newsletter-layer {
  margin-top: 56px;
  max-width: 1216px;
}

/* SnapFood Section */

.snapfood-title-block {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin-bottom: 40px;
}

.snapfood-title-block h1 {
  font-family: 'Yellix-ExtraBold';
  font-style: normal;
  font-weight: 800;
  font-size: 32px;
  letter-spacing: -0.64px;
  line-height: 44px;
  color: #171717;
  margin: 0 0 16px;
}

.snapfood-title-block p {
  font-family: 'Yellix-Medium';
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 28px;
  color: #737373;
  margin: 0;
  max-width: 730px;
  text-align: center;
  margin: auto;
}

.snapfood-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 32px;
}

.snapfood-feature {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  max-width: 360px;
  flex: 1;
}

.snapfood-feature-image {
  width: 76px;
  height: 80px;
  margin-bottom: 20px;
}

.snapfood-feature h2 {
  font-family: 'Yellix';
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 28px;
  color: #171717;
  margin: 0 0 16px;
}

.snapfood-feature p {
  font-family: 'Yellix';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  color: #737373;
  margin: 0;
}

/* Newsletter */

.newsletter-main-layer {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 64px 112px;
  gap: 19px;
  background: #fafafa;
  width: 100%;
}

.newsletter-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.newsletter-container .mail-icon {
  width: 48px;
  height: 48px;
  border-radius: 100%;
  background-color: #50b7ed19;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 12px;
}

.newsletter-container h1 {
  font-family: 'Yellix-ExtraBold';
  font-style: normal;
  font-weight: 800;
  font-size: 32px;
  line-height: 44px;
  letter-spacing: -0.64px;
  color: #50b7ed;
  text-align: center;
  margin: 0;
}

.newsletter-container p {
  font-family: 'Yellix-Medium';
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #404040;
  margin-top: 16px;
  margin-bottom: 32px;
}

.newsletter-container .form-section {
  width: 100%;
  width: 592px;
  display: flex;
  flex-direction: column;
}

.newsletter-content {
  display: flex;
  flex-direction: row;
  gap: 16px;
}

.newsletter-content input {
  width: 300px;
  border: 1px solid #ccc;
  border-radius: 4px;
  flex: 1;
  font-size: 14px;
  height: 40px;
  padding: 10px;
}

.newsletter-content input::placeholder {
  color: #a3a3a3;
}

.newsletter-content button {
  width: 85px;
  height: 40px;
  color: white;
  background-color: #50b7ed;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-family: 'Yellix-Medium';
  font-size: 14px;
}

.newsletter-content button:hover {
  background-color: #4094c7;
}

.newsletter-checkbox {
  display: flex;
  align-items: center;
  gap: 12px;
  margin-top: 20px;
}

.newsletter-checkbox input[type='checkbox'] {
  /* appearance: none; */
  width: 40px;
  min-width: 40px;
  height: 40px;
  border: 1px solid #e5e5e5;
  border-radius: 6px;
}

.newsletter-checkbox label {
  font-family: 'Yellix-Regular';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  color: #737373;
}

/* Media Queries */

.snapfood-ordering {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  gap: 32px;
  /* padding-inline: 2%; */
}

.snapfood-ordering h5 {
  font-family: 'Yellix-Bold';
  font-size: 22px;
  letter-spacing: -0.44px;
  color: #222222;
  opacity: 1;
  margin-top: 12px;
  margin-bottom: 16px;
}

.snapfood-ordering .sf-section {
  display: flex;
  flex-direction: column;
  align-items: start;
  width: 32%;
  min-height: 184px;
}

.sf-section .tab-img img {
  height: 80px;
  width: auto;
}

.sf-section h5 {
  margin-top: 12px;
  margin-bottom: 12px;
  font-family: 'Yellix-Bold';
  font-size: 22px;
  line-height: 28px;
  letter-spacing: 0px;
  color: #171717;
}

.sf-section p {
  margin: 0px;
  font-family: 'Yellix-Regular';
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 0px;
  color: #737373;
}

@media (max-width: 768px) {
  .vendors {
    padding: 0 16px;
  }

  .image-container .restaurant-image {
    display: none;
  }

  .image-container .restaurant-image-mobile {
    width: 100%;
    height: auto;
    display: block;
  }

  .vendors .section-title {
    gap: 16px;
  }

  .vendors .h1-title {
    margin-top: 48px;
    font-size: 36px;
    line-height: 44px;
    letter-spacing: -0.72px;
  }

  .vendors .vendor-subtitle {
    font-family: 'Yellix-Regular';
    font-size: 18px;
  }

  .image-container .child-layer {
    padding: 15px;
  }

  .heading {
    font-size: 18px;
  }

  .snapfood-title-block h1 {
    width: 280px;
    font-size: 24px;
    line-height: 32px;
  }

  .snapfood-title-block p {
    font-family: 'Yellix-Regular';
    font-size: 18px;
    width: 300px;
  }

  .snapfood-ordering .sf-section,
  .snapfood-ordering .sf-section>div:nth-child(2) {
    width: 100%;
  }

  .sf-section .tab-img {
    margin: auto;
  }

  .snapfood-ordering {
    gap: 10px;
  }

  .snapfood-ordering h5 {
    font-size: 18px;
    text-align: center;
  }

  .snapfood-ordering p {
    font-size: 16px;
    text-align: center;
    line-height: 24px;
  }

  .grid-menu {
    flex-direction: column;
    align-items: center;
  }

  .grid-menu .feature {
    width: 100%;
  }

  .snapfood-ordering {
    flex-direction: column;
  }

  .snapfood-row {
    flex-direction: column;
    align-items: center;
  }

  .newsletter-main-layer {
    padding: 32px 16px;
  }

  .newsletter-content {
    flex-direction: column;
    align-items: stretch;
  }

  .newsletter-container h1 {
    width: 320px;
    font-size: 28px;
    line-height: 40px;
  }

  .newsletter-container p {
    font-size: 14px;
  }

  .newsletter-container .form-section {
    width: calc(100vw - 32px);
  }

  .newsletter-content input {
    width: 100%;
    margin-bottom: 10px;
  }

  .newsletter-content button {
    width: 100%;
  }

  .newsletter-checkbox label {
    font-size: 14px;
  }
}


.vendor-landing .vendor-info {
  margin-top: 40px;
  width: 100%;
  max-width: 1216px;
  border-radius: 13px;
  background-color: #FAFAFA;
  padding: 56px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
}

.vendor-landing .vendor-info .info {
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.vendor-landing .vendor-info .info .tags {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 20px;
}


.vendor-landing .vendor-info .info .tag {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;

  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  color: #FF9C00;

  height: 32px;
  border-radius: 24px;
  padding-left: 12px;
  padding-right: 12px;

  white-space: nowrap;
}

.vendor-landing .vendor-info .order-now {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
  height: 48px;
  border-radius: 6px;
  padding-left: 20px;
  padding-right: 20px;
  background-color: #50B7ED;

  font-size: 16px;
  line-height: 24px;
  font-weight: 700;
  color: #fff;
  margin-top: 24px;
}

.vendor-landing .vendor-info .info h1 {
  font-size: 56px;
  line-height: 122%;
  font-weight: 900;
  color: #1B1B1B;
  margin-top: 28px;
  margin-bottom: 28px;
}

.vendor-landing .vendor-info .info p {
  font-size: 18px;
  line-height: 26px;
  color: #5C5C5C;
  margin-top: 12px;
}

.vendor-landing .vendor-info .photo {
  width: 50%;
  height: 470px;
  border-radius: 6px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

  padding-left: 0;
  margin-left: 0;
  border-left-width: 0;

  display: flex;
  justify-content: center;
  align-items: flex-end;
  padding-bottom: 40px;
}

.vendor-landing .vendor-info .wifi-block {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  padding: 24px;
  background-color: white;
  gap: 16px;
}

.vendor-landing .vendor-info .wifi-block h3 {
  font-size: 18px;
  line-height: 28px;
  font-weight: 500;
  color: #171717;
  margin-top: 0;
  margin-bottom: 0;
}

.vendor-landing .vendor-info .wifi-block p {
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  color: #737373;
  margin: 0;
}

@media (max-width: 768px) {
  .vendor-landing .vendor-info {
    padding: 20px;
    flex-direction: column;
  }

  .vendor-landing .vendor-info .info {
    width: 100%;
    align-items: center;
  }

  .vendor-landing .vendor-info .photo {
    width: 100%;
  }

  .vendor-landing .vendor-info .info .tags {
    gap: 10px;
  }

  .vendor-landing .vendor-info .info h1 {
    font-size: 32px;
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .vendor-landing .vendor-info .info p {
    font-size: 16px;
    line-height: 24px;
  }

  .vendor-landing .vendor-info .photo {
    width: 100%;
    height: 280px;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 40px;
  }


  .vendor-landing .vendor-info .wifi-block {
    padding: 12px;
    gap: 10px;
  }

  .vendor-landing .vendor-info .wifi-block h3 {
    font-size: 16px;
    line-height: 24px;
  }

  .vendor-landing .vendor-info .wifi-block p {
    font-size: 12px;
    line-height: 18px;
  }

}


.popular-items {
  margin-top: 56px;
  width: 100%;
  max-width: 1216px;
}

.popular-items .grids {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
}

.popular-items .product-item {
  background-color: #FAFAFC;
  border-radius: 16px;
  padding: 16px;
  position: relative;
}

.popular-items .product-item h3 {
  font-family: 'Yellix-Bold';
  font-size: 18px;
  color: #222;
  margin: 0;
}

.popular-items .product-item h5 {
  font-family: 'Yellix-Bold';
  font-size: 19px;
  color: #222;
  margin: 0;
  margin-top: 12px;
  margin-bottom: 12px;
}

.popular-items .product-item p {
  font-family: 'Yellix-Regular';
  font-size: 17px;
  color: #6A6A6A;
  margin: 0;
}

.popular-items .product-item .fav-btn {
  position: absolute;
  top: 12px;
  right: 12px;
}

@media (max-width: 768px) {
  .popular-items .grids {
    grid-template-columns: repeat(1, 1fr);
    gap: 20px;
  }
}

.reviews {
  margin-top: 56px;
  width: 100%;
  max-width: 1216px;
  padding-top: 32px;
  padding-bottom: 32px;
  border-radius: 8px;
  background-color: #FAFAFA;
}

.reviews .review-item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 60%;
}

.reviews .review-item .title-view {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 28px;
}

.reviews .review-item h1 {
  font-family: 'Yellix-ExtraBold';
  font-size: 32px;
  color: #171717;
  margin: 0;
}

.reviews .review-item p {
  font-size: 18px;
  line-height: 24px;
  color: #737373;
  margin: 24px 0px;
  text-align: center;
}

.reviews .review-item h4 {
  font-family: 'Yellix-ExtraBold';
  font-size: 18px;
  line-height: 28px;
  color: #171717;
  margin: 0;
}

.reviews .react-multi-carousel-item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.reviews .react-multi-carousel-dot button {
  border-width: 0;
  background-color: #D9D9D9;
}

.reviews .react-multi-carousel-dot--active button {
  background-color: #8C8C8C;
}

.reviews .react-multi-carousel-list {
  padding-bottom: 40px;
}

@media (max-width: 768px) {
  .reviews {
    margin-top: 40px;
    padding-top: 28px;
    padding-bottom: 28px;
  }

  .reviews .review-item {
    width: 100%;
    padding-left: 10px;
    padding-right: 10px;
  }

  .reviews .review-item .title-view {
    gap: 24px;
  }

  .review-item img {
    width: 32px;
    object-fit: contain;
  }

  .reviews .review-item h1 {
    font-size: 24px;
  }

  .reviews .review-item p {
    font-size: 14px;
    line-height: 20px;
    margin: 18px 0px;
  }

  .reviews .review-item h4 {
    font-size: 18px;
    line-height: 22px;
  }
}

.faqs {
  margin-bottom: 56px;
  width: 100%;
  max-width: 1216px;
  padding-bottom: 20px;
  padding-left: 20px;
  padding-right: 20px;
  border-radius: 8px;
  background-color: #FAFAFA;
  border-width: 1px;
  border-color: #E5E5E5;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.faqs h1 {
  font-family: 'Yellix-ExtraBold';
  font-size: 36px;
  line-height: 44px;
  color: #171717;
  margin-top: 24px;
  margin-bottom: 24px;
}

@media (max-width: 768px) {
  .faqs {
    margin-bottom: 40px;
    width: 100%;
  }
  
  .faqs h1 {
    font-size: 24px;
    line-height: 32px;
    text-align: center;
  }
}