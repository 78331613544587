.socialBtn,
#appleid-signin {
  background-color: var(--color-whitePrimary);
  margin-top: 10px;
  width: 40px;
  height: 40px;
  border: 1px solid var(--color-gray5);
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  font-size: 20px;
  cursor: pointer;
}
