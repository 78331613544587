.support-msg-item {
  height: 32px;
  border-radius: 7px;
  padding: 4px 12px;
  background-color: #c0ebec;
  margin-right: 12px;
  font-size: 15px;
  line-height: 25px;
  font-family: Yellix-SemiBold;
  color: var(--color-cyan2);
}
