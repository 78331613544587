@import url(https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700;800&display=swap);
.header {
  display: flex;
  justify-content: space-between;
  padding: 18px 32px 8px 32px;
  height: 80px;
}

.header-bottom-border {
  box-shadow: rgb(0 0 0 / 45%) 0px 0px 3px 0px;
}



.app-home-header {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1100;
  width: 100%;
}

.app-sticky-header {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1100;
  width: 100%;
  background-color: white;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: var(--color-gray6);
}

.header .left-side {
  z-index: 1;
}

.header .drawerBtn {
  margin-right: 20px;
  width: 46px;
  min-width: 46px;
}

.header .drawerBtn svg {
  -webkit-transform: rotateZ(180deg);
          transform: rotateZ(180deg);
}

.header .header-logo {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
}

.header .header-logo > img {
  height: 40px;
  width: 250px;
  object-fit: contain;
}

.login-button {
  width: 46px;
  height: 46px;
  border-radius: 24px;
  background-color: var(--color-text);
  margin-right: 20px;
  z-index: 2;
  font-size: 20px;
}

.lang-container{
}

.header .header-logo > .vendor {
  font-size: 13px;
  font-family: Yellix-Light, serif;
  color: white;
  background-color: var(--color-text);
  padding: 5px 12px;
  border-radius: 8px;
  margin-top: -2px;
}

.header > div.right-side {
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
}

.header .user-info {
  text-decoration: none;
}

.header .user-info img {
  width: 46px;
  height: 46px;
  border-radius: 30px;
  margin-right: 10px;
}

.header .user-info h2 {
  color: var(--color-text);
  font-size: 18px;
  font-family: Yellix-SemiBold, serif;
  margin: 0;
  white-space: nowrap;
}

.header > div.right-side .cart-btn {
  margin-left: 40px;
}

.header > div.right-side .cart-btn img {
  width: 44px;
  height: 46px;
}

.header > div.right-side .cart-btn .cart-badge {
  position: absolute;
  top: -5px;
  right: -5px;
  width: 22px;
  height: 22px;
  border-radius: 11px;
  background-color: var(--color-primary);
  font-size: 10px;
  line-height: 14px;
  font-family: Yellix-SemiBold;
  color: white;
}

.header > div.right-side .divider {
  margin-left: 40px;
  margin-right: 40px;
  height: 36px;
  width: 2px;
  background-color: #efefef;
}
.header > div.right-side .dropdown {
  background-color: transparent;
}

.header .scan-button {
  width: 230px;
  height: 44px;
  border-radius: 30px;
  border-width: 1px;
  border-style: solid;
  border-color: var(--color-primary);
  background-color: white;
  font-size: 17px;
  line-height: 23px;
  font-family: Yellix-SemiBold;
  color: var(--color-primary);
  margin-right: 20px;
  cursor: pointer;
}

.header .scan-button span{
  padding-left: 6px;
}

@media screen and (max-width: 930px) {
  .header .user-info {
    display: none;
  }
}

@media screen and (max-width: 768px) {
  .header .header-logo > img {
    height: 37.5px;
    width: 160px;
    object-fit: contain;
  }

  .header .drawerBtn {
    margin-right: 10px;
    padding-left: 0px;
    padding-right: 0px;
    padding-top: 0px;
    padding-bottom: 0px;
    width: 30px;
    min-width: 30px;
  }

  .header {
    padding: 18px 0px 8px 0px;
  }
}


@media screen and (max-width: 576px) {
  .login-button {
    /* width: 120px; */
    width: 42px;
    height: 42px;
    margin-right: 15px;
    z-index: 2
  }
  .lang-container {
    margin-left: 0px;
  }

  .header > div.right-side .divider {
    margin-left: 0px;
    margin-right: 15px;
    width: 0px;
  }

  .header > div.right-side .cart-btn img {
    width: 40px;
    height: 42px;
  }
}

@media screen and (max-width: 370px) {
  .header .header-logo > img {
    height: 37.5px;
    width: 140px;
    object-fit: contain;
  }

  .login-button {
    /* width: 120px; */
    width: 36px;
    height: 36px;
    margin-right: 6px;
    z-index: 2
  }

  .header > div.right-side .cart-btn {
    margin-left: 10px;
  }

  .header > div.right-side .cart-btn img {
    width: 36px;
    height: 38px;
  }
}

@media screen and (max-width: 330px) {
  .header .header-logo > img {
    height: 37.5px;
    width: 120px;
    object-fit: contain;
  }
}
/* address map modal */

.get-app-modal .MuiDialog-paper {
  border-radius: 16px;
  width: 460px !important;
  max-width: calc(100vw - 32px) !important;
}

.get-app-modal .content {
  background-color: white;
  padding: 25px;
}

.get-app-modal .content img.close-btn {
  width: 40px;
  height: 40px;
  object-fit: contain;
  cursor: pointer;
}

.get-app-modal h2 {
  font-size: 24px;
  line-height: 32px;
  font-family: Yellix-Bold, serif;
  color: var(--color-text);
  margin: 0;
}

.get-app-modal .content h4{
  font-size: 20px;
  line-height: 28px;
  font-family: Yellix-Bold, serif;
  color: var(--color-text);
  width: 80%;
  text-align: center;
}

.get-app-modal .content p{
  font-size: 16px;
  line-height: 24px;
  font-family: Yellix-Medium, serif;
  color: var(--color-text);
}

.get-app-modal .badge-wrap{
  flex-wrap: wrap;
}

.get-app-modal .badge {
  width: 150px;
  height: 52px;
  background-repeat: no-repeat;
  display: table;
  margin: 0 12px;
  background-size: contain;
  margin-bottom: 10px;
}

.get-app-modal .badge-ios {
  background-image: url(/static/media/app_store.df809ae9.svg);
}

.get-app-modal .badge-android {
  background-image: url(/static/media/googleplay.5d3345a0.svg);
}
.modal .MuiDialog-container {
  width: 100%;
  max-width: calc(100% - 64px);
  padding-top: 80px;
}

.modal .MuiDialog-paper {
  width: 100%;
  margin: 0 !important;
  border-radius: 12px;
}

.quiz-modal-content {
  padding-inline: 10px;
}

.quiz-footer {
  padding-inline: 10px;
  padding-bottom: 1%;
  display: flex;
  justify-content: space-between;
  grid-gap: 2px;
  gap: 2px;
  align-items: center;
}

@media (max-width: 576px) {
  .modal .MuiDialog-container {
    max-width: calc(100% - 20px);
  }

  .modal .MuiDialog-paper {
    border-radius: 6px;
    max-height: calc(100% - 20px);
  }

  .modal .close-btn {
    width: 32px !important;
    height: 32px !important;
  }
}

.vendor-closed-modal {
  width: 676px;
}

.vendor-closed-modal .MuiDialog-paper {
  border-radius: 16px;
}

.vendor-closed-modal .content {
  background-color: white;
  border-radius: 16px;
  padding: 32px 40px;
}

.vendor-closed-modal .content h3 {
  font-family: Yellix-Bold, serif;
  color: var(--color-text);
  margin: 0;
}

.vendor-closed-modal .content p {
  font-size: 18px;
  font-family: Yellix-Bold, serif;
  color: var(--color-text);
  margin-top: 20px;
  margin-bottom: 40px;
  text-align: center;
}

.vendor-closed-modal .actions .explore {
  margin-top: 20px;
  width: calc(50% - 10px);
  background-color: var(--color-gray6) !important;
  color: var(--color-gray7);
}

.vendor-closed-modal .actions .see_menu {
  margin-top: 20px;
  width: calc(50% - 10px);
  background-color: var(--color-cyan2) !important;
  color: white;
}

@media (max-width: 768px) {
  .vendor-closed-modal .content {
    padding: 24px 18px;
  }
  .vendor-closed-modal .content p {
    font-size: 16px;
  }
  .vendor-closed-modal .actions {
    flex-direction: column;
  }
}

@media (max-width: 576px) {
  .vendor-closed-modal .content {
    padding: 24px 18px;
  }
  .vendor-closed-modal .content p {
    font-size: 16px;
  }
  .vendor-closed-modal .actions {
    flex-direction: column;
  }

  .vendor-closed-modal .actions .explore,
  .vendor-closed-modal .actions .see_menu {
    width: 100%;
  }
}

/* remove cart item confirm modal */

.remove-cart-modal {
  width: 676px;
}

.remove-cart-modal .MuiDialog-paper {
  border-radius: 16px;
}

.remove-cart-modal .content {
  background-color: white;
  padding: 26px 36px;
  padding-bottom: 16px;
}

.remove-cart-modal .content h2 {
  font-size: 18px;
  font-family: Yellix-Bold, serif;
  color: var(--color-text);
  margin-top: 0;
  margin-bottom: 20px;
  text-align: center;
}

.remove-cart-modal .actions .cancel-btn {
  width: 100%;
  background-color: white !important;
  color: var(--color-gray7);
  margin-top: 12px;
}

.remove-cart-modal .actions .sure-btn {
  width: 100%;
  background-color: var(--color-cyan2) !important;
  color: white;
}

/* end */

/* Restaurant Tip Modal*/
.resturant-tip-modal {
  width: 676px;
}

.resturant-tip-modal .MuiDialog-paper {
  border-radius: 16px;
}

.resturant-tip-modal .content {
  background-color: white;
  padding: 26px 36px;
}
.resturant-tip-modal .content img.close-btn {
  width: 40px;
  height: 40px;
  object-fit: contain;
  position: absolute;
  left: 0;
  top: 0;
  cursor: pointer;
}

.resturant-tip-modal .content h1 {
  font-size: 32px;
  font-family: Yellix-Bold, serif;
  color: var(--color-text);
  margin-top: 0;
  margin-bottom: 20px;
  text-align: center;
}

.resturant-tip-modal .content .tip-text {
  overflow-y: auto;
}

.resturant-tip-modal .content .tip-text p {
  font-size: 16px;
  font-family: Yellix-Medium, serif;
  color: #6a6a6a;
  margin-top: 0;
}

/* end */

/* Split Bill Modal */

.split-bill-modal {
  width: 676px;
}

.split-bill-modal .MuiDialog-paper {
  border-radius: 16px;
  width: 600px;
  max-width: calc(100vw - 32px) !important;
}

.split-bill-modal .content {
  background-color: white;
  padding: 26px 36px;
}
.split-bill-modal .content img.close-btn {
  width: 40px;
  height: 40px;
  object-fit: contain;
  position: absolute;
  left: 0;
  top: 0;
  cursor: pointer;
}

.split-bill-modal .content h1 {
  font-size: 32px;
  font-family: Yellix-Bold, serif;
  color: var(--color-text);
  margin-top: 0;
  margin-bottom: 20px;
  text-align: center;
}

.split-bill-modal .content .list-btn {
  height: 60px;
  border-radius: 12px;
  background-color: var(--color-gray8);
  cursor: pointer;
  margin-top: 16px;
  padding: 8px 20px;
}

.split-bill-modal .content .list-btn p {
  flex: 1 1;
  font-size: 16px;
  font-family: Yellix-SemiBold, serif;
  color: var(--color-text);
  margin: 0;
  padding: 0;
}

/* end */

/* Order Failed Modal */
.order-failed-modal {
  width: 676px;
}

.order-failed-modal .MuiDialog-paper {
  border-radius: 16px;
  width: 600px;
  max-width: calc(100vw - 32px) !important;
}

.order-failed-modal .content {
  background-color: white;
  padding: 26px 18px;
}

.order-failed-modal .content h2 {
  font-size: 18px;
  font-family: Yellix-Bold, serif;
  color: var(--color-text);
  margin-top: 20px;
  margin-bottom: 20px;
  text-align: center;
}

.order-failed-modal .actions .save-btn {
  width: 100%;
  background-color: var(--color-gray6) !important;
  color: var(--color-gray7);
  margin-top: 12px;
}

.order-failed-modal .actions .try-btn {
  width: 100%;
  background-color: var(--color-cyan2) !important;
  color: white;
}
/* end */

/* Allergies Modal*/
.allergy-modal {
  width: 676px;
}

.allergy-modal .MuiDialog-paper {
  border-radius: 16px;
}

.allergy-modal .content {
  background-color: white;
  padding: 26px 36px;
}
.allergy-modal .content img.close-btn {
  width: 40px;
  height: 40px;
  object-fit: contain;
  position: absolute;
  left: 0;
  top: 0;
  cursor: pointer;
}
.allergy-modal .content img.call-btn {
  width: 40px;
  height: 40px;
  object-fit: contain;
  position: absolute;
  right: 0;
  top: 0;
  cursor: pointer;
}

.allergy-modal .content img.bg {
  width: 370px;
  height: 173px;
  object-fit: contain;
  margin-top: 56px;
}

.allergy-modal .content h1 {
  font-size: 32px;
  font-family: Yellix-Bold, serif;
  color: var(--color-text);
  margin-top: 18px;
  margin-bottom: 20px;
  text-align: center;
}

.allergy-modal .content .tip-text {
  overflow-y: auto;
}

.allergy-modal .content .tip-text p {
  font-size: 16px;
  font-family: Yellix-Medium, serif;
  color: #6a6a6a;
  margin-top: 0;
}

/* end */

/* Allergies Modal*/
.restricted-item-modal {
  width: 676px;
}

.restricted-item-modal .MuiDialog-paper {
  border-radius: 16px;
}

.restricted-item-modal .content {
  background-color: white;
  padding: 26px 24px;
}
.restricted-item-modal .content img.close-btn {
  width: 40px;
  height: 40px;
  object-fit: contain;
  position: absolute;
  left: 0;
  top: 0;
  cursor: pointer;
}
.restricted-item-modal .content img.call-btn {
  width: 40px;
  height: 40px;
  object-fit: contain;
  position: absolute;
  right: 0;
  top: 0;
  cursor: pointer;
}

.restricted-item-modal .content img.bg {
  width: 225px;
  height: 195px;
  object-fit: contain;
  margin-top: 56px;
}

.restricted-item-modal .content h1 {
  font-size: 32px;
  font-family: Yellix-Bold, serif;
  color: var(--color-text);
  margin-top: 18px;
  margin-bottom: 0;
  text-align: center;
}

.restricted-item-modal .content h3 {
  font-size: 18px;
  font-family: Yellix-Bold, serif;
  color: var(--color-text);
  margin-top: 12px;
  margin-bottom: 0;
  text-align: center;
}

.restricted-item-modal .content .tip-text {
  overflow-y: auto;
}

.restricted-item-modal-modal .content .tip-text p {
  font-size: 16px;
  font-family: Yellix-Medium, serif;
  color: #6a6a6a;
  margin-top: 0;
}

/* end */

/* delivery schedule modal */
.delivery-schedule-modal {
  width: 676px;
}

.delivery-schedule-modal .MuiDialog-paper {
  border-radius: 16px;
  width: 600px;
  max-width: calc(100vw - 32px) !important;
}

.delivery-schedule-modal .content {
  background-color: white;
  padding: 26px 36px;
}

.delivery-schedule-modal .content h1.title {
  font-size: 32px;
  font-family: Yellix-Bold, serif;
  color: var(--color-text);
  margin: 0px;
  text-align: center;
}

.delivery-schedule-modal .content .date-input {
  z-index: 2;
}

.delivery-schedule-modal .content .time-input {
  z-index: 1;
}

.delivery-schedule-modal .content img.close-btn {
  width: 40px;
  height: 40px;
  object-fit: contain;
  position: absolute;
  left: 0;
  top: 0;
  cursor: pointer;
}

.delivery-schedule-modal .actions .deliver-btn {
  width: 100%;
  background-color: var(--color-gray6) !important;
  color: var(--color-gray7);
  margin-top: 12px;
}

.delivery-schedule-modal .actions .save-btn {
  width: 100%;
  background-color: var(--color-cyan2) !important;
  color: white;
}
/* end */

/* delivery address modal */
.delivery-address-modal {
  width: 676px;
}

.delivery-address-modal .MuiDialog-paper {
  border-radius: 16px;
  width: 600px;
  max-width: calc(100vw - 32px) !important;
}

.delivery-address-modal .content {
  background-color: white;
  padding: 26px 18px;
}

.delivery-address-modal .content h4.title {
  font-family: Yellix-Bold, serif;
  color: var(--color-text);
  margin: 0px;
  text-align: center;
}

.delivery-address-modal .content img.close-btn {
  width: 40px;
  height: 40px;
  object-fit: contain;
  position: absolute;
  left: 0;
  top: 0;
  cursor: pointer;
}

.delivery-address-modal .list {
  margin-top: 26px;
}

.delivery-address-modal .list .add-btn {
  background-color: white !important;
  font-size: 18px;
  font-family: Yellix-SemiBold, serif;
  color: var(--color-cyan2);
  margin-bottom: 20px;
  margin-top: 0;
  cursor: pointer;
}

.delivery-address-modal .actions .save-btn {
  width: 100%;
  background-color: var(--color-cyan2) !important;
  color: white;
}
/* end */

/* add address modal */
.add-address-modal {
  width: 676px;
}

.add-address-modal .MuiDialog-paper {
  border-radius: 16px;
  width: 560px;
  max-width: calc(100vw - 32px) !important;
}

.add-address-modal .content {
  background-color: white;
  padding: 26px 26px;
}

.add-address-modal .content h4.title {
  font-family: Yellix-Bold, serif;
  color: var(--color-text);
  margin: 0px;
  text-align: center;
}

.add-address-modal .content img.close-btn {
  width: 40px;
  height: 40px;
  object-fit: contain;
  position: absolute;
  left: 0;
  top: 0;
  cursor: pointer;
}

.add-address-modal .list {
  margin-top: 26px;
}

.add-address-modal .list .add-btn {
  background-color: white !important;
  font-size: 18px;
  font-family: Yellix-SemiBold, serif;
  color: var(--color-cyan2);
  margin-bottom: 20px;
  margin-top: 0;
  cursor: pointer;
}

.add-address-modal .actions .save-btn {
  width: 100%;
  background-color: var(--color-cyan2) !important;
  color: white;
}

.center-div {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px;
}

.add-address-modal .form .row {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 8px 0px;
}

.add-address-modal .form div.label {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 16px;
  font-family: Yellix-SemiBold, serif;
  color: var(--color-text);
  margin-right: 16px;
}

.add-address-modal .form .row div.input-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex: 1 1;
}

.add-address-modal .form .row div.input-wrapper .text-dropdown {
  width: 100%;
  height: 48px;
}

.add-address-modal .form .row div.input-wrapper .text-dropdown .text-drop-btn {
  height: 48px;
}

.add-address-modal .form .find-btn {
  font-size: 16px;
  font-family: Yellix-SemiBold, serif;
  color: var(--color-cyan2);
  margin-bottom: 40px;
  margin-top: 10px;
  cursor: pointer;
  text-align: center;
}

/* end */

/* pickup time modal */
.pickup-time-modal {
  width: 676px;
}

.pickup-time-modal .MuiDialog-paper {
  border-radius: 16px;
  width: 600px;
  max-width: calc(100vw - 32px) !important;
}

.pickup-time-modal .content {
  background-color: white;
  padding: 26px 18px;
}

.pickup-time-modal .content h4.title {
  font-family: Yellix-Bold, serif;
  color: var(--color-text);
  margin: 0px;
  text-align: center;
}

.pickup-time-modal .content .date-input {
  z-index: 2;
}

.pickup-time-modal .content .time-input {
  z-index: 1;
}

.pickup-time-modal .content img.close-btn {
  width: 40px;
  height: 40px;
  object-fit: contain;
  position: absolute;
  left: 0;
  top: 0;
  cursor: pointer;
}

.pickup-time-modal .actions .deliver-btn {
  width: 100%;
  background-color: var(--color-gray6) !important;
  color: var(--color-gray7);
  margin-top: 12px;
}

.pickup-time-modal .actions .save-btn {
  width: 100%;
  background-color: var(--color-cyan2) !important;
  color: white;
}
/* end */

/* add card modal */
.add-card-modal {
  width: 676px;
}

.add-card-modal .MuiDialog-paper {
  border-radius: 16px;
  width: 560px;
  max-width: calc(100vw - 32px) !important;
}

.add-card-modal .card-bg {
  width: 335px;
  height: 170px;
  padding: 32px 20px;
  background-image: url(/static/media/card_bg.66034147.svg);
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 36px;
  margin-bottom: 12px;
}

.add-card-modal .card-bg h3 {
  font-size: 12px;
  font-family: Yellix-SemiBold, serif;
  color: white;
  margin: 0;
}

.add-card-modal .card-bg h1 {
  font-size: 18px;
  font-family: Yellix-SemiBold, serif;
  color: white;
  margin: 0;
}

.add-card-modal .card-bg .cvv_expiry {
  font-size: 12px;
  font-family: Yellix-SemiBold, serif;
  color: white;
  margin: 0;
}

.add-card-modal .content {
  background-color: white;
  padding: 26px 18px;
}

.add-card-modal .content h4.title {
  font-family: Yellix-Bold, serif;
  color: var(--color-text);
  margin: 0px;
  text-align: center;
}

.add-card-modal .content img.close-btn {
  width: 40px;
  height: 40px;
  object-fit: contain;
  position: absolute;
  left: 0;
  top: 0;
  cursor: pointer;
}

.add-card-modal .actions .save-btn {
  width: 100%;
  background-color: var(--color-cyan2) !important;
  color: white;
}

.add-card-modal .row {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 8px 0px;
}

/* end */

/* address map modal */
.address-map-modal {
  width: 876px;
}

.address-map-modal .MuiDialog-paper {
  border-radius: 16px;
  width: 800px;
  max-width: calc(100vw - 32px) !important;
}

.address-map-modal .content {
  background-color: white;
  padding: 0px;
}

.address-map-modal .map-view {
  width: 100%;
  height: 400px;
  max-height: calc(100vh - 100px) !important;
}

.address-map-modal .search-bar {
  width: 100%;
  padding: 20px;
  background-color: white;
}

.address-map-modal .search-bar button {
}

.address-map-modal .search-view p {
  font-size: 10px;
  font-family: Yellix-SemiBold;
  color: var(--color-gray5);
  margin-top: 14px;
  margin-bottom: 0;
}

.address-map-modal .search-view h4 {
  font-size: 14px;
  font-family: Yellix-SemiBold;
  color: var(--color-text);
  margin-top: 5px;
  margin-bottom: 0;
}

.address-map-modal .save-btn {
  width: 100%;
  max-width: 400px;
  background-color: var(--color-cyan2) !important;
  color: white;
}
/* end*/


.choose-lang-modal .MuiDialog-paper {
    border-radius: 16px;
    width: 560px !important;
    max-width: calc(100vw - 32px) !important;
}

.choose-lang-modal .content {
    background-color: white;
    padding: 26px 0px;
    overflow: hidden;
}

.choose-lang-modal .content h3.title {
    font-family: Yellix-Bold, serif;
    color: var(--color-text);
    margin: 0px;
    text-align: center;
}

.choose-lang-modal .content img.close-btn {
    width: 40px;
    height: 40px;
    object-fit: contain;
    position: absolute;
    left: 15px;
    top: 0;
    cursor: pointer;
}

.choose-lang-modal .save-btn {
    width: 100%;
    background-color: var(--color-cyan2) !important;
    color: white;
}


.choose-lang-modal .header {
    height: 55px;
    display: flex;
    align-items: flex-end;
    padding-left: 20px;
    padding-right: 20px;
}

.choose-lang-modal .scroll_view {
    flex: 1 1;
    width: 100%;
    background-color: white;
    margin-top: 18px;
    overflow-y: auto;
}


.choose-lang-modal .list-btn {
    background-color: var(--color-gray8) !important;
    color: var(--color-text);
    height: 48px;
    border-radius: 10px;
    margin-bottom: 15px;
    margin-top: 15px;
}

.choose-lang-modal .MuiList-root {
    padding-bottom: 0 !important;
    padding-top: 0 !important;
}

.choose-lang-modal .list-btn span {
    font-size: 16px;
    font-family: Yellix-Bold, serif;
    color: var(--color-text);
}

.choose-lang-modal .choose-link {
    font-size: 16px;
    font-family: Yellix-Bold, serif;
    color: var(--color-cyan2);
}
.footer,
.footer-dark-img {
  background-image: url(/static/media/footer_bg.bafc4590.svg);
  background-repeat: no-repeat;
  background-size: cover;
}

.footer {
  /*-webkit-clip-path: polygon(0 0, 100% 15%, 100% 100%, 0 100%);*/
  /*clip-path: polygon(0 0, 100% 15%, 100% 100%, 0 100%);*/
  position: relative;
  padding-bottom: 40px;
}

.footer.landing {
  margin-top: -100px;
}

/*.footer:after {*/
/*  !*-webkit-clip-path: polygon(0 0, 100% 15%, 100% 100%, 0 85%);*!*/
/*  !*clip-path: polygon(0 0, 100% 15%, 100% 100%, 0 85%);*!*/
/*  content: '';*/
/*  position: absolute;*/
/*  left: 0;*/
/*  top: -82%;*/
/*  right: 0;*/
/*  height: 100%;*/
/*  display: block;*/
/*  z-index: 9;*/
/*}*/

.footer > div {
  padding-top: 150px;
  padding-bottom: 40px;
}

.footer img.logo {
  height: 36px;
  width: 100%;
  object-fit: contain;
}

.footer h4.subject {
  font-size: 20px;
  font-family: Yellix-SemiBold, serif;
  color: var(--color-cyan2);
  margin-top: 0 !important;
  margin-bottom: 24px !important;
}

.footer ul {
  padding: 0;
  margin: 0;
}

.footer li {
  list-style: none;
  margin-bottom: 24px;
}

.footer a {
  font-size: 18px;
  font-family: Yellix-Medium, serif;
  color: white !important;
  text-decoration: none;
  background-color: transparent;
}

.footer a :hover {
  color: #0d5bdd;
  text-decoration: underline;
}

.footer .social a {
  margin-right: 24px;
}

.footer .lang-link {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

@media screen and (max-width: 850px)  {
  .footer,
  .footer-dark-img {
    background-color: transparent !important;
  }
}

@media screen and (max-width: 768px) { 

  .footer img.logo {
    margin-bottom: 50px;
  }

  .footer.landing {
    margin-top: -44px;
  }

  .footer h4.subject {
    font-size: 25px;
    margin-top: 19px !important;
  }


.footer > div {
  padding-top: 100px;
}
}
.app-btn {
  width: 100%;
  height: 54px;
  background-color: var(--color-text) !important;
  font-size: 16px !important;
  font-family: Yellix-Medium, serif !important;
  border-radius: 12px !important;
  text-transform: none !important;
  box-shadow: none !important;
}

.Mui-disabled {
  color: var(--color-gray6) !important;
}

.auth-btn {
  background-color: var(--color-cyan2) !important;
  color: white;
}

.auth-btn svg {
  color: white;
}

.main-btn {
  background-color: var(--color-text) !important;
  color: white;
}

/* switch button */
.switch-btn {
  width: 100%;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
  border: 1px solid var(--color-gray5) !important;
}

.switch-btn button {
  flex: 1 1;
  font-size: 17px !important;
  padding: 0 !important;
}

.switch-btn button.active {
  background-color: var(--color-cyan2) !important;
  color: white;
}

.switch-btn button.inactive {
  background-color: white !important;
  color: var(--color-text);
}

/* end switch button */

/* city button */
.city-btn {
  height: 110px;
  background-color: white !important;
  margin-bottom: 25px !important;
  border-radius: 16px !important;
  padding-right: 25px !important;
  padding-left: 25px !important;
}
.city-btn .city-btn-title {
  font-size: 22x;
  font-family: Yellix-SemiBold, serif;
  color: var(--color-text);
}

/* end city button */

/* drawer item button */
.draweritem-btn {
  background-color: var(--color-gray8) !important;
  color: var(--color-text);
  height: 48px;
}
/* end drawer item button */

/* outline button */
.outline-btn {
  background-color: white !important;
  color: var(--color-text) !important;
  border: 1px solid var(--color-text) !important;
}
/* end outline button */

/* round-icon-btn button */
.round-icon-btn {
  width: 50px;
  height: 50px;
  background-color: white !important;
  border-radius: 13px;
  border-width: 1px;
  border-color: var(--color-gray6);
  border-style: solid;
}
/* end round-icon-btn button */

/* dot-border-btn  button */
.dot-border-btn {
  background-color: white !important;
  color: var(--color-cyan2) !important;
  border: 1px dashed var(--color-cyan2) !important;
}
/* end dot-border-btn  button */

/* list button */
.list-btn {
  padding: 20px;
  background-color: var(--color-gray8) !important;
  border-radius: 8px !important;
}

.list-btn .btn-title {
  font-size: 17px;
  font-family: Yellix-SemiBold, serif;
  color: var(--color-text);
}

/* end city button */

.app-sidebar {
  padding-left: 25px;
  padding-right: 25px;
  padding-top: 40px;
  width: 420px;
  max-width: calc(100vw - 32px) !important;
  flex: 1 1;
}

.app-sidebar ul {
  padding: 0;
}

.app-sidebar .list-btn {
  background-color: var(--color-gray8) !important;
  color: var(--color-text);
  height: 48px;
  border-radius: 10px;
  margin-bottom: 15px;
  margin-top: 15px;
}

.app-sidebar .list-btn span {
  font-size: 16px;
  font-family: Yellix-SemiBold, serif;
  color: var(--color-text);
}

/* .app-sidebar .drawer-body {
  margin-bottom: 30px;
} */

.drawer-apps {
  width: 100%;
  padding-top: 25px;
  padding-bottom: 25px;
  background-color: var(--color-text);
}

.drawer-apps h4 {
  font-size: 16px;
  font-family: Yellix-Bold, serif;
  color: white;
  margin-top: 0;
  margin-bottom: 20px;
  width: 83%;
}

.drawer-apps .badge {
  width: 150px;
  height: 52px;
  background-repeat: no-repeat;
  display: table;
  margin: 0 12px;
  background-size: contain;
  margin-bottom: 10px;
}

.drawer-apps .badge-ios {
  background-image: url(/static/media/app_store.df809ae9.svg);
}

.drawer-apps .badge-android {
  background-image: url(/static/media/googleplay.5d3345a0.svg);
}

.app-sidebar .cashback-info {
  background-color: var(--color-text);
  border-radius: 8px;
  color: white;
  font-size: 16px;
  font-family: Yellix-SemiBold, serif;
  padding: 6px 9px;
}

.app-sidebar .user-info {
  margin-bottom: 15px;
}

.app-sidebar .user-info img {
  width: 56px;
  height: 56px;
  border-radius: 28px;
  margin-right: 10px;
}

.app-sidebar .user-info h2 {
  color: var(--color-text);
  font-size: 20px;
  font-family: Yellix-Bold, serif;
  margin: 0;
  max-width: 200px;
  word-break: break-all;
}

.app-sidebar .user-info a {
  color: var(--color-red1);
  font-size: 16px;
  font-family: Yellix-Medium, serif;
  margin-top: 4px;
  text-decoration: none;
}

.app-sidebar .list-btn .new_msg_tag {
  color: var(--color-cyan2);
  font-size: 14px;
  font-family: Yellix-SemiBold, serif;
}

.home-featured-cards-block .react-multiple-carousel__arrow {
  display: none;
}

.home-featured-cards-block .react-multi-carousel-list {
  padding-bottom: 16px;
}

.home-featured-cards-block .react-multi-carousel-dot button {
  border-width: 0px;
}

.home-featured-cards-block .react-multi-carousel-dot--active button {
  background: var(--color-cyan2);
}

@media (max-width: 576px) {
  .home-featured-cards-block {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }
}
.home-featured-card-item {
  border-radius: 20px;
  padding: 25px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}

.home-featured-card-item img {
  width: 30px;
  height: 30px;
}

.home-featured-card-item .title {
  font-size: 18px;
  font-family: Yellix-SemiBold;
  color: var(--color-cyan2);
}

.home-featured-card-item .desc {
  font-size: 16px;
  font-family: Yellix-SemiBold;
  color: var(--color-text);
}

@media (max-width: 380px) {
  .home-featured-card-item img {
    width: 30px;
    height: 30px;
  }

  .home-featured-card-item {
    border-radius: 16px;
    padding: 15px;
  }
  .home-featured-card-item .title {
    font-size: 18px;
  }

  .home-featured-card-item .desc {
    font-size: 16px;
    margin-top: 4px;
  }
}
.business-card-view .react-multiple-carousel__arrow {
  display: none;
}

.business-card-view .react-multi-carousel-list {
  padding-bottom: 16px;
}

.business-card-view .react-multi-carousel-dot button {
  border-width: 0px;
}

.business-card-view .react-multi-carousel-dot--active button {
  background: var(--color-cyan2);
}

@media (max-width: 576px) {
  .business-card-view {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }
}

.home-business-card {
  width: 100%;
  height: 318px;
  background-size: cover;
  background-position: center;
  border-radius: 30px;
  border: 2px solid #d6d5e1;
}

.home-business-card-vendor {
  background-image: url(/static/media/rider_card.e1ff5db7.png);
}

.home-business-card-rider {
  background-image: url(/static/media/rider_card.e1ff5db7.png);
}

.home-business-card-snapfood {
  background-image: url(/static/media/snapfood_team_card.3b9b07c5.png);
}

.home-business-card .card-body {
  width: 60%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
}

.home-business-card .card-body h3 {
  font-family: Yellix-Bold, serif;
  color: var(--color-text);
  margin: 0;
}

.home-business-card .card-body p {
  font-family: Yellix-Bold, serif;
  color: var(--color-text);
  margin-top: 20px;
  margin-bottom: 20px;
}

@media (max-width: 1440px) {
  .home-business-card .card-body {
    width: 70%;
    max-width: 245px;
  }
}

@media (max-width: 992px) {
  .home-business-card {
    height: 260px;
  }
}

@media screen and (max-width: 370px) {
  .home-business-card .card-body {
    width: 70%;
    max-width: 100%;
  }
}
.home-app-intro {
  justify-content: space-between !important;
}

.home-app-intro h2 {
  font-family: Yellix-Bold, serif;
  color: var(--color-text);
  margin-top: 20px;
}

.home-app-intro h2 span,
.home-app-intro h5 span
{
  color: var(--color-cyan2);
}

.home-app-intro h5 {
  font-family: Yellix-SemiBold, serif;
  color: var(--color-text);
  margin-top: 40px;
  margin-bottom: 40px;
}

.home-app-intro .right-side img {
  width: 540px;
  max-width: calc(100% - 32px);
}

@media (max-width: 1200px) {

}

@media (max-width: 992px) {
  .home-app-intro .right-side img {
    width: 390px;
  }
}

@media (max-width: 768px) {
  .home-app-intro {
    flex-wrap: wrap;
  }
  .home-app-intro .left-side {
    width: 100%;
  }
  .home-app-intro .right-side {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .home-app-intro .right-side img {
    width: 450px;
    max-width: calc(100% - 32px);
  }

  .home-app-intro h2,
  .home-app-intro h5
  {
    width: 100%;
    text-align: center;
  }
}

@media (max-width: 576px) {
  .home-app-intro {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }
}

.home-app-features-item {
  width: 380px;
  height: 280px;
  max-width: calc(100vw - 32px);
  border-radius: 20px;
  padding: 22px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
  background: white;
}

.home-app-features-item img {
  width: 60px;
  height: 60px;
}

.home-app-features-item .title {
  font-size: 20px;
  font-family: Yellix-SemiBold;
  color: var(--color-text);
}

.home-app-features-item .desc {
  font-size: 16px;
  margin-top: 8px;
  font-family: Yellix-SemiBold;
  color: var(--color-placeholder);
}

.home-new-blocks-title{
  font-size: 30px;
  margin-top: 0!important;
}

.home-new-blocks-desc {
  font-size: 20px;
  margin-top: 20px!important;
  margin-bottom: 20px!important;
}


@media (max-width: 1440px) {
  .home-app-features-item {
    width: 330px;
  }
}

@media (max-width: 1200px) {
  .home-app-features-item {
    width: 280px;
  }
}

@media (max-width: 992px) {

}

@media (max-width: 768px) {
  .home-app-features-item {
    width: 100%;
    max-width: 100%;
  }
}

@media (max-width: 576px) {
  .home-app-features-item {
    height: 280px;
  }

  .home-app-features-item img {
    width: 54px;
    height: 54px;
  }

  .home-app-features-item {
    border-radius: 16px;
    padding: 18px;
  }
  .home-app-features-item .title {
    font-size: 18px;
  }

  .home-app-features-item .desc {
    font-size: 16px;
    margin-top: 8px;
  }

  .home-new-blocks-title {
    font-size: 25px;
  }

  .home-new-blocks-desc {
    font-size: 17px;
    padding-left: 35px;
    padding-right: 35px;
  }
}

@media (max-width: 420px) {
  .home-app-features-item {
    height: 280px;
  }

  .home-app-features-item img {
    width: 100px;
    height: 100px;
  }

  .home-app-features-item {
    border-radius: 16px;
    padding: 15px;
  }
  .home-app-features-item .title {
    font-size: 18px;
  }

  .home-app-features-item .desc {
    font-size: 16px;
    margin-top: 8px;
  }
}

@media (max-width: 360px) {
  .home-app-features-item {
    height: 330px;
  }

}
.home-app-features {
  justify-content: space-between !important;
  background-color: var(--color-gray9);
  -webkit-clip-path: polygon(0 12%, 100% 0%, 100% 100%, 0% 100%);
  clip-path: polygon(0 12%, 100% 0%, 100% 100%, 0% 100%);
}

.home-app-features .content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.home-app-features h2 {
  font-family: Yellix-Bold, serif;
  color: var(--color-text);
  margin-top: 20px;
}

.home-app-features h2 span,
.home-app-features h5 span
{
  color: var(--color-cyan2);
}

.home-app-features h5 {
  font-family: Yellix-SemiBold, serif;
  color: var(--color-text);
  margin-top: 40px;
  margin-bottom: 40px;
}

.home-app-features .content .craving-something {
  color: var(--color-cyan2);
  padding-right: 10px;
}


.home-app-features .desktop-only {
  display: flex;
}

.home-app-features .mobile-only {
  display: none;
}

@media (max-width: 1200px) {

}

@media (max-width: 992px) {
  .home-app-features {
    flex-wrap: wrap;
    -webkit-clip-path: polygon(0 2%, 100% 0%, 100% 100%, 0% 100%);
    clip-path: polygon(0 2%, 100% 0%, 100% 100%, 0% 100%);
  }
  .home-app-features .left-side {
    width: 100%;
  }
  .home-app-features .right-side {
    width: 100%;
  }
  .home-app-features h2,
  .home-app-features h5
  {
    width: 100%;
    text-align: center;
  }
}

@media (max-width: 768px) {
  .home-app-features .left-side {
    width: 100%;
  }
  .home-app-features .right-side {
    width: 100%;
    flex-wrap: wrap;
  }
  .home-app-features .right-side > div {
    width: 100%;
  }

  .home-app-features .desktop-only {
    display: none;
  }

  .home-app-features .mobile-only {
    display: flex;
  }
}

@media (max-width: 576px) {
  .home-app-features {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }

  .home-app-features h5 {
    margin-top: 20px;
  }
}
.home-app-features-carousel .react-multiple-carousel__arrow {
  display: none;
}

.home-app-features-carousel .react-multi-carousel-list {
  padding-bottom: 16px;
}

.home-app-features-carousel .react-multi-carousel-dot button {
  border-width: 0px;
}

.home-app-features-carousel .react-multi-carousel-dot--active button {
  background: var(--color-cyan2);
}

@media (max-width: 576px) {

}

.input {
  width: 100%;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
}

.input input,
textarea {
  width: 100%;
  outline: none !important;
  background-color: white !important;
  border-width: 0 !important;
  border-color: white !important;
  color: var(--color-text) !important;
}

/* auth input start */
.auth-input {
  margin-bottom: 12px;
  height: 54px;
  border-radius: 12px;
  border: 1px solid var(--color-gray5);
  padding: 10px;
}

.auth-input input,
textarea {
  font-size: 16px !important;
  line-height: 24px !important;
  font-family: Yellix-SemiBold, serif !important;
  width: 100%;
}

.auth-input input::-webkit-input-placeholder {
  color: var(--color-gray5);
  opacity: 1; /* Firefox */
}

.auth-input input:-ms-input-placeholder {
  color: var(--color-gray5);
  opacity: 1; /* Firefox */
}

.auth-input input::placeholder {
  color: var(--color-gray5);
  opacity: 1; /* Firefox */
}

.auth-input input:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: var(--color-gray5);
}

.auth-input input::-ms-input-placeholder {
  /* Microsoft Edge */
  color: var(--color-gray5);
}

.auth-input .eye {
  width: 28px !important;
  height: 28px !important;
  padding: 5px !important;
}

/* end auth input  */

/* partner input start */
.partner-input {
  height: 48px !important;
  border-radius: 10px;
  border: 1px solid var(--color-gray5) !important;
  padding: 6px !important;
}

.partner-input .prefix {
  font-size: 14px !important;
  font-family: Yellix-SemiBold, serif !important;
  line-height: 20px;
  color: var(--color-text);
  margin-left: 4px;
  margin-right: 6px;
}

.partner-input input,
textarea {
  font-size: 14px !important;
  font-family: Yellix-SemiBold, serif !important;
  line-height: 20px;
}

.partner-input input::-webkit-input-placeholder {
  color: var(--color-gray5);
  opacity: 1; /* Firefox */
}

.partner-input input:-ms-input-placeholder {
  color: var(--color-gray5);
  opacity: 1; /* Firefox */
}

.partner-input input::placeholder {
  color: var(--color-gray5);
  opacity: 1; /* Firefox */
}

.partner-input input:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: var(--color-gray5);
}

.partner-input input::-ms-input-placeholder {
  /* Microsoft Edge */
  color: var(--color-gray5);
}

/* end partner input */

/* select input start */
.select-input {
  height: 54px;
  border-radius: 10px;
  border: 1px solid var(--color-gray5) !important;
  padding: 6px !important;
}

.select-input .MuiFormControl-root {
  flex: 1 1;
}

.select-input .MuiSelect-select {
  flex: 1 1;
  font-size: 14px !important;
  line-height: 20px;
  font-family: Yellix-SemiBold, serif !important;
}

.select-input fieldset {
  /* Microsoft Edge */
  border-width: 0 !important;
}

#menu- li {
  font-size: 14px !important;
  font-family: Yellix-SemiBold, serif !important;
}

/* end select input */

/* search input start */
.search-input {
  height: 54px;
  border-radius: 10px;
  border: 1px solid var(--color-gray5) !important;
  padding: 6px !important;
}

.search-input .icon {
  padding: 0 6px;
}

.search-input .active-icon {
  padding: 0 6px;
}

.search-input .active-icon svg {
  color: var(--color-text);
}

.search-input input {
  font-size: 15px !important;
  line-height: 20px;
  font-family: Yellix-SemiBold, serif !important;
}

.search-input input::-webkit-input-placeholder {
  color: var(--color-gray5);
  opacity: 1; /* Firefox */
}

.search-input input:-ms-input-placeholder {
  color: var(--color-gray5);
  opacity: 1; /* Firefox */
}

.search-input input::placeholder {
  color: var(--color-gray5);
  opacity: 1; /* Firefox */
}

.search-input input:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: var(--color-gray5);
}

.search-input input::-ms-input-placeholder {
  /* Microsoft Edge */
  color: var(--color-gray5);
}

/* end search input */

/* location input start */
.location-input {
  height: 54px;
  border-radius: 10px;
  border: 1px solid var(--color-gray5);
  padding: 6px !important;
}

.location-input .icon {
  padding: 0 6px;
}

.location-input .active-icon {
  padding: 0 6px;
}

.location-input .active-icon svg {
  color: var(--color-text);
}

.location-input input {
  font-size: 15px !important;
  line-height: 20px;
  font-family: Yellix-SemiBold, serif !important;
}

.location-input input::-webkit-input-placeholder {
  color: var(--color-gray5);
  opacity: 1; /* Firefox */
}

.location-input input:-ms-input-placeholder {
  color: var(--color-gray5);
  opacity: 1; /* Firefox */
}

.location-input input::placeholder {
  color: var(--color-gray5);
  opacity: 1; /* Firefox */
}

.location-input input:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: var(--color-gray5);
}

.location-input input::-ms-input-placeholder {
  /* Microsoft Edge */
  color: var(--color-gray5);
}

/* end search input */

/* comment input */
.comment-input {
  margin-bottom: 12px;
  border-radius: 12px;
  border: 1px solid var(--color-gray6);
  padding: 10px !important;
}

.comment-input textarea {
  font-size: 16px !important;
  line-height: 18px !important;
  font-family: Yellix-Medium, serif !important;
  width: 100%;
  text-align: left;
}
/* end comment input */

.vendor-search-form {
  width: 100%;
  max-width: calc(100vw - 32px) !important;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;
  border-radius: 16px;
  box-shadow: 0 0 25px rgba(66, 133, 244, 0.1) !important;
  padding: 15px 20px;
}

.vendor-search-form .row {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 8px 0;
}


.vendor-search-form-spacer {
  width: 16px;
}

.error .location-input {
  border: 1px solid var(--color-red1);
}

.vendor-search-form .app-btn {
  font-size: 18px !important;
}

@media screen and (max-width: 900px) {
  .vendor-search-form {
    max-width: 100%;
    padding: 10px 15px;
  }

  .vendor-search-form .row {
    flex-direction: column !important;
    display: block !important;
  }

  .vendor-search-form-spacer {
    height: 16px;
  }
}

@media (max-width: 576px) {
  .vendor-search-form .location-input input {
    font-size: 16px !important;
  }

  .vendor-search-form .search-input input {
    font-size: 16px !important;
  }
}
.dropdown {
  position: relative;
  margin: 0 auto;
  width: 96px;
  height: 46px;
  background: white;
  border: 1px solid var(--color-text);
  border-radius: 12px;
  z-index: 2;
}

.drop-btn {
  padding: 6px 10px;
  cursor: pointer;
  height: 42px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.drop-btn img {
  height: 24px;
  width: 22px;
  object-fit: contain;
}

.drop-btn h1 {
  color: var(--color-text);
  font-family: Yellix-SemiBold, serif;
  font-size: 16px !important;
  padding-left: 7px;
  padding-right: 14px;
  margin: 0 !important;
}

.drop-btn:after {
  content: '';
  transition: all 0.3s;
  border: solid var(--color-text);
  border-width: 0 2px 2px 0;
  float: right;
  margin-top: 8px;
  margin-right: 6px;
  padding: 4px;
  transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg);
}

.dropdown.active .drop-btn:after {
  margin-top: 1px;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}

.dropdown__items {
  position: relative;
  visibility: hidden;
  opacity: 0;
  max-height: 0px;
  transition: max-height 0.6s ease;
  box-shadow: 0 2px 5px 1px rgb(64 60 67 / 16%);
  border-radius: 12px;
  background-color: white;
}

.dropdown.active .dropdown__items {
  visibility: visible;
  border: 1px solid var(--color-text);
  height: auto;
  max-height: 290px;
  opacity: 1;
  transition: max-height 0.7s, opacity 0.6s, visibility 1s ease;
}

.dropdown__item {
  cursor: pointer;
  padding: 10px 18px;
  font-family: Yellix-Medium, serif;
  font-size: 13px;
}

.dropdown__item:not(:last-child) {
  border-bottom: 1px solid var(--color-text);
}

.dropdown__item:hover {
  color: var(--color-text);
  font-family: Yellix-SemiBold, serif;
}

.dropdown .dropdown__items {
  margin-left: 0;
  width: 100%;
}

@media screen and (max-width: 576px) {
  .dropdown .drop-btn:after {
    display: none;
  }

  .dropdown {
    width: 60px;
    height: 42px;
    font-size: 13px;
  }

  .dropdown .dropdown__item {
    padding: 8px 5px;
    word-break: break-all;
  }

   .dropdown .dropdown__items {
    width: 86px;
    margin-left: -26px;
  }

  .drop-btn h1 {
    font-size: 13px !important;
    padding-right: 4px;
  }
}
.country-dropdown {
  position: relative;
  margin: 0 auto;
  width: 96px;
  height: 54px;
  background: white;
  border: 1px solid var(--color-gray5);
  border-radius: 12px;
  z-index: 2;
}

.country-drop-btn {
  padding: 6px 10px;
  height: 48px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.country-drop-btn img {
  height: 24px;
  width: 22px;
  object-fit: contain;
}

.country-drop-btn span {
  color: var(--color-text);
  font-family: Yellix-SemiBold, serif;
  font-size: 14px !important;
  padding-left: 7px;
  padding-right: 14px;
}

.country-drop-btn:after {
  content: '';
  transition: all 0.3s;
  border: solid var(--color-text);
  border-width: 0 2px 2px 0;
  float: right;
  margin-top: 1px;
  margin-right: 6px;
  padding: 4px;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}

.country-dropdown.active .country-drop-btn:after {
  margin-top: 6px;
  transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg);
}

.country-dropdown-items {
  position: relative;
  visibility: hidden;
  opacity: 0;
  max-height: 0px;
  transition: max-height 0.6s ease;
  box-shadow: 0 2px 5px 1px rgb(64 60 67 / 16%);
  border-radius: 12px;
  background-color: white;
}

.country-dropdown.active .country-dropdown-items {
  visibility: visible;
  border: 1px solid var(--color-gray5);
  height: auto;
  max-height: 290px;
  opacity: 1;
  transition: max-height 0.7s, opacity 0.6s, visibility 1s ease;
}

.country-dropdown-item {
  cursor: pointer;
  padding: 3px 18px;
}

.country-dropdown-item img {
  height: 24px;
  width: 22px;
  object-fit: contain;
}

.country-dropdown-item h1 {
  font-family: Yellix-Medium, serif !important;
  font-size: 13px !important;
}

.country-dropdown-item:not(:last-child) {
  border-bottom: 1px solid var(--color-gray5);
}

.country-dropdown-item:hover {
  color: var(--color-text);
  font-family: Yellix-SemiBold, serif;
}

.text-dropdown {
  position: relative;
  height: 54px;
  background: white;
  border: 1px solid var(--color-gray5);
  border-radius: 12px;
  z-index: 2;
}

.text-drop-btn {
  padding: 6px 10px;
  height: 54px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.text-drop-btn img {
  height: 24px;
  width: 22px;
  object-fit: contain;
}

.text-drop-btn span {
  color: var(--color-text);
  font-family: Yellix-SemiBold, serif;
  font-size: 16px !important;
  padding-left: 7px;
  padding-right: 14px;
}

.text-drop-btn:after {
  content: '';
  transition: all 0.3s;
  border: solid var(--color-text);
  border-width: 0 2px 2px 0;
  float: right;
  margin-top: 0px;
  margin-right: 6px;
  padding: 4px;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}

.text-dropdown.active .text-drop-btn:after {
  margin-top: 6px;
  transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg);
}

.text-dropdown-items {
  position: relative;
  visibility: hidden;
  opacity: 0;
  max-height: 0px;
  transition: max-height 0.6s ease;
  box-shadow: 0 2px 5px 1px rgb(64 60 67 / 16%);
  border-radius: 12px;
  background-color: white;
  overflow: hidden;
}

.text-dropdown.active .text-dropdown-items {
  visibility: visible;
  border: 1px solid var(--color-gray5);
  height: auto;
  opacity: 1;
  transition: max-height 0.7s, opacity 0.6s, visibility 1s ease;
  max-height: 168px;
  overflow-y: auto;
}

.text-dropdown-item {
  cursor: pointer;
  padding: 8px 18px;
}

.text-dropdown-item img {
  height: 24px;
  width: 22px;
  object-fit: contain;
}

.text-dropdown-item h1 {
  font-family: Yellix-Medium, serif !important;
  font-size: 15px !important;
}

.text-dropdown-item:not(:last-child) {
  border-bottom: 1px solid var(--color-gray5);
}

.text-dropdown-item:hover {
  color: var(--color-text);
  font-family: Yellix-SemiBold, serif;
}

.vendor-dropdown {
  position: relative;
  margin: 0 auto;
  height: 54px;
  background: white;
  border-radius: 12px;
  z-index: 3;
}

.vendor-dropdown-items {
  position: relative;
  visibility: hidden;
  opacity: 0;
  max-height: 0px;
  transition: max-height 0.6s ease;
  box-shadow: 0 2px 5px 1px rgb(64 60 67 / 16%);
  border-radius: 12px;
  background-color: white;
  padding-left: 12px;
  padding-right: 12px;
}

.vendor-dropdown.active .vendor-dropdown-items {
  visibility: visible;
  border: 1px solid var(--color-gray5);
  height: auto;
  max-height: 390px;
  opacity: 1;
  transition: max-height 0.7s, opacity 0.6s, visibility 1s ease;
}

.vendor-dropdown-item {
  cursor: pointer;
  padding: 12px 0px;
}


.vendor-dropdown-item .count {
  font-family: Yellix-Regular, serif;
  font-size: 14px;
  color: var(--color-text);
}

.vendor-dropdown-item .suggested {
  font-family: Yellix-Medium, serif;
  font-size: 17px;
  line-height: 19px;
  color: var(--color-text);
  padding-left: 2px;
}

.vendor-dropdown-item .suggested span {
  font-family: Yellix-Bold, serif;
}

.vendor-dropdown-item:not(:last-child) {
  border-bottom: 1px solid var(--color-gray5);
}

.vendor-dropdown-item:hover {
  color: var(--color-text);
  font-family: Yellix-SemiBold, serif;
}

.home-banner {
  width: 100%;
  /*height: 80vh;*/
  background-size: contain;
  background-repeat: no-repeat;
  background-position-x: right;
  background-position-y: top;

  background-image: url(/static/media/landing_bg.c7887166.png);
  position: relative;
}


.home-banner .content {
  z-index: 1;
  width: 50%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.home-banner .content h2 {
  font-family: Yellix-Bold, serif;
  color: var(--color-text);
  margin: 0;
}

.home-banner .content h4 {
  font-family: Yellix-SemiBold, serif;
  color: var(--color-text);
  margin-top: 20px;
  margin-bottom: 20px;
}

.wanna-work-description {
  font-size: 17px;
  font-family: Yellix-SemiBold, serif;
  color: var(--color-text);
  margin-top: 20px;
  margin-bottom: 20px;
  z-index: 10;
}

.wanna-work-description a {
  color: var(--color-red1);
  padding-left: 8px;
  padding-right: 8px;
}

.bg-wrap {
  position: absolute;
  top: 0;
  right: 0;
  height: 80vh;
  display: block;
}

.bg-wrap img {
  object-fit: contain;
  height: 100%;
  overscroll-behavior-y: unset;
  overflow-y: hidden;
}

@media screen and (max-width: 576px) {
  .home-banner .content h2 {
    font-size: 23px;
  }

  .home-banner .content h4 {
    font-size: 18px;
  }

  .home-banner .content {
    width: 100%;
  }
}

@media (max-width: 1200px) {
  .home-banner .content {
    width: 100%;
  }
  .home-banner {
    /*height: 76vh;*/
  }
}

@media (max-width: 992px) {
  .home-banner {
    /*height: 72vh;*/
  }
}

@media (max-width: 768px) {
  .home-banner {
    /*height: 68vh;*/
  }
}

@media (max-width: 576px) {
  .home-banner {
    /*height: 60vh;*/
  }
  .home-banner .content p {
    text-align: center;
  }
}
.where-heard-feedback-modal {
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
    z-index: 999;
    max-width: calc(100vw - 30px);
    width: 520px;
    position: fixed;
    bottom: 0;
    right: 15px;
    margin: 0 !important;
    box-shadow: 0px 11px 15px -7px rgb(0 0 0 / 20%), 0px 24px 38px 3px rgb(0 0 0 / 14%),
    0px 9px 46px 8px rgb(0 0 0 / 12%);
    background-color: white;
}

.where-heard-feedback-modal .titleview {
    width: 100%;
    align-items: flex-start;
    position: relative;
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
}

.where-heard-feedback-modal .titleview img {
    width: 32px;
    height: 32px;
    object-fit: contain;
}

.where-heard-feedback-modal .content {
    width: 100%;
    border-radius: 16px;
}

.where-heard-feedback-modal .content .content-view {
    min-height: 380px;
    justify-content: flex-start;
}

.where-heard-feedback-modal .content h2 {
    font-size: 16px;
    font-family: Yellix-SemiBold, serif;
    color: var(--color-text);
    margin: 0;
    flex: 1 1;
    text-align: center;
    padding: 6px 0;
}

.where-heard-feedback-modal .content h3 {
    font-size: 22px;
    line-height: 24px;
    font-family: Yellix-SemiBold, serif;
    color: white;
    margin: 0;
    flex: 1 1;
}

.where-heard-feedback-modal .scrollview {
    justify-content: flex-start;
    overflow-y: auto;
    padding: 0px 20px;
    margin-bottom: 20px;
}

.where-heard-feedback-modal .section {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 0 8px;
}

.where-heard-feedback-modal .header {
    margin-bottom: 16px;
}

.where-heard-feedback-modal .headerTitle {
    color: var(--color-text);
    font-family: Yellix-Bold, serif;
    font-size: 18px;
}

.where-heard-feedback-modal .sectionContent {
    background-color: var(--color-gray8);
    border-radius: 12px;
    display: flex;
    flex-direction: row;
    margin-top: 16px;
    align-items: center;
    min-height: 56px;
    padding: 8px 20px;
}

.where-heard-feedback-modal .sectionCol {
    display: flex;
    flex-direction: column;
    flex: 1 1;
}

.where-heard-feedback-modal .sectionTitle {
    margin-bottom: 8px;
    color: var(--color-text);
    font-family: Yellix-Bold, serif;
    font-size: 14px;
}


.where-heard-feedback-modal .sectionRadioTitle {
    color: var(--color-text);
    font-family: Yellix-SemiBold, serif;
    font-size: 14px;
}

.auth-form .forgot-pass {
    display: flex;
    justify-content: flex-end;
}

.auth-form .forgot-pass-btn {
    font-size: 16px;
    font-family: Yellix-SemiBold;
    color: var(--color-red1);
    cursor: pointer;
}

.auth-bottom-link {
    margin-top: 40px;
}

.auth-bottom-link .text {
    font-size: 16px;
    font-family: Yellix-SemiBold;
    color: var(--color-text);
    margin-right: 4px;
}

.auth-bottom-link .btn {
    font-size: 16px;
    font-family: Yellix-SemiBold;
    color: var(--color-cyan2);
    cursor: pointer;
}

.cancel-btn {
    font-size: 16px;
    font-family: Yellix-SemiBold;
    color: var(--color-text);
    cursor: pointer;
}


@media (max-width: 576px) {
    .auth-bottom-link {
        flex-direction: column;
        text-align: center;
    }
}


.socialBtn,
#appleid-signin {
  background-color: var(--color-whitePrimary);
  margin-top: 10px;
  width: 40px;
  height: 40px;
  border: 1px solid var(--color-gray5);
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  font-size: 20px;
  cursor: pointer;
}

.auth-screen {
  margin-top: 120px;
  width: 552px !important;
  max-width: calc(100vw - 32px) !important;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.auth-screen .logo {
  width: 310px;
  height: 48px;
  object-fit: contain;
}

.auth-screen > div {
  width: 100%;
}

.lang-bar {
  position: absolute;
  right: 40px;
  top: 40px;
}

.auth-form {
  width: 100%;
  padding-top: 36px;
}

.auth-form h1 {
  font-size: 28px;
  font-family: Yellix-ExtraBold;
  text-align: center;
}

.auth-form h3 {
  font-size: 18px;
  font-family: Yellix-SemiBold;
  margin-top: 24px;
  margin-bottom: 31px;
  text-align: center;
  padding-left: 20px;
  padding-right: 20px;
}

.auth-form .forgot-pass {
  display: flex;
  justify-content: flex-end;
}

.auth-form .forgot-pass-btn {
  font-size: 16px;
  font-family: Yellix-SemiBold;
  color: var(--color-red1);
  cursor: pointer;
}

.auth-form img.reset-done {
  width: 240px;
  height: 240px;
  object-fit: contain;
}

.auth-form a.return-login {
  font-size: 16px;
  font-family: Yellix-SemiBold;
  color: var(--color-cyan2);
  -webkit-text-decoration-line: none;
          text-decoration-line: none;
}

.auth-bottom-link {
  margin-top: 40px;
}

.auth-bottom-link .text {
  font-size: 16px;
  font-family: Yellix-SemiBold;
  color: var(--color-text);
  margin-right: 4px;
}

.auth-bottom-link .btn {
  font-size: 16px;
  font-family: Yellix-SemiBold;
  color: var(--color-cyan2);
  cursor: pointer;
}

.cancel-btn {
  font-size: 16px;
  font-family: Yellix-SemiBold;
  color: var(--color-text);
  cursor: pointer;
}

.auth-form h3 a {
  color: var(--color-red1);
}

.auth-bottom-link {
  margin-top: 40px;
}

.auth-bottom-link .text {
  font-size: 16px;
  font-family: Yellix-SemiBold;
  color: var(--color-text);
  margin-right: 4px;
}

.auth-bottom-link .btn {
  font-size: 16px;
  font-family: Yellix-SemiBold;
  color: var(--color-cyan2);
  cursor: pointer;
}

.text-btn {
  font-size: 16px;
  font-family: Yellix-SemiBold;
  color: var(--color-text);
  cursor: pointer;
}

.vcode-inputs .auth-input input,
textarea {
  text-align: center;
}

.view-verification .auth-input {
  padding: 0px !important;
  width: 54px;
  margin-right: 16px;
}

@media (max-width: 576px) {
  .view-verification .auth-input {
    margin-right: 8px;
    width: 46px !important;
    height: 46px !important;
  }
}
.contact_wrapper {
  display: flex;
  max-width: 80%;
  margin: auto;
  /* padding-left: 20px;
    padding-right: 20px; */
  padding-top: 83px;
}

.find_us a {
  text-decoration: none;
  color: inherit;
}
.contact_wrapper_left {
  width: 60%;
  padding-left: 0px;
  padding-right: 0px;
  height: inherit;

  box-shadow: 0px 30px 60px #0e0d1b0f;
  border-radius: 20px;
}

.checkbox-wrapper {
  display: flex;
  width: 100%;
  grid-gap: 2px;
  gap: 2px;
  padding-block: 16px;
}

.contact_wrapper_right {
  width: 40%;
  padding-left: 40px;
  padding-right: 0px;
}

.contact_form {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 50px #0e0d1b0f;
  border-radius: 20px;
  opacity: 1;
  padding: 40px;

  text-align: center;
}

.contact_form p {
  text-align: center;
  font: normal normal 800 24px/29px Yellix-Bold;
  letter-spacing: 0px;
  color: #222222;
  opacity: 1;
  margin-bottom: 40px;
  margin-top: 0px;
}

.contact_form .input,
.contact_form textarea {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #d5d4e0 !important;
  border-radius: 16px;
  opacity: 1;
  display: block;
  height: 60px;
  margin-bottom: 20px;
  padding-left: 20px;
  width: 100%;
  padding-top: 20px;
  padding-bottom: 20px;
}

.contact_form textarea {
  height: 169px;
  text-align: left;
}

.contact_form input:focus {
  outline: none;
}

.contact_form ::-webkit-input-placeholder {
  font: normal normal 600 16px/19px Yellix-SemiBold;
  letter-spacing: 0px;
  color: #aaa8bf;
}

.contact_form :-ms-input-placeholder {
  font: normal normal 600 16px/19px Yellix-SemiBold;
  letter-spacing: 0px;
  color: #aaa8bf;
}

.contact_form ::placeholder {
  font: normal normal 600 16px/19px Yellix-SemiBold;
  letter-spacing: 0px;
  color: #aaa8bf;
}

.contact_form button {
  border: none;
  background: #222222 0% 0% no-repeat padding-box;
  border-radius: 16px;
  opacity: 1;

  text-align: center;
  font: normal normal 600 18px/22px Yellix-SemiBold;
  letter-spacing: 0px;
  color: #ffffff;

  -webkit-appearance: none;

          appearance: none;
  height: 60px;
  padding-left: 60px;
  padding-right: 60px;

  margin: auto;
  cursor: pointer;
}

@media screen and (max-width: 1200px) {
  .contact_wrapper {
    max-width: 90%;
  }
}
@media screen and (max-width: 992px) {
  .contact_form {
    padding-left: 15px;
    padding-right: 15px;
  }

  .contact_wrapper {
    max-width: 95%;
    padding-top: 87px;
  }

  .contact_wrapper_left {
    width: 100%;
    height: 50vh;
    margin-bottom: 40px;
  }

  .contact_wrapper_right {
    width: 100%;
    padding-left: 0px;
  }

  .contact_wrapper {
    flex-wrap: wrap;
  }
}

.find_us {
  padding-top: 56px;
  display: block;
}

.find_us h2 {
  font: normal normal 800 28px/24px Yellix-Bold;
  letter-spacing: 0px;
  color: #50b7ed;
  opacity: 1;
  margin-top: 0px;
  margin-bottom: 35px;
}

.contact_address {
  display: block;
  margin-bottom: 32px;

  font: normal normal 600 20px/24px Yellix-SemiBold;
  letter-spacing: 0px;
  color: #222222;
  opacity: 1;
}

.contact_address img {
  margin-right: 20px;
  height: 24px;
  float: left;
}

.contact_address img {
  vertical-align: middle;
}

/* map marker */
.map-marker {
    position: relative;
}

.map-marker .marker-body {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.map-marker img {
    cursor: pointer;
}

.map-marker .popup {
    position: absolute;
    background-color: white;
    text-align: center;
    z-index: 2;
    opacity: 1;
    transition: opacity 0.6s;
    box-shadow: 0 0 17px 2px rgb(2 2 2 / 15%) !important;
}

.map-marker .popup::after {
    content: '';
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -15px;
    border-width: 15px;
    border-style: solid;
    border-color: white transparent transparent transparent;
}

/* end map marker */

.location-marker img {
    width: 42px;
    height: 42px;
    object-fit: contain;
    cursor: pointer;
}

.date-selector {
  width: 100%;
  height: 48px;
}

.date-selector .picker-item {
  height: 100%;
  padding-left: 8px;
  padding-right: 8px;
  background: white;
  border: 1px solid var(--color-gray5);
  border-radius: 12px;
}

.date-selector .picker-item select {
  height: 100%;
  width: 100%;
  border: none;
  outline: none;
  padding-top: 2px;
  font-family: Yellix-Medium, serif;
  font-size: 14px;
}

.vehicletype-btn {
  width: 100%;
  height: 48px;
  background-color: var(--color-text) !important;
  font-size: 16px !important;
  font-family: Yellix-Medium, serif !important;
  border-radius: 12px !important;
  text-transform: none !important;
  box-shadow: none !important;
}

/* switch button */
.vehicle-switch {
  width: 100%;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
  border: 1px solid var(--color-gray5) !important;
}

.vehicle-switch button {
  flex: 1 1;
  font-size: 14px !important;
  padding: 0 !important;
}

.vehicle-switch button.active {
  background-color: var(--color-text) !important;
  color: white;
}

.vehicle-switch button.inactive {
  background-color: white !important;
  color: var(--color-text);
}

.vehicle-switch button img {
  height: 22px;
  padding-right: 8px;
  object-fit: contain;
}

/* end switch button */

.courier-screen .apply-form {
  width: 620px !important;
  max-width: calc(100vw - 32px) !important;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;
  border-radius: 16px;
  box-shadow: 0 0 25px rgba(66, 133, 244, 0.1) !important;
  padding: 20px;
}

.courier-screen .apply-form h2 {
  font-size: 18px;
  font-family: Yellix-Bold, serif;
  color: var(--color-text);
}

.courier-screen .apply-form .row {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  padding: 8px 0px;
}

.courier-screen .apply-form .row div.label {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 48px;
  width: 20%;
  font-size: 15px;
  font-family: Yellix-SemiBold, serif;
  color: var(--color-text);
  margin-right: 16px;
}


.courier-screen .apply-form .row div.input-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1 1;
}

.courier-screen .apply-form .row div.input-wrapper label {
  margin-right: 50px;
}

.courier-screen .apply-form .row div.input-wrapper .MuiFormControlLabel-label {
  font-size: 14px;
  font-family: Yellix-Medium, serif;
  color: var(--color-text);
}

.courier-screen .apply-form .terms-txt {
  font-size: 13px;
  font-family: Yellix-Medium, serif;
  color: var(--color-gray1);
}
.courier-screen .apply-form .terms-txt a {
  color: var(--color-text);
}

.courier-screen .apply-form .login-txt {
  font-size: 13px;
  font-family: Yellix-Medium, serif;
  color: var(--color-gray1);
}
.courier-screen .apply-form .login-txt a {
  color: var(--color-cyan2);
  -webkit-text-decoration-line: none;
          text-decoration-line: none;
}

.courier-screen .apply-form .courier-apply-form .auth-input {
  border-width: 1px;
  height: 48px;
}

.courier-screen .apply-form .courier-apply-form .auth-input input {
  font-size: 14px !important;
  font-family: Yellix-SemiBold, serif !important;
  line-height: 20px;
}

.courier-screen .apply-form .auth-btn {
  background-color: var(--color-text) !important;
}

@media (max-width: 576px) {
  .courier-screen .apply-form .row div.label {
    width: 100%;
  }
}


.courier-screen .features {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;
}

.courier-screen .features h3 {
  font-family: Yellix-ExtraBold, serif;
  color: var(--color-text);
  margin-bottom: 56px;
}

.courier-screen .features .features-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding-left: 20px;
  padding-right: 20px;
  height: 100%;
}

.courier-screen .features .features-item img {
  height: 152px;
  object-fit: contain;
}

.courier-screen .features .features-item h5 {
  font-family: Yellix-Bold, serif;
  color: var(--color-text);
  text-align: center;
}

.courier-screen .features .features-item p {
  font-size: 20px;
  font-family: Yellix-SemiBold, serif;
  color: var(--color-text);
  text-align: center;
  margin: 0 !important;
}


@media (max-width: 576px) {
  .courier-screen .features .features-item p {
    font-size: 16px;
  }
  .courier-screen .features .features-item img {
    height: 118px;
  }
}
.courier-screen .need-support {
  background-image: url(/static/media/support_bg.43c6c299.svg);
  height: 78vh;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  -webkit-clip-path: polygon(0 10%, 100% 0%, 100% 100%, 0 90%);
  clip-path: polygon(0 10%, 100% 0%, 100% 100%, 0 90%);
  position: relative;
  background-repeat: no-repeat !important;
  background-position: bottom !important;
  background-size: cover !important;
}

@media (max-width: 768px) {
  .courier-screen .need-support {
    height: 60vh;
  }
}

.courier-screen .need-support .title-view {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  padding-right: 20%;
}

.courier-screen .need-support h2 {
  font-family: Yellix-ExtraBold, serif;
  color: var(--color-cyan2);
}

.courier-screen .need-support h5 {
  margin-top: 0 !important;
  font-family: Yellix-SemiBold, serif;
  color: var(--color-text);
}

.courier-screen .features {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;
}

.courier-screen .features h3 {
  font-family: Yellix-ExtraBold, serif;
  color: var(--color-text);
  margin-bottom: 56px;
}

.courier-screen .features .features-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding-left: 20px;
  padding-right: 20px;
  height: 100%;
}

.courier-screen .features .features-item img {
  height: 152px;
  object-fit: contain;
}

.courier-screen .features .features-item h5 {
  font-family: Yellix-Bold, serif;
  color: var(--color-text);
  text-align: center;
}

.courier-screen .features .features-item p {
  font-size: 20px;
  font-family: Yellix-SemiBold, serif;
  color: var(--color-text);
  text-align: center;
  margin: 0 !important;
}

@media (max-width: 576px) {
  .courier-screen .features .features-item p {
    font-size: 16px;
  }
  .courier-screen .features .features-item img {
    height: 118px;
  }
}
.faq-item {
  margin-bottom: 16px;
  box-shadow: none !important;
  border-radius: 12px !important;
  background-color: var(--color-gray8) !important;
}

.faq-item:before {
  height: 0 !important;
}

.faq-item,
Mui-expanded {
  margin-top: 0 !important;
}

.faq-item .answer {
  background-color: white !important;
}

.faq-item .question p {
  font-size: 20px;
  font-family: Yellix-SemiBold, serif;
  margin: 0 !important;
}
.faq-item .answer p {
  font-size: 20px;
  font-family: Yellix-Medium, serif;
  margin: 0 !important;
}

@media (max-width: 768px) {
  .faq-item .question p {
    font-size: 16px;
  }
  .faq-item .answer p {
    font-size: 16px;
  }
}
.courier-screen .faqs {
  display: flex;
  flex-direction: column;
  background-color: white;
}

.courier-screen .faqs h3 {
  font-family: Yellix-Bold, serif;
  color: var(--color-text);
  margin-bottom: 40px;
  text-align: center;
}

.courier-screen .faqs .items {
  width: 100%;
  max-width: calc(100vw - 32px) !important;
}

.courier-screen {
  position: relative;
  z-index: 0;
}

.courier-screen section.main {
  background-image: url(/static/media/courier-bg.382f724a.png);
  position: relative;
  background-repeat: no-repeat !important;
  background-position: bottom !important;
  background-size: cover !important;
}

.courier-screen section.main .title-view {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  height: 100%;
}

.courier-screen section.main h1 {
  font-family: Yellix-ExtraBold, serif;
  color: var(--color-text);
}

.courier-screen section.main h4 {
  font-family: Yellix-SemiBold, serif;
  color: var(--color-text);
  margin: 0;
}

.faq-items {
  display: flex;
  flex-direction: column;
  align-items: start;
  grid-gap: 8px;
  gap: 8px;
  border: 1px red solid;
}

@media (min-width: 1440px) {
  .courier-screen section.main h1 {
    margin-top: -500px;
  }
}

.home-foods-menu h2 {
  font-family: Yellix-Bold, serif;
  color: var(--color-text);
}

.home-foods-menu .menu-item-view {
  width: calc(20% - 40px);
  min-width: 240px;
  height: 140px;
  cursor: pointer;
  border-radius: 20px;
  padding-top: 20px;
  padding-left: 20px;
  background-color: var(--color-gray8);
  margin-left: 20px;
  margin-right: 20px;
}

.home-foods-menu .menu-item {
  width: 100%;
  height: 100%;
  position: relative;
  cursor: pointer;
  border-radius: 20px;
}

.home-foods-menu .menu-item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 20px;
}

.home-foods-menu .menu-item h4 {
  font-size: 18px;
  font-family: Yellix-Bold, serif;
  color: var(--color-text);
  margin: 0 !important;
  position: absolute;
  top: 20px;
  left: 20px;
}

.home-foods-menu .list {
  flex-wrap: wrap;
}

.home-foods-menu .see_more_btn {
  height: 100%;
  background-color: var(--color-gray8);
  font-size: 18px;
  font-family: Yellix-Bold, serif;
  color: var(--color-text);
}


@media (max-width: 576px) {
  .home-foods-menu .menu-item-view  {
    width: 100%;
  }
}
.food-category-item {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: calc(20% - 40px);
  min-width: 240px;
  height: 120px;
  cursor: pointer;
  border-radius: 20px;
  padding-top: 10px;
  padding-left: 20px;
  padding-right: 20px;
  background-color: var(--color-gray8);
  margin-left: 20px;
  margin-right: 20px;
}

.food-category-item .img-wrapper {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.food-category-item img {
  height: 70px;
  object-fit: contain;
  padding-bottom: 10px;
}

.food-category-item h4 {
  padding-bottom: 10px;
  font-size: 22px;
  font-family: Yellix-Bold, serif;
  color: var(--color-text);
  margin: 0 !important;
}

@media (max-width: 576px) {
  .food-category-item {
    width: 100%;
  }
  .food-category-item h4 {
    font-size: 19px;
  }
}
.our-app-intro {
    width: 100%;
    height: 100vh;
    margin-top: 36px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    background-image: url(/static/media/app-intro.9af274b0.png);
    /* background-color: #f9f9f9; */
    -webkit-clip-path: polygon(0 0, 100% 0%, 100% 100%, 0 91%);
            clip-path: polygon(0 0, 100% 0%, 100% 100%, 0 91%);
}

.our-app-intro .content {
    max-width: 1216px;
    width: 100%;
    margin: auto;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}

.our-app-intro .content .craving-something {
    font-family: 'Yellix-Bold';
    font-size: 32px;
    color: var(--color-text);
    margin: 0;
    display: flex;
    flex-direction: column;
    margin-bottom: 100px;
}

.our-app-intro .content .craving-something span {
    font-family: 'Yellix-Bold';
    color: #222222;
}

.our-app-intro .content h4 {
    font-family: Yellix-SemiBold, serif;
    color: var(--color-text);
}

.our-app-intro .content .craving-something {
    color: var(--color-cyan2);
    padding-right: 10px;
}

.our-app-intro .content img {
    width: 240px;
    height: 80px;
    object-fit: contain;
}

@media (max-width: 1200px) {
    .our-app-intro {
        height: 85vh;
    }

    .our-app-intro .content img {
        width: 210px;
        height: 70px;
        object-fit: contain;
    }
}

@media screen and (max-width: 872px) {
    .our-app-intro {
        height: 68vh;
        background-position: 67%;
    }

    .our-app-intro .align-middle {
        flex-direction: column;
        justify-content: flex-start;
    }

    .our-app-intro .content a {
        margin-bottom: 20px;
    }

    .our-app-intro .content img {
        width: 180px;
        height: 60px;
        object-fit: contain;
    }

    .our-app-intro .badge-android {
        margin-left: 0;
    }
}

@media screen and (max-width: 768px) {

    .our-app-intro {
        background-image: url(/static/media/app-intro-mobile.28d70338.png);
        margin-top: 0;
    }
    .our-app-intro .content {
        width: 100%;
        text-align: left;
        justify-content: flex-start;
    }

    .our-app-intro .content div {
      display: flex;
      width: 100%;
      flex-direction: column;
      align-items: center;
    }

    .our-app-intro .content .craving-something {
        font-size: 19px;
        display: block;
        text-align: center;
    }

    .our-app-intro .content a {
        display: block;
        margin-bottom: 30px;
    }
    .our-app-intro .content img {
        width: 150px;
        height: 50px;
    }
}

.our-app-intro-last {

    position: relative;
    width: 100%;
    height: 100vh;
    margin-bottom: -100px;
    padding: 0px 100px 0px;
    /* background-color: #f9f9f9; */
}

.our-app-intro-last::after {

    content: '';
    position: absolute;
    top: 0px;
    right: 0px;
    left: 0px;
    width: 100%;
    height: 100%;

    background-size: cover;
    background-repeat: no-repeat;
    background-position-x: right;
    background-position-y: center;
    background-image: url(/static/media/bg.cf3e09ad.png);
    /* background-color: #f9f9f9; */

    -webkit-clip-path: polygon(0 0, 100% 0%, 100% 100%, 0 91%);

            clip-path: polygon(0 0, 100% 0%, 100% 100%, 0 91%);

}

.our-app-intro-last .content {
    width: 50%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;


    position: relative;
    z-index: 1;
}

.our-app-intro-last .content h2 {
    letter-spacing: 0px;
    font-family: Yellix-Bold, serif;
    color: var(--color-text);
    margin-top: 0;
    margin-left: 0;
    margin-right: 0;
}


.our-app-intro-last .content .craving-something {
    color: var(--color-cyan2);
    padding-right: 10px;
}


.our-app-intro-last .content img {
    width: 240px;
    height: 80px;
    object-fit: contain;
}

@media screen and (max-width: 1200px) {

    .our-app-intro-last {
        padding: 0px 100px;
        height: 80vh;
    }

    .our-app-intro-last img {
        width: 200px;
    }

    .our-app-intro-last .content img {
        width: 210px;
        height: 70px;
        object-fit: contain;
    }
}


@media screen and (max-width: 872px) {

    .our-app-intro-last {
        height: 50vh;
        background-color: #fff;
        background-position: 100%;
    }

    .our-app-intro-last::after {

        background-size: auto;
        background-position-x: 1%;
        background-position-y: center;

    }

    .our-app-intro-last .content {
        width: 100%;
        text-align: center;
        align-items: center;
        justify-content: flex-start;
        padding-top: 50px;
    }

    .our-app-intro-last .align-middle {
        width: 100%;
    }

    .our-app-intro-last .content a {
        margin-bottom: 18px;
    }

    .our-app-intro-last .content img {
        width: 180px;
        height: 60px;
        object-fit: contain;
    }
}

@media screen and (max-width: 768px) {


    .our-app-intro-last::after {
        background-position-x: 1%;
        background-position-y: center;
    }

    .our-app-intro-last .align-middle {
        flex-direction: column;
    }

    .our-app-intro-last {
        padding-left: 20px;
        padding-right: 20px;
        margin-bottom: -60px;
    }

    .our-app-intro-last {
        padding: 0px 20px;
        background-position-y: -93px;
        background-size: contain;
        margin-top: 90px;
    }

    .our-app-intro-last .content {
        width: 100%;
        text-align: left;
        justify-content: flex-start;
        padding-top: 50px;
    }

    .our-app-intro-last .content a {
        display: block;
        width: 70%;
    }

    .our-app-intro-last br {
        display: none;
    }

    .our-app-intro-last .content img {
        width: 150px;
        height: 50px;
    }

    .our-app-intro-last .content h2 {
        text-align: center;
        margin-bottom: 20px;
    }
}
.view-download-app .food-tabs {
    margin-left: auto;
    margin-right: auto;
    max-width: 1450px;
    display: flex;
    align-items: center;
}

.view-download-app .food-tabs-left {
    flex: 0 0 auto;
    width: 50%;
    height: 600px;
    background-image: url(/static/media/3tabs.4aa4c119.png);
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
}

.view-download-app .food-tabs-right {
    flex: 0 0 auto;
    width: 50%;
    padding-left: 50px;
}

.view-download-app .food-tabs-right h2 {
    font-family: Yellix-Bold;
    letter-spacing: 0px;
    color: #222222;
    opacity: 1;
    margin-top: 0px;
}

.view-download-app .tab-section {
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 458px;
}

.view-download-app .tab-img {
    margin-right: 32px;
}

.view-download-app .tab-img img {
    width: 83px;
}

.view-download-app .food-tabs-right h5 {
    font-family:  Yellix-Bold;
    letter-spacing: 0px;
    color: #222222;
    opacity: 1;
    margin-top: 0px;
    margin-bottom: 21px;
}

.view-download-app .food-tabs-right p {
    margin: 0px;
    font-family:  Yellix-Medium;
    font-size: 20px;
    letter-spacing: 0px;
    color: #222222;
    opacity: 1;
}

@media screen and (max-width: 1100px) {
    .view-download-app .food-tabs {
        padding-left: 20px;
        padding-right: 20px;
    }

    .view-download-app .food-tabs-right {
        padding-left: 10px;
        padding-right: 10px;
    }

    .view-download-app .food-tabs-left {
        height: 530px;
    }
}

@media screen and (max-width: 830px) {
    .view-download-app .food-tabs-right p {
        font-size: 16px;
    }
}

@media screen and (max-width: 768px) {

    .view-download-app .food-tabs-left,
    .view-download-app .food-tabs-right {
        width: 100%;
    }

    .view-download-app .food-tabs {
        flex-direction: column;
    }

    .view-download-app .food-tabs-left {
        height: 380px;
    }

    .view-download-app .tab-section {
        max-width: none;
        justify-content: start;
    }

    .view-download-app .food-tabs-right h2 {
        margin-top: 25px;
    }
}

@media  screen and (max-width: 576px) {
    .view-download-app .food-tabs-left {
        margin-top: 0;
        height: 280px;
    }
}

.partner-screen .apply-form {
  width: 552px !important;
  max-width: calc(100vw - 32px) !important;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;
  border-radius: 16px;
  box-shadow: 0 0 25px rgba(66, 133, 244, 0.1) !important;
  padding: 20px;
}

.partner-screen .apply-form h2 {
  font-size: 18px;
  font-family: Yellix-Bold, serif;
  color: var(--color-text);
}

.partner-screen .apply-form .row {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  padding: 8px 0px;
}

.partner-screen .apply-form .row div.label {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 48px;
  width: 20%;
  font-size: 15px;
  font-family: Yellix-SemiBold, serif;
  color: var(--color-text);
  margin-right: 16px;
}

.partner-screen .apply-form .row div.input-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1 1;
}

.partner-screen .apply-form .row div.input-wrapper label {
  margin-right: 50px;
}

.partner-screen .apply-form .row div.input-wrapper .MuiFormControlLabel-label {
  font-size: 14px;
  font-family: Yellix-Medium, serif;
  color: var(--color-text);
}

.partner-screen .apply-form .terms-txt {
  font-size: 13px;
  font-family: Yellix-Medium, serif;
  color: var(--color-gray1);
}
.partner-screen .apply-form .terms-txt a {
  color: var(--color-text);
}

.partner-screen .apply-form .text-dropdown {
  height: 48px !important;
}

.partner-screen .apply-form .text-drop-btn {
  height: 48px !important;
}

.partner-screen .apply-form .text-dropdown-item {
  padding: 6px 18px;
}

.partner-screen .apply-form .text-drop-btn span.placeholder {
  color: var(--color-gray6);
}

.partner-screen .apply-form .auth-btn {
  background-color: var(--color-text) !important;
}

@media (max-width: 576px) {
  .partner-screen .apply-form .row div.label {
    width: 100%;
  }
}


.partner-screen .features {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;
}

.partner-screen .features h3 {
  font-family: Yellix-ExtraBold, serif;
  color: var(--color-text);
  margin-bottom: 56px;
}

.partner-screen .features .features-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding-left: 20px;
  padding-right: 20px;
  height: 100%;
}

.partner-screen .features .features-item img {
  height: 152px;
  object-fit: contain;
}

.partner-screen .features .features-item h5 {
  font-family: Yellix-Bold, serif;
  color: var(--color-text);
  text-align: center;
}

.partner-screen .features .features-item p {
  font-size: 20px;
  font-family: Yellix-SemiBold, serif;
  color: var(--color-text);
  text-align: center;
  margin: 0 !important;
}

@media (max-width: 576px) {
  .partner-screen .features .features-item p {
    font-size: 16px;
  }
  .partner-screen .features .features-item img {
    height: 118px;
  }
}
.partner-screen .mobile-intro {
  background-image: url(/static/media/bg.6deab9bd.png);
  height: 78vh;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  -webkit-clip-path: polygon(0 10%, 100% 0%, 100% 100%, 0 90%);
  clip-path: polygon(0 10%, 100% 0%, 100% 100%, 0 90%);
  position: relative;
}

.partner-screen .mobile-intro {
  background-position: left !important;
}

@media (max-width: 768px) {
  .partner-screen .mobile-intro {
    height: 56vh;
  }
}

@media (max-width: 576px) {
  .partner-screen .mobile-intro {
    height: 44vh;
  }
}

.partner-screen .mobile-intro .title-view {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  padding-right: 20%;
}

.partner-screen .mobile-intro h2 {
  font-family: Yellix-ExtraBold, serif;
  color: var(--color-cyan2);
}

.partner-screen .mobile-intro h5 {
  margin-top: 0 !important;
  font-family: Yellix-SemiBold, serif;
  color: var(--color-text);
}

.partner-screen .partners {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;
}

.partner-screen .partners h3 {
  font-family: Yellix-Bold, serif;
  color: var(--color-text);
  margin-bottom: 20px;
  text-align: center;
}

.partner-screen .partners h5 {
  font-family: Yellix-SemiBold, serif;
  color: var(--color-text);
  margin-bottom: 20px;
  text-align: center;
}

.partner-screen .partners .partner-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 25px;
}

.partner-screen .partners .partner-item img {
  width: 80px;
  height: 80px;
  object-fit: contain;
  border-radius: 50%;
  border: 1px solid var(--color-gray5);
}

@media (max-width: 576px) {
  .partner-screen .partners .partner-item img {
    width: 56px;
    height: 56px;
  }
}
.partner-screen .faqs {
  display: flex;
  flex-direction: column;
  background-color: white;
}

.partner-screen .faqs h3 {
  font-family: Yellix-Bold, serif;
  color: var(--color-text);
  margin-bottom: 40px;
  text-align: center;
}

.partner-screen .faqs .items {
  width: 100%;
  max-width: calc(100vw - 32px) !important;
}


.partner-screen {
  position: relative;
  z-index: 0;
}

.partner-screen section.main {
  background-image: url(/static/media/bg.2e0ccf4c.svg);
  position: relative;
}

.partner-screen section.main .title-view {
  display: flex;
  flex-direction: column;
  justify-content: left;
  height: 100%;
}

.partner-screen section.main h1 {
  font-family: Yellix-ExtraBold, serif;
  color: var(--color-text);
}

.partner-screen section.main h4 {
  font-family: Yellix-SemiBold, serif;
  color: var(--color-text);
  margin: 0;
}

@media (min-width: 0px) {
  .partner-screen .MuiGrid-item {
     padding-left: 0px;
  }
}
.view-terms {
    margin: auto;
    border-top-width: 1px;
    border-top-style: solid;
    border-top-color: var(--color-gray6);
}

.view-terms br {
    height: 20px;
}
.view-terms {
    padding-top: 30px;
    padding-left: 20px;
    padding-right: 20px;
}


.view-terms h1 {
    margin-top: 32px;
    margin-bottom: 32px;
    font: normal normal 800 32px/38px Yellix-Bold;
    letter-spacing: 0px;
    color: #222222;
    opacity: 1;
}

.view-terms p {
    font: normal normal 400 16px/19px Yellix-Medium;
    letter-spacing: 0px;
    color: #222222;
    opacity: 1;
}
.view-terms {
    margin: auto;
    border-top-width: 1px;
    border-top-style: solid;
    border-top-color: var(--color-gray6);
}

.view-terms br {
    height: 20px;
}
.view-terms {
    padding-top: 30px;
    padding-left: 20px;
    padding-right: 20px;
}


.view-terms h1 {
    margin-top: 32px;
    margin-bottom: 32px;
    font: normal normal 800 32px/38px Yellix-Bold;
    letter-spacing: 0px;
    color: #222222;
    opacity: 1;
}

.view-terms p {
    font: normal normal 400 16px/19px Yellix-Medium;
    letter-spacing: 0px;
    color: #222222;
    opacity: 1;
}
.view-privacy {
    margin: auto;
    border-top-width: 1px;
    border-top-style: solid;
    border-top-color: var(--color-gray6);
}

.view-privacy br {
    height: 20px;
}
.view-privacy {
    padding-top: 30px;
    padding-left: 20px;
    padding-right: 20px;
}


.view-privacy h1 {
    margin-top: 32px;
    margin-bottom: 32px;
    font: normal normal 800 32px/38px Yellix-Bold;
    letter-spacing: 0px;
    color: #222222;
    opacity: 1;
}

.view-privacy p {
    font: normal normal 600 16px/19px Yellix-SemiBold;
    letter-spacing: 0px;
    color: #222222;
    opacity: 1;
}
@-webkit-keyframes spinner {
    0% {
      -webkit-transform: rotate(0deg);
              transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
              transform: rotate(360deg);
    }
  }
  @keyframes spinner {
    0% {
      -webkit-transform: rotate(0deg);
              transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
              transform: rotate(360deg);
    }
  }
  .loading-spinner {
    width: 50px;
    height: 50px;
    border: 5px solid #f3f3f3; /* Light grey */
    border-top: 5px solid #50B7ED; /* Blue */
    border-radius: 50%;
    -webkit-animation: spinner 0.75s linear infinite;
            animation: spinner 0.75s linear infinite;
  }
  
  .spinner-container {
    display: grid;
    justify-content: center;
    align-items: center;
    height: 350px;
  }
.view-blog .header-bg {
  width: 100%;
  height: 390px;
  margin-top: -80px;
  background-image: url(/static/media/blog_header_bg.1f73e3e7.svg);
  background-repeat: no-repeat;
}

.view-blog .main {
  margin-top: -195px;
}

.view-blog .main .bloglist-body {
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;
  background-color: white;
}


@media (max-width: 1400px) {
  .view-blog .blog-item-wrap {
    width: 50% !important;
    max-width: 50% !important;
    flex: none !important;
  }
}

@media (max-width: 992px) {
  .view-blog .blog-item-wrap {
    width: 100% !important;
    max-width: 100% !important;
    flex: none !important;
  }
}

@media (max-width: 576px) {
  .view-blog .main {
    padding-left: 0 !important;
    padding-right: 0 !important;
    margin-top: -295px;
  }

}
.blog-item {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: var(--color-gray8);
  border-radius: 16px;
  margin-bottom: 32px;
  cursor: pointer;
}

.blog-item .img {
  width: 100%;
  height: 120px;
  position: relative;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding-left: 20px;
  padding-right: 6px;
  padding-bottom: 12px;
}

.blog-item .overlay-dark:after {
  opacity: 0.25;
  background-color: black;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
}

.blog-item .tags {
  margin-top: 12px;
}

.blog-item .tags .tag-text {
  font-size: 15px;
  font-family: Yellix-SemiBold, serif;
  color: var(--color-cyan2);
}

.blog-item h3.blog-title {
  color: var(--color-text);
  font-size: 17px;
  font-family: Yellix-SemiBold, serif;
  margin: 0;
}

.blog-item h3.author-txt {
  color: var(--color-cyan2);
  font-size: 15px;
  font-family: Yellix-SemiBold, serif;
  margin: 0;
}

.blog-item h3.date-txt {
  color: var(--color-gray7);
  font-size: 15px;
  font-family: Yellix-SemiBold, serif;
  margin: 0;
}

.blog-item .info {
  display: flex;
  justify-content: space-between;
  padding: 10px 16px 16px;
}

.blog-filter h1 {
  font-size: 24px;
  font-family: Yellix-ExtraBold, serif;
  color: var(--color-text);
}

.blog-filter .list-btn {
  background-color: var(--color-gray8) !important;
  color: var(--color-text);
  height: 48px;
  border-radius: 10px;
  margin-bottom: 15px;
  margin-top: 15px;
}

.blog-filter .list-btn span {
  font-size: 16px;
  font-family: Yellix-Bold, serif;
  color: var(--color-text);
}

.blog-filter .round-icon-btn {
  display: none !important;
}

@media (max-width: 767px) {
  .blog-filter .search-input {
    height: 48px !important;
  }

  .blog-filter h1 {
    display: none;
  }

  .blog-filter .round-icon-btn {
    display: flex !important;
    border-color : var(--color-gray5)
  }

  .blog-filter .filter-body {
    display: none !important;
  }
}

/* add card modal */
.blog-filter-modal {
    width: 676px;
}

.blog-filter-modal .MuiDialog-paper {
    border-radius: 16px;
    width: 560px;
    max-width: calc(100vw - 32px) !important;
}

.blog-filter-modal .content {
    background-color: white;
    padding: 26px 0px;
    overflow: hidden;
}

.blog-filter-modal .content h1.title {
    font-size: 26px;
    font-family: Yellix-Bold, serif;
    color: var(--color-text);
    margin: 0px;
    text-align: center;
}

.blog-filter-modal .content img.close-btn {
    width: 40px;
    height: 40px;
    object-fit: contain;
    position: absolute;
    left: 15px;
    top: 0;
    cursor: pointer;
}

.blog-filter-modal .save-btn {
    width: 100%;
    background-color: var(--color-cyan2) !important;
    color: white;
}

.blog-filter-modal {
    flex: 1 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100vh;
}

.blog-filter-modal  .header {
    height: 55px;
    display: flex;
    align-items: flex-end;
    padding-left: 20px;
    padding-right: 20px;
}

.blog-filter-modal .scroll_view {
    flex: 1 1;
    width: 100%;
    background-color: white;
    margin-top: 10px;
    overflow-y: auto;
}


.blog-filter-modal .list-btn {
    background-color: var(--color-gray8) !important;
    color: var(--color-text);
    height: 48px;
    border-radius: 10px;
    margin-bottom: 15px;
    margin-top: 15px;
}

.blog-filter-modal .MuiList-root {
    padding-bottom: 0 !important;
    padding-top: 0 !important;
}

.blog-filter-modal .list-btn span {
    font-size: 16px;
    font-family: Yellix-Bold, serif;
    color: var(--color-text);
}

.no_bloglist_view {
    flex: 1 1;
}

.no_bloglist_view .description {
    color: var(--color-text);
    line-height: 24px;
    font-family: Yellix-Medium, serif;
    font-size: 16px;
    text-align: center;
    padding-left: 20px;
    padding-right: 20px;
}
.view-blog-details .header-bg {
  width: 100%;
  height: 520px;
  margin-top: -80px;
  background-repeat: no-repeat;
  background-size: cover;
  -webkit-clip-path: polygon(0 0%, 100% 0%, 100% 92%, 0% 100%);
  clip-path: polygon(0 0%, 100% 0%, 100% 92%, 0% 100%);
  position: relative;
}

.view-blog-details .share-btn {
  cursor: pointer;
  position: absolute;
  right: 20px;
  bottom: 12%;
}

.view-blog-details .blog-body .back-link {
  font-size: 18px;
  font-family: Yellix-SemiBold, serif;
  color: #858490;
  margin-top: 32px;
  margin-left: -8px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  text-decoration: none;
}

.view-blog-details .blog-body .back-link svg {
  padding-top: 4px;
}

.view-blog-details .blog-body .title h1 {
  font-family: Yellix-ExtraBold, serif;
  color: var(--color-text);
  margin-top: 16px;
  margin-bottom: 20px;
}

.view-blog-details .blog-body .tag-text {
  font-size: 16px;
  font-family: Yellix-SemiBold, serif;
  color: var(--color-cyan2);
  margin-top: 28px;
}

.view-blog-details .blog-body .author div:first-child {
  font-size: 16px;
  font-family: Yellix-Medium, serif;
  color: var(--color-gray7);
  margin-right: 8px;
}

.view-blog-details .blog-body .author div:last-child {
  font-size: 16px;
  font-family: Yellix-Medium, serif;
  color: var(--color-red1);
}

.view-blog-details .blog-body .date div:first-child {
  font-size: 14px;
  font-family: Yellix-Medium, serif;
  color: var(--color-gray7);
  margin-top: 14px;
}

.view-blog-details .blog-body .content {
  font-size: 16px;
  margin-top: 10px;
  margin-bottom: 30px;
}

.view-blog-details .blog-body .content img {
  object-fit: contain;
  width: 100% !important;
}

.view-blog-details .blog-body .content * {
  max-width: calc(100vw - 40px);
}

.custom-grid {
  display: flex;
  grid-gap: 16px;
  gap: 16px;
  align-items: center;
}

@media (max-width: 768px) {
  .custom-grid {
    flex-direction: column;
    grid-gap: 8px;
    gap: 8px;
  }
}

@media (max-width: 576px) {
  .view-blog-details .header-bg {
    height: 200px;
    background-size: cover;
    clip-path: none;
    -webkit-clip-path: none;
    margin-top: 0;
  }

  .view-blog-details .blog-body .content img {
    height: 100% !important;
  }
}

.quiz-container {
  margin: 20px auto;
  padding: 10px 10px;
  border: 1px solid #ddd;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.quiz-synopsis {
  color: #555;
  margin-bottom: 20px;
}

.filter-dropdown-select {
  height: 40px !important;
  overflow-y: auto;
  width: 200px !important;
}

.react-quiz-container {
  max-width: unset !important;
}

.react-quiz-container .selection-tag,
.number-of-selection {
  display: none !important;
}

.questionBtnContainer {
  display: flex;
  justify-content: flex-end !important;
}

.questionWrapperBody .answerBtn {
  height: auto !important;
}

.react-quiz-container .questionWrapper .btn.correct,
.questionWrapperBody .incorrect .answerBtn .btn,
.react-quiz-container .incorrect {
  background-color: #78c43d !important;
  height: unset !important;
  border-image-source: linear-gradient(
    101.56deg,
    #240b3b 3.95%,
    #3d1e59 34%,
    #5d1c82 49%,
    #8319b4 67.14%,
    #bc14fe 94.28%
  ) !important;

  box-sizing: border-box !important;

  background: rgba(36, 11, 59, 0.2) !important;
  border-radius: 7px !important;

  color: linear-gradient(
      101.56deg,
      #240b3b 3.95%,
      #3d1e59 34%,
      #5d1c82 49%,
      #8319b4 67.14%,
      #bc14fe 94.28%
    ),
    linear-gradient(0deg, rgba(36, 11, 59, 0.2), rgba(36, 11, 59, 0.2)) !important;
  color: black !important;
  font-weight: 700 !important;
}

.react-quiz-container .questionWrapper .btn.correct {
  border: 3px #90dd40 solid !important;
}
.questionWrapperBody .incorrect .answerBtn .btn,
.react-quiz-container .incorrect {
  border: red 2px solid !important;
}

.questionWrapperBody h3 {
  font-size: 15px !important;
}

.quiz-title {
  font-family: 'Manrope' !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 25px !important;
  line-height: 54px !important;
  color: #240b3b !important;
  margin-bottom: 10px !important;
}

.react-quiz-container div :first-child:not(button):not(span),
.react-quiz-container div :nth-child(2):not(button):not(span) {
  font-family: 'Manrope' !important;
  font-style: normal !important;
  font-weight: 600 !important;
  margin-top: 10px !important;
}

.react-quiz-container div :first-child:not(button):not(span) {
  font-size: 22px;
}

.react-quiz-container div :nth-child(2):not(button):not(span) {
  font-size: 15px !important;
}

.quiz-synopsis {
  font-family: 'Manrope' !important;
  font-style: normal !important;
  font-weight: 700 !important;
  color: #565656 !important;
  margin-top: 0px !important;
}

.react-quiz-container .questionWrapper .btn {
  display: flex !important;
  align-items: center !important;
}

.react-quiz-container .tag-container {
  display: flex !important;
  flex-wrap: wrap !important;
  grid-gap: 1px !important;
  gap: 1px !important;
  margin-bottom: 15px !important;
  margin-top: 15px !important;
}
.startQuizWrapper button,
.questionBtnContainer button {
  width: 177.25px !important;
  height: 46.39px !important;
  border-radius: 6px !important;
  border: none !important;
  outline: none !important;
  background: rgba(80, 183, 237, 255) !important;
  color: white !important;
  background-color: rgba(80, 183, 237, 255) !important;
  text-transform: uppercase !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

.ctaTerms button {
  border-radius: 6px;
  outline: none;
  color: linear-gradient(101.56deg, #240b3b 3.95%, #3d1e59 34%, #bc14fe 94.28%);
  color: #240b3b;
  padding-block: 0px;
  margin-block: 0px;
  font-size: 15px;
  font-weight: 700;
  text-decoration: underline;
}

@media only screen and (max-width: 800px) {
  .react-quiz-container div :first-child:not(button):not(span) {
    padding-top: 20px !important;
    line-height: 1.2 !important;
  }

  .react-quiz-container div :nth-child(2):not(button):not(span) {
    font-size: 17px !important;
  }

  .modal-props {
    max-height: 400px;
  }
}

.subscribe h1 {
  font-family: 'Yellix-Bold', sans-serif;
  font-size: 2rem;
  font-weight: bold;
  color: #2c3e50;
  margin-bottom: 20px;
  margin-bottom: 5px;
}

.sign-up-container {
  border-radius: 0.5rem;
  border: 1px solid #dee2e6;
  padding: 36px;
  display: flex;
  flex-direction: column;
  grid-gap: 16px;
  gap: 16px;
  width: 100%;
  height: 100%;
  margin-bottom: 20px;
}

.sign-up-header {
  color: #222222;
  font-weight: bold;
  font-size: 18px;
}

.sign-up-subtitle {
  font-family: 'Yellix-SemiBold';
  font-style: normal;
  font-weight: 700;
  color: #565656;
  margin-top: 0px;
}

.email-input-container {
  border: 1px solid #dee2e6;
  border-radius: 0.5rem;
  padding-left: 24px;
  display: flex;
  justify-content: space-between;
}

.email-input {
  border: none;
  font-weight: 600;
  font-size: 14px;
  width: 100%;
  height: 44px;
  outline: none;
}

.subscribe-button {
  background-color: rgba(80, 183, 237, 255);
  color: white;
  cursor: pointer;
  border-radius: 0.5rem;
  font-weight: 600;
  font-size: 14px;
  padding: 12px 20px;
  border: rgba(80, 183, 237, 255);
}

.vendor-search-view {
  width: 100%;
  max-width: calc(100vw - 32px) !important;
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: white;
  border-radius: 16px;
  box-shadow: 0 0 25px rgba(66, 133, 244, 0.1) !important;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 0px;
  padding-bottom: 15px;
}

.vendor-search-view .row {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 8px 0;
}

.vendor-search-view .vendor_type_radios {
  justify-content: center;
}

.vendor-search-view .vendor_type_radios label {
  margin-right: 20px;
  min-width: 116px;
}

.vendor-search-view .vendor_type_radios .MuiFormControlLabel-label {
  font-size: 16px;
  font-family: Yellix-Medium, serif;
  color: var(--color-text);
}

.error .location-input {
  border: 1px solid var(--color-red1);
}

.vendor-search-view .app-btn {
  font-size: 18px !important;
}

@media (max-width: 1440px) {

}

@media (max-width: 1200px) {
  .vendor-search-view {
    flex-direction: column;
  }
}

@media (max-width: 576px) {
  .vendor-search-view .location-input input {
    font-size: 16px !important;
  }

  .vendor-search-view .search-input input {
    font-size: 16px !important;
  }
}
.vendor-item {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: var(--color-gray8);
  border-radius: 16px;
  margin-bottom: 32px;
  cursor: pointer;
}

.vendor-item .img {
  width: 100%;
  height: 160px;
  position: relative;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding-left: 20px;
  padding-right: 6px;
  padding-bottom: 12px;
}

.vendor-item .overlay-dark:after {
  opacity: 0.25;
  background-color: black;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
}

.vendor-item .only_snapfood {
  font-size: 14px;
  font-family: Yellix-Medium, serif;
  color: white;
  padding: 4px 9px 5px;
  background-color: var(--color-cyan2);
  border-radius: 8px;
  margin-right: 6px;
}

.vendor-item .buy_get_free {
  width: 80px;
  text-align: center;
  font-size: 12px;
  font-family: Yellix-Medium, serif;
  color: white;
  padding: 4px 6px;
  background-color: var(--color-red1);
  border-radius: 8px;
  margin-right: 6px;
}

.vendor-item .tags {
  flex-wrap: wrap;
  margin-top: 12px;
}

.vendor-item .tags .tag-text {
  font-size: 16px;
  font-family: Yellix-Medium, serif;
  color: white;
  padding: 4px 6px;
  background-color: #aaaaaa44;
  border-radius: 8px;
  margin-right: 6px;
  white-space: nowrap;
}

.vendor-item .info {
  display: flex;
  justify-content: space-between;
  padding: 10px 16px 16px;
}

.vendor-item .info div:first-child {
  margin-right: 8px;
}

.vendor-item .vendor-logo {
  width: 80px;
  height: 80px;
  background-color: white;
  border-radius: 12px;
}

.vendor-item .vendor-logo img {
  width: 80px;
  height: 80px;
  object-fit: contain;
  border-radius: 12px;
}

.vendor-item h3.vendor-title {
  color: var(--color-text);
  font-size: 18px;
  font-family: Yellix-Bold;
  margin: 0;
  padding-top: 4px;
}

.vendor-item .indicator {
  font-size: 16px;
  font-family: Yellix-Medium;
  display: flex;
  align-items: center;
}

.vendor-item .indicator > div {
  width: 10px;
  height: 10px;
  border-radius: 5px;
  margin-right: 5px;
}

.vendor-item h3.liked-txt {
  color: var(--color-red1);
  font-size: 16px;
  font-family: Yellix-Medium;
  margin: 0;
}

.vendor-item .rate-item {
  font-size: 16px;
  font-family: Yellix-Medium;
  color: var(--color-gray7);
}

.vendor-item .price-item img {
  width: 18px;
  height: 18px;
  object-fit: contain;
  margin-right: 4px;
}

.vendor-item .price-item {
  font-size: 16px;
  font-family: Yellix-Medium;
  color: var(--color-gray7);
}

.vendor-item .price-item img {
  width: 14px;
  height: 14px;
  object-fit: contain;
  margin-right: 6px;
}

.vendor-item .fav-btn {
  padding: 4px;
  position: absolute;
  top: 10px;
  right: 10px;
}

.vendor-item img.open-close-img {
  position: absolute;
  top: 6px;
  left: 6px;
  width: 65px;
  height: 45px;
  object-fit: contain;
}

@media (max-width: 576px) {
  .vendor-item {
    margin-bottom: 18px;
  }
}
.view-vendors .header-bg {
  width: 100%;
  height: 180px;
  margin-top: -80px;
  background-image: url(/static/media/blog_header_bg.1f73e3e7.svg);
  background-repeat: no-repeat;
  background-size: cover;
}

.view-vendors .header-bg h4 {
  font-family: Yellix-Bold, serif;
  color: white;
  font-weight: normal;
  margin: 0;
  text-align: center;
}

.view-vendors .main {
  padding-left: 104px;
  padding-right: 104px;
}

.view-vendors .main .vendors-body {
  width: 100%;
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;
  background-color: white;
}

.view-vendors .search-view {
  margin-top: -50px;
  padding-left: 104px;
  padding-right: 104px;
  padding-bottom: 20px;
}

.view-vendors .main .vendors-body .featured-block-title {
  color: var(--color-text);
  font-family: Yellix-Bold, serif;
  font-size: 26px;
  margin-bottom: 20px;
}

@media (max-width: 1400px) {
  .view-vendors .vendor-item-wrap {
    width: 50% !important;
    max-width: 50% !important;
    flex: none !important;
  }

  .view-vendors .search-view {
    padding-left: 40px;
    padding-right: 40px;
  }

  .view-vendors .main {
    padding-left: 40px;
    padding-right: 40px;
  }
}

@media (max-width: 992px) {
  .view-vendors .header-bg {
    height: 180px;
  }

  .view-vendors .vendor-item-wrap {
    width: 100% !important;
    max-width: 100% !important;
    flex: none !important;
  }

  .view-vendors .search-view {
    padding-left: 20px;
    padding-right: 20px;
  }

  .view-vendors .main {
    padding-left: 20px;
    padding-right: 20px;
  }

  .view-vendors .featured-vendor-list {
    flex-wrap: nowrap !important;
    overflow-x: auto;
  }
}

.view-vendors .featured-mobile-view  {
  display: none;
}

@media screen and (max-width: 576px) {
  .header-bg {
    padding: 0px 15% 0px 15%;
  }
  .view-vendors .header-bg h1 {
    padding-bottom: 20px;
  }

  .view-vendors .main .vendors-body .featured-block-title {
    font-size: 22px;
  }

  .view-vendors .featured-vendor-list {
    display: none !important;
  }

  .view-vendors .featured-mobile-view {
    display: inherit;
  }
}
.vendor-filter h1 {
  font-size: 24px;
  font-family: Yellix-ExtraBold, serif;
  color: var(--color-text);
  margin-bottom: 0;
  margin-top: 0;
}

.vendor-filter .list-btn {
  background-color: var(--color-gray8) !important;
  color: var(--color-text);
  height: 48px;
  border-radius: 10px;
  margin-bottom: 15px;
}

.vendor-filter .MuiAccordion-root {
  box-shadow: none;
  margin: 0 !important;
}

.vendor-filter .list-btn span,
.vendor-filter .MuiAccordionSummary-content span {
  font-size: 16px;
  font-family: Yellix-Bold, serif;
  color: var(--color-text);
}

.vendor-filter .MuiAccordion-root,
.vendor-filter .MuiCollapse-root {
  background-color: var(--color-gray8);
  border-radius: 10px;
}

.vendor-filter .MuiAccordion-root::before {
  opacity: 0;
}

.vendor-filter .sort-btn {
  margin-bottom: 20px;
}

.vendor-filter .sort-btn span {
  font-size: 14px;
  font-family: Yellix-SemiBold, serif;
  color: var(--color-text);
}

.vendor-filter .MuiSlider-valueLabel {
  border-radius: 20px;
  background-color: var(--color-cyan2);
  color: white;
  font-size: 11px;
  padding: 2px 10px;
  font-family: Yellix-Medium, serif;
}

.vendor-filter .MuiSlider-root {
  width: calc(100% - 40px);
}

.vendor-filter .MuiSlider-rail {
  color: var(--color-gray7);
}

.vendor-filter .MuiSlider-track {
  color: var(--color-cyan2);
}

.vendor-filter .MuiSlider-thumb {
  color: var(--color-cyan2);
  width: 16px;
  height: 16px;
}

.vendor-filter .map-btn {
  color: var(--color-cyan2) !important;
  border: 1px solid var(--color-cyan2) !important;
  font-family: Yellix-SemiBold, serif;
}

.vendor-filter .filter-header .round-icon-btn {
  display: none;
}

@media (max-width: 576px) {
  .vendor-filter .filter-header .round-icon-btn {
    display: inherit;
  }

  .vendor-filter .filter-body {
    display: none;
  }
}

/* add card modal */
.vendor-filter-modal {
    width: 676px;
}

.vendor-filter-modal .MuiDialog-paper {
    border-radius: 16px;
    width: 560px;
    max-width: calc(100vw - 32px) !important;
}

.vendor-filter-modal .content {
    background-color: white;
    padding: 26px 0px;
    overflow: hidden;
}

.vendor-filter-modal .content h1.title {
    font-size: 26px;
    font-family: Yellix-Bold, serif;
    color: var(--color-text);
    margin: 0px;
    text-align: center;
}

.vendor-filter-modal .content img.close-btn {
    width: 40px;
    height: 40px;
    object-fit: contain;
    position: absolute;
    left: 15px;
    top: 0;
    cursor: pointer;
}

.vendor-filter-modal .save-btn {
    width: 100%;
    background-color: var(--color-cyan2) !important;
    color: white;
}

.vendor-filter-modal {
    flex: 1 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100vh;
}

.vendor-filter-modal  .header {
    height: 55px;
    display: flex;
    align-items: flex-end;
    padding-left: 20px;
    padding-right: 20px;
}

.vendor-filter-modal .scroll_view {
    flex: 1 1;
    width: 100%;
    background-color: white;
    margin-top: 10px;
    overflow-y: auto;
}


.vendor-filter-modal .list-btn {
    background-color: var(--color-gray8) !important;
    color: var(--color-text);
    height: 48px;
    border-radius: 10px;
    margin-bottom: 15px;
    margin-top: 15px;
}

.vendor-filter-modal .MuiList-root {
    padding-bottom: 0 !important;
    padding-top: 0 !important;
}

.vendor-filter-modal .MuiAccordion-root {
    box-shadow: none;
    margin: 0 !important;
}

.vendor-filter-modal .list-btn span,
.vendor-filter-modal .MuiAccordionSummary-content span {
    font-size: 16px;
    font-family: Yellix-Bold, serif;
    color: var(--color-text);
}

.vendor-filter-modal .MuiAccordion-root,
.vendor-filter-modal .MuiCollapse-root {
    background-color: var(--color-gray8);
    border-radius: 10px;
}

.vendor-filter-modal .MuiAccordion-root::before {
    opacity: 0;
}

.vendor-filter-modal .sort-btn {
    margin-bottom: 20px;
}

.vendor-filter-modal .sort-btn span {
    font-size: 14px;
    font-family: Yellix-SemiBold, serif;
    color: var(--color-text);
}

.vendor-filter-modal .MuiSlider-valueLabel {
    border-radius: 20px;
    background-color: var(--color-cyan2);
    color: white;
    font-size: 11px;
    padding: 2px 10px;
    font-family: Yellix-Medium, serif;
}

.vendor-filter-modal .MuiSlider-root {
    width: calc(100% - 40px);
}

.vendor-filter-modal .MuiSlider-rail {
    color: var(--color-gray7);
}

.vendor-filter-modal .MuiSlider-track {
    color: var(--color-cyan2);
}

.vendor-filter-modal .MuiSlider-thumb {
    color: var(--color-cyan2);
    width: 16px;
    height: 16px;
}
.no_location_view {
  flex: 1 1;
}

.no_location_view img {
  width: 240px;
  object-fit: contain;
}

.no_location_view .description {
  color: var(--color-text);
  line-height: 24px;
  font-family: Yellix-Medium, serif;
  font-size: 16px;
  text-align: center;
  padding-left: 20px;
  padding-right: 20px;
}

.no_restaurant_view {
    flex: 1 1;
}

.no_restaurant_view .description {
    color: var(--color-text);
    line-height: 24px;
    font-family: Yellix-Medium, serif;
    font-size: 14px;
    text-align: center;
    padding-left: 20px;
    padding-right: 20px;
}
.view-vendors .breadcrumb {
  margin-bottom: 20px;
}

.view-vendors .breadcrumb img {
  width: 20px;
  height: 20px;
  object-fit: contain;
  margin-right: 6px;
}

.view-vendors .breadcrumb a {
  color: var(--color-text);
  font-family: Yellix-SemiBold, serif;
  font-size: 15px;
}

.view-vendors .breadcrumb .address-tag {
  padding: 8px;
  background-color: var(--color-gray9);
  border-radius: 10px;
}


@media screen and (max-width: 420px) {
  .view-vendors .breadcrumb .address-tag {
    margin-top: 10px;
  }
}

.featured-vendors-block .react-multiple-carousel__arrow {
  bottom: 65px;
}
.view-vendor {
  position: relative;
}

.view-vendor .overlay-dark:after {
  height: 420px;
  z-index: 0;
  opacity: 0.2;
  background-color: black;
}

.view-vendor .vendor-bg {
  width: 100%;
  height: 420px;
  margin-top: 80px;
  padding-top: 18px;
  padding-bottom: 8px;
  display: flex;
  align-items: flex-end;
  background-repeat: no-repeat;
  background-size: cover;
}

.view-vendor .action-menu .past-order-link {
  background-color: var(--color-red1);
  -webkit-text-decoration-line: none;
          text-decoration-line: none;
  color: white;
  border-radius: 6px;
  height: 32px;
  text-align: center;
  font-size: 16px;
  font-family: Yellix-Medium, serif;
  line-height: 20px;
  margin-right: 12px;
  padding: 4px 6px;
}

.view-vendor .order-methods-tabs {
  border-width: 1px;
  border-color: var(--color-gray6);
  border-style: solid;
  border-radius: 12px;
}

.view-vendor .order-methods-tabs .tab-btn-active {
  background-color: var(--color-text) !important;
  color: white;
}

.view-vendor .sticky-header {
  position: fixed;
  top: 80px;
  left: 0;
  z-index: 2;
}

.view-vendor .categ-list {
  width: 100%;
  background-color: white;
}

.view-vendor .cartbtn-view {
  position: fixed;
  bottom: 18px;
  left: 0;
  z-index: 1;
}

.view-vendor .cartbtn-view .cartview-btn {
  width: 576px;
  max-width: calc(100% - 40px);
}

.view-vendor .vendor-hscroll-menu .active-cat-item,
.view-vendor .vendor-hscroll-menu .inactive-cat-item
{
  font-size: 20px;
}

@media (max-width: 576px) {
  .view-vendor .common-tabs .tab-btn {
    font-size: 18px !important;
  }

  .view-vendor .categ-list {
    padding-left: 12px !important;
    padding-right: 12px !important;
  }

  /*.view-vendor .overlay-dark:after {*/
  /*  height: 340px;*/
  /*}*/

  /*.view-vendor .vendor-bg {*/
  /*  height: 340px;*/
  /*}*/
}
.shopinfo-view {
  align-items : flex-start !important;
}

.shopinfo-view img.vendor-logo {
  width: 80px;
  height: 80px;
  border-width: 1px;
  border-radius: 15px;
  border-style: solid;
  border-color: var(--color-gray9);
  background-color: white;
}

.shopinfo-view div.right {
  margin-left: 8px;
  justify-content: center;
  align-items: flex-start;
}

.shopinfo-view h1 {
  margin-right: 8px;
  font-size: 20px;
  font-family: Yellix-Bold, serif;
  color: white;
  margin-top: 0;
  margin-bottom: 0;
  font-weight: normal;
}

.shopinfo-view h3 {
  margin-top: 4px;
  margin-bottom: 4px;
  font-size: 14px;
  font-family: Yellix-Medium, serif;
  color: white;
  font-weight: normal;
}

.shopinfo-view .open-close-badge {
  border-radius: 3px;
  background-color: var(--color-red1);
  padding: 4px 6px;
  margin-left: 8px;
}

.shopinfo-view .open-close-badge > div {
  width: 7px;
  height: 7px;
  border-radius: 4px;
}
.shopinfo-view .open-close-badge > span {
  font-size: 12px;
  font-family: Yellix-Medium, serif;
  color: white;
  padding-left: 4px;
}

.shopinfo-view .tag-item {
  border-radius: 3px;
  background-color: #7b7462;
  padding: 4px 6px;
  margin-right: 8px;
  font-size: 12px;
  font-family: Yellix-Medium, serif;
  color: white;
}

.vendor-food-list {
  background-color: white;
}

.vendor-food-list .food-list {
  width: 100%;
  background-color: white;
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.vendor-food-list .category {
  font-size: 21px;
  font-family: Yellix-SemiBold, serif;
  color: var(--color-text);
  height: 48px;
  padding-bottom: 12px;
  padding-left: 15px;
}

@media (max-width: 576px) {
  .vendor-food-list .food-list .food-item {
    margin-bottom: 18px !important;
  }

  .vendor-food-list {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}

.food-item {
    background: var(--color-gray8);
    border-radius: 16px;
    margin-bottom: 32px;
    cursor: pointer;
    padding: 12px;
}

.food-item .food-title {
    font-size: 18px;
    font-family: Yellix-Bold, serif;
    color: var(--color-text);
    margin: 0 !important;
}

.food-item .cart-cnt {
    font-size: 17px;
    font-family: Yellix-SemiBold, serif;
    color: var(--color-cyan2);
    margin-right: 5px;
}

.food-item .discount {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 8px;
}

.food-item .discount span {
    font-size: 14px;
    font-family: Yellix-SemiBold, serif;
    color: var(--color-cyan2);
    padding-left: 2px;
}

.food-item .food-desc {
    font-size: 17px;
    font-family: Yellix-Medium, serif;
    color: var(--color-gray2);
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3; /* number of lines to show */
    line-clamp: 3;
    -webkit-box-orient: vertical;
}


.food-item .info {
    display: flex;
    justify-content: space-between;
}

.food-item .info div:first-child {
    margin-right: 2px;
}

.food-item .food-img {
    width: 108px;
    height: 108px;
    background-color: white;
    border-radius: 12px;
}

.food-item .food-img img {
    width: 108px;
    height: 108px;
    object-fit: contain;
    border-radius: 12px;
}

.food-item .indicator {
    font-size: 12px;
    font-family: Yellix-Medium;
    display: flex;
    align-items: center;
}

.food-item .indicator > div {
    width: 10px;
    height: 10px;
    border-radius: 5px;
    margin-right: 5px;
}

.food-item .like-btns span {
    color: var(--color-gray2);
    font-size: 12px;
    font-family: Yellix-Medium, serif;
    padding-left: 3px;
}

.food-item .like-btns span.divider {
    padding-left: 10px;
    padding-right: 10px;
}

.food-item .like-btns .active span,
.food-item .like-btns .active svg {
    color: var(--color-red1);
}

.food-item .price-item span.default-price {
    font-size: 19px;
    font-family: Yellix-Bold, serif;
    color: var(--color-text);
    padding-right: 6px;
}

.food-item .price-item span.discount-price {
    font-size: 16px;
    font-family: Yellix-Medium, serif;
    color: var(--color-gray7);
    -webkit-text-decoration-line: line-through;
            text-decoration-line: line-through;
    -webkit-text-decoration-color: var(--color-gray7);
            text-decoration-color: var(--color-gray7);
    padding-top: 1px;
}

.food-item .fav-btn {
    padding: 4px;
    position: absolute;
    top: 4px;
    right: 4px;
}

.food-item-cart-border {
    border-left : 8px solid var(--color-cyan2);
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
}

.food-item .img-view {
    position: relative;
    width: 108px;
    height: 108px;
}
.food-item {
  background: var(--color-gray8);
  border-radius: 16px;
  margin-bottom: 32px;
  cursor: pointer;
  padding: 12px;
}

.food-item .food-title {
  font-size: 18px;
  font-family: Yellix-Bold, serif;
  color: var(--color-text);
  margin: 0 !important;
}

.food-item .discount {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 8px;
}

.food-item .discount span {
  font-size: 14px;
  font-family: Yellix-SemiBold, serif;
  color: var(--color-cyan2);
  padding-left: 2px;
}

.food-item .food-desc {
  font-size: 17px;
  font-family: Yellix-Medium, serif;
  color: var(--color-gray2);
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3; /* number of lines to show */
  line-clamp: 3;
  -webkit-box-orient: vertical;
}

.food-item .info {
  display: flex;
  justify-content: space-between;
}

.food-item .info div:first-child {
  margin-right: 2px;
}

.food-item .food-img {
  width: 108px;
  height: 108px;
  background-color: white;
  border-radius: 12px;
}

.food-item .food-img img {
  width: 108px;
  height: 108px;
  object-fit: contain;
  border-radius: 12px;
}

.food-item .indicator {
  font-size: 12px;
  font-family: Yellix-Medium;
  display: flex;
  align-items: center;
}

.food-item .indicator > div {
  width: 10px;
  height: 10px;
  border-radius: 5px;
  margin-right: 5px;
}

.food-item .like-btns span {
  color: var(--color-gray2);
  font-size: 12px;
  font-family: Yellix-Medium, serif;
  padding-left: 3px;
}
.food-item .like-btns span.divider {
  padding-left: 10px;
  padding-right: 10px;
}

.food-item .like-btns .active span,
.food-item .like-btns .active svg {
  color: var(--color-red1);
}

.food-item .price-item span.default-price {
  font-size: 19px;
  font-family: Yellix-Bold, serif;
  color: var(--color-text);
  padding-right: 6px;
}

.food-item .price-item span.discount-price {
  font-size: 16px;
  font-family: Yellix-Medium, serif;
  color: var(--color-gray7);
  -webkit-text-decoration-line: line-through;
          text-decoration-line: line-through;
  -webkit-text-decoration-color: var(--color-gray7);
          text-decoration-color: var(--color-gray7);
  padding-top: 1px;
}

.food-item .fav-btn {
  padding: 4px;
}

.food-item .img-view {
  position: relative;
  width: 108px;
  height: 108px;
}
.food-category {
  height: 110px;
  display: flex;
  justify-content: space-between;
  background: var(--color-gray8);
  border-radius: 16px;
  padding-top: 20px;
  padding-left: 20px;
  margin-bottom: 32px;
  cursor: pointer;
}

.food-category h3 {
  font-size: 18px;
  font-family: Yellix-SemiBold, serif;
  margin: 0 !important;
}

.food-category .img {
  width: 42%;
  height: 100%;
  position: relative;
}

.food-category img {
  height: 75%;
  position: absolute;
  bottom: 0;
  right: 0;
}

.cart-counter {
    width: 128px;
    height: 50px;
    border-radius: 12px;
    background-color: var(--color-gray9);
    padding: 10px;
    display: flex;
    justify-content: space-between;
}

.cart-counter .text {
    font-size: 18px;
    font-family: Yellix-SemiBold, serif;
    color: var(--color-text);
    flex: 1 1;
    text-align: center;
}
.food-details-screen {
    padding: 0px;
}

.food-details-screen .MuiDialog-container {
    width: 100%;
    max-width: calc(100% - 64px);
    padding-top: 80px;
}

.food-details-screen .MuiDialog-paper {
    width: 100%;
    margin: 0 !important;
    border-radius: 12px;
}

.food-details-screen .header {
    position: absolute;
    top: 20px;
    left: 0px;
    right: 0px;
    height: 50px;
    width: 100%;
    align-items: flex-end;
}

.food-details-screen .subject-title {
    margin-top: 24px;
    margin-bottom: 12px;
    font-size: 16px;
    font-family: Yellix-SemiBold, serif;
    color: var(--color-text);
}

.food-details-screen .info-view {
    width: 100%;
    align-items: flex-start;
    padding-bottom: 20px;
    background-color: white;
    border-bottom-color: var(--color-gray9);
    border-bottom-width: 1px;
    border-bottom-style: solid;
}

.food-details-screen .info-view h2 {
    font-size: 18px;
    font-family: Yellix-Bold, serif;
    color: var(--color-text);
    margin: 0;
}

.food-details-screen .info-view p {
    font-size: 14px;
    font-family: Yellix-Medium, serif;
    color: var(--color-gray7);
    text-align: left;
    line-height: 14px;
    margin-top: 8px;
    margin-bottom: 9px;
}

.food-details-screen .option-item {
    height: 40px;
    width: 100%;
}

.food-details-screen .option-item p {
    flex: 1 1;
    font-size: 16px;
    font-family: Yellix-Medium, serif;
    color: var(--color-text);
}

.food-details-screen .cart-btns {
    width: 100%;
}

@media (max-width: 576px) {
    .food-details-screen .MuiDialog-container {
        max-width: calc(100% - 20px);
    }

    .food-details-screen .MuiDialog-paper {
        border-radius: 6px;
        max-height: calc(100% - 20px);
    }

    .food-details-screen .header {
        top: 10px;
    }

    .food-close-btn {
        margin-left: 10px;
    }
    .social-stripe {
        margin-right: 10px;
    }
}
.vendor-info {
  width: 100%;
  border-radius: 15px;
  align-items: flex-start;
}

.vendor-info p {
  font-size: 16px;
  font-family: Yellix-Medium, serif;
  color: var(--color-text);
  padding: 0;
  margin: 0 12px 0 6px;
}

.vendor-info a {
  font-size: 16px;
  font-family: Yellix-Medium, serif;
  -webkit-text-decoration-line: none;
          text-decoration-line: none;
  color: var(--color-cyan2);
}

.vendor-info > div {
  justify-content: space-between;
  align-items: flex-start;
}

.vendor-info > div:nth-child(2),
.vendor-info > div:nth-child(3) {
  padding-left: 25px;
  margin-left: 25px;
  border-left-width: 2px;
  border-left-color: var(--color-gray7);
  border-left-style: solid;
}

.vendor-info .title {
  font-size: 16px;
  color: var(--color-text);
  font-family: Yellix-Medium, serif;
  display: flex;
}

.vendor-info .desc {
  font-size: 15px;
  color: var(--color-gray7);
  font-family: Yellix-Medium, serif;
  margin-right: 8px;
  margin-bottom: 4px;
}

.vendor-info .title .small-order-fee {
  padding-right: 4px;
  margin-top: -3px;
}

@media (max-width: 700px) {
  .vendor-info {
    flex-direction: column;
  }
  .vendor-info > div:nth-child(2),
  .vendor-info > div:nth-child(3) {
    padding-left: 0px;
    margin-left: 0px;
    border-left-width: 0px;
  }
}
.small-order-fee .tooltip {
  background-color: white;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
}

.small-order-fee .tooltip p {
  font-size: 14px;
  font-family: Yellix-Medium, serif;
  color: var(--color-text);
  margin: 0;
}

.cart-item {
  width: 100%;
  align-items: flex-start !important;
  margin-bottom: 5px;
  cursor: pointer;
  padding: 10px 0px;
  height: 86px;
}

.cart-item .qty {
  font-size: 14px;
  font-family: Yellix-SemiBold, serif;
  color: var(--color-red1);
  padding: 5px 0px;
  border-radius: 5px;
}

.cart-item .middle-side {
  border-left-width: 1px;
  border-left-style: solid;
  border-left-color: var(--color-gray6);
  padding-left: 15px;
  margin-left: 10px;
  height: 100%;
}

.cart-item h3 {
  font-size: 16px;
  font-family: Yellix-SemiBold, serif;
  color: var(--color-text);
  margin: 0 !important;
}

.cart-item .middle-side p {
  font-size: 15px;
  font-family: Yellix-Medium, serif;
  color: var(--color-gray7);
  margin-top: 9px;
  margin-bottom: 0;
}

.cart-item .right {
  justify-content: space-between;
  align-items: flex-end;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.cart-view-modal {
  padding: 0px;
}

.cart-view-modal .content img.close-btn {
  width: 40px;
  height: 40px;
  object-fit: contain;
  position: absolute;
  left: 0;
  top: 0;
  cursor: pointer;
}

.cart-view-modal .content h4.title {
  font-family: normal;
  font-family: Yellix-Bold, serif;
  color: var(--color-text);
  margin: 0px;
  text-align: center;
}

.cart-view-modal .cart-btns {
  width: 100%;
  margin-top: 20px;
}

.cart-view-modal .cart-btns p {
  font-size: 15px;
  font-family: Yellix-Medium, serif;
  color: var(--color-text);
  margin-top: 0;
  margin-bottom: 3px;
}

.cart-view-modal .cart-btns h4 {
  font-size: 18px;
  font-family: Yellix-SemiBold, serif;
  color: var(--color-text);
  margin: 0;
}

.cart-view-modal .divider {
  height: 1px;
  width: 100%;
  background-color: var(--color-gray6);
  margin-bottom: 20px;
}

.cart-view-modal .subject-title {
  font-size: 16px;
  font-family: Yellix-SemiBold, serif;
  color: var(--color-text);
  margin: 10px 0px;
}

.common-tabs {
  height: 54px;
}

.common-tabs .tab-btn {
  width: 100%;
  height: 100%;
  background-color: var(--color-text) !important;
  font-size: 16px !important;
  font-family: Yellix-SemiBold, serif !important;
  border-radius: 12px !important;
  text-transform: none !important;
  box-shadow: none !important;
  border-radius: 12px;
}

.common-tabs .tab-btn-active {
  flex: 1 1;
  padding: 0 !important;
  background-color: #d9f3f5 !important;
  color: var(--color-cyan2);
}

.common-tabs .tab-btn-inactive {
  flex: 1 1;
  padding: 0 !important;
  background-color: white !important;
  color: var(--color-text);
}

.vendor-hscroll-menu {
    width: 100%;
    align-items: flex-start;
}

.vendor-hscroll-menu .hscrollview {
    width: 100%;
    margin-top: 16px;
    padding-bottom: 18px;
    overflow-x: auto;
}

.vendor-hscroll-menu .scrollview_hider {
    width: 100%;
    margin-top: -18px;
    height: 18px;
    background-color: white;
    z-index: 1;
}

.vendor-hscroll-menu .inactive-cat-item {
    font-size: 16px;
    font-family: Yellix-SemiBold, serif;
    color : var(--color-gray7);
    margin-right: 30px;
    white-space: nowrap;
}

.vendor-hscroll-menu .active-cat-item {
    font-size: 16px;
    font-family: Yellix-SemiBold, serif;
    color : var(--color-cyan2);
    margin-right: 30px;
    line-height: 22px;
    border-bottom-width: 1px;
    border-bottom-color: var(--color-cyan2);
    border-bottom-style: solid;
    white-space: nowrap;
}

.shopinfo-view {
}

.shopinfo-view img.vendor-logo {
  width: 80px;
  height: 80px;
  border-width: 1px;
  border-radius: 15px;
  border-style: solid;
  border-color: var(--color-gray9);
  background-color: white;
}

.shopinfo-view div.right {
  margin-left: 8px;
  justify-content: center;
  align-items: flex-start;
}

.shopinfo-view h1 {
  margin-right: 8px;
  font-size: 20px;
  font-family: Yellix-Bold, serif;
  color: white;
  margin-top: 0;
  margin-bottom: 0;
  font-weight: normal;
}

.shopinfo-view h3 {
  margin-top: 4px;
  margin-bottom: 4px;
  font-size: 14px;
  font-family: Yellix-Medium, serif;
  color: white;
  font-weight: normal;
}

.shopinfo-view .open-close-badge {
  border-radius: 3px;
  background-color: var(--color-red1);
  padding: 4px 6px;
  margin-left: 8px;
}

.shopinfo-view .open-close-badge > div {
  width: 7px;
  height: 7px;
  border-radius: 4px;
}
.shopinfo-view .open-close-badge > span {
  font-size: 12px;
  font-family: Yellix-Medium, serif;
  color: white;
  padding-left: 4px;
}

.shopinfo-view .tag-item {
  border-radius: 3px;
  background-color: #7b7462;
  padding: 4px 6px;
  margin-right: 8px;
  font-size: 12px;
  font-family: Yellix-Medium, serif;
  color: white;
}

.vendor_cart_btn .react-tooltip-lite {
  background-color: white;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
  padding: 16px !important;
  max-width: calc(100vw - 60px) !important;
  opacity: 1 !important;
  border-radius: 20px !important;
  width: inherit !important;
}

.vendor_cart_btn .react-tooltip-lite h3 {
  font-size: 16px;
  font-family: Yellix-Bold, serif;
  color: var(--color-text);
  margin: 0;
}

.vendor_cart_btn .react-tooltip-lite p {
  font-size: 15px;
  font-family: Yellix-Medium, serif;
  color: var(--color-text);
  margin: 0;
  padding-right: 12px;
  max-width: 200px;
}

.vendor_cart_btn .react-tooltip-lite .dismiss-btn {
  font-size: 14px;
  font-family: Yellix-Medium, serif;
  color: var(--color-red1);
  margin-bottom: 0;
  margin-top: 16px;
  cursor: pointer;
  text-align: center;
}

.vendor_cart_btn .react-tooltip-lite .row-item {
  align-items: flex-start;
  margin-top: 16px;
}

.vendor_cart_btn .react-tooltip-lite-arrow {
  z-index: 999 !important;
}
.address-item {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: var(--color-gray8);
  border-radius: 16px;
  margin-bottom: 16px;
  cursor: pointer;
  padding: 10px 16px 16px;
}

.address-item .address-type {
  font-size: 14px;
  font-family: Yellix-SemiBold, serif;
  color: var(--color-cyan2);
  padding: 6px 15px;
  border-radius: 5px;
  background-color: #23cbd833;
}

.address-item .primary {
  font-size: 14px;
  font-family: Yellix-SemiBold, serif;
  color: var(--color-cyan2);
}

.address-item .edit-link {
  font-size: 14px;
  font-family: Yellix-SemiBold, serif;
  color: var(--color-gray7);
  padding-right: 16px;
}

.address-item .radio {
  padding-left: 16px;
  border-left-width: 1px;
  border-left-style: solid;
  border-left-color: var(--color-gray5);
}

.address-item .info h3 {
  font-size: 16px;
  font-family: Yellix-SemiBold, serif;
  color: var(--color-text);
  margin-top: 7px;
  margin-bottom: 12px;
}

.address-item .info p {
  font-size: 16px;
  font-family: Yellix-Medium, serif;
  color: var(--color-text);
  margin-top: 9px;
  margin-bottom: 0;
}

.address-item .out_delivery_area_txt {
  color: var(--color-red1) !important;
}


/* add card modal */
.address-label-modal {
    width: 676px;
}

.address-label-modal .MuiDialog-paper {
    border-radius: 16px;
    width: 560px;
    max-width: calc(100vw - 32px) !important;
}
 
.address-label-modal .content {
    background-color: white;
    padding: 26px 18px;
}

.address-label-modal .content h4.title {
    font-family: Yellix-Bold, serif;
    color: var(--color-text);
    margin: 0px;
    text-align: center;
}

.address-label-modal .content img.close-btn {
    width: 40px;
    height: 40px;
    object-fit: contain;
    position: absolute;
    left: 0;
    top: 0;
    cursor: pointer;
}

.address-label-modal .actions .save-btn {
    width: 100%;
    background-color: var(--color-cyan2) !important;
    color: white;
}

.address-label-modal .row {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 8px 0px;
}

.address-label-modal {
    flex: 1 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100vh;
}

.address-label-modal  .header {
    height: 55px;
    display: flex;
    align-items: flex-end;
    padding-left: 20px;
    padding-right: 20px;
}

.address-label-modal .scrollview {
    flex: 1 1;
    width: 100%;
    background-color: white;
    margin-top: 20px;
}

.address-label-modal .save-btn {
    font-size: 18px;
    font-family: Yellix-SemiBold, serif;
    color: var(--color-cyan2);
}
 
.address-label-modal .modal-content {
    width: 100%;
    padding: 20px 20px 30px;
    background-color: white;
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
}

.address-label-modal .modal-title {
    width: 100%;
    text-align: center;
    font-size: 18px;
    font-family: Yellix-Bold, serif;
    color: var(--color-text);
    margin-bottom: 12px;
}
 
.common_tooltip a {
    font-size: 16px;
    font-family: Yellix-Medium, serif;
    color: var(--color-gray7);
    -webkit-text-decoration-line: underline;
            text-decoration-line: underline;
    cursor: pointer;
    margin-top: 16px;
}

.common_tooltip_body .react-tooltip-lite {
    background-color: white;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
    padding: 16px !important;
    max-width: 320px !important;
    opacity: 1 !important;
    border-radius: 20px !important;
    width: inherit !important;
}


.common_tooltip_body .react-tooltip-lite h3 {
    font-size: 16px;
    font-family: Yellix-Bold, serif;
    color: var(--color-text);
    margin: 0;
}

.common_tooltip_body .react-tooltip-lite p {
    font-size: 15px;
    font-family: Yellix-Medium, serif;
    color: var(--color-text);
    margin: 0;
    padding-left: 4px;
}

.common_tooltip_body .react-tooltip-lite .dismiss-btn {
    font-size: 14px;
    font-family: Yellix-Medium, serif;
    color: var(--color-red1);
    margin-bottom: 0;
    margin-top: 16px;
    cursor: pointer;
    text-align: center;
}

.common_tooltip_body .react-tooltip-lite .row-item {
    align-items: flex-start;
    margin-top: 16px;
}

.common_tooltip_body svg {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
}

.shop-feature-view {
    width: 100%;
    padding: 12px 0px;
    background-color: white;
    border-bottom-width: 1px;
    border-bottom-style: solid;
    border-bottom-color: var(--color-gray9);
}

.shop-feature-view .tags-view {
    width: 100%;
    flex-wrap: wrap;
}

.shop-feature-view .tags-view .tag {
    height: 25px;
    margin: 5px;
    padding: 5px 10px;
    border-radius: 7px;
    background-color: #23CBD826;
    font-size: 14px;
    line-height: 14px;
    font-family: Yellix-SemiBold, serif;
    color: var(--color-cyan2);
}

.shop-feature-view .feature-text {
    font-size: 16px;
    font-family: Yellix-Medium, serif;
    color: var(--color-gray7);
}

.shop-feature-view .delivery_type_txt {
    margin-top: 8px;
    margin-bottom: 8px;
    padding-left: 4px;
    font-size: 15px;
    font-family: Yellix-SemiBold, serif;
    color: var(--color-text);
    -webkit-text-decoration-line: underline;
            text-decoration-line: underline;
    -webkit-text-decoration-color: var(--color-text);
            text-decoration-color: var(--color-text);
}

.cartview-btn .anchor {
    position: absolute;
    bottom: -6px;
    right: 40px;
    width: 10px;
    height: 16px;
    background-color: var(--color-cyan2);
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
}

.cartview-btn .btn-container {
    width: 100%;
    height: 50px;
    padding-left: 20px;
    padding-right: 20px;
    border-radius: 12px;
    background-color: var(--color-cyan2);
}

.cartview-btn .desc-text {
    font-size: 10px;
    font-family: Yellix-Medium, serif;
    color: white;
}

.cartview-btn .total-price {
    margin-right: 8px;
    font-size: 18px;
    font-family: Yellix-SemiBold, serif;
    color: white;
}

.cartview-btn .badge {
    padding: 3px;
    border-radius: 20px;
    background-color: white;
    min-width: 20px;
}

.cartview-btn .badge-text {
    font-size: 14px;
    line-height: 16px;
    font-family: Yellix-SemiBold, serif;
    color: var(--color-cyan2);
}
.view-categories .header-bg {
    width: 100%;
    height: 390px;
    margin-top: -80px;
    background-image: url(/static/media/blog_header_bg.1f73e3e7.svg);
    background-repeat: no-repeat;
    background-size: cover;
}

.view-categories .header-bg h4 {
    font-family: Yellix-Bold, serif;
    color: white;
    font-weight: normal;
    margin: 0;
    text-align: center;
}

.view-categories .main {
    flex-wrap: wrap;
    padding-left: 84px;
    padding-right: 84px;
}

.view-categories .main .list{
    flex-wrap: wrap;
    display: flex;
    flex-direction: row;
}

.view-categories .search-view {
    margin-top: -50px;
    padding-left: 104px;
    padding-right: 104px;
    padding-bottom: 20px;
}

.view-categories .subject-title {
    padding: 0 20px;
    font-size: 26px;
    font-family: Yellix-Bold, serif;
    color: var(--color-text);
}

.view-categories .subject-title span{
    font-size: 18px;
    font-family: Yellix-SemiBold, serif;
    color: var(--color-text);
}


@media (max-width: 1600px) {
    .view-categories .food-category-item {
        width: calc(25% - 40px);
    }
}

@media (max-width: 1400px) {
    .view-categories .vendor-item-wrap {
        width: 50% !important;
        max-width: 50% !important;
        flex: none !important;
    }

    .view-categories .search-view {
        padding-left: 40px;
        padding-right: 40px;
    }

    .view-categories .main {
        padding-left: 40px;
        padding-right: 40px;
    }
}

@media (max-width: 1200px) {
    .view-categories .food-category-item {
        width: calc(33% - 40px);
    }
}

@media (max-width: 992px) {
    .view-categories .header-bg {
        height: 280px;
    }

    .view-categories .vendor-item-wrap {
        width: 100% !important;
        max-width: 100% !important;
        flex: none !important;
    }

    .view-categories .search-view {
        padding-left: 20px;
        padding-right: 20px;
    }

    .view-categories .main {
        padding-left: 20px;
        padding-right: 20px;
    }
}

@media (max-width: 880px) {
    .view-categories .food-category-item {
        width: calc(50% - 40px);
    }
}

@media (max-width: 600px) {
    .view-categories .food-category-item {
        width: calc(100% - 40px);
    }
}

@media screen and (max-width: 576px) {
    .view-categories .header-bg {
        padding: 0px 15% 0px 15%;
    }
    .view-categories .header-bg h4 {
        padding-bottom: 20px;
    }
}
.view-social {
    border-top: 1px solid #e9e9f7;
}

.view-social .main .tabs .MuiTabs-flexContainer {
    border-top: 0 !important;
}

.view-social .main .tabs .MuiTabs-flexContainer {
    flex-direction: column;
}

.view-social .main .tabs .MuiButtonBase-root {
    padding: 40px 0 !important;
    font-family: Yellix-SemiBold, serif;
    font-size: 16px !important;
    color: var(--color-gray7);
    text-transform: capitalize;
}

.view-social .main .tabs .Mui-selected {
    color: var(--color-cyan2);
}

.view-social .main .tabs .Mui-selected svg path {
    fill: var(--color-cyan2) !important;
}

.view-social .main .tabs .Mui-selected:after {
    display: none;
}

.view-social .main .tabs .MuiTabs-indicator {
    display: none;
}

.view-social .main {
    align-items: flex-start;
}

.view-social .main .tabs {
    width: 140px;
}

.view-social .main .content {
    width: calc(100vw - 140px);
    border-left: 1px solid #e9e9f7;
}

@media (max-width: 1200px) {
    .view-social .main .tabs {
        width: 120px;
    }
    .view-social .main .content {
        width: calc(100vw - 120px);
    }
}

@media (max-width: 576px) {
    .view-social .main .tabs {
        width: 100%;
    }

    .view-social .main {
        flex-direction: column;
    }

    .view-social .main .content {
        width: 100%;
    }

    .view-social .main .tabs .MuiButtonBase-root {
        padding: 10px 15px !important;
        font-size: 14px !important;
    }

    .view-social .main .tabs .MuiTabs-flexContainer {
        flex-direction: row;
        overflow-x: auto;
    }

    .view-social .main .tabs img {
        width: 24px;
        height: 24px;
        object-fit: contain;
    }
}

@media (max-width: 1199px) {
    .hide-md {
        display: none;
    }
}
.view-chat {
  border-top: 1px solid #e9e9f7;
}
.view-chat .tabs-chat {
  display: flex;
}
.view-chat .tabs-chat .lis {
  padding-right: 30px;
  border-right: 1px solid #e9e9f7;
}
.view-chat .tabs-chat .MuiTabs-flexContainer {
  border-top: 0 !important;
}
.view-chat .tabs-chat .MuiTabs-flexContainer {
  flex-direction: column;
}
.view-chat .tabs-chat .lis .MuiButtonBase-root {
  padding: 40px 0px !important;
  font-size: 16px !important;
}
.view-chat .tabs-chat .lis .Mui-selected svg path {
  fill: #50B7ED !important;
}
.view-chat .tabs-chat .lis .Mui-selected:after {
  display: none;
}

.view-chat .left-side {
  border-right: 1px solid #e9e9f7;
  padding-left: 0 !important;
  padding-top: 0 !important;
}
.no_promolist_view {
    flex: 1 1;
    margin-top: 120px;
}

.no_promolist_view .description {
    color: var(--color-text);
    line-height: 24px;
    font-family: Yellix-Medium, serif;
    font-size: 16px;
    text-align: center;
    padding-left: 20px;
    padding-right: 20px;
}
.chat-channel-item {
  width: 100%;
  display: flex;
  flex-direction: row;
  border-radius: 15px !important;
  background-color: var(--color-gray8) !important;
  padding: 20px !important;
  margin-bottom: 15px !important;
}

.chat-channel-item img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: cover;
}

.chat-channel-item .name {
  font-size: 16px;
  font-family: Yellix-SemiBold, serif;
  color: var(--color-text);
}

.chat-channel-item .time {
  font-size: 14px;
  font-family: Yellix-SemiBold, serif;
  color: var(--color-gray7);
}

.chat-channel-item .message {
  font-size: 14px;
  font-family: Yellix-Medium, serif;
  color: var(--color-text);
  margin-right: 24px;
  text-align: left;
  word-break: break-word;
}

.chat-channel-item .unreadContainer {
  height: 15px;
  background-color: var(--color-red1);
  margin-left: 20px;
  padding-left: 5px;
  padding-right: 5px;
  border-radius: 15px;
}

.chat-channel-item .unread {
  text-align: center;
  font-size: 14px;
  line-height: 14px;
  color: white;
}

.chat-hist-screen {
  flex: 1 1;
  background-color: white;
  width: 100%;
  height: calc(100vh - 160px);
}

.chat-hist-screen .scrollview {
  flex: 1 1;
  width: 100%;
  background-color: white;
  overflow-y: auto;
  padding-left: 20px;
  padding-right: 20px;
}

.chat-hist-screen .search-cntainer {
  padding: 10px 20px;
  margin-top: 15px;
  padding-top: 0;
}

.chat-profile-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 0 solid #e9e9f7;
  border-bottom-width: 1px;
}

.chat-profile-item .user-info img {
  width: 42px;
  height: 42px;
  object-fit: cover;
  border-radius: 50%;
  margin-right: 8px;
}

.chat-profile-item .user-info h5 {
  font-size: 18px;
  font-family: Yellix-Bold, serif;
  margin: 0;
}

.chat-profile-item .user-info p {
  font-size: 14px;
  font-family: Yellix-SemiBold, serif;
  color: #aaa8bf;
  margin: 5px 0 0 0;
}

.new-chat-menu-items .item {
  padding: 6px;
  display: flex;
  align-items: center;
  color: var(--color-text);
  font-size: 16px;
  font-family: Yellix-SemiBold, serif;
}

.new-chat-menu-items .item div {
  margin-left: 8px;
}

.new-chat-menu-items ul li:first-child {
  border-bottom: 1px var(--color-gray6) solid;
}

.chat-profile-item .new-menu button {
  background: var(--color-text);
  padding: 6px 14px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  text-decoration: none;
}

.chat-profile-item .new-menu button img {
  margin-right: 0px;
}

.chat-profile-item .new-menu button p {
  color: #ffffff;
  font-size: 16px;
  font-family: Yellix-SemiBold, serif;
  margin: 0;
  text-transform: none;
  font-weight: normal;
}

.chat-menu li.MuiMenuItem-root   {
    background-color: white !important;
}
.loading-modal .content {
    width: calc(100vw - 30px);
    max-width: 450px;
    padding: 30px 20px;
    background-color: transparent;
}

.loading-modal .MuiBackdrop-root {
    background-color: rgba(0, 0, 0, 0.2);
}

.loading-modal .MuiPaper-root {
    background-color: transparent;
    box-shadow: none;
}

/* add card modal */
.new-chat-modal {
    width: 676px;
}

.new-chat-modal .MuiDialog-paper {
    border-radius: 16px;
    width: 560px;
    max-width: calc(100vw - 32px) !important;
}
 

.new-chat-modal .content {
    background-color: white;
    height: 80vh;
}

.new-chat-modal .content h4.title {
    flex: 1 1;
    font-family: Yellix-Bold, serif;
    color: var(--color-text);
    margin: 0px;
    text-align: center;
}

.new-chat-modal .content img.close-btn {
    width: 40px;
    height: 40px;
    object-fit: contain;
    cursor: pointer;
}

.new-chat-modal .actions .save-btn {
    width: 100%;
    background-color: var(--color-cyan2) !important;
    color: white;
}

.new-chat-modal .row {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 8px 0px;
}

.new-chat-modal {
    flex: 1 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100vh;
}
 

.new-chat-modal .save-btn {
    font-size: 18px;
    font-family: Yellix-SemiBold, serif;
    color: var(--color-cyan2);
}

.new-chat-modal .scrollview {
    flex: 1 1;
    width: 100%;
    background-color: white;
    overflow-y: auto;
}

.new-chat-modal .operation-tab {
    height: 62px;
    width: 100%;
    margin-top: 20px;
    border-top-width: 1px;
    border-bottom-Width: 1px;
    border-top-color: #F6F6F9;
    border-top-style: solid;
    border-bottom-color: #F6F6F9;
    border-bottom-style: solid;
}

.new-chat-modal .operation-tab .common-tabs {
    width: 360px;
}

.new-chat-modal .searchview {
    width: 100%;
}
.no_friends_view {
    flex: 1 1;
    margin-top: 120px;
}

.no_friends_view .description {
    color: var(--color-text);
    line-height: 24px;
    font-family: Yellix-Medium, serif;
    font-size: 16px;
    text-align: center;
    padding-left: 20px;
    padding-right: 20px;
}
.user-item {
  display: flex;
  flex-direction: row;
  border-radius: 15px;
  background-color: var(--color-gray8);
  margin-bottom: 15px;
  cursor: pointer;
}

.user-item img.avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 10px;
}

.user-item .name {
  font-size: 18px;
  font-family: Yellix-SemiBold, serif;
  color: var(--color-text);
  margin-right: 6px;
}

.user-item img.zodiac {
  width: 14px;
  height: 14px;
  object-fit: contain;
  margin-top: 3px;
}

.user-item .invite_status {
  font-size: 16px;
  font-family: Yellix-SemiBold, serif;
  color: var(--color-cyan2);
  padding-top: 5px;
  padding-bottom: 5px;
}


/* add card modal */
. {
    width: 676px;
}

.new-group-chat-modal .MuiDialog-paper {
    border-radius: 16px;
    width: 560px;
    max-width: calc(100vw - 32px) !important;
}
 

.new-group-chat-modal .content {
    background-color: white;
    height: 80vh;
}

.new-group-chat-modal .content h4.title {
    flex: 1 1;
    font-family: Yellix-Bold, serif;
    color: var(--color-text);
    margin: 0px;
    text-align: center;
}

.new-group-chat-modal .content img.close-btn {
    width: 40px;
    height: 40px;
    object-fit: contain;
    cursor: pointer;
}

.new-group-chat-modal .actions .save-btn {
    width: 100%;
    background-color: var(--color-cyan2) !important;
    color: white;
}

.new-group-chat-modal .row {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 8px 0px;
}

.new-group-chat-modal {
    flex: 1 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100vh;
}

.new-group-chat-modal  .header {
    height: 65px;
    display: flex;
    align-items: flex-end;
}

.new-group-chat-modal .save-btn {
    font-size: 18px;
    font-family: Yellix-SemiBold, serif;
    color: var(--color-cyan2);
}

.new-group-chat-modal .scrollview {
    flex: 1 1;
    width: 100%;
    background-color: white;
    overflow-y: auto;
}

.new-group-chat-modal .operation-tab {
    height: 62px;
    width: 100%;
    margin-top: 20px;
    border-top-width: 1px;
    border-bottom-Width: 1px;
    border-top-color: #F6F6F9;
    border-top-style: solid;
    border-bottom-color: #F6F6F9;
    border-bottom-style: solid;
}

.new-group-chat-modal .operation-tab .common-tabs {
    width: 360px;
}

.new-group-chat-modal .searchview {
    width: 100%;
}
.new-conv-modal .MuiDialog-container {
}

.new-conv-modal .MuiDialog-paper {
    margin: 0 !important;
    border-radius: 30px;
}

.new-conv-modal .content {
}

.new-conv-modal .content h1{
    font-size: 18px;
    font-family: Yellix-Bold, serif;
    color: var(--color-text);
}
.create-group-screen {
    flex: 1 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: calc(100% - 100px);
    background-size: cover;
    background-position: center;
}

.create-group-screen .scrollview {
    flex: 1 1;
    width: 100%;
    background-color: white;
    overflow-y: auto;
}

.create-group-screen .avatar-view {
    width: 100px;
    height: 100px;
    border-radius: 50px;
    border-width: 1px;
    border-style: solid;
    border-color: var(--color-gray9);
}

.create-group-screen img.avartar {
    width: 100px;
    height: 100px;
    border-radius: 50px;
    object-fit: cover;
}

.create-group-screen .upload-btn p{
    margin-top: 16px;
    margin-bottom: 0;
    font-size: 11px;
    color: var(--color-gray5);
    font-family: Yellix-SemiBold, serif;
}

.create-group-screen .permission-view {
    display: flex;
    border-top-width: 1px;
    border-top-style: solid;
    border-top-color: var(--color-gray9);
    border-bottom-width: 1px;
    border-bottom-style: solid;
    border-bottom-color: var(--color-gray9);
    padding-top: 20px;
    padding-bottom: 20px;
    margin-top: 10px;
}

.create-group-screen .permission-view p {
    font-size: 16px;
    font-family: Yellix-SemiBold, serif;
    color: var(--color-text);
    margin: 0
}

.create-group-screen .subject-title {
    font-size: 18px;
    font-family: Yellix-Bold, serif;
    color: var(--color-text);
}

.camera-view {
    position: absolute !important;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    background-color: #000000;
}

.support-msg-item {
  height: 32px;
  border-radius: 7px;
  padding: 4px 12px;
  background-color: #c0ebec;
  margin-right: 12px;
  font-size: 15px;
  line-height: 25px;
  font-family: Yellix-SemiBold;
  color: var(--color-cyan2);
}

.emoji-modal .MuiDialog-container {
}

.emoji-modal .MuiDialog-paper {
  margin: 0 !important;
  border-radius: 15px;
}

.emoji-modal .content {
  padding: 15px 0;
}

.emoji-modal .content h1 {
  font-size: 18px;
  font-family: Yellix-Bold, serif;
  color: var(--color-text);
}

.emoji-modal aside.emoji-picker-react {
  border: none;
  box-shadow: none;
  width: 100%;
}

.emoji-modal .emoji-scroll-wrapper {
  overflow-x: hidden;
}

.location-msg-option-modal .MuiDialog-paper {
  margin: 0 !important;
  border-radius: 30px;
}

.location-msg-option-modal .content {
  padding: 20px 20px 30px;
  background-color: white;
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
}

.location-msg-option-modal .content h1 {
  font-size: 18px;
  font-family: Yellix-Bold, serif;
  color: var(--color-text);
  text-align: center;
  margin: 0;
  padding: 5px;
  width: 100%;
}

.location-msg-option-modal .content .btn-text {
  flex: 1 1;
  margin-left: 8px;
  font-size: 15px;
  font-family: Yellix-Medium, serif;
  color: var(--color-text);
}

.location-msg-option-modal .content .divider {
  width: 100%;
  height: 1px;
  background-color: var(--color-gray9);
}

.location-msg-option-modal .content img.close-btn {
  width: 32px;
  height: 32px;
  object-fit: contain;
  position: absolute;
  left: 0;
  top: 0;
  cursor: pointer;
}

.location-msg-option-modal .content .item-btn {
  border-radius: 8px;
  padding: 15px 16px;
  background-color: var(--color-gray8);
  margin-bottom: 12px;
}

.location-msg-option-modal .content .item-btn img {
  height: 15px;
  width: 15px;
  object-fit: contain;
}

.confirm-modal .content {
    padding: 30px 20px;
    background-color: white;
    border-radius: 15px;
}

.confirm-modal .content h2{
    font-size: 20px;
    font-family: Yellix-SemiBold, serif;
    color: var(--color-text);
    text-align: center;
    margin-top: 0;
    margin-bottom: 30px;
}

.confirm-modal .content .yes_btn{
    font-size: 16px;
    font-family: Yellix-SemiBold, serif;
    color: var(--color-cyan2);
    margin-right: 20px;
}

.confirm-modal .content .no_btn{
    font-size: 16px;
    font-family: Yellix-SemiBold, serif;
    color: var(--color-gray7);
    margin-left: 20px;
}

.messages-header {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 28px;
  background-color: #fffffff8;
  border: 0 solid #e9e9f7;
  border-bottom-width: 1px;
  z-index: 2;
}

.messages-header .popup-btn {
  padding-left: 5px;
  padding-right: 5px;
  padding-bottom: 12px;
  border-bottom-color: var(--color-gray9);
  border-bottom-style: solid;
  border-bottom-width: 1px;
}

.messages-header-popup-btn-text {
  flex: 1 1;
  margin-left: 10px;
  font-size: 16px;
  font-family: Yellix-SemiBold, serif;
  color: var(--color-text);
}

.messages-header .name {
  margin-right: 7px;
  margin-bottom: 5px;
  font-size: 18px;
  font-family: Yellix-Bold, serif;
  color: var(--color-text);
}

.messages-header .desc {
  font-size: 14px;
  font-family: Yellix-SemiBold, serif;
  color: var(--color-red1);
}

.messages-header .avatar {
  background-color: white;
  width: 42px;
  height: 42px;
  object-fit: cover;
  border-radius: 30px;
  margin-right: 8px;
}

.messages-header .message-header-menu {
  width: 42px;
  height: 42px;
}

.messages-header .round-icon-btn {
  display: none;
}

@media (max-width: 1200px) {
  .messages-header .round-icon-btn {
    display: inherit;
    width: 42px;
    height: 42px;
  }
}

.rhap_container  {
    border-radius: 10px;
}

.messages-screen {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: calc(100vh - 100px);
  position: relative;
}

*:not(input,textarea) {
  -webkit-touch-callout:none;
  -webkit-user-select:none;
  -ms-user-select:none;
  user-select:none;
}

.messages-screen .no-members-txt {
  margin-left: 30px;
  margin-right: 30px;
  margin-top: 30px;
  text-align: center;
  font-size: 18px;
  font-family: Yellix-SemiBold, serif;
  color: var(--color-gray7);
}

.messages-screen .web-scroll-view {
  padding: 0 0px;
}

.messages-screen .web-scroll-view > div {
  width: 100%;
}

.messages-screen .order-chat-default-messages .react-horizontal-scrolling-menu--item div {
  white-space: nowrap;
}

.message-context-menu .menu-item span {
  padding-left: 8px;
  font-size: 14px;
  font-family: Yellix-SemiBold, serif;
  color: var(--color-text);
}

/* address map modal */

.location-pickup-modal .MuiDialog-paper {
  border-radius: 16px;
  width: 800px;
  max-width: calc(100vw - 32px) !important;
}

.location-pickup-modal .content {
  background-color: white;
  padding: 0px;
}

.location-pickup-modal .map-view {
  width: 100%;
  height: 400px;
  max-height: calc(100vh - 100px) !important;
}

.location-pickup-modal .search-bar {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  padding: 20px;
}

.location-pickup-modal .search-bar .location-input {
  background-color: white;
  height: 46px;
  margin-left: 8px;
}

.location-pickup-modal .search-view p {
  font-size: 10px;
  font-family: Yellix-SemiBold;
  color: var(--color-gray5);
  margin-top: 14px;
  margin-bottom: 0;
}

.location-pickup-modal .search-view h4 {
  font-size: 14px;
  font-family: Yellix-SemiBold;
  color: var(--color-text);
  margin-top: 5px;
  margin-bottom: 0;
}

.location-pickup-modal .save-btn {
  width: 100%;
  max-width: 400px;
  background-color: var(--color-cyan2) !important;
  color: white;
}
/* end*/

.camera-modal .MuiDialog-container {
  width: 100vw;
  max-width: 520px;
}

.camera-modal .MuiDialog-paper {
  margin: 0 !important;
  border-radius: 8px;
}

.camera-modal .content {
  width: 100vw;
  max-width: 520px;
  height: 600px;
}

.location-msg-modal .MuiDialog-paper {
  border-radius: 16px;
  width: 800px;
  max-width: calc(100vw - 32px) !important;
}

.location-msg-modal .content {
  background-color: white;
  padding: 0px;
}

.location-msg-modal .map-view {
  width: 100%;
  height: 460px;
  max-height: calc(100vh - 100px) !important;
}

.location-msg-modal .round-icon-btn {
  position: absolute;
  left: 20px;
  top: 20px;
}
.myfriends-screen {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  border-top: 1px solid #e9e9f7;
}

.myfriends-screen .scrollview {
  flex: 1 1;
  width: 100%;
  background-color: white;
  overflow-y: auto;
  margin-top: 10px;
  padding-left: 20px;
  padding-right: 20px;
}

.myfriends-screen .searchview {
  width: 100%;
  margin-top: 15px;
  padding-left: 20px;
  padding-right: 20px;
}

.myfriends-screen .list {
  height: calc(100vh - 160px);
}

.myfriends-screen .left-side {
  border-right: 1px solid #e9e9f7;
  padding-left: 0 !important;
  padding-top: 0 !important;
}
.snapfooder-list-screen {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  border-top: 1px solid #e9e9f7;
}

.snapfooder-list-screen .scrollview {
  flex: 1 1;
  width: 100%;
  background-color: white;
  overflow-y: auto;
  margin-top: 20px;
  padding-left: 20px;
  padding-right: 20px;
}

.snapfooder-list-screen .searchview {
  width: 100%;
  padding-left: 20px;
  padding-right: 20px;
}

.snapfooder-list-screen .list {
  margin-top: 15px;
  height: calc(100vh - 160px);
}

.snapfooder-list-screen .left-side {
  border-right: 1px solid #e9e9f7;
  padding-left: 0 !important;
  padding-top: 0 !important;
}
.invitation-item {
  display: flex;
  flex-direction: row;
  padding: 20px;
  border-radius: 15px;
  background-color: var(--color-gray8);
  margin-bottom: 15px;
  cursor: pointer;
}

.invitation-item img.avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 10px;
}

.invitation-item .name {
  font-size: 14px;
  font-family: Yellix-SemiBold, serif;
  color: var(--color-text);
  margin-right: 6px;
}

.invitation-item .invite_date {
  font-size: 14px;
  font-family: Yellix-Medium, serif;
  color: var(--color-text);
  margin-top: 4px;
  margin-right: 6px;
}

.invitation-item .cancel-btn {
  font-size: 14px;
  font-family: Yellix-SemiBold, serif;
  color: var(--color-gray7);
  cursor: pointer;
}

.invitation-item img.btn {
  margin-left: 15px;
  cursor: pointer;
}

.invitation-screen {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  background-size: cover;
  background-position: center;
  border-top: 1px solid #e9e9f7;
}

.invitation-screen .header {
  height: 65px;
  display: flex;
  align-items: flex-end;
  padding-left: 20px;
  padding-right: 20px;
}

.invitation-screen .scrollview {
  flex: 1 1;
  width: 100%;
  background-color: white;
  overflow-y: auto;
  margin-top: 20px;
  padding-left: 20px;
  padding-right: 20px;
}

.invitation-screen .operation-tab {
  width: 100%;
  margin-top: 0px;
  border: 0 solid #e9e9f7;
  border-bottom-width: 1px;
}

.invitation-screen .list {
  height: calc(100vh - 160px);
}

.invitation-screen .left-side {
  border-right: 1px solid #e9e9f7;
  padding-left: 0 !important;
  padding-top: 0 !important;
}
.snapfooder-screen {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 480px;
}

.snapfooder-screen .header {
    height: 65px;
    display: flex;
    align-items: flex-end;
    padding-left: 20px;
    padding-right: 20px;
}

.snapfooder-screen .scroll_view {
    width: 100%;
    background-color: white;
    padding-left: 20px;
    padding-right: 20px;
}

.snapfooder-screen .subject-title {
    margin-top: 15px;
    font-size: 18px;
    font-family: Yellix-Bold, serif;
    color: var(--color-text);
}

.snapfooder-screen .divider {
    width: 100%;
    height: 1px;
    background-color: var(--color-gray9);
}

.snapfooder-screen .suggested-hlist {
    width: 100%;
    align-items: flex-start;
}

.snapfooder-screen .suggested-hlist .scrollview_hider {
    width: 100%;
    margin-top: -18px;
    height: 18px;
    background-color: white;
    z-index: 1;
}

.snapfooder-screen .remove-friend {
    padding-top: 12px;
    padding-bottom: 12px;
    font-size: 18px;
    font-family: Yellix-Bold, serif;
    color: var(--color-gray7);
    text-align: center;
}

.snapfooder-screen .suggested-list {
    width: 100%;
    display: flex;
    margin-top: 16px;
    padding-bottom: 15px;
    overflow-x: auto;
}

.snapfooder-screen .gray7_bg {
    background-color: var(--color-gray7) !important;
}

.snapfooder-screen .cyan2_bg {
    background-color: var(--color-cyan2) !important;
}

@media (max-width: 576px) {
    .snapfooder-screen .snapfooder-avatar .photoview {
        width: 80px !important;
        height: 80px !important;
        border-radius: 16px !important;
    }
    .snapfooder-screen .snapfooder-avatar .avatarimg {
        width: 80px !important;
        height: 80px !important;
        border-radius: 6px !important;
    }
}

.snapfooder-detail-map-view {
  width: 100%;
  height: 214px;
  border-radius: 18px;
  overflow: hidden;
  background-color: white;
}

/* map marker */
.map-marker {
  position: relative;
}

.map-marker .marker-body {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.map-marker img {
  cursor: pointer;
}

.map-marker .popup {
  position: absolute;
  background-color: white;
  text-align: center;
  z-index: 2;
  opacity: 1;
  transition: opacity 0.6s;
  box-shadow: 0 0 17px 2px rgb(2 2 2 / 15%) !important;
}

.map-marker .popup::after {
  content: '';
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -15px;
  border-width: 15px;
  border-style: solid;
  border-color: white transparent transparent transparent;
}

/* user marker */
.user-marker .popup {
  top: -60px;
  border-radius: 8px;
  width: 75px;
  height: 45px;
}

.user-marker .user-info p {
  font-size: 16px;
  font-family: Yellix-Bold, serif;
  color: var(--color-text);
  flex-wrap: nowrap;
  overflow-wrap: unset;
  margin: 0 !important;
}

/* end user marker */

.view-favorites {
  margin-top: 80px;
}

.view-favorites h1 {
  font-size: 24px;
  font-family: Yellix-ExtraBold, serif !important;
  color: var(--color-text);
}

.view-favorites .tab-wrapper {
  width: 100%;
  padding-top: 20px;
  padding-bottom: 20px;
  border-top-width: 1px;
  border-top-style: solid;
  border-top-color: var(--color-gray6);
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: var(--color-gray6);
}

.view-favorites .empty-view {
  padding: 60px;
  width: 100%;
}

.view-favorites h2.empty-txt {
  font-size: 16px;
  font-family: Yellix-SemiBold, serif !important;
  color: var(--color-text);
  margin-top: 35px;
}

.no_fav_view {
    flex: 1 1;
}

.no_fav_view .description {
    color: var(--color-text);
    line-height: 24px;
    font-family: Yellix-Medium, serif;
    font-size: 16px;
    text-align: center;
    padding-left: 20px;
    padding-right: 20px;
}
.view-promotions {
  margin-top: 80px;
}

.view-promotions h1 {
  font-size: 24px;
  font-family: Yellix-ExtraBold, serif !important;
  color: var(--color-text);
}

.view-promotions .tab-wrapper {
  width: 100%;
  padding-top: 20px;
  padding-bottom: 20px;
  border-top-width: 1px;
  border-top-style: solid;
  border-top-color: var(--color-gray6);
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: var(--color-gray6);
}

.view-promotions .empty-view {
  padding: 60px;
  width: 100%;
}

.view-promotions h2.empty-txt {
  font-size: 16px;
  font-family: Yellix-SemiBold, serif !important;
  color: var(--color-text);
  margin-top: 35px;
}

.no_promolist_view {
    flex: 1 1;
    margin-top: 120px;
}

.no_promolist_view .description {
    color: var(--color-text);
    line-height: 24px;
    font-family: Yellix-Medium, serif;
    font-size: 16px;
    text-align: center;
    padding-left: 20px;
    padding-right: 20px;
}
.promotion-item {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  background: var(--color-gray8);
  border-radius: 15px;
  margin-bottom: 16px;
  cursor: pointer;
  padding: 12px;
}

.promotion-item .title {
  font-size: 18px;
  font-family: Yellix-Bold, serif;
  color: var(--color-text);
}

.promotion-item .date_limit {
  font-size: 14px;
  font-family: Yellix-SemiBold, serif;
  color: var(--color-red1);
  margin-bottom: 3px;
}

.promotion-item .sub_title {
  font-size: 16px;
  font-family: Yellix-SemiBold, serif;
  color: var(--color-text);
}

.promotion-item .description {
  font-size: 14px;
  font-family: Yellix-Medium, serif;
  color: var(--color-gray7);
  text-overflow: ellipsis;
  word-wrap: break-word;
  overflow: hidden;
  max-height: 2.4em;
  line-height: 1.2em;
  margin-top: 8px;
}

.promotion-item .food-img {
  background-color: white;
  border-width: 1px;
  border-style: solid;
  border-color: var(--color-gray9);
  border-radius: 10px;
  margin-left: 12px;
}


.promotion-item .food-img img {
  width: 56px;
  height: 56px;
  object-fit: cover;
  border-radius: 12px;
}
.order-screen {
  margin-top: 120px;
}

.order-screen h2 {
  font-family: Yellix-ExtraBold, serif !important;
  color: var(--color-text);
}

.order-screen .tab-wrapper {
  width: 100%;
  justify-content: center !important;
  align-items: center !important;
  margin-left: 0px !important;
  margin-right: 0px !important;
  padding-top: 20px;
  padding-bottom: 20px;
  border-top-width: 1px;
  border-top-style: solid;
  border-top-color: var(--color-gray6);
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: var(--color-gray6);
}

@media (max-width: 1350px) {
  .order-screen .order-item-wrap {
    width: 50% !important;
    max-width: 50% !important;
    flex: none !important;
  }
}

@media (max-width: 992px) {
  .order-screen {
    margin-top: 100px;
  }

  .order-screen .order-item-wrap {
    width: 100% !important;
    max-width: 100% !important;
    flex: none !important;
  }
}

.orders-filter h1 {
  font-size: 22px;
  font-family: Yellix-ExtraBold, serif;
  color: var(--color-text);
}

.orders-filter .list-btn {
  background-color: var(--color-gray8) !important;
  color: var(--color-text);
  height: 48px;
  border-radius: 10px;
  margin-bottom: 15px;
}

.orders-filter .list-btn span {
  font-size: 16px;
  font-family: Yellix-Bold, serif;
  color: var(--color-text);
}

.orders-filter .round-icon-btn {
  display: none !important;
}

@media (max-width: 767px) {
  .orders-filter .search-input {
    height: 48px !important;
  }

  .orders-filter h1 {
    visibility: hidden;
    height: 0;
  }

  .orders-filter .round-icon-btn {
    display: flex !important;
    border-color : var(--color-gray5)
  }

  .orders-filter .filter-body {
    display: none !important;
  }
}

/* add card modal */
.blog-filter-modal {
    width: 676px;
}

.blog-filter-modal .MuiDialog-paper {
    border-radius: 16px;
    width: 560px;
    max-width: calc(100vw - 32px) !important;
}

.blog-filter-modal .content {
    background-color: white;
    padding: 26px 0px;
    overflow: hidden;
}

.blog-filter-modal .content h1.title {
    font-size: 26px;
    font-family: Yellix-Bold, serif;
    color: var(--color-text);
    margin: 0px;
    text-align: center;
}

.blog-filter-modal .content img.close-btn {
    width: 40px;
    height: 40px;
    object-fit: contain;
    position: absolute;
    left: 15px;
    top: 0;
    cursor: pointer;
}

.blog-filter-modal .save-btn {
    width: 100%;
    background-color: var(--color-cyan2) !important;
    color: white;
}

.blog-filter-modal {
    flex: 1 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100vh;
}

.blog-filter-modal  .header {
    height: 55px;
    display: flex;
    align-items: flex-end;
    padding-left: 20px;
    padding-right: 20px;
}

.blog-filter-modal .scroll_view {
    flex: 1 1;
    width: 100%;
    background-color: white;
    margin-top: 10px;
    overflow-y: auto;
}


.blog-filter-modal .list-btn {
    background-color: var(--color-gray8) !important;
    color: var(--color-text);
    height: 48px;
    border-radius: 10px;
    margin-bottom: 15px;
    margin-top: 15px;
}

.blog-filter-modal .MuiList-root {
    padding-bottom: 0 !important;
    padding-top: 0 !important;
}

.blog-filter-modal .list-btn span {
    font-size: 16px;
    font-family: Yellix-Bold, serif;
    color: var(--color-text);
}

.no_orders_view {
    flex: 1 1;
}

.no_orders_view .description {
    color: var(--color-text);
    line-height: 24px;
    font-family: Yellix-Medium, serif;
    font-size: 16px;
    text-align: center;
    padding-left: 20px;
    padding-right: 20px;
}
.order-item {
  width: 100%;
  align-items: flex-start !important;
  margin-top: 15px;
  cursor: pointer;
  background-color: var(--color-gray8);
  padding: 15px ;
  border-radius: 15px;
}

.order-item .payment_method {
  font-size: 15px;
  line-height: 16px;
  margin-top: 9px;
  font-family: Yellix-Medium, serif;
  color: var(--color-text);
}

.order-item .vendor-title {
  font-size: 17px;
  font-family: Yellix-SemiBold, serif;
  color: var(--color-text);
  padding-right: 16px;
  margin-bottom: 4px;
}

.order-item .price {
  font-size: 18px;
  font-family: Yellix-Bold, serif;
  color: var(--color-text);
  margin-bottom: 6px;
}

.order-item .status {
  font-size: 15px;
  font-family: Yellix-SemiBold, serif;
  color: var(--color-cyan2);
}

.order-item .date {
  font-size: 15px;
  line-height: 16px;
  font-family: Yellix-Medium, serif;
  color: var(--color-text);
  padding-right: 16px;
  margin-top: 10px;
}

.view-profile {
}

.profile {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.user-profile-info {
    display: flex;
}

.user-profile-info-img {
    position: relative;
}

.user-profile-info-img > img {
    width: 150px;
    height: 150px;
    border-radius: 80px;
    object-fit: cover;
}

.user-profile-info-img > div {
    position: absolute;
    top: 5px;
    right: 5px;
}

.user-profile-info > div {
    margin-left: 35px;
}

.user-profile-info > div h3 > img {
    width: 22px;
    height: 22px;
    object-fit: contain;
    margin-left: 5px;
}

.cont-info {
    color: #222222;
    margin-left: 35px;
}

.cont-info h3,
.user-profile-info > div h3 {
    font-family: Yellix-ExtraBold, serif;
    margin-top: 0;
}

.cont-info ul,
.user-profile-info > div ul {
    list-style-type: none;
    padding: 0;
}

.cont-info a,
.user-profile-info > div a {
    font-size: 20px;
    color: #222222;
    display: flex;
    text-decoration: none;
    align-items: center;
    margin: 15px 0;
    font-family: Yellix-SemiBold, serif;
}

.cont-info a > img,
.user-profile-info > div a > img {
    margin-right: 8px;
}

.tabs-main {
    margin-top: 40px;
}

.lis .MuiTabs-flexContainer {
    justify-content: center;
    padding: 30px 0;
    border-top: 1px solid rgba(0, 0, 0, 0.12);
}

.lis .Mui-selected {
    color: #50B7ED !important;
}

.lis .MuiButtonBase-root {
    font-family: Yellix-SemiBold, serif;
    font-size: 18px;
    text-transform: capitalize;
    padding: 15px 75px;
}

/* .lis .Mui-selected:after {
  position: absolute;
  content: '';
  background: #50B7ED;
  width: 15px;
  height: 2px;
  bottom: 24%;
} */
.confirm-indetity {
    max-width: 600px;
    margin: 0 auto;
    text-align: center;
}

.confirm-indetity p {
    color: #222222;
    font-size: 16px;
    font-family: Yellix-SemiBold, serif;
    margin-bottom: 25px;
    margin-top: 40px;
}

.view-profile .balance {
    max-width: 600px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    background: transparent linear-gradient(270deg, #00d2ff 0%, #715ee7 100%) 0% 0% no-repeat padding-box;
    box-shadow: 0px 10px 50px #007a9829;
    border-radius: 25px;
    padding: 22px 24px;
    margin-top: 30px;
}

.balanc {
    margin-left: 15px;
    margin-right: 70px;
}

.balanc h5 {
    color: white;
    font-size: 16px;
    font-family: Yellix-SemiBold, serif;
    margin: 0 0 15px 0;
}

.balanc p {
    color: #fafafccc;
    font-size: 14px;
    font-family: Yellix-Medium, serif;
    margin: 0;
}

.balance > p {
    margin: 0;
    font-size: 40px;
    color: white;
    font-family: Yellix-Bold, serif;
}

.balance > p > span {
    font-size: 20px;
}

.cashback {
    max-width: 600px;
    margin: 0px auto;
    border-top: 1px solid rgba(0, 0, 0, 0.12);
    margin-top: 30px;
    display: flex;
    flex-direction: column;
}

.cashback h2 {
    color: #222222;
    font-family: Yellix-ExtraBold, serif;
    font-size: 32px;
    margin-bottom: 40px;
}

.cashback img {
    margin: 0 auto;
    align-items: center;
}

.cashback p {
    color: #222222;
    font-size: 16px;
    font-family: Yellix-SemiBold, serif;
    margin: 40px auto 25px;
}


.view-profile .MuiTabs-indicator {
    display: none;
}

@media (max-width: 1440px) {
}

@media (max-width: 1200px) {
    .user-profile-info-img > img {
        width: 135px;
        height: 135px;
        border-radius: 22px;
    }

    .user-profile-info > div h3 img {
        width: 22px;
        height: 22px;
        object-fit: contain;
    }

    .lis .MuiTabs-flexContainer {
        padding: 26px 0;
        justify-content: start;
    }

    .lis .MuiButtonBase-root {
        padding: 15px 50px;
    }
}

@media (max-width: 992px) {
    .user-profile-info-img > img {
        width: 120px;
        height: 120px;
        border-radius: 20px;
    }

    .user-profile-info > div h3 img {
        width: 19px;
        height: 19px;
        object-fit: contain;
    }
}

@media (max-width: 768px) {
    .user-profile-info-img > img {
        width: 90px;
        height: 90px;
        border-radius: 18px;
    }

    .user-profile-info > div h3 img {
        width: 14px;
        height: 14px;
        object-fit: contain;
    }

    .profile {
        flex-direction: column;
        justify-content: center;
    }

    .cont-info {
        margin-left: 0px;
        margin-top: 15px;
    }

    .user-profile-info > div {
        margin-left: 0px;
        margin-top: 15px;
    }

    .lis .MuiButtonBase-root {
        padding: 15px 25px;
    }

    .lis .MuiTabs-flexContainer {
        padding: 18px 0;
    }

    .cont-info a,
    .user-profile-info > div a {
        font-size: 16px;
    }
}

/* add card modal */
.edit-profile-modal {
    width: 676px;
}

.edit-profile-modal .MuiDialog-paper {
    border-radius: 16px;
    width: 560px;
    max-width: calc(100vw - 32px) !important;
}
 

.edit-profile-modal .content {
    background-color: white;
    padding: 26px 26px;
}

.edit-profile-modal .content h4.title {
    font-family: Yellix-Bold, serif;
    color: var(--color-text);
    margin: 0px;
    text-align: center;
}

.edit-profile-modal .content img.close-btn {
    width: 40px;
    height: 40px;
    object-fit: contain;
    position: absolute;
    left: 0;
    top: 0;
    cursor: pointer;
}

.edit-profile-modal .actions .save-btn {
    width: 100%;
    background-color: var(--color-cyan2) !important;
    color: white;
}

.edit-profile-modal .row {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 8px 0px;
}

.edit-profile-modal {
    flex: 1 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100vh;
}

.edit-profile-modal  .header {
    height: 55px;
    display: flex;
    align-items: flex-end;
    padding-left: 20px;
    padding-right: 20px;
}

.edit-profile-modal .scrollview {
    flex: 1 1;
    width: 100%;
    background-color: white;
    margin-top: 20px;
}

.edit-profile-modal .save-btn {
    font-size: 18px;
    font-family: Yellix-SemiBold, serif;
    color: var(--color-cyan2);
}

.edit-profile-modal .avatar-view {
    width: 116px;
    height: 116px;
    position: relative;
}

.edit-profile-modal .photo-view {
    width: 100px;
    height: 100px;
    border-width: 1px;
    border-color: var(--color-gray9);
    border-radius: 50px;
    background-color: #E8D7D0;
}

.edit-profile-modal .photo-view img {
    width: 100px;
    height: 100px;
    border-radius: 50px;
    object-fit: cover;
}

.edit-profile-modal .photo-edit {
    width: 24px;
    height: 24px;
    border-radius: 12px;
    background-color: var(--color-cyan2);
    position: absolute;
    top: 10px;
    right: 10px;
}

.edit-profile-modal .birthday-view {
    display: flex;
    border-width: 1px;
    border-color: var(--color-gray5);
    border-style: solid;
    border-radius: 12px;
    height: 50px;
    padding-left: 12px;
    padding-right: 12px;
    margin-bottom: 12px;
}

.edit-profile-modal .birthday-txt {
    font-size: 16px;
    font-family: Yellix-Medium, serif;
    flex: 1 1;
    margin-left: 4px;
}

.edit-profile-modal .modal-content {
    width: 100%;
    padding: 20px 20px 30px;
    background-color: white;
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
}

.edit-profile-modal .modal-title {
    width: 100%;
    text-align: center;
    font-size: 18px;
    font-family: Yellix-Bold, serif;
    color: var(--color-text);
    margin-bottom: 12px;
}

.datepicker-modal ul.datepicker-scroll li {
    font-size: 16px;
    font-family: Yellix-SemiBold, serif;
    color: var(--color-text);
}

.camera-view {
    position: absolute !important;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    background-color: #000000;
}

.address-item {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: var(--color-gray8);
  border-radius: 16px;
  cursor: pointer;
  padding: 16px;
}

.address-item .address-type {
  font-size: 11px;
  font-family: Yellix-SemiBold, serif;
  color: var(--color-cyan2);
  padding: 3px 8px;
  border-radius: 5px;
  background-color: #23cbd833;
}

.address-item .edit-link {
  font-size: 15px;
  font-family: Yellix-SemiBold, serif;
  color: var(--color-gray7);
  margin: 0;
}

.address-item .delete-link {
  font-size: 15px;
  font-family: Yellix-SemiBold, serif;
  color: var(--color-red1);
  margin: 0;
}

.address-item .makeprimary-link {
  font-size: 15px;
  font-family: Yellix-SemiBold, serif;
  color: var(--color-gray4);
  -webkit-text-decoration-line: underline;
          text-decoration-line: underline;
  margin: 0;
  margin-left: 8px;
}

.address-item .radio {
}

.address-item .info h3 {
  font-size: 15px;
  font-family: Yellix-SemiBold, serif;
  color: var(--color-text);
  margin-top: 7px;
  margin-bottom: 12px;
}

.address-item .info p {
  font-size: 15px;
  font-family: Yellix-Medium, serif;
  color: var(--color-text);
  margin-top: 9px;
  margin-bottom: 0;
}

.address-item .primary-text {
  margin-left: 8px;
  font-size: 15px;
  color: var(--color-red1);
  font-family: Yellix-Bold, serif;
}

.address-item .out_delivery_area_txt {
  margin-top: 4px;
  width: 100%;
  text-align: center;
  font-size: 17px;
  font-family: Yellix-SemiBold, serif;
  color: #f53d3d;
}
.addresses-screen {
    flex: 1 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.addresses-screen .scrollview {
    width: 100%;
    background-color: white;
    overflow-y: auto;
}

.addresses-screen .rc-swipeout-actions {
    padding: 2px;
}
.add-address-screen {
    flex: 1 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.add-address-screen .header-view {
    width: 100%;
    margin: 15px 0px;
}

.add-address-screen .title{
    text-align: center;
    font-size: 20px;
    font-family: Yellix-Bold, serif;
    color: var(--color-text);
}

.add-address-screen .round-icon-btn {
    width: 33px;
    height: 33px;
    border-radius: 8px;
    background-color: white;
}

.add-address-screen .subject-title {
    font-size: 17px;
    font-family: Yellix-SemiBold, serif;
    color: var(--color-text);
}

.add-address-screen .address-type .text-drop-btn {
    height: 40px;
}

.add-address-screen .list {
    margin-top: 26px;
}

.add-address-screen .list .add-btn {
    background-color: white !important;
    font-size: 18px;
    font-family: Yellix-SemiBold, serif;
    color: var(--color-cyan2);
    margin-bottom: 20px;
    margin-top: 0;
    cursor: pointer;
}

.add-address-screen .actions .save-btn {
    width: 100%;
    background-color: var(--color-cyan2) !important;
    color: white;
}

.add-address-screen .form .row {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 8px 0px;
}

.add-address-screen .form div.label {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 16px;
    font-family: Yellix-SemiBold, serif;
    color: var(--color-text);
    margin-right: 16px;
}

.add-address-screen .form .row div.input-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex: 1 1;
}

.add-address-screen .form .row div.input-wrapper .text-dropdown {
    width: 100%;
    height: 48px;
}

.add-address-screen .form .row div.input-wrapper .text-dropdown .text-drop-btn {
    height: 48px;
}

.add-address-screen .form .find-btn {
    font-size: 16px;
    font-family: Yellix-SemiBold, serif;
    color: var(--color-cyan2);
    margin-bottom: 40px;
    margin-top: 10px;
    cursor: pointer;
    text-align: center;
}
.credit-card-item {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  background: var(--color-gray8);
  border-width: 1px;
  border-style: solid;
  border-color: var(--color-gray8);
  border-radius: 15px;
  margin-bottom: 16px;
  cursor: pointer;
  padding: 12px 15px;
}

.credit-card-item .primarytxt {
  font-size: 14px;
  font-family: Yellix-Bold, serif;
  color: var(--color-gray7);
  margin-left: 8px;
}

.credit-card-item .edit-btn {
  font-size: 14px;
  font-family: Yellix-SemiBold, serif;
  color: var(--color-gray7);
}

.credit-card-item .user-name {
  margin-top: 12px;
  margin-bottom: 14px;
  font-size: 14px;
  color: var(--color-text);
  font-family: Yellix-SemiBold, serif;
}

.credit-card-item .card-num {
  font-size: 20px;
  font-family: Yellix-SemiBold, serif;
  color: var(--color-text);
}

.credit-card-item .cvv {
  flex: 1 1;
  font-size: 14px;
  font-family: Yellix-Medium, serif;
  color: var(--color-text);
}

.address-item .primary-text {
  margin-left: 8px;
  font-size: 15px;
  color: var(--color-red1);
  font-family: Yellix-Bold, serif;
}
.no_cardlist_view {
    flex: 1 1;
}

.no_cardlist_view .description {
    color: var(--color-text);
    line-height: 24px;
    font-family: Yellix-Medium, serif;
    font-size: 16px;
    text-align: center;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 20px;
}
.payment-methods {
    flex: 1 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    background-size: cover;
    background-position: center;
}

.payment-methods .scrollview {
    width: 100%;
    background-color: white;
    overflow-y: auto;
    padding-left: 20px;
    padding-right: 20px;
}

.payment-methods-card-view {
  width: 100%;
  max-width: 350px;
  height: 170px;
  padding: 20px;
  background-size: cover;
  border-radius: 20px;
  background-image: url(/static/media/card_bg.66034147.svg);
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.payment-methods-card-view h3 {
  font-size: 14px;
  color: white;
  font-family: Yellix-SemiBold, serif;
  margin: 0;
}

.payment-methods-card-view h1 {
  font-size: 20px;
  color: white;
  font-family: Yellix-SemiBold, serif;
  margin: 0;
}

.payment-methods-card-view p {
  flex: 1 1;
  font-size: 14px;
  color: white;
  font-family: Yellix-Medium, serif;
  margin: 0;
}

.add-card-screen {
    flex: 1 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.add-card-screen .scrollview {
    width: 100%;
    background-color: white;
    overflow-y: auto;
    margin-top: 20px;
}


.add-card-screen .save-btn {
    width: 100%;
    background-color: var(--color-cyan2) !important;
    color: white;
}
.earn-invitation-modal .MuiDialog-container {
}

.earn-invitation-modal .MuiDialog-paper {
    margin: 0 !important;
    border-radius: 30px;
}

.earn-invitation-modal .content {
}

.earn-invitation-modal .content h1{
    font-size: 18px;
    font-family: Yellix-Bold, serif;
    color: var(--color-text);
}

.earn-invitation-modal .name{
    width: 100%;
    margin: 22px 0px;
    padding: 16px;
    border-radius: 12px;
    background-color: var(--color-gray9);
    font-size: 16px;
    font-family: Yellix-Medium, serif;
    color: var(--color-text);
}
.invite-option-modal .MuiDialog-container {
}

.invite-option-modal .MuiDialog-paper {
    margin: 0 !important;
    border-radius: 30px;
}

.invite-option-modal .content {
    width: 100%;
}

.invite-option-modal .content h1{
    font-size: 18px;
    font-family: Yellix-Bold, serif;
    color: var(--color-text);
}
.earn-screen {
    flex: 1 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.earn-screen .balance .balance-price {
    font-size: 40px;
    font-family: Yellix-Bold, serif;
    color: white;
}

.earn-screen .balance .balance-unit {
    padding-bottom: 5px;
    font-size: 20px;
    font-family: Yellix-Bold, serif;
    color: white;
}
.earn-screen .learnmore {
    font-size: 16px;
    font-family: Yellix-Medium, serif;
    color: var(--color-gray7);
    -webkit-text-decoration-line: underline;
            text-decoration-line: underline;
}

.earn-screen .divider {
    width: 100%;
    height: 1px;
    margin: 16px 0px;
    background-color: var(--color-gray9);
}

.earn-screen .hist-btn {

}

.earn-screen .earn-desc {
    font-size: 18px;
    font-family: Yellix-Medium, serif;
    color: var(--color-text);
    text-align: center;
}

.earn-screen .dot {
    width: 7px;
    height: 7px;
    margin-top: 10px;
    margin-right: 8px;
    border-radius: 3.5px;
    background-color: var(--color-cyan2);
}
.earn-screen .tooltip-row {
    display: flex;
    width: 100%;
    margin-top: 12px;
}
.earn-screen .tooltip-txt {
    font-size: 16px;
    font-family: Yellix-Medium, serif;
    color: var(--color-text);
}

.earn-invitation-history {
  width: 100%;
}

.earn-invitation-history .scroll-view {
  width: 100%;
}


.earn-invitation-history .header-view {
  width: 100%;
  margin: 15px 0px;
}

.earn-invitation-history .title{
  text-align: center;
  font-size: 20px;
  font-family: Yellix-Bold, serif;
  color: var(--color-text);
}

.earn-invitation-history .round-icon-btn {
  width: 33px;
  height: 33px;
  border-radius: 8px;
  background-color: white;
}

.earn-invitation-history .operation-tab {
  width: 100%;
  margin-top: 0px;
  border: 0 solid #e9e9f7;
  border-bottom-width: 1px;
}

.invitation-hist-item {
  width: 100%;
  align-items: flex-start;
  border-radius: 15px;
  background-color: var(--color-gray8);
  padding: 16px 12px;
  margin-bottom: 16px;
}

.invitation-hist-item p {
  margin-top: 0;
  margin-bottom: 0;
}

.invitation-hist-item .name {
  font-size: 16px;
  font-family: Yellix-Medium, serif;
  color: var(--color-text);
}

.invitation-hist-item .invite-code {
  font-size: 16px;
  font-family: Yellix-Medium, serif;
  color: var(--color-text);
}

.invitation-hist-item .status {
  font-size: 15px;
  font-family: Yellix-SemiBold, serif;
}

.invitation-hist-item .date_limit {
  font-size: 15px;
  font-family: Yellix-SemiBold, serif;
  color: var(--color-text);
}
.earn-invitation-details {
    flex: 1 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.earn-invitation-details .header-view {
    width: 100%;
    margin: 15px 0px;
}

.earn-invitation-details .title{
    text-align: center;
    font-size: 20px;
    font-family: Yellix-Bold, serif;
    color: var(--color-text);
}

.earn-invitation-details .learnmore {
    font-size: 16px;
    font-family: Yellix-Medium, serif;
    color: var(--color-gray7);
    -webkit-text-decoration-line: underline;
            text-decoration-line: underline;
    text-align: center;
}


.earn-invitation-details .dot {
    width: 7px;
    height: 7px;
    margin-top: 10px;
    margin-right: 8px;
    border-radius: 3.5px;
    background-color: var(--color-cyan2);
}
.earn-invitation-details .tooltip-row {
    display: flex;
    width: 100%;
    margin-top: 12px;
}
.earn-invitation-details .tooltip-txt {
    font-size: 16px;
    font-family: Yellix-Medium, serif;
    color: var(--color-text);
}

.earninvite-friends-screen {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.earninvite-friends-screen .scrollview {
  width: 100%;
  background-color: white;
  margin-top: 10px;
}

.earninvite-friends-screen .searchview {
  width: 100%;
  margin-top: 15px;
}

.earninvite-friends-screen .header-view {
  width: 100%;
  margin: 15px 0px;
}

.earninvite-friends-screen .title{
  text-align: center;
  font-size: 20px;
  font-family: Yellix-Bold, serif;
  color: var(--color-text);
}

.earninvite-friends-screen .round-icon-btn {
  width: 33px;
  height: 33px;
  border-radius: 8px;
  background-color: white;
}
.earninvite-snapfooders-screen {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.earninvite-snapfooders-screen .scrollview {
  flex: 1 1;
  width: 100%;
  background-color: white;
  margin-top: 20px;
}

.earninvite-snapfooders-screen .searchview {
  width: 100%;
  margin-top: 15px;
}

.earninvite-snapfooders-screen .header-view {
  width: 100%;
  margin: 15px 0px;
}

.earninvite-snapfooders-screen .title{
  text-align: center;
  font-size: 20px;
  font-family: Yellix-Bold, serif;
  color: var(--color-text);
}

.earninvite-snapfooders-screen .round-icon-btn {
  width: 33px;
  height: 33px;
  border-radius: 8px;
  background-color: white;
}
.settings-screen {
    flex: 1 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    background-color: white;
    border-top-width: 1px;
    border-top-style: solid;
    border-top-color: var(--color-gray6);
}

.settings-screen .header {
    padding-top: 20px;
}

.settings-screen h3 {
    font-size: 24px;
    font-family: Yellix-Bold, serif;
    color: var(--color-text);
    text-align: center;
}

.settings-screen .subject-title {
    font-size: 16px;
    font-family: Yellix-SemiBold, serif;
    color: var(--color-text);
}

.settings-screen .section-view {
    width: 100%;
    padding-top: 20px;
    padding-bottom: 20px;
    border-bottom-width: 1px;
    border-bottom-style: solid;
    border-bottom-color: var(--color-gray9);
}

.settings-screen .item-btn {
    margin-top: 20px;
    padding: 20px;
    background-color: var(--color-gray8);
    border-radius: 15px;
    font-size: 16px;
    font-family: Yellix-SemiBold, serif;
    color: var(--color-text);
}

.settings-screen .noti-text {
    font-size: 14px;
    font-family: Yellix-SemiBold, serif;
    color: var(--color-red1);
    text-align: center;
}

.settings-screen .lang-text {
    font-size: 16px;
    font-family: Yellix-Medium, serif;
    color: var(--color-text);
}

.settings-screen .lang-setting {
    width: 100%;
    padding: 20px;
    background-color: var(--color-gray8);
    border-radius: 15px;
}

.settings-screen .divider {
    width: 100%;
    height: 1px;
    background-color: var(--color-gray6);
}

/* add card modal */
.edit-profile-modal {
    width: 676px;
}

.edit-profile-modal .MuiDialog-paper {
    border-radius: 16px;
    width: 560px;
    max-width: calc(100vw - 32px) !important;
}
 

.edit-profile-modal .content {
    background-color: white;
    padding: 26px 18px;
}

.edit-profile-modal .content h4.title {
    font-family: Yellix-Bold, serif;
    color: var(--color-text);
    margin: 0px;
    text-align: center;
}

.edit-profile-modal .content img.close-btn {
    width: 40px;
    height: 40px;
    object-fit: contain;
    position: absolute;
    left: 0;
    top: 0;
    cursor: pointer;
}

.edit-profile-modal .actions .save-btn {
    width: 100%;
    background-color: var(--color-cyan2) !important;
    color: white;
}

.edit-profile-modal .row {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 8px 0px;
}

.edit-profile-modal {
    flex: 1 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100vh;
}

.edit-profile-modal  .header {
    height: 55px;
    display: flex;
    align-items: flex-end;
    padding-left: 20px;
    padding-right: 20px;
}

.edit-profile-modal .scrollview {
    flex: 1 1;
    width: 100%;
    background-color: white;
    margin-top: 20px;
}

.edit-profile-modal .save-btn {
    font-size: 18px;
    font-family: Yellix-SemiBold, serif;
    color: var(--color-cyan2);
}

.edit-profile-modal .birthday-view {
    display: flex;
    border-width: 1px;
    border-color: var(--color-gray5);
    border-style: solid;
    border-radius: 12px;
    height: 50px;
    padding-left: 12px;
    padding-right: 12px;
    margin-bottom: 12px;
}

.edit-profile-modal .birthday-txt {
    font-size: 16px;
    font-family: Yellix-Medium, serif;
    flex: 1 1;
    margin-left: 4px;
}

.edit-profile-modal .modal-content {
    width: 100%;
    padding: 20px 20px 30px;
    background-color: white;
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
}

.edit-profile-modal .modal-title {
    width: 100%;
    text-align: center;
    font-size: 18px;
    font-family: Yellix-Bold, serif;
    color: var(--color-text);
    margin-bottom: 12px;
}

.datepicker-modal ul.datepicker-scroll li {
    font-size: 16px;
    font-family: Yellix-SemiBold, serif;
    color: var(--color-text);
}

.camera-view {
    position: absolute !important;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    background-color: #000000;
}

/* map marker */
.map-marker {
  position: relative;
}

.map-marker .marker-body {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.map-marker img {
  cursor: pointer;
}

.map-marker .popup {
  position: absolute;
  background-color: white;
  text-align: center;
  z-index: 2;
  opacity: 1;
  transition: opacity 0.6s;
  box-shadow: 0 0 17px 2px rgb(2 2 2 / 15%) !important;
}

.map-marker .popup::after {
  content: '';
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -15px;
  border-width: 15px;
  border-style: solid;
  border-color: white transparent transparent transparent;
}

/* end map marker */

/* vendor marker */
.vendor-marker .new_tag {
  position: absolute;
  top: -5px;
  width: 36px;
  font-size: 10px;
  text-align: center;
  font-family: Yellix-Medium, serif;
  background-color: var(--color-red1);
  color: white;
  border-radius: 6px;
  z-index: 1;
}
.vendor-marker .fav_tag {
  position: absolute;
  top: -5px;
  right: -18px;
  background-color: var(--color-cyan2);
  border-radius: 7px;
  z-index: 1;
  width: 15px;
  height: 15px;
}

.vendor-marker .vendor-marker-info {
}

.vendor-marker .vendor-marker-info img {
  width: 38px;
  height: 38px;
  object-fit: contain;
  margin-right: 10px;
}

.vendor-marker .vendor-marker-info p {
  font-size: 14px;
  font-family: Yellix-Bold;
  color: var(--color-text);
  flex-wrap: nowrap;
  overflow-wrap: unset;
  text-align: left;
  margin-top: 0px;
  margin-bottom: 3px;
}

.vendor-marker .vendor-marker-info .rate-item {
  font-size: 12px;
  font-family: Yellix-Medium, serif;
  color: var(--color-gray7);
  margin-top: 4px;
}

.vendor-marker .popup {
  top: -90px;
  border-radius: 8px;
  padding: 12px 18px;
}

.vendor-marker .popup .delivery_range_indicator {
  font-size: 12px;
  font-family: Yellix-Medium, serif;
  margin-top: 4px;
  min-width: 150px;
}

/* end of vendor marker */

/* snapfooder marker */
.snapfooder-marker .user-info img.avatar {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  object-fit: contain;
  margin-right: 10px;
}

.snapfooder-marker .user-info p {
  font-size: 14px;
  font-family: Yellix-Bold, serif;
  color: var(--color-text);
  flex-wrap: nowrap;
  overflow-wrap: unset;
  margin: 0 !important;
}

.snapfooder-marker .popup {
  top: -60px;
  border-radius: 8px;
  padding: 8px 12px;
}

.snapfooder-marker .popup .chat-link {
  border-left-width: 1px;
  border-left-style: solid;
  border-left-color: var(--color-gray5);
  padding-left: 16px;
  cursor: pointer;
}

.snapfooder-marker .user-info .chat-link span {
  font-size: 14px;
  font-family: Yellix-SemiBold, serif;
  color: var(--color-cyan2);
}

.snapfooder-marker .popup img.zodiac {
  width: 12px;
  height: 12px;
  object-fit: contain;
  margin-left: 8px;
  margin-right: 12px;
}

/* end of snapfooder marker */

/* user marker */
.user-marker .popup {
  top: -60px;
  border-radius: 8px;
  width: 75px;
  height: 45px;
}

.user-marker .user-info p {
  font-size: 14px;
  font-family: Yellix-Bold, serif;
  color: var(--color-text);
  flex-wrap: nowrap;
  overflow-wrap: unset;
  margin: 0 !important;
}

/* end user marker */

/* group marker */
.snapfooder-group-marker {
  width: 36px;
  height: 36px;
  background-color: var(--color-primary);
  border-radius: 18px;
  cursor: pointer;
}

.snapfooder-group-marker .marker-text {
  font-size: 16px;
  font-family: Yellix-Bold, serif;
  color: white;
}
/* end group marker */
.snapfooders-group-modal {
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  z-index: 999;
  max-width: calc(100vw - 30px);
  width: 520px;
  position: fixed;
  bottom: 0;
  right: 15px;
  margin: 0 !important;
  box-shadow: 0px 11px 15px -7px rgb(0 0 0 / 20%), 0px 24px 38px 3px rgb(0 0 0 / 14%),
    0px 9px 46px 8px rgb(0 0 0 / 12%);
  background-color: white;
}

.snapfooders-group-modal .titleview {
  width: 100%;
  align-items: flex-start;
  position: relative;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
}

.snapfooders-group-modal .titleview img {
  width: 32px;
  height: 32px;
  object-fit: contain;
}

.snapfooders-group-modal .content {
  width: 100%;
  border-radius: 16px;
}

.snapfooders-group-modal .content .content-view {
  min-height: 380px;
  justify-content: flex-start;
}

.snapfooders-group-modal .content h2 {
  font-size: 22px;
  font-family: Yellix-SemiBold, serif;
  color: var(--color-text);
  margin: 0;
  flex: 1 1;
  text-align: center;
  padding: 6px 0;
}

.snapfooders-group-modal .content h3 {
  font-size: 22px;
  line-height: 24px;
  font-family: Yellix-SemiBold, serif;
  color: white;
  margin: 0;
  flex: 1 1;
}

.snapfooders-group-modal .scrollview {
  height: 48vh;
  justify-content: flex-start;
  overflow-y: auto;
  padding: 0px 20px;
  margin-bottom: 40px;
}

.map-setting-modal {
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
    z-index: 999;
    max-width: calc(100vw - 30px);
    max-height: calc(100vh - 100px);
    width: 520px;
    position: fixed;
    bottom: 0;
    right: 15px;
    margin: 0 !important;
    box-shadow: 0px 11px 15px -7px rgb(0 0 0 / 20%), 0px 24px 38px 3px rgb(0 0 0 / 14%),
    0px 9px 46px 8px rgb(0 0 0 / 12%);
    background-color: white;
}

.map-setting-modal .titleview {
    width: 100%;
    align-items: flex-start;
    position: relative;
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
}

.map-setting-modal .titleview img {
    width: 32px;
    height: 32px;
    object-fit: contain;
}

.map-setting-modal .content {
    width: 100%;
    max-height: calc(100vh - 100px);
    border-radius: 16px;
}

.map-setting-modal .content .content-view {
    min-height: 380px;
    justify-content: flex-start;
}

.map-setting-modal .content h2 {
    font-size: 22px;
    font-family: Yellix-SemiBold, serif;
    color: var(--color-text);
    margin: 0;
    flex: 1 1;
    text-align: center;
    padding: 6px 0;
}


.map-setting-modal .scrollview {
    justify-content: flex-start;
    overflow-y: auto;
    padding: 0px 20px;
    margin-bottom: 40px;
}

.map-setting-modal .section {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 0 8px;
}

.map-setting-modal .header {
    margin-bottom: 16px;
}

.map-setting-modal .headerTitle {
    color: var(--color-text);
    font-family: Yellix-Bold, serif;
    font-size: 20px;
}

.map-setting-modal .headerSubTitle {
    color: var(--color-text);
    font-family: Yellix-SemiBold, serif;
    font-size: 16px;
}

.map-setting-modal .sectionContent {
    background-color: var(--color-gray8);
    border-radius: 12px;
    display: flex;
    flex-direction: row;
    margin-top: 16px;
    align-items: center;
    min-height: 56px;
    padding: 8px 20px;
}

.map-setting-modal .sectionCol {
    display: flex;
    flex-direction: column;
    flex: 1 1;
}

.map-setting-modal .sectionTitle {
    margin-bottom: 8px;
    color: var(--color-text);
    font-family: Yellix-Bold, serif;
    font-size: 16px;
}

.map-setting-modal .sectionSubTitle {
    color: var(--color-text);
    font-family: Yellix-Medium, serif;
    font-size: 16px;
}

.map-setting-modal .sectionRadioTitle {
    color: var(--color-text);
    font-family: Yellix-SemiBold, serif;
    font-size: 16px;
}

.map-setting-modal .selectedFriends {
    color: var(--color-text);
    font-family: Yellix-Medium, serif;
    font-size: 15px;
}

/* add card modal */
.choose-friend-modal {
    width: 676px;
}

.choose-friend-modal .MuiDialog-paper {
    border-radius: 16px;
    width: 560px;
    max-width: calc(100vw - 32px) !important;
}
 

.choose-friend-modal .content {
    background-color: white;
    padding: 26px 18px;
    height: 80vh;
}

.choose-friend-modal .content h1.title {
    flex: 1 1;
    font-size: 28px;
    font-family: Yellix-Bold, serif;
    color: var(--color-text);
    margin: 0px;
    text-align: center;
}

.choose-friend-modal .content img.close-btn {
    width: 40px;
    height: 40px;
    object-fit: contain;
    cursor: pointer;
}

.choose-friend-modal .save-btn {
    width: 100%;
    background-color: var(--color-cyan2) !important;
    color: white;
}

.choose-friend-modal .row {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 8px 0px;
}

.choose-friend-modal {
    flex: 1 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100vh;
}

.choose-friend-modal .scrollview {
    flex: 1 1;
    width: 100%;
    background-color: white;
    overflow-y: auto;
}

.choose-friend-modal .operation-tab {
    height: 62px;
    width: 100%;
    margin-top: 20px;
    border-top-width: 1px;
    border-bottom-Width: 1px;
    border-top-color: #F6F6F9;
    border-top-style: solid;
    border-bottom-color: #F6F6F9;
    border-bottom-style: solid;
}

.choose-friend-modal .operation-tab .common-tabs {
    width: 360px;
}

.choose-friend-modal .searchview {
    width: 100%;
}
.view-snapfood-map .map-view {
  flex: 1 1;
  height: calc(100vh - 80px);
  position: relative;
}

.view-snapfood-map .search-bar {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  padding: 20px 40px;
}

.view-snapfood-map .setting-icon {
  position: absolute;
  top: 120px;
  right: 30px;
  width: 45px;
  height: 45px;
  object-fit: contain;
}

/* add card modal */
.snapfooder-profile-modal {
    width: 676px;
}

.snapfooder-profile-modal .MuiDialog-paper {
    border-radius: 16px;
    width: 560px;
    max-width: calc(100vw - 32px) !important;
}
 

.snapfooder-profile-modal .content {
    background-color: white;
    padding: 26px 18px;
}

.snapfooder-profile-modal .content h1.title {
    font-size: 32px;
    font-family: Yellix-Bold, serif;
    color: var(--color-text);
    margin: 0px;
    text-align: center;
}

.snapfooder-profile-modal .content img.close-btn {
    width: 40px;
    height: 40px;
    object-fit: contain;
    position: absolute;
    left: 0;
    top: 0;
    cursor: pointer;
}

.snapfooder-profile-modal .actions .save-btn {
    width: 100%;
    background-color: var(--color-cyan2) !important;
    color: white;
}

.snapfooder-profile-modal .row {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 8px 0px;
}

.snapfooder-profile-modal {
    flex: 1 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100vh;
}

.snapfooder-profile-modal  .header {
    height: 55px;
    display: flex;
    align-items: flex-end;
    padding-left: 20px;
    padding-right: 20px;
}

.snapfooder-profile-modal .scroll_view {
    flex: 1 1;
    width: 100%;
    background-color: white;
    margin-top: 20px;
}

.snapfooder-profile-modal .modal-content {
    width: 100%;
    padding: 20px 20px 30px;
    background-color: white;
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
}

.snapfooder-profile-modal .modal-title {
    width: 100%;
    text-align: center;
    font-size: 18px;
    font-family: Yellix-Bold, serif;
    color: var(--color-text);
    margin-bottom: 12px;
}


.snapfooder-profile-modal .subject-title {
    margin-top: 15px;
    font-size: 18px;
    font-family: Yellix-Bold, serif;
    color: var(--color-text);
}

.snapfooder-profile-modal .divider {
    width: 100%;
    height: 1px;
    background-color: var(--color-gray9);
}

.snapfooder-profile-modal .suggested-hlist {
    width: 100%;
    align-items: flex-start;
}

.snapfooder-profile-modal .suggested-hlist .scrollview_hider {
    width: 100%;
    margin-top: -18px;
    height: 18px;
    background-color: white;
    z-index: 1;
}

.snapfooder-profile-modal .remove-friend {
    padding-top: 12px;
    padding-bottom: 12px;
    font-size: 18px;
    font-family: Yellix-Bold, serif;
    color: var(--color-gray7);
    text-align: center;
}

.snapfooder-profile-modal .suggested-list {
    width: 100%;
    display: flex;
    margin-top: 16px;
    padding-bottom: 15px;
    overflow-x: auto;
}

.snapfooder-profile-modal .gray7_bg {
    background-color: var(--color-gray7) !important;
}

.snapfooder-profile-modal .cyan2_bg {
    background-color: var(--color-cyan2) !important;
}

.snapfooder-profile-modal .snapfooder-avatar .photoview {
    width: 100px !important;
    height: 100px !important;
    border-radius: 15px !important;
}

.snapfooder-profile-modal .snapfooder-avatar .avatarimg {
    width: 100px !important;
    height: 100px !important;
}
.view-terms {
    margin: auto;
    border-top-width: 1px;
    border-top-style: solid;
    border-top-color: var(--color-gray6);
}

.view-terms br {
    height: 20px;
}
.view-terms {
    padding-top: 30px;
    padding-left: 20px;
    padding-right: 20px;
}


.view-terms h1 {
    margin-top: 32px;
    margin-bottom: 32px;
    font: normal normal 800 32px/38px Yellix-Bold;
    letter-spacing: 0px;
    color: #222222;
    opacity: 1;
}

.view-terms p {
    font: normal normal 400 16px/19px Yellix-Medium;
    letter-spacing: 0px;
    color: #222222;
    opacity: 1;
}
.checkout-screen {
}

.checkout-screen .subject-title {
  font-family: Yellix-SemiBold, serif;
  color: var(--color-text);
}

.checkout-screen .proceed-btn {
  background-color: var(--color-cyan2) !important;
}

.checkout-screen .handover-view .h2 {
  font-size: 32px !important;
  font-family: normal;
  font-family: Yellix-Bold, serif;
  color: var(--color-text);
  margin: 0px;
  text-align: center;
}

.checkout-screen .radio-legal-age {
  font-size: 15px !important;
  font-family: normal;
  font-family: Yellix-Medium, serif;
  color: var(--color-text);
  justify-content: flex-start;
}

.checkout-screen .error_msg_view {
  width: 100%;
  padding: 12px;
  background-color: white;
  justify-content: center;
  align-items: center;
  display: flex;
  border-radius: 12px;
  border-width: 1px;
  border-color: var(--color-red1);
  border-style: dashed;
  font-size: 14px !important;
  font-family: normal;
  font-family: Yellix-SemiBold, serif;
  color: var(--color-red1);
}

.checkout-screen .left-view {
  padding-left: 80px !important;
  padding-right: 40px !important;
}

.checkout-screen .right-view {
  padding-left: 40px !important;
  padding-right: 80px !important;
}

@media (max-width: 1200px) {
  .checkout-screen .left-view {
    padding-left: 40px !important;
    padding-right: 20px !important;
  }

  .checkout-screen .right-view {
    padding-left: 20px !important;
    padding-right: 40px !important;
  }
}

@media (max-width: 992px) {

}

.checkout-screen .hide-md {
  display: none;
}


@media (max-width: 768px) {
  .checkout-screen .left-view {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }

  .checkout-screen .right-view {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }

  .checkout-screen .hide-md {
    display: inherit;
  }
  .checkout-screen .show-md {
    display: none;
  }
}

@media (max-width: 576px) {
  .checkout-screen .left-view {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }

  .checkout-screen .right-view {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
}
.checkout-stepper {
}

.checkout-stepper .step img {
  width: 24px;
  height: 24px;
  object-fit: contain;
  margin-right: 6px;
}

.checkout-stepper .step .label {
  font-family: Yellix-SemiBold;
  font-size: 15px;
}

.checkout-stepper .step .divider {
  border-top-width: 1px;
  border-top-style: dashed;
  margin-left: 25px;
  margin-right: 15px;
}

.checkout-stepper .step.active .label {
  color: var(--color-text);
}

.checkout-stepper .step.inactive .label {
  color: var(--color-gray7);
}

.checkout-stepper .step.active .divider {
  border-top-color: var(--color-cyan2);
}

.checkout-stepper .step.inactive .divider {
  border-top-color: var(--color-gray7);
}

@media (max-width: 768px) {
  .checkout-stepper .step {
    flex: none;
    width: 100%;
    justify-content: flex-start;
  }
  .checkout-stepper .step .divider{
    display: none;
  }
}
.checkout-cart-item {
  width: 100%;
  align-items: center;
}

.checkout-cart-item .qty {
  font-size: 14px;
  font-family: Yellix-SemiBold, serif;
  color: var(--color-red1);
  padding: 0px 15px;
  border-radius: 5px;
}

.checkout-cart-item h4 {
  font-size: 16px;
  font-family: Yellix-SemiBold, serif;
  color: var(--color-text);
  margin: 0 !important;
}

.checkout-cart-item h3 {
  font-size: 16px;
  font-family: Yellix-Bold, serif;
  color: var(--color-text);
  margin: 0 !important;
}

.checkout-cart-item .counter {
  margin-left: 20px;
  padding-left: 20px;
  border-left-width: 1px;
  border-left-style: solid;
  border-left-color: var(--color-gray7);
}

.checkout-cart-view {
  padding: 0px;
}

.checkout-cart-view .items {
  border-radius: 12px;
  margin-top: 20px;
  padding: 20px;
  width: 100%;
  background-color: var(--color-gray9);
}

.checkout-cart-view .h2 {
  font-size: 32px !important;
  font-family: normal;
  font-family: Yellix-Bold, serif;
  color: var(--color-text);
  margin: 0px;
  text-align: center;
}

.checkout-cart-view .cart-btns {
  width: 100%;
  margin-top: 20px;
}

.checkout-cart-view .cart-btns p {
  font-size: 15px;
  font-family: Yellix-Medium, serif;
  color: var(--color-text);
  margin-top: 0;
  margin-bottom: 3px;
}

.checkout-cart-view h2 {
  font-size: 22px;
  font-family: Yellix-SemiBold, serif;
  color: var(--color-text);
  margin: 0;
}

.checkout-cart-view a {
  font-size: 16px;
  font-family: Yellix-SemiBold, serif;
  color: var(--color-cyan2);
  margin: 0;
}

.checkout-cart-view .divider {
  height: 1px;
  width: 100%;
  background-color: var(--color-gray6);
  margin-bottom: 20px;
}

.checkout-cart-view .subject-title {
  font-size: 16px;
  font-family: Yellix-SemiBold, serif;
  color: var(--color-text);
  margin: 10px 0px;
}

.checkout-cart-view .vendor-logo-view .round-icon-btn{
  width: 50px;
  height: 50px;
  border-radius: 12px;
  background-color: white;
}

.checkout-cart-view .vendor-logo-view img {
  width: 48px;
  height: 48px;
  object-fit: contain;
  border-radius: 10px;
}

.checkout-cart-view .vendor-logo-view .logo-text {
  font-size: 20px;
  font-family: Yellix-Bold;
  color: var(--color-text);
  margin-left: 14px;
}
.coupon-input {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex: 1 1;
  padding: 15px 8px;
  border-radius: 12px;
  border: 1px solid var(--color-gray6);
  position: relative;
  max-height: 52px;
}

.coupon-input input {
  font-size: 14px !important;
  line-height: 18px !important;
  font-family: Yellix-Medium, serif !important;
  flex: 1 1;
  padding-top: 6px;
  padding-bottom: 6px;
  padding-left: 10px;

  width: 100%;
  outline: none !important;
  background-color: white !important;
  border-width: 0 !important;
  border-color: white !important;
  color: var(--color-text) !important;
}

.coupon-input input::-webkit-input-placeholder {
  color: var(--color-gray5);
  opacity: 1; /* Firefox */
}

.coupon-input input:-ms-input-placeholder {
  color: var(--color-gray5);
  opacity: 1; /* Firefox */
}

.coupon-input input::placeholder {
  color: var(--color-gray5);
  opacity: 1; /* Firefox */
}

.coupon-input input:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: var(--color-gray5);
}

.coupon-input input::-ms-input-placeholder {
  /* Microsoft Edge */
  color: var(--color-gray5);
}

.coupon-input .remove {
  font-size: 12px;
  font-family: Yellix-SemiBold, serif;
  color: var(--color-gray7);
  padding-left: 6px;
  padding-right: 6px;
}

.coupon-input .code-text {
  margin-right: 8px;
  font-size: 12px;
  font-family: Yellix-SemiBold, serif;
  color: var(--color-text);
}

.suggested-item {
  width: 100% !important;
  min-width: 100% !important;
  border-radius: 12px;
  border-width: 1px;
  border-style: solid;
  border-color: var(--color-gray6);
  padding: 0 !important;
}

.suggested-item img {
  width: 60px;
  height: 60px;
  border-radius: 4px;
  margin-right: 10px;
}

.suggested-item .info {
  flex: 1 1;
}

.suggested-item .btn-view {
  width: 100%;
  padding: 10px 0px;
  text-transform: none;
  background-color: var(--color-gray6);
  color: var(--color-text);
  font-family: Yellix-SemiBold;
}

.suggested-products .react-multi-carousel-list li {
  padding-right: 10px;
}

.price-board {
  background-color: var(--color-gray9);
  border-radius: 12px;
}

.price-board .key-text {
  font-size: 16px;
  color: var(--color-text);
  font-family: Yellix-Medium, serif;
}

.price-board .value-text {
  font-size: 16px;
  color: var(--color-text);
  font-family: Yellix-Medium, serif;
}

.price-board .top-border {
  border-top-width: 1px;
  border-top-style: solid;
  border-top-color: var(--color-gray7);
  padding-top: 12px;
}

.cutlery-view .subject-title {
  font-family: Yellix-SemiBold, serif;
  color: var(--color-text);
}

.promotion-view {
  background-color: var(--color-text);
  border-radius: 12px;
}

.promotion-view .title {
  font-family: Yellix-SemiBold, serif;
  color: var(--color-white);
}

.promotion-view .desc {
  font-family: Yellix-SemiBold;
  color: var(--color-cyan2);
}

.cart-delivery-info .vendor-address {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 15px;
  background-color: var(--color-gray8);
  padding: 12px 15px;
}

.cart-delivery-info .vendor-address p {
  color: var(--color-text);
  font-family: Yellix-Medium, serif;
  margin: 0;
}

.cart-delivery-info .handover-method .text-drop-btn {
  height: 46px;
}

.cart-delivery-info .map-view {
  width: 100%;
  height: 320px;
  border-radius: 12px;
  overflow: hidden;
  background-color: white;
}

.cart-delivery-info .address-view {
}

.cart-delivery-info .pickup-info .noti-text,
.cart-delivery-info .reservation-info .noti-text {
  font-family: Yellix-SemiBold, serif;
  text-align: center;
  color: var(--color-red1);
}

.checkout-screen .num_guest input {
  text-align: center;
}

.schedule-info-view .schedule-time-label {
  font-size: 15px;
  color: var(--color-text);
  font-family: Yellix-SemiBold, serif;
  -webkit-text-decoration-line: underline;
          text-decoration-line: underline;
  text-align: center;
}

@media (max-width: 420px) {
  .schedule-info-view {
    flex-direction: column;
    justify-content: center;
  }
  .schedule-info-view .subject-title {
    width: 100%;
    margin-bottom: 20px;
    margin-right: 0;
  }
}

.schedule-tab {
    padding-left: 0;
    padding-right: 0;
    width: 216px;
    height: 40px;
    border-radius: 15px;
    background-color: white;
    border-width: 1px;
    border-style: solid;
    border-color: #E9E9F7;
}

.schedule-tab .btn {
    flex: 1 1;
    cursor: pointer;
}

.schedule-tab .btn-active {
    height: 40px;
    margin-left: 0;
    margin-right: 0;
    border-radius: 15px;
    background-color: var(--color-text)
}

.schedule-tab .btn-inactive {
    height: 38px;
    margin-left: 0;
    margin-right: 0;
    border-radius: 15px;
    background-color: white;
}

.inactive_txt{
    font-size: 16px;
    color: var(--color-text);
    font-family: Yellix-SemiBold, serif;
}
.active_txt {
    font-size: 16px;
    color: white;
    font-family: Yellix-SemiBold, serif;
}
.delivery-schedule-modal .MuiDialog-paper {
  border-radius: 16px;
  width: 600px;
  max-width: calc(100vw - 32px) !important;
}

.delivery-schedule-modal .content {
  background-color: white;
  padding: 26px 18px;
}

.delivery-schedule-modal .content h4.title {
  font-family: Yellix-Bold, serif;
  color: var(--color-text);
  margin: 0px;
  text-align: center;
}

.delivery-schedule-modal .content .date-input {
  z-index: 2;
}

.delivery-schedule-modal .content .time-input {
  z-index: 1;
}

.delivery-schedule-modal .content img.close-btn {
  width: 40px;
  height: 40px;
  object-fit: contain;
  position: absolute;
  left: 0;
  top: 0;
  cursor: pointer;
}

.delivery-schedule-modal .actions .deliver-btn {
  width: 100%;
  background-color: var(--color-gray6) !important;
  color: var(--color-gray7);
  margin-top: 12px;
}

.delivery-schedule-modal .actions .save-btn {
  width: 100%;
  background-color: var(--color-cyan2) !important;
  color: white;
}
/* end */

.invite-input {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex: 1 1;
  padding: 15px 8px;
  border-radius: 12px;
  border: 1px solid var(--color-gray6);
  position: relative;
  max-height: 52px;
}

.invite-input input {
  font-size: 14px !important;
  line-height: 18px !important;
  font-family: Yellix-Medium, serif !important;
  flex: 1 1;
  padding-top: 6px;
  padding-bottom: 6px;
  padding-left: 10px;

  width: 100%;
  outline: none !important;
  background-color: white !important;
  border-width: 0 !important;
  border-color: white !important;
  color: var(--color-text) !important;
}

.invite-input input::-webkit-input-placeholder {
  color: var(--color-gray5);
  opacity: 1; /* Firefox */
}

.invite-input input:-ms-input-placeholder {
  color: var(--color-gray5);
  opacity: 1; /* Firefox */
}

.invite-input input::placeholder {
  color: var(--color-gray5);
  opacity: 1; /* Firefox */
}

.invite-input input:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: var(--color-gray5);
}

.invite-input input::-ms-input-placeholder {
  /* Microsoft Edge */
  color: var(--color-gray5);
}

.invite-input .remove {
  font-size: 12px;
  font-family: Yellix-SemiBold, serif;
  color: var(--color-gray7);
  padding-left: 6px;
  padding-right: 6px;
}

.invite-input .code-text {
  margin-right: 8px;
  font-size: 12px;
  font-family: Yellix-SemiBold, serif;
  color: var(--color-text);
}

.payment-method-item {
  align-items: flex-start;
  border-radius: 15px;
  background-color: var(--color-gray8);
  padding: 16px;
}

.payment-method-item .title {
  font-size: 16px;
  color: var(--color-text);
  font-family: Yellix-Medium, serif;
}

.cashback-row .subject-title {
  font-family: Yellix-SemiBold, serif;
  color: var(--color-text);
}
.checkout-screen .cashback-row .balance {
  margin-top: 4px;
  font-family: Yellix-SemiBold;
  color: var(--color-gray7);
}

.checkout-screen .cashback-row input,
.checkout-screen .rider-tip-view input {
  text-align: center;
}

.checkout-payment-methods {
}

.checkout-payment-methods h5 {
  font-family: normal;
  font-family: Yellix-Bold, serif;
  color: var(--color-text);
  margin: 0px;
  text-align: center;
}

.card-payment-method-item {
    width: 100%;
    align-items: flex-start;
    margin-bottom: 16px;
    border-radius: 15px;
    background-color: var(--color-gray8);
    padding: 16px;
}

.card-payment-method-item .title {
    font-size: 16px;
    color: var(--color-text);
    font-family: Yellix-Medium, serif;
}

.card-payment-method-item .card_number {
    font-size: 16px;
    color: var(--color-gray7);
    font-family: Yellix-Medium, serif;
}

.card-payment-method-item .add_card_btn {
    font-size: 16px;
    color: var(--color-cyan2);
    font-family: Yellix-SemiBold, serif;
    cursor: pointer;
}

.checkout-screen {
}

.checkout-screen .subject-title {
  font-family: Yellix-SemiBold, serif;
  color: var(--color-text);
}

.checkout-screen .proceed-btn {
  background-color: var(--color-cyan2) !important;
}

.checkout-screen .handover-view .h2 {
  font-size: 32px !important;
  font-family: normal;
  font-family: Yellix-Bold, serif;
  color: var(--color-text);
  margin: 0px;
  text-align: center;
}

.checkout-screen .cashback-row .balance {
  margin-top: 4px;
  font-family: Yellix-SemiBold;
  color: var(--color-gray7);
}

.checkout-screen .cashback-row input,
.checkout-screen .rider-tip-view input {
  text-align: center;
}

.order-summ-view .order-info-view {
  width: 100%;
  align-items: flex-start;
  padding: 16px;
  border-radius: 15px;
  background-color: var(--color-gray8);
}

.order-summ-view h5 {
  font-family: normal;
  font-family: Yellix-Bold, serif;
  color: var(--color-text);
  margin: 0px;
}

.order-summ-view h5 span {
  font-size: 16px;
  font-family: Yellix-Bold, serif;
  color: var(--color-gray7);
}

.order-summ-view .order-cancelled {
  width: 100%;
  text-align: center;
  margin-bottom: 10px;
  font-size: 16px;
  font-family: Yellix-SemiBold, serif;
  color: var(--color-red1);
}

.order-summ-view .order-total {
  font-size: 18px;
  font-family: Yellix-Bold, serif;
  color: var(--color-text);
}

.order-summ-view .subject-title {
  font-size: 18px;
  font-family: Yellix-SemiBold, serif;
  color: var(--color-text);
}

.order-summ-view .order-info-text {
  font-size: 16px;
  line-height: 20px;
  font-family: Yellix-Medium, serif;
  color: var(--color-gray7);
}

.order-summ-view .top-border {
  border-top-width: 1px;
  border-top-style: solid;
  border-top-color: var(--color-gray6);
  padding-top: 12px;
}

.order-summ-view .order-date {
  font-size: 16px;
  line-height: 20px;
  font-family: Yellix-SemiBold, serif;
  color: var(--color-text);
}

.order-summ-view .vendor-address {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 15px;
  background-color: var(--color-gray8);
  padding: 12px 15px;
}

.order-summ-view .vendor-address .vendor-phone {
  font-size: 16px;
  font-family: Yellix-Medium;
  color: var(--color-text);
}

.order-summ-view .info-value-txt {
  font-size: 16px;
  font-family: Yellix-SemiBold, serif;
  color: var(--color-text);
}
.cashback-row .order-for-row .subject-title {
  font-family: Yellix-SemiBold, serif;
  color: var(--color-text);
}

.checkout-screen .order-for-row .choosed-friend {
  font-size: 16px;
  font-family: Yellix-SemiBold;
  color: var(--color-text);
  margin-right: 10px;
}

.checkout-screen .order-for-row .choose-btn {
  padding: 8px 15px;
  border-width: 1px;
  border-color: var(--color-cyan2);
  font-size: 15px;
  font-family: Yellix-SemiBold;
  color: var(--color-cyan2);
}

.order-summ-screen {
  margin-top: 100px;
}

.order-summ-screen .subject-title {
  font-family: Yellix-SemiBold, serif;
  color: var(--color-text);
}

.order-summ-screen .proceed-btn {
  background-color: var(--color-cyan2) !important;
}

.order-summ-screen .lineStyle {
  border: 1px solid #e9e9f7;
  opacity: 1;
  height: 0px;
  width: 100%;
}

.order-summ-screen .back-link {
  font-size: 16px;
  font-family: Yellix-SemiBold, serif;
  color: var(--color-gray7);
  justify-content: flex-start;
  margin-top: 20px;
}

.order-summ-screen .reorder-btn {
  font-size: 16px;
  font-family: Yellix-SemiBold, serif;
  color: var(--color-text);
}

@media (max-width: 1200px) {
  .order-summ-screen .header-view {
    flex: none !important;
    width: 100% !important;
    max-width: 100% !important;
  }
}
declined-status.order-stepper {
}

.order-stepper .step img {
  width: 24px;
  height: 24px;
  object-fit: contain;
  margin-right: 6px;
}

.order-stepper .step .name {
  font-family: Yellix-Bold;
  font-size: 18px;
}

.order-stepper .step .desc {
  font-family: Yellix-SemiBold;
  font-size: 13px;
  max-width: 300px;
  margin-top: 4px;
}


.order-stepper .step .divider {
  border-top-width: 1px;
  border-top-style: dashed;
  margin-left: 25px;
  margin-right: 15px;
}

.order-stepper .step.active .name {
  color: #000;
}

.order-stepper .step.inactive .name {
  color: var(--color-gray7);
}

.order-stepper .step.active .desc {
  color: var(--color-text);
}

.order-stepper .step.inactive .desc {
  color: var(--color-gray7);
}

.order-stepper .step.active .divider {
  border-top-color: var(--color-cyan2);
}

.order-stepper .step.inactive .divider {
  border-top-color: var(--color-gray7);
}

.order-stepper .step .divider{
  min-width: 30px;
}

@media (max-width: 900px) {
  .order-stepper {
    flex-direction: column;
  }
  .order-stepper .step {
    width: 100%;
    justify-content: flex-start;
  }
  .order-stepper .step .divider{
    display: none;
  }
}
.order-review-modal .MuiDialog-paper {
  border-radius: 16px;
}

.order-review-modal .titleview {
  width: 100%;
  height: 60px;
  position: relative;
}

.order-review-modal .titleview img {
  position: absolute;
  left: 0;
  top: 10px;
}

.order-review-modal .content {
  max-width: calc(100vw - 30px);
  width: 600px;
  background-color: white;
  border-radius: 16px;
  padding: 32px 32px;
}

.order-review-modal .content h2 {
  font-size: 22px;
  font-family: Yellix-SemiBold, serif;
  color: var(--color-text);
  margin: 0;
}

.order-review-modal .content img.review-done-img {
  width: 200px;
  height: 200px;
  object-fit: contain;
}

.order-review-modal .content p.review-done {
  font-size: 19px;
  font-family: Yellix-SemiBold, serif;
  color: var(--color-text);
  text-align: center;
  line-height: 27px;
  margin: 20px 0px 40px 0;
}

.order-review-input {
  width: 100%;
  padding-top: 20px;
  padding-bottom: 20px;
  border-bottom-width: 1px;
  border-bottom-color: var(--color-gray9);
  border-bottom-style: solid;
}

.order-review-input h3 {
  margin-top: 0;
  margin-bottom: 12px;
  font-size: 17px;
  font-family: Yellix-SemiBold, serif;
  color: var(--color-text);
}

.order-review-input img {
  width: 200px;
  height: 200px;
  object-fit: contain;
}

.order-support-modal {
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  z-index: 999;
  max-width: calc(100vw - 30px);
  width: 520px;
  position: fixed;
  bottom: 0;
  right: 15px;
  margin: 0 !important;
  box-shadow: 0px 11px 15px -7px rgb(0 0 0 / 20%), 0px 24px 38px 3px rgb(0 0 0 / 14%),
    0px 9px 46px 8px rgb(0 0 0 / 12%);
  background-color: white;
}

.order-support-modal .titleview {
  width: 100%;
  height: 160px;
  align-items: flex-start;
  position: relative;
  background: url(/static/media/help_bg.eba6104d.png);
  background-size: cover;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
}

.order-support-modal .titleview img {
  width: 32px;
  height: 32px;
  object-fit: contain;
}

.order-support-modal .content {
  width: 100%;
  border-radius: 16px;
}

.order-support-modal .content .content-view {
  min-height: 380px;
  justify-content: flex-start;
}

.order-support-modal .content h2 {
  font-size: 22px;
  font-family: Yellix-SemiBold, serif;
  color: white;
  margin: 0;
  flex: 1 1;
  text-align: center;
  padding: 6px 0;
}

.order-support-modal .content h3 {
  font-size: 22px;
  line-height: 24px;
  font-family: Yellix-SemiBold, serif;
  color: white;
  margin: 0;
  flex: 1 1;
}

.order-support-modal .content p.members {
  font-size: 16px;
  line-height: 22px;
  font-family: Yellix-Medium, serif;
  color: var(--color-red1);
  margin: 0;
}

.order-support-modal .content .logo-view {
  width: 84px;
  height: 84px;
  margin-top: -46px;
  background-color: white;
  z-index: 1;
  border-radius: 8px;
  border-width: 1px;
  border-style: solid;
  border-color: var(--color-gray9);
}

.order-support-modal .content .logo-view img {
  width: 80px;
  height: 80px;
  object-fit: contain;
}

.order-support-modal .content p.need-help {
  font-size: 16px;
  font-family: Yellix-SemiBold, serif;
  color: var(--color-text);
  text-align: center;
  line-height: 22px;
  margin: 20px 0px 30px 0;
}

.order-support-modal .content p.call-vendor {
  font-size: 15px;
  font-family: Yellix-Medium, serif;
  color: var(--color-text);
  text-align: center;
  line-height: 19px;
  margin: 0;
}

.order-support-modal .content .or-divider {
  width: 100%;
  margin: 28px 0;
}

.order-support-modal .content .or-divider div:first-child {
  width: 100%;
  border-width: 1px;
  border-color: var(--color-gray6);
  border-style: solid;
}

.order-support-modal .content .or-divider div:nth-child(2) {
  margin-top: -12px;
  background-color: white;
  padding: 0 8px;
  text-align: center;
  font-size: 15px;
  font-family: Yellix-Medium, serif;
  color: var(--color-text);
}

.order-support-modal .order-support-chat .messages-screen {
  max-height: calc(100vh - 364px);
}

.order-faqs {
  width: 100%;
  padding-top: 20px;
  padding-bottom: 20px;
}

.order-faqs .order-faq .question p {
  font-size: 18px;
}

.order-faqs .order-faq .answer p {
  font-size: 16px;
}

.order-review-item {
    width: 100%;
    border-top-width: 1px;
    border-top-color: var(--color-gray9);
    border-top-style: solid;
}

.order-review-item h3 {
    font-size: 16px;
    font-family: Yellix-SemiBold, serif;
    color: var(--color-text);
    margin-top: 20px;
    margin-bottom: 25px;
}

.order-review-item p {
    font-size: 16px;
    font-family: Yellix-Medium, serif;
    color: var(--color-text);
    margin-top: 0;
    margin-bottom: 6px;
}
.view-vendors-map .map-view {
  flex: 1 1;
  height: calc(100vh - 80px);
  position: relative;
}

.view-vendors-map .search-bar {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  padding: 20px 40px;
}

.no_cashback_view {
    flex: 1 1;
    margin-top: 40px;
}

.no_cashback_view .description {
    color: var(--color-text);
    line-height: 24px;
    font-family: Yellix-Medium, serif;
    font-size: 16px;
    text-align: center;
    padding-left: 20px;
    padding-right: 20px;
}
.wallet-screen {
    flex: 1 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.wallet-screen .scrollview {
    width: 100%;
    background-color: white;
    padding-left: 20px;
    padding-right: 20px;
}

.wallet-screen .divider {
    width: 100%;
    height: 1px;
    background-color: #F6F6F9;
}

.wallet-screen .subject-title {
    margin-top: 20px;
    margin-bottom: 12px;
    font-size: 18px;
    font-family: Yellix-Bold, serif;
    color: var(--color-text)
}

.wallet-screen .avatar {

}

.wallet-screen .avatar .photoview {
    height: 100px;
    width: 100px;
    border-width: 1px;
    border-color: var(--color-gray9);
    border-style: solid;
    border-radius: 15px;
    background-color: #E8D7D0;
}

.wallet-screen .avatar .photoview img {
    height: 100px;
    width: 100px;
    border-radius: 6px;
}

.wallet-screen .avatar .level {
    padding: 8px 12px;
    border-radius: 20px;
    background-color: #ffda0f
}

.wallet-screen .avatar .level-txt {
    line-height: 13px;
    font-size: 15px;
    font-family: Yellix-SemiBold, serif;
    color: white;
}

.wallet-screen .avatar .name {
    line-height: 13px;
    font-size: 18px;
    font-family: Yellix-Bold, serif;
    color: var(--color-text);
    margin-top: 10px;
    margin-bottom: 6px;
}

.wallet-screen .balance {
    margin-top: 24px;
    margin-bottom: 20px;
    background-color: white;
    border-radius: 15px;
    padding: 23px 18px 23px 11px;
    background-image: linear-gradient(to right, #715EE7, #00D2FF);
}

.wallet-screen .balance .balance-txt {
    margin-bottom: 6px;
    font-size: 17px;
    font-family: Yellix-SemiBold, serif;
    color: white;
}

.wallet-screen .balance .balance-desc {
    line-height: 13px;
    font-size: 14px;
    font-family: Yellix-SemiBold, serif;
    color: #FAFAFCCC;
}

.wallet-screen .balance .balance-price {
    font-size: 40px;
    font-family: Yellix-Bold, serif;
    color: white;
}

.wallet-screen .balance .balance-unit {
    padding-bottom: 5px;
    font-size: 20px;
    font-family: Yellix-Bold, serif;
    color: white;
}
.wallet-transaction-item {
    width: 100%;
    align-items: flex-start;
    border-radius: 15px;
    background-color: var(--color-gray8);
    padding: 15px;
    cursor: pointer;
    margin-bottom: 12px;
    display: flex;
}

.wallet-transaction-item .pay_method_txt {
    font-size: 15px;
    font-family: Yellix-Medium, serif;
    color: var(--color-gray7);
    margin-top: 4px;
    margin-bottom: 4px;
}

.wallet-transaction-item .price {
    font-size: 17px;
    font-family: Yellix-Bold, serif;
    color: var(--color-text);
    margin: 0 !important;
}

.wallet-transaction-item .title {
    font-size: 17px;
    font-family: Yellix-SemiBold, serif;
    color: var(--color-text);
    margin: 0 !important;
}

.past-order-screen {
  position: relative;
}

.past-order-screen .past-order-screen-padding {
  padding-left: 32px !important;
  padding-right: 32px !important;
}

.past-order-screen .overlay-dark:after {
  height: 420px;
  z-index: 0;
  opacity: 0.2;
  background-color: black;
}

.past-order-screen .vendor-bg {
  width: 100%;
  height: 420px;
  padding: 18px 0;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  background-repeat: no-repeat;
  background-size: cover;
}


@media (max-width: 576px) {

  .past-order-screen .overlay-dark:after{
    height: 340px;
  }

  .past-order-screen .vendor-bg{
    height: 340px;
  }
}

.view-terms {
    margin: auto;
    border-top-width: 1px;
    border-top-style: solid;
    border-top-color: var(--color-gray6);
}

.view-terms br {
    height: 20px;
}
.view-terms {
    padding-top: 30px;
    padding-left: 20px;
    padding-right: 20px;
}


.view-terms h1 {
    margin-top: 32px;
    margin-bottom: 32px;
    font: normal normal 800 32px/38px Yellix-Bold;
    letter-spacing: 0px;
    color: #222222;
    opacity: 1;
}

.view-terms p {
    font: normal normal 400 16px/19px Yellix-Medium;
    letter-spacing: 0px;
    color: #222222;
    opacity: 1;
}
.auth-form h3 a {
  color: var(--color-red1);
}

.auth-bottom-link {
  margin-top: 40px;
}

.auth-bottom-link .text {
  font-size: 16px;
  font-family: Yellix-SemiBold;
  color: var(--color-text);
  margin-right: 4px;
}

.auth-bottom-link .btn {
  font-size: 16px;
  font-family: Yellix-SemiBold;
  color: var(--color-cyan2);
  cursor: pointer;
}

.text-btn {
  font-size: 16px;
  font-family: Yellix-SemiBold;
  color: var(--color-text);
  cursor: pointer;
}

.vcode-inputs .auth-input input,
textarea {
  text-align: center;
}

.view-editphone .auth-input {
  width: 100%;
}

@media (max-width: 576px) {
  .view-editphone .auth-input {
    height: 46px !important;
  }
}
/* Vendors Section */

.vendors {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px;
  padding-top: 59px;
}

.vendors .section-title {
  max-width: 680px;
  display: flex;
  flex-direction: column;
  align-items: center;
  grid-gap: 27px;
  gap: 27px;
}

.vendors .h1-title {
  font-family: 'Yellix-ExtraBold';
  font-style: normal;
  font-weight: 800;
  font-size: 52px;
  line-height: 56px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: -1.04px;
  color: #171717;
  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 0;
}

.vendors .vendor-subtitle {
  font-family: 'Yellix-Medium';
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 28px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #737373;
}

.grid-menu {
  margin-top: 32px;
  max-width: 1216px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  grid-gap: 32px;
  gap: 32px;
  width: 100%;
}

.feature {
  width: 50%;
  display: flex;
  flex-direction: column;
  grid-gap: 32px;
  gap: 32px;
}

.image-container {
  background: #fafafa;
  border-radius: 8px;
  position: relative;
  display: flex;
  justify-content: center;
  width: 100%;
  padding-top: 50px;
  padding-bottom: 37px;
}

.image-container .child-layer {
  background-color: white;
  padding: 28px;
  border-radius: 30px;
}

.image-container .restaurant-image {
  width: 191px;
  height: 191px;
}

.image-container .restaurant-image-mobile {
  display: none;
}

.text-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  width: 100%;
}

.overline {
  width: 100%;
  font-family: 'Yellix-Bold';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #50b7ed;
}

.heading {
  font-family: 'Yellix-ExtraBold';
  font-style: normal;
  font-weight: 800;
  font-size: 22px;
  line-height: 28px;
  letter-spacing: -0.02em;
  color: #171717;
  margin-top: 8px;
}

.description {
  width: 100%;
  font-family: 'Yellix-Regular';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  color: #737373;
  margin-top: 16px;
}

.order-button {
  text-decoration: none;
  font-family: 'Yellix-Bold';
  font-size: 16px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 20px;
  grid-gap: 8px;
  gap: 8px;
  height: 48px;
  background: #50b7ed;
  border-radius: 6px;
  border: none;
  color: white;
  cursor: pointer;
  margin-top: 32px;
}

.order-button:hover {
  background-color: #4094c7;
}

.newsletter-layer {
  margin-top: 56px;
  max-width: 1216px;
}

/* SnapFood Section */

.snapfood-title-block {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin-bottom: 40px;
}

.snapfood-title-block h1 {
  font-family: 'Yellix-ExtraBold';
  font-style: normal;
  font-weight: 800;
  font-size: 32px;
  letter-spacing: -0.64px;
  line-height: 44px;
  color: #171717;
  margin: 0 0 16px;
}

.snapfood-title-block p {
  font-family: 'Yellix-Medium';
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 28px;
  color: #737373;
  margin: 0;
  max-width: 730px;
  text-align: center;
  margin: auto;
}

.snapfood-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  grid-gap: 32px;
  gap: 32px;
}

.snapfood-feature {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  max-width: 360px;
  flex: 1 1;
}

.snapfood-feature-image {
  width: 76px;
  height: 80px;
  margin-bottom: 20px;
}

.snapfood-feature h2 {
  font-family: 'Yellix';
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 28px;
  color: #171717;
  margin: 0 0 16px;
}

.snapfood-feature p {
  font-family: 'Yellix';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  color: #737373;
  margin: 0;
}

/* Newsletter */

.newsletter-main-layer {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 64px 112px;
  grid-gap: 19px;
  gap: 19px;
  background: #fafafa;
  width: 100%;
}

.newsletter-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.newsletter-container .mail-icon {
  width: 48px;
  height: 48px;
  border-radius: 100%;
  background-color: #50b7ed19;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 12px;
}

.newsletter-container h1 {
  font-family: 'Yellix-ExtraBold';
  font-style: normal;
  font-weight: 800;
  font-size: 32px;
  line-height: 44px;
  letter-spacing: -0.64px;
  color: #50b7ed;
  text-align: center;
  margin: 0;
}

.newsletter-container p {
  font-family: 'Yellix-Medium';
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #404040;
  margin-top: 16px;
  margin-bottom: 32px;
}

.newsletter-container .form-section {
  width: 100%;
  width: 592px;
  display: flex;
  flex-direction: column;
}

.newsletter-content {
  display: flex;
  flex-direction: row;
  grid-gap: 16px;
  gap: 16px;
}

.newsletter-content input {
  width: 300px;
  border: 1px solid #ccc;
  border-radius: 4px;
  flex: 1 1;
  font-size: 14px;
  height: 40px;
  padding: 10px;
}

.newsletter-content input::-webkit-input-placeholder {
  color: #a3a3a3;
}

.newsletter-content input:-ms-input-placeholder {
  color: #a3a3a3;
}

.newsletter-content input::placeholder {
  color: #a3a3a3;
}

.newsletter-content button {
  width: 85px;
  height: 40px;
  color: white;
  background-color: #50b7ed;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-family: 'Yellix-Medium';
  font-size: 14px;
}

.newsletter-content button:hover {
  background-color: #4094c7;
}

.newsletter-checkbox {
  display: flex;
  align-items: center;
  grid-gap: 12px;
  gap: 12px;
  margin-top: 20px;
}

.newsletter-checkbox input[type='checkbox'] {
  /* appearance: none; */
  width: 40px;
  min-width: 40px;
  height: 40px;
  border: 1px solid #e5e5e5;
  border-radius: 6px;
}

.newsletter-checkbox label {
  font-family: 'Yellix-Regular';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  color: #737373;
}

/* Media Queries */

.snapfood-ordering {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  grid-gap: 32px;
  gap: 32px;
  /* padding-inline: 2%; */
}

.snapfood-ordering h5 {
  font-family: 'Yellix-Bold';
  font-size: 22px;
  letter-spacing: -0.44px;
  color: #222222;
  opacity: 1;
  margin-top: 12px;
  margin-bottom: 16px;
}

.snapfood-ordering .sf-section {
  display: flex;
  flex-direction: column;
  align-items: start;
  width: 32%;
  min-height: 184px;
}

.sf-section .tab-img img {
  height: 80px;
  width: auto;
}

.sf-section h5 {
  margin-top: 12px;
  margin-bottom: 12px;
  font-family: 'Yellix-Bold';
  font-size: 22px;
  line-height: 28px;
  letter-spacing: 0px;
  color: #171717;
}

.sf-section p {
  margin: 0px;
  font-family: 'Yellix-Regular';
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 0px;
  color: #737373;
}

@media (max-width: 768px) {
  .vendors {
    padding: 0 16px;
  }

  .image-container .restaurant-image {
    display: none;
  }

  .image-container .restaurant-image-mobile {
    width: 100%;
    height: auto;
    display: block;
  }

  .vendors .section-title {
    grid-gap: 16px;
    gap: 16px;
  }

  .vendors .h1-title {
    margin-top: 48px;
    font-size: 36px;
    line-height: 44px;
    letter-spacing: -0.72px;
  }

  .vendors .vendor-subtitle {
    font-family: 'Yellix-Regular';
    font-size: 18px;
  }

  .image-container .child-layer {
    padding: 15px;
  }

  .heading {
    font-size: 18px;
  }

  .snapfood-title-block h1 {
    width: 280px;
    font-size: 24px;
    line-height: 32px;
  }

  .snapfood-title-block p {
    font-family: 'Yellix-Regular';
    font-size: 18px;
    width: 300px;
  }

  .snapfood-ordering .sf-section,
  .snapfood-ordering .sf-section>div:nth-child(2) {
    width: 100%;
  }

  .sf-section .tab-img {
    margin: auto;
  }

  .snapfood-ordering {
    grid-gap: 10px;
    gap: 10px;
  }

  .snapfood-ordering h5 {
    font-size: 18px;
    text-align: center;
  }

  .snapfood-ordering p {
    font-size: 16px;
    text-align: center;
    line-height: 24px;
  }

  .grid-menu {
    flex-direction: column;
    align-items: center;
  }

  .grid-menu .feature {
    width: 100%;
  }

  .snapfood-ordering {
    flex-direction: column;
  }

  .snapfood-row {
    flex-direction: column;
    align-items: center;
  }

  .newsletter-main-layer {
    padding: 32px 16px;
  }

  .newsletter-content {
    flex-direction: column;
    align-items: stretch;
  }

  .newsletter-container h1 {
    width: 320px;
    font-size: 28px;
    line-height: 40px;
  }

  .newsletter-container p {
    font-size: 14px;
  }

  .newsletter-container .form-section {
    width: calc(100vw - 32px);
  }

  .newsletter-content input {
    width: 100%;
    margin-bottom: 10px;
  }

  .newsletter-content button {
    width: 100%;
  }

  .newsletter-checkbox label {
    font-size: 14px;
  }
}


.vendor-landing .vendor-info {
  margin-top: 40px;
  width: 100%;
  max-width: 1216px;
  border-radius: 13px;
  background-color: #FAFAFA;
  padding: 56px;
  display: flex;
  justify-content: center;
  align-items: center;
  grid-gap: 20px;
  gap: 20px;
}

.vendor-landing .vendor-info .info {
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.vendor-landing .vendor-info .info .tags {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  grid-gap: 20px;
  gap: 20px;
}


.vendor-landing .vendor-info .info .tag {
  display: flex;
  justify-content: center;
  align-items: center;
  grid-gap: 4px;
  gap: 4px;

  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  color: #FF9C00;

  height: 32px;
  border-radius: 24px;
  padding-left: 12px;
  padding-right: 12px;

  white-space: nowrap;
}

.vendor-landing .vendor-info .order-now {
  display: flex;
  justify-content: center;
  align-items: center;
  grid-gap: 4px;
  gap: 4px;
  height: 48px;
  border-radius: 6px;
  padding-left: 20px;
  padding-right: 20px;
  background-color: #50B7ED;

  font-size: 16px;
  line-height: 24px;
  font-weight: 700;
  color: #fff;
  margin-top: 24px;
}

.vendor-landing .vendor-info .info h1 {
  font-size: 56px;
  line-height: 122%;
  font-weight: 900;
  color: #1B1B1B;
  margin-top: 28px;
  margin-bottom: 28px;
}

.vendor-landing .vendor-info .info p {
  font-size: 18px;
  line-height: 26px;
  color: #5C5C5C;
  margin-top: 12px;
}

.vendor-landing .vendor-info .photo {
  width: 50%;
  height: 470px;
  border-radius: 6px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

  padding-left: 0;
  margin-left: 0;
  border-left-width: 0;

  display: flex;
  justify-content: center;
  align-items: flex-end;
  padding-bottom: 40px;
}

.vendor-landing .vendor-info .wifi-block {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  padding: 24px;
  background-color: white;
  grid-gap: 16px;
  gap: 16px;
}

.vendor-landing .vendor-info .wifi-block h3 {
  font-size: 18px;
  line-height: 28px;
  font-weight: 500;
  color: #171717;
  margin-top: 0;
  margin-bottom: 0;
}

.vendor-landing .vendor-info .wifi-block p {
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  color: #737373;
  margin: 0;
}

@media (max-width: 768px) {
  .vendor-landing .vendor-info {
    padding: 20px;
    flex-direction: column;
  }

  .vendor-landing .vendor-info .info {
    width: 100%;
    align-items: center;
  }

  .vendor-landing .vendor-info .photo {
    width: 100%;
  }

  .vendor-landing .vendor-info .info .tags {
    grid-gap: 10px;
    gap: 10px;
  }

  .vendor-landing .vendor-info .info h1 {
    font-size: 32px;
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .vendor-landing .vendor-info .info p {
    font-size: 16px;
    line-height: 24px;
  }

  .vendor-landing .vendor-info .photo {
    width: 100%;
    height: 280px;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 40px;
  }


  .vendor-landing .vendor-info .wifi-block {
    padding: 12px;
    grid-gap: 10px;
    gap: 10px;
  }

  .vendor-landing .vendor-info .wifi-block h3 {
    font-size: 16px;
    line-height: 24px;
  }

  .vendor-landing .vendor-info .wifi-block p {
    font-size: 12px;
    line-height: 18px;
  }

}


.popular-items {
  margin-top: 56px;
  width: 100%;
  max-width: 1216px;
}

.popular-items .grids {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 20px;
  gap: 20px;
}

.popular-items .product-item {
  background-color: #FAFAFC;
  border-radius: 16px;
  padding: 16px;
  position: relative;
}

.popular-items .product-item h3 {
  font-family: 'Yellix-Bold';
  font-size: 18px;
  color: #222;
  margin: 0;
}

.popular-items .product-item h5 {
  font-family: 'Yellix-Bold';
  font-size: 19px;
  color: #222;
  margin: 0;
  margin-top: 12px;
  margin-bottom: 12px;
}

.popular-items .product-item p {
  font-family: 'Yellix-Regular';
  font-size: 17px;
  color: #6A6A6A;
  margin: 0;
}

.popular-items .product-item .fav-btn {
  position: absolute;
  top: 12px;
  right: 12px;
}

@media (max-width: 768px) {
  .popular-items .grids {
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 20px;
    gap: 20px;
  }
}

.reviews {
  margin-top: 56px;
  width: 100%;
  max-width: 1216px;
  padding-top: 32px;
  padding-bottom: 32px;
  border-radius: 8px;
  background-color: #FAFAFA;
}

.reviews .review-item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 60%;
}

.reviews .review-item .title-view {
  display: flex;
  justify-content: center;
  align-items: center;
  grid-gap: 28px;
  gap: 28px;
}

.reviews .review-item h1 {
  font-family: 'Yellix-ExtraBold';
  font-size: 32px;
  color: #171717;
  margin: 0;
}

.reviews .review-item p {
  font-size: 18px;
  line-height: 24px;
  color: #737373;
  margin: 24px 0px;
  text-align: center;
}

.reviews .review-item h4 {
  font-family: 'Yellix-ExtraBold';
  font-size: 18px;
  line-height: 28px;
  color: #171717;
  margin: 0;
}

.reviews .react-multi-carousel-item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.reviews .react-multi-carousel-dot button {
  border-width: 0;
  background-color: #D9D9D9;
}

.reviews .react-multi-carousel-dot--active button {
  background-color: #8C8C8C;
}

.reviews .react-multi-carousel-list {
  padding-bottom: 40px;
}

@media (max-width: 768px) {
  .reviews {
    margin-top: 40px;
    padding-top: 28px;
    padding-bottom: 28px;
  }

  .reviews .review-item {
    width: 100%;
    padding-left: 10px;
    padding-right: 10px;
  }

  .reviews .review-item .title-view {
    grid-gap: 24px;
    gap: 24px;
  }

  .review-item img {
    width: 32px;
    object-fit: contain;
  }

  .reviews .review-item h1 {
    font-size: 24px;
  }

  .reviews .review-item p {
    font-size: 14px;
    line-height: 20px;
    margin: 18px 0px;
  }

  .reviews .review-item h4 {
    font-size: 18px;
    line-height: 22px;
  }
}

.faqs {
  margin-bottom: 56px;
  width: 100%;
  max-width: 1216px;
  padding-bottom: 20px;
  padding-left: 20px;
  padding-right: 20px;
  border-radius: 8px;
  background-color: #FAFAFA;
  border-width: 1px;
  border-color: #E5E5E5;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.faqs h1 {
  font-family: 'Yellix-ExtraBold';
  font-size: 36px;
  line-height: 44px;
  color: #171717;
  margin-top: 24px;
  margin-bottom: 24px;
}

@media (max-width: 768px) {
  .faqs {
    margin-bottom: 40px;
    width: 100%;
  }
  
  .faqs h1 {
    font-size: 24px;
    line-height: 32px;
    text-align: center;
  }
}
.app-snackbar-message .MuiSnackbarContent-root{
  background-color: var(--color-cyan2) !important;
  font-size: 16px;
  font-family: Yellix-SemiBold;
  color: white;
}
.download-app-sticky {
  z-index: 998;
  width: 320px;
  padding: 10px;
  border-radius: 18px;
  position: fixed;
  bottom: 20px;
  right: 15px;
  box-shadow: 0px 11px 15px -7px rgb(0 0 0 / 20%), 0px 24px 38px 3px rgb(0 0 0 / 14%),
  0px 9px 46px 8px rgb(0 0 0 / 12%);
  background-color: white;
}

.download-app-sticky title {
  width: 50%;
  height: 160px;
  padding-right: 15px;
}

.download-app-sticky h2 {
  font-size: 22px;
  line-height: 28px;
  font-family: Yellix-Bold, serif;
  color: var(--color-text);
  margin: 0;
  padding-left: 20px;
}

@media (max-width: 576px) {
  .download-app-sticky {
    display: none !important;
    width: 200px;
    border-radius: 16px;
    right: calc((100vw - 200px) / 2);
  }
  
  .download-app-sticky title {
    width: 50%;
    height: 75px;
    padding-right: 12px;
  }
  
  .download-app-sticky h2 {
    font-size: 17px;
    line-height: 22px;
  }
}


* {
  font-family: 'Manrope', sans-serif;
}

@font-face {
  font-family: 'Yellix-Black';
  src: url(/static/media/Yellix-Black.578f1dfb.ttf) format('truetype');
}

@font-face {
  font-family: 'Yellix-BlackItalic';
  src: url(/static/media/Yellix-BlackItalic.eb2fc808.ttf) format('truetype');
}

@font-face {
  font-family: 'Yellix-Bold';
  src: url(/static/media/Yellix-Bold.562dbe3d.ttf) format('truetype');
}

@font-face {
  font-family: 'Yellix-BoldItalic';
  src: url(/static/media/Yellix-BoldItalic.025b1473.ttf) format('truetype');
}

@font-face {
  font-family: 'Yellix-ExtraBold';
  src: url(/static/media/Yellix-ExtraBold.597e19f8.ttf) format('truetype');
}

@font-face {
  font-family: 'Yellix-ExtraBoldItalic';
  src: url(/static/media/Yellix-ExtraBoldItalic.e6e09523.ttf) format('truetype');
}

@font-face {
  font-family: 'Yellix-Light';
  src: url(/static/media/Yellix-Light.32044d70.ttf) format('truetype');
}

@font-face {
  font-family: 'Yellix-LightItalic';
  src: url(/static/media/Yellix-LightItalic.919a9fb5.ttf) format('truetype');
}

@font-face {
  font-family: 'Yellix-Medium';
  src: url(/static/media/Yellix-Medium.f9db1e51.ttf) format('truetype');
}

@font-face {
  font-family: 'Yellix-MediumItalic';
  src: url(/static/media/Yellix-MediumItalic.fa13a57f.ttf) format('truetype');
}

@font-face {
  font-family: 'Yellix-Regular';
  src: url(/static/media/Yellix-Regular.3cad5f93.ttf) format('truetype');
}

@font-face {
  font-family: 'Yellix-SemiBold';
  src: url(/static/media/Yellix-SemiBold.7eec000b.ttf) format('truetype');
}

@font-face {
  font-family: 'Yellix-Thin';
  src: url(/static/media/Yellix-Thin.5abb2e00.ttf) format('truetype');
}

:root {
  --color-transparent: 'transparent';
  --color-background: #fafafa;
  --color-darkerBackground: #ededed;
  --color-greyBackground: #f5f5f5;
  --color-text: #222222;
  --color-placeholder: #595959;
  --color-whitePrimary: #ffffff;
  --color-blackPrimary: #000000;
  --color-btnPrimary: #50B7ED;
  --color-disabled: #c9d6df;
  --color-blackTransparent6: 'rgba(0; 0; 0; 0.6)';
  --color-backgroundTransparent3: 'rgba(225; 225; 225; 0.3)';
  --color-backgroundTransparent4: 'rgba(225; 225; 225; 0.4)';
  --color-backgroundTransparent5: 'rgba(225; 225; 225; 0.5)';
  --color-backgroundTransparent6: 'rgba(225; 225; 225; 0.6)';
  --color-backgroundTransparent7: 'rgba(225; 225; 225; 0.7)';
  --color-backgroundTransparent8: 'rgba(225; 225; 225; 0.8)';
  --color-primaryTransparent8: 'rgba(34; 173; 196; 0.8);';
  --color-inactiveTintColor: #373a3f;
  --color-backgroundColor: #f2f7f9;
  --color-listBorderColor: #d3e0e5;
  --color-primary: #50B7ED;
  --color-secondary: #32db64;
  --color-anger: #f53d3d;
  --color-light: #f4f4f4;
  --color-dark: #222;
  --color-black: #000;
  --color-white: #fff;
  --color-red: #f00;
  --color-gray1: #454b4c;
  --color-gray2: #6a6a6a;
  --color-gray3: #8c9799;
  --color-gray4: #737373;
  --color-gray5: #d5d4e0;
  --color-gray6: #e9e9f7;
  --color-gray7: #aaa8bf;
  --color-gray8: #fafafc;
  --color-gray9: #f6f6f9;
  --color-green1: #00db16;
  --color-cyan1: #50b7ed;
  --color-cyan2: #50b7ed;
  --color-cyan3: #23dee2;
  --color-red1: #f55a00;
  --color-red2: #00c22d;
  --color-blue1: #9900ff;
  --color-alertError: #cc1c4e;
  --color-alertSuccess: #1dd890;
  --color-alertWarning: #f4e637;
  --color-warning: #f4be38;
  --color-alertInfo: #109df7;
  --color-alertNeutral: #7850dd;
  --color-socialFacebook: #3b5998;
  --color-socialTwitter: #00aced;
}

iframe {
  display: none;
}

* {
  box-sizing: border-box;
}

body {
  margin: 0;
}

#root {
  width: 100% !important;
  overflow-y: hidden;
}

.hidden {
  display: none;
}

.flex_1 {
  flex: 1 1;
}

.flex_wrap {
  flex-wrap: wrap;
}

.pos_relative {
  position: relative;
}

.row_center {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.flex_between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.center-align {
  display: flex;
  justify-content: center;
}

.align-middle {
  display: flex;
  justify-content: center;
  align-items: center;
}

.align-col-middle {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.align-col-start {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.align-start {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
}

.align-row-start {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
}

.align-end {
  display: flex;
  justify-content: flex-end;
}

.row-start {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.h100 {
  height: 100%;
}

.w100 {
  width: 100%;
}

.bg-cover {
  background-repeat: no-repeat !important;
  background-position: 50% !important;
  background-size: cover !important;
}

.bg-theme-color-light {
  background-color: rgba(66, 133, 244, 0.04) !important;
}

.overlay-dark {
  transition: all 0.2s ease;
}

.overlay-dark:after {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 0;
  /*opacity: .2;*/
  /*background-color: black;*/
}

.overlay-dark > *,
.overlay-light > * {
  z-index: 1;
  position: relative;
}

.btn-style {
  cursor: pointer;
}

.MuiDialog-root {
  z-index: 1000 !important;
}

.react-confirm-alert-body {
  font-family: Yellix-Medium, serif !important;
  width: 400px !important;
  max-width: calc(100vw - 40px) !important;
  text-align: center !important;
  color: #222222 !important;
  color: var(--color-text) !important;
  padding: 16px 25px !important;
}

.react-confirm-alert-body > h1 {
  font-size: 18px;
}

.react-confirm-alert-overlay {
  z-index: 100000;
  background: rgba(0, 0, 0, 0.3) !important;
}

.react-confirm-alert-button-group > button {
  background: #50b7ed !important;
  background: var(--color-cyan2) !important;
  font-family: Yellix-Medium, serif !important;
}

.react-confirm-alert-button-group {
  justify-content: center !important;
}

.react-confirm-alert-button-group > button.error-ok-btn {
  background: white !important;
  color: #50B7ED !important;
}

.error-ok-btn {
  font-size: 14px !important;
}

.lds-ellipsis div {
  background: #50B7ED !important;
  width: 9px !important;
  height: 9px !important;
}

.ptr__pull-down--pull-more div p {
  display: none;
}

.hide-sm {
  display: inherit;
}

.sm-100 {
  width: inherit;
}

.sm-flex-100 {
  flex: 1 1;
}

.md-flex-100 {
  flex: 1 1;
}

.max-lg {
  max-width: 1600px;
}

@media (max-width: 768px) {
  .md-flex-100 {
    flex: none;
    width: 100%;
  }
}

@media (max-width: 576px) {
  .hide-sm {
    display: none;
  }
  .sm-100 {
    width: 100%;
  }
  .sm-flex-100 {
    flex: none;
    width: 100%;
  }
}

:root {
    --size-unit: 10px;
}

@media (max-width: 1200px) {
    :root {
        --size-unit: 8px;
    }
}

@media (max-width: 992px) {
    :root {
        --size-unit: 6px;
    }

    .mt10 {
        /* on Small devices mt10 is same as mt5 */
        margin-top: calc(10px * 5);
        margin-top: calc(var(--size-unit) * 5);
    }
}

/*@media (max-width: 768px) {*/
/*  :root {*/
/*    --size-unit: 4px;*/
/*  }*/
/*}*/

/*@media (max-width: 576px) {*/
/*  :root {*/
/*    --size-unit: 2px;*/
/*  }*/
/*}*/

.mt0 {
    margin-top: 0px;
}

.mt1 {
    margin-top: calc(10px * 1);
    margin-top: calc(var(--size-unit) * 1);
}

.mt2 {
    margin-top: calc(10px * 2);
    margin-top: calc(var(--size-unit) * 2);
}

.mt3 {
    margin-top: calc(10px * 3);
    margin-top: calc(var(--size-unit) * 3);
}

.mt4 {
    margin-top: calc(10px * 4);
    margin-top: calc(var(--size-unit) * 4);
}

.mt5 {
    margin-top: calc(10px * 5);
    margin-top: calc(var(--size-unit) * 5);
}

.mt6 {
    margin-top: calc(10px * 6);
    margin-top: calc(var(--size-unit) * 6);
}

.mt7 {
    margin-top: calc(10px * 7);
    margin-top: calc(var(--size-unit) * 7);
}

.mt8 {
    margin-top: calc(10px * 8);
    margin-top: calc(var(--size-unit) * 8);
}

.mt9 {
    margin-top: calc(10px * 9);
    margin-top: calc(var(--size-unit) * 9);
}

.mt10 {
    margin-top: calc(10px * 10);
    margin-top: calc(var(--size-unit) * 10);
}

.mb0 {
    margin-bottom: 0px;
}

.mb1 {
    margin-bottom: calc(10px * 1);
    margin-bottom: calc(var(--size-unit) * 1);
}

.mb2 {
    margin-bottom: calc(10px * 2);
    margin-bottom: calc(var(--size-unit) * 2);
}

.mb3 {
    margin-bottom: calc(10px * 3);
    margin-bottom: calc(var(--size-unit) * 3);
}

.mb4 {
    margin-bottom: calc(10px * 4);
    margin-bottom: calc(var(--size-unit) * 4);
}

.mb5 {
    margin-bottom: calc(10px * 5);
    margin-bottom: calc(var(--size-unit) * 5);
}

.mb6 {
    margin-bottom: calc(10px * 6);
    margin-bottom: calc(var(--size-unit) * 6);
}

.mb7 {
    margin-bottom: calc(10px * 7);
    margin-bottom: calc(var(--size-unit) * 7);
}

.mb8 {
    margin-bottom: calc(10px * 8);
    margin-bottom: calc(var(--size-unit) * 8);
}

.mb9 {
    margin-bottom: calc(10px * 9);
    margin-bottom: calc(var(--size-unit) * 9);
}

.mb10 {
    margin-bottom: calc(10px * 10);
    margin-bottom: calc(var(--size-unit) * 10);
}

.ml0 {
    margin-left: 0px;
}

.ml1 {
    margin-left: calc(10px * 1);
    margin-left: calc(var(--size-unit) * 1);
}

.ml2 {
    margin-left: calc(10px * 2);
    margin-left: calc(var(--size-unit) * 2);
}

.ml3 {
    margin-left: calc(10px * 3);
    margin-left: calc(var(--size-unit) * 3);
}

.ml4 {
    margin-left: calc(10px * 4);
    margin-left: calc(var(--size-unit) * 4);
}

.ml5 {
    margin-left: calc(10px * 5);
    margin-left: calc(var(--size-unit) * 5);
}

.ml6 {
    margin-left: calc(10px * 6);
    margin-left: calc(var(--size-unit) * 6);
}

.ml7 {
    margin-left: calc(10px * 7);
    margin-left: calc(var(--size-unit) * 7);
}

.ml8 {
    margin-left: calc(10px * 8);
    margin-left: calc(var(--size-unit) * 8);
}

.ml9 {
    margin-left: calc(10px * 9);
    margin-left: calc(var(--size-unit) * 9);
}

.ml10 {
    margin-left: calc(10px * 10);
    margin-left: calc(var(--size-unit) * 10);
}

.mr0 {
    margin-right: 0px;
}

.mr1 {
    margin-right: calc(10px * 1);
    margin-right: calc(var(--size-unit) * 1);
}

.mr2 {
    margin-right: calc(10px * 2);
    margin-right: calc(var(--size-unit) * 2);
}

.mr3 {
    margin-right: calc(10px * 3);
    margin-right: calc(var(--size-unit) * 3);
}

.mr4 {
    margin-right: calc(10px * 4);
    margin-right: calc(var(--size-unit) * 4);
}

.mr5 {
    margin-right: calc(10px * 5);
    margin-right: calc(var(--size-unit) * 5);
}

.mr6 {
    margin-right: calc(10px * 6);
    margin-right: calc(var(--size-unit) * 6);
}

.mr7 {
    margin-right: calc(10px * 7);
    margin-right: calc(var(--size-unit) * 7);
}

.mr8 {
    margin-right: calc(10px * 8);
    margin-right: calc(var(--size-unit) * 8);
}

.mr9 {
    margin-right: calc(10px * 9);
    margin-right: calc(var(--size-unit) * 9);
}

.mr10 {
    margin-right: calc(10px * 10);
    margin-right: calc(var(--size-unit) * 10);
}

.mh0 {
    margin-left: 0;
    margin-right: 0;
}

.mh1 {
    margin-left: calc(10px * 1);
    margin-left: calc(var(--size-unit) * 1);
    margin-right: calc(10px * 1);
    margin-right: calc(var(--size-unit) * 1);
}

.mh2 {
    margin-left: calc(10px * 2);
    margin-left: calc(var(--size-unit) * 2);
    margin-right: calc(10px * 2);
    margin-right: calc(var(--size-unit) * 2);
}

.mh3 {
    margin-left: calc(10px * 3);
    margin-left: calc(var(--size-unit) * 3);
    margin-right: calc(10px * 3);
    margin-right: calc(var(--size-unit) * 3);
}

.mh4 {
    margin-left: calc(10px * 4);
    margin-left: calc(var(--size-unit) * 4);
    margin-right: calc(10px * 4);
    margin-right: calc(var(--size-unit) * 4);
}

.mh5 {
    margin-left: calc(10px * 5);
    margin-left: calc(var(--size-unit) * 5);
    margin-right: calc(10px * 5);
    margin-right: calc(var(--size-unit) * 5);
}

.mh6 {
    margin-left: calc(10px * 6);
    margin-left: calc(var(--size-unit) * 6);
    margin-right: calc(10px * 6);
    margin-right: calc(var(--size-unit) * 6);
}

.mh7 {
    margin-left: calc(10px * 7);
    margin-left: calc(var(--size-unit) * 7);
    margin-right: calc(10px * 7);
    margin-right: calc(var(--size-unit) * 7);
}

.mh8 {
    margin-left: calc(10px * 8);
    margin-left: calc(var(--size-unit) * 8);
    margin-right: calc(10px * 8);
    margin-right: calc(var(--size-unit) * 8);
}

.mh9 {
    margin-left: calc(10px * 9);
    margin-left: calc(var(--size-unit) * 9);
    margin-right: calc(10px * 9);
    margin-right: calc(var(--size-unit) * 9);
}

.mh10 {
    margin-left: calc(10px * 10);
    margin-left: calc(var(--size-unit) * 10);
    margin-right: calc(10px * 10);
    margin-right: calc(var(--size-unit) * 10);
}

.mv0 {
    margin-top: 0;
    margin-bottom: 0;
}

.mv1 {
    margin-top: calc(10px * 1);
    margin-top: calc(var(--size-unit) * 1);
    margin-bottom: calc(10px * 1);
    margin-bottom: calc(var(--size-unit) * 1);
}

.mv2 {
    margin-top: calc(10px * 2);
    margin-top: calc(var(--size-unit) * 2);
    margin-bottom: calc(10px * 2);
    margin-bottom: calc(var(--size-unit) * 2);
}

.mv3 {
    margin-top: calc(10px * 3);
    margin-top: calc(var(--size-unit) * 3);
    margin-bottom: calc(10px * 3);
    margin-bottom: calc(var(--size-unit) * 3);
}

.mv4 {
    margin-top: calc(10px * 4);
    margin-top: calc(var(--size-unit) * 4);
    margin-bottom: calc(10px * 4);
    margin-bottom: calc(var(--size-unit) * 4);
}

.mv5 {
    margin-top: calc(10px * 5);
    margin-top: calc(var(--size-unit) * 5);
    margin-bottom: calc(10px * 5);
    margin-bottom: calc(var(--size-unit) * 5);
}

.mv6 {
    margin-top: calc(10px * 6);
    margin-top: calc(var(--size-unit) * 6);
    margin-bottom: calc(10px * 6);
    margin-bottom: calc(var(--size-unit) * 6);
}

.mv7 {
    margin-top: calc(10px * 7);
    margin-top: calc(var(--size-unit) * 7);
    margin-bottom: calc(10px * 7);
    margin-bottom: calc(var(--size-unit) * 7);
}

.mv8 {
    margin-top: calc(10px * 8);
    margin-top: calc(var(--size-unit) * 8);
    margin-bottom: calc(10px * 8);
    margin-bottom: calc(var(--size-unit) * 8);
}

.mv9 {
    margin-top: calc(10px * 9);
    margin-top: calc(var(--size-unit) * 9);
    margin-bottom: calc(10px * 9);
    margin-bottom: calc(var(--size-unit) * 9);
}

.mv10 {
    margin-top: calc(10px * 10);
    margin-top: calc(var(--size-unit) * 10);
    margin-bottom: calc(10px * 10);
    margin-bottom: calc(var(--size-unit) * 10);
}

.pt0 {
    padding-top: 0px;
}

.pt1 {
    padding-top: calc(10px * 1);
    padding-top: calc(var(--size-unit) * 1);
}

.pt2 {
    padding-top: calc(10px * 2);
    padding-top: calc(var(--size-unit) * 2);
}

.pt3 {
    padding-top: calc(10px * 3);
    padding-top: calc(var(--size-unit) * 3);
}

.pt4 {
    padding-top: calc(10px * 4);
    padding-top: calc(var(--size-unit) * 4);
}

.pt5 {
    padding-top: calc(10px * 5);
    padding-top: calc(var(--size-unit) * 5);
}

.pt6 {
    padding-top: calc(10px * 6);
    padding-top: calc(var(--size-unit) * 6);
}

.pt7 {
    padding-top: calc(10px * 7);
    padding-top: calc(var(--size-unit) * 7);
}

.pt8 {
    padding-top: calc(10px * 8);
    padding-top: calc(var(--size-unit) * 8);
}

.pt9 {
    padding-top: calc(10px * 9);
    padding-top: calc(var(--size-unit) * 9);
}

.pt10 {
    padding-top: calc(10px * 10);
    padding-top: calc(var(--size-unit) * 10);
}

.pb0 {
    padding-bottom: 0px;
}

.pb1 {
    padding-bottom: calc(10px * 1);
    padding-bottom: calc(var(--size-unit) * 1);
}

.pb2 {
    padding-bottom: calc(10px * 2);
    padding-bottom: calc(var(--size-unit) * 2);
}

.pb3 {
    padding-bottom: calc(10px * 3);
    padding-bottom: calc(var(--size-unit) * 3);
}

.pb4 {
    padding-bottom: calc(10px * 4);
    padding-bottom: calc(var(--size-unit) * 4);
}

.pb5 {
    padding-bottom: calc(10px * 5);
    padding-bottom: calc(var(--size-unit) * 5);
}

.pb6 {
    padding-bottom: calc(10px * 6);
    padding-bottom: calc(var(--size-unit) * 6);
}

.pb7 {
    padding-bottom: calc(10px * 7);
    padding-bottom: calc(var(--size-unit) * 7);
}

.pb8 {
    padding-bottom: calc(10px * 8);
    padding-bottom: calc(var(--size-unit) * 8);
}

.pb9 {
    padding-bottom: calc(10px * 9);
    padding-bottom: calc(var(--size-unit) * 9);
}

.pb10 {
    padding-bottom: calc(10px * 10);
    padding-bottom: calc(var(--size-unit) * 10);
}

.pl0 {
    padding-left: 0px;
}

.pl1 {
    padding-left: calc(10px * 1);
    padding-left: calc(var(--size-unit) * 1);
}

.pl2 {
    padding-left: calc(10px * 2);
    padding-left: calc(var(--size-unit) * 2);
}

.pl3 {
    padding-left: calc(10px * 3);
    padding-left: calc(var(--size-unit) * 3);
}

.pl4 {
    padding-left: calc(10px * 4);
    padding-left: calc(var(--size-unit) * 4);
}

.pl5 {
    padding-left: calc(10px * 5);
    padding-left: calc(var(--size-unit) * 5);
}

.pl6 {
    padding-left: calc(10px * 6);
    padding-left: calc(var(--size-unit) * 6);
}

.pl7 {
    padding-left: calc(10px * 7);
    padding-left: calc(var(--size-unit) * 7);
}

.pl8 {
    padding-left: calc(10px * 8);
    padding-left: calc(var(--size-unit) * 8);
}

.pl9 {
    padding-left: calc(10px * 9);
    padding-left: calc(var(--size-unit) * 9);
}

.pl10 {
    padding-left: calc(10px * 10);
    padding-left: calc(var(--size-unit) * 10);
}

.pr0 {
    padding-right: 0px;
}

.pr1 {
    padding-right: calc(10px * 1);
    padding-right: calc(var(--size-unit) * 1);
}

.pr2 {
    padding-right: calc(10px * 2);
    padding-right: calc(var(--size-unit) * 2);
}

.pr3 {
    padding-right: calc(10px * 3);
    padding-right: calc(var(--size-unit) * 3);
}

.pr4 {
    padding-right: calc(10px * 4);
    padding-right: calc(var(--size-unit) * 4);
}

.pr5 {
    padding-right: calc(10px * 5);
    padding-right: calc(var(--size-unit) * 5);
}

.pr6 {
    padding-right: calc(10px * 6);
    padding-right: calc(var(--size-unit) * 6);
}

.pr7 {
    padding-right: calc(10px * 7);
    padding-right: calc(var(--size-unit) * 7);
}

.pr8 {
    padding-right: calc(10px * 8);
    padding-right: calc(var(--size-unit) * 8);
}

.pr9 {
    padding-right: calc(10px * 9);
    padding-right: calc(var(--size-unit) * 9);
}

.pr10 {
    padding-right: calc(10px * 10);
    padding-right: calc(var(--size-unit) * 10);
}

.ph0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
}

.ph05 {
    padding-left: calc(10px * 0.5);
    padding-left: calc(var(--size-unit) * 0.5);
    padding-right: calc(10px * 0.5);
    padding-right: calc(var(--size-unit) * 0.5);
}

.ph1 {
    padding-left: calc(10px * 1);
    padding-left: calc(var(--size-unit) * 1);
    padding-right: calc(10px * 1);
    padding-right: calc(var(--size-unit) * 1);
}

.ph2 {
    padding-left: calc(10px * 2);
    padding-left: calc(var(--size-unit) * 2);
    padding-right: calc(10px * 2);
    padding-right: calc(var(--size-unit) * 2);
}

.ph3 {
    padding-left: calc(10px * 3) !important;
    padding-left: calc(var(--size-unit) * 3) !important;
    padding-right: calc(10px * 3) !important;
    padding-right: calc(var(--size-unit) * 3) !important;
}

.ph4 {
    padding-left: calc(10px * 4) !important;
    padding-left: calc(var(--size-unit) * 4) !important;
    padding-right: calc(10px * 4) !important;
    padding-right: calc(var(--size-unit) * 4) !important;
}

.ph5 {
    padding-left: calc(10px * 5) !important;
    padding-left: calc(var(--size-unit) * 5) !important;
    padding-right: calc(10px * 5) !important;
    padding-right: calc(var(--size-unit) * 5) !important;
}

.ph6 {
    padding-left: calc(10px * 6) !important;
    padding-left: calc(var(--size-unit) * 6) !important;
    padding-right: calc(10px * 6) !important;
    padding-right: calc(var(--size-unit) * 6) !important;
}

.ph7 {
    padding-left: calc(10px * 7) !important;
    padding-left: calc(var(--size-unit) * 7) !important;
    padding-right: calc(10px * 7) !important;
    padding-right: calc(var(--size-unit) * 7) !important;
}

.ph8 {
    padding-left: calc(10px * 8) !important;
    padding-left: calc(var(--size-unit) * 8) !important;
    padding-right: calc(10px * 8) !important;
    padding-right: calc(var(--size-unit) * 8) !important;
}

.ph9 {
    padding-left: calc(10px * 9) !important;
    padding-left: calc(var(--size-unit) * 9) !important;
    padding-right: calc(10px * 9) !important;
    padding-right: calc(var(--size-unit) * 9) !important;
}

.ph10 {
    padding-left: calc(10px * 10);
    padding-left: calc(var(--size-unit) * 10);
    padding-right: calc(10px * 10);
    padding-right: calc(var(--size-unit) * 10);
}

.pv0 {
    padding-top: 0;
    padding-bottom: 0;
}

.pv05 {
    padding-top: calc(10px * 0.5);
    padding-top: calc(var(--size-unit) * 0.5);
    padding-bottom: calc(10px * 0.5);
    padding-bottom: calc(var(--size-unit) * 0.5);
}

.pv1 {
    padding-top: calc(10px * 1);
    padding-top: calc(var(--size-unit) * 1);
    padding-bottom: calc(10px * 1);
    padding-bottom: calc(var(--size-unit) * 1);
}

.pv2 {
    padding-top: calc(10px * 2);
    padding-top: calc(var(--size-unit) * 2);
    padding-bottom: calc(10px * 2);
    padding-bottom: calc(var(--size-unit) * 2);
}

.pv3 {
    padding-top: calc(10px * 3);
    padding-top: calc(var(--size-unit) * 3);
    padding-bottom: calc(10px * 3);
    padding-bottom: calc(var(--size-unit) * 3);
}

.pv4 {
    padding-top: calc(10px * 4);
    padding-top: calc(var(--size-unit) * 4);
    padding-bottom: calc(10px * 4);
    padding-bottom: calc(var(--size-unit) * 4);
}

.pv5 {
    padding-top: calc(10px * 5);
    padding-top: calc(var(--size-unit) * 5);
    padding-bottom: calc(10px * 5);
    padding-bottom: calc(var(--size-unit) * 5);
}

.pv6 {
    padding-top: calc(10px * 6);
    padding-top: calc(var(--size-unit) * 6);
    padding-bottom: calc(10px * 6);
    padding-bottom: calc(var(--size-unit) * 6);
}

.pv7 {
    padding-top: calc(10px * 7);
    padding-top: calc(var(--size-unit) * 7);
    padding-bottom: calc(10px * 7);
    padding-bottom: calc(var(--size-unit) * 7);
}

.pv8 {
    padding-top: calc(10px * 8);
    padding-top: calc(var(--size-unit) * 8);
    padding-bottom: calc(10px * 8);
    padding-bottom: calc(var(--size-unit) * 8);
}

.pv9 {
    padding-top: calc(10px * 9);
    padding-top: calc(var(--size-unit) * 9);
    padding-bottom: calc(10px * 9);
    padding-bottom: calc(var(--size-unit) * 9);
}

.pv10 {
    padding-top: calc(10px * 10);
    padding-top: calc(var(--size-unit) * 10);
    padding-bottom: calc(10px * 10);
    padding-bottom: calc(var(--size-unit) * 10);
}

:root {
  --base-font: 12px;
}

@media (max-width: 1440px) {
  :root {
    --base-font: 11px;
  }
}

@media (max-width: 1200px) {
  :root {
    --base-font: 10px;
  }
}

/*@media (max-width: 992px) {*/
/*  :root {*/
/*    --base-font: 6px;*/
/*  }*/
/*}*/

/*@media (max-width: 768px) {*/
/*  :root {*/
/*    --base-font: 4px;*/
/*  }*/
/*}*/

/*@media (max-width: 576px) {*/
/*  :root {*/
/*    --base-font: 2px;*/
/*  }*/
/*}*/

.fs0 {
  font-size: 12px;
  font-size: var(--base-font);
}

.fs1 {
  font-size: calc(12px + 1px);
  font-size: calc(var(--base-font) + 1px);
}

.fs2 {
  font-size: calc(12px + 2px);
  font-size: calc(var(--base-font) + 2px);
}

.fs3 {
  font-size: calc(12px + 3px);
  font-size: calc(var(--base-font) + 3px);
}

.fs4 {
  font-size: calc(12px + 4px);
  font-size: calc(var(--base-font) + 4px);
}

.fs5 {
  font-size: calc(12px + 5px);
  font-size: calc(var(--base-font) + 5px);
}

.fs6 {
  font-size: calc(12px + 6px);
  font-size: calc(var(--base-font) + 6px);
}

.fs7 {
  font-size: calc(12px + 7px);
  font-size: calc(var(--base-font) + 7px);
}

.fs8 {
  font-size: calc(12px + 8px);
  font-size: calc(var(--base-font) + 8px);
}

.fs9 {
  font-size: calc(12px + 9px);
  font-size: calc(var(--base-font) + 9px);
}

.fs10 {
  font-size: calc(12px + 10px);
  font-size: calc(var(--base-font) + 10px);
}

.fs11 {
  font-size: calc(12px + 11px);
  font-size: calc(var(--base-font) + 11px);
}

.fs12 {
  font-size: calc(12px + 12px);
  font-size: calc(var(--base-font) + 12px);
}

.fs13 {
  font-size: calc(12px + 13px);
  font-size: calc(var(--base-font) + 13px);
}

.fs14 {
  font-size: calc(12px + 14px);
  font-size: calc(var(--base-font) + 14px);
}

.fs15 {
  font-size: calc(12px + 15px);
  font-size: calc(var(--base-font) + 15px);
}

.fs16 {
  font-size: calc(12px + 16px);
  font-size: calc(var(--base-font) + 16px);
}

.fs17 {
  font-size: calc(12px + 17px);
  font-size: calc(var(--base-font) + 17px);
}

.fs18 {
  font-size: calc(12px + 18px);
  font-size: calc(var(--base-font) + 18px);
}

.fs19 {
  font-size: calc(12px + 19px);
  font-size: calc(var(--base-font) + 19px);
}

.fs20 {
  font-size: calc(12px + 20px);
  font-size: calc(var(--base-font) + 20px);
}


h1 {
  font-size: 40px ;
}
h2 {
  font-size: 36px ;
}
h3 {
  font-size: 32px ;
}
h4 {
  font-size: 28px ;
}
h5 {
  font-size: 24px ;
}
h6 {
  font-size: 22px ;
}


@media (max-width: 1440px) {
  h1 {
    font-size: 36px ;
  }
  h2 {
    font-size: 32px ;
  }
  h3 {
    font-size: 28px ;
  }
  h4 {
    font-size: 24px ;
  }
  h5 {
    font-size: 22px ;
  }
  h6 {
    font-size: 20px ;
  }
}

@media (max-width: 1200px) {
  h1 {
    font-size: 32px ;
  }
  h2 {
    font-size: 28px ;
  }
  h3 {
    font-size: 24px ;
  }
  h4 {
    font-size: 22px ;
  }
  h5 {
    font-size: 20px ;
  }
  h6 {
    font-size: 18px ;
  }
}

@media (max-width: 992px) {
  h1 {
    font-size: 28px ;
  }
  h2 {
    font-size: 24px ;
  }
  h3 {
    font-size: 22px ;
  }
  h4 {
    font-size: 20px ;
  }
  h5 {
    font-size: 18px ;
  }
  h6 {
    font-size: 17px ;
  }
}

@media (max-width: 768px) {
  h1 {
    font-size: 24px ;
  }
  h2 {
    font-size: 22px ;
  }
  h3 {
    font-size: 20px ;
  }
  h4 {
    font-size: 18px ;
  }
  h5 {
    font-size: 17px ;
  }
  h6 {
    font-size: 16px ;
  }
}

@media (max-width: 576px) {
  h1 {
    font-size: 22px ;
  }
  h2 {
    font-size: 20px ;
  }
  h3 {
    font-size: 19px ;
  }
  h4 {
    font-size: 17px ;
  }
  h5 {
    font-size: 16px ;
  }
  h6 {
    font-size: 15px ;
  }
}

@media (max-width: 420px) {
  h1 {
    font-size: 20px ;
  }
  h2 {
    font-size: 19px ;
  }
  h3 {
    font-size: 18px ;
  }
  h4 {
    font-size: 17px ;
  }
  h5 {
    font-size: 16px ;
  }
  h6 {
    font-size: 15px ;
  }
}
