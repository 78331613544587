.confirm-modal .content {
    padding: 30px 20px;
    background-color: white;
    border-radius: 15px;
}

.confirm-modal .content h2{
    font-size: 20px;
    font-family: Yellix-SemiBold, serif;
    color: var(--color-text);
    text-align: center;
    margin-top: 0;
    margin-bottom: 30px;
}

.confirm-modal .content .yes_btn{
    font-size: 16px;
    font-family: Yellix-SemiBold, serif;
    color: var(--color-cyan2);
    margin-right: 20px;
}

.confirm-modal .content .no_btn{
    font-size: 16px;
    font-family: Yellix-SemiBold, serif;
    color: var(--color-gray7);
    margin-left: 20px;
}
