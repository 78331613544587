.home-app-intro {
  justify-content: space-between !important;
}

.home-app-intro h2 {
  font-family: Yellix-Bold, serif;
  color: var(--color-text);
  margin-top: 20px;
}

.home-app-intro h2 span,
.home-app-intro h5 span
{
  color: var(--color-cyan2);
}

.home-app-intro h5 {
  font-family: Yellix-SemiBold, serif;
  color: var(--color-text);
  margin-top: 40px;
  margin-bottom: 40px;
}

.home-app-intro .right-side img {
  width: 540px;
  max-width: calc(100% - 32px);
}

@media (max-width: 1200px) {

}

@media (max-width: 992px) {
  .home-app-intro .right-side img {
    width: 390px;
  }
}

@media (max-width: 768px) {
  .home-app-intro {
    flex-wrap: wrap;
  }
  .home-app-intro .left-side {
    width: 100%;
  }
  .home-app-intro .right-side {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .home-app-intro .right-side img {
    width: 450px;
    max-width: calc(100% - 32px);
  }

  .home-app-intro h2,
  .home-app-intro h5
  {
    width: 100%;
    text-align: center;
  }
}

@media (max-width: 576px) {
  .home-app-intro {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }
}
