.partner-screen .faqs {
  display: flex;
  flex-direction: column;
  background-color: white;
}

.partner-screen .faqs h3 {
  font-family: Yellix-Bold, serif;
  color: var(--color-text);
  margin-bottom: 40px;
  text-align: center;
}

.partner-screen .faqs .items {
  width: 100%;
  max-width: calc(100vw - 32px) !important;
}

