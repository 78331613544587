.order-review-input {
  width: 100%;
  padding-top: 20px;
  padding-bottom: 20px;
  border-bottom-width: 1px;
  border-bottom-color: var(--color-gray9);
  border-bottom-style: solid;
}

.order-review-input h3 {
  margin-top: 0;
  margin-bottom: 12px;
  font-size: 17px;
  font-family: Yellix-SemiBold, serif;
  color: var(--color-text);
}

.order-review-input img {
  width: 200px;
  height: 200px;
  object-fit: contain;
}
