.cart-view-modal {
  padding: 0px;
}

.cart-view-modal .content img.close-btn {
  width: 40px;
  height: 40px;
  object-fit: contain;
  position: absolute;
  left: 0;
  top: 0;
  cursor: pointer;
}

.cart-view-modal .content h4.title {
  font-family: normal;
  font-family: Yellix-Bold, serif;
  color: var(--color-text);
  margin: 0px;
  text-align: center;
}

.cart-view-modal .cart-btns {
  width: 100%;
  margin-top: 20px;
}

.cart-view-modal .cart-btns p {
  font-size: 15px;
  font-family: Yellix-Medium, serif;
  color: var(--color-text);
  margin-top: 0;
  margin-bottom: 3px;
}

.cart-view-modal .cart-btns h4 {
  font-size: 18px;
  font-family: Yellix-SemiBold, serif;
  color: var(--color-text);
  margin: 0;
}

.cart-view-modal .divider {
  height: 1px;
  width: 100%;
  background-color: var(--color-gray6);
  margin-bottom: 20px;
}

.cart-view-modal .subject-title {
  font-size: 16px;
  font-family: Yellix-SemiBold, serif;
  color: var(--color-text);
  margin: 10px 0px;
}
