@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700;800&display=swap');
* {
  font-family: 'Manrope', sans-serif;
}

@font-face {
  font-family: 'Yellix-Black';
  src: url(../assets/fonts/Yellix-Black.ttf) format('truetype');
}

@font-face {
  font-family: 'Yellix-BlackItalic';
  src: url(../assets/fonts/Yellix-BlackItalic.ttf) format('truetype');
}

@font-face {
  font-family: 'Yellix-Bold';
  src: url(../assets/fonts/Yellix-Bold.ttf) format('truetype');
}

@font-face {
  font-family: 'Yellix-BoldItalic';
  src: url(../assets/fonts/Yellix-BoldItalic.ttf) format('truetype');
}

@font-face {
  font-family: 'Yellix-ExtraBold';
  src: url(../assets/fonts/Yellix-ExtraBold.ttf) format('truetype');
}

@font-face {
  font-family: 'Yellix-ExtraBoldItalic';
  src: url(../assets/fonts/Yellix-ExtraBoldItalic.ttf) format('truetype');
}

@font-face {
  font-family: 'Yellix-Light';
  src: url(../assets/fonts/Yellix-Light.ttf) format('truetype');
}

@font-face {
  font-family: 'Yellix-LightItalic';
  src: url(../assets/fonts/Yellix-LightItalic.ttf) format('truetype');
}

@font-face {
  font-family: 'Yellix-Medium';
  src: url(../assets/fonts/Yellix-Medium.ttf) format('truetype');
}

@font-face {
  font-family: 'Yellix-MediumItalic';
  src: url(../assets/fonts/Yellix-MediumItalic.ttf) format('truetype');
}

@font-face {
  font-family: 'Yellix-Regular';
  src: url(../assets/fonts/Yellix-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'Yellix-SemiBold';
  src: url(../assets/fonts/Yellix-SemiBold.ttf) format('truetype');
}

@font-face {
  font-family: 'Yellix-Thin';
  src: url(../assets/fonts/Yellix-Thin.ttf) format('truetype');
}

:root {
  --color-transparent: 'transparent';
  --color-background: #fafafa;
  --color-darkerBackground: #ededed;
  --color-greyBackground: #f5f5f5;
  --color-text: #222222;
  --color-placeholder: #595959;
  --color-whitePrimary: #ffffff;
  --color-blackPrimary: #000000;
  --color-btnPrimary: #50B7ED;
  --color-disabled: #c9d6df;
  --color-blackTransparent6: 'rgba(0; 0; 0; 0.6)';
  --color-backgroundTransparent3: 'rgba(225; 225; 225; 0.3)';
  --color-backgroundTransparent4: 'rgba(225; 225; 225; 0.4)';
  --color-backgroundTransparent5: 'rgba(225; 225; 225; 0.5)';
  --color-backgroundTransparent6: 'rgba(225; 225; 225; 0.6)';
  --color-backgroundTransparent7: 'rgba(225; 225; 225; 0.7)';
  --color-backgroundTransparent8: 'rgba(225; 225; 225; 0.8)';
  --color-primaryTransparent8: 'rgba(34; 173; 196; 0.8);';
  --color-inactiveTintColor: #373a3f;
  --color-backgroundColor: #f2f7f9;
  --color-listBorderColor: #d3e0e5;
  --color-primary: #50B7ED;
  --color-secondary: #32db64;
  --color-anger: #f53d3d;
  --color-light: #f4f4f4;
  --color-dark: #222;
  --color-black: #000;
  --color-white: #fff;
  --color-red: #f00;
  --color-gray1: #454b4c;
  --color-gray2: #6a6a6a;
  --color-gray3: #8c9799;
  --color-gray4: #737373;
  --color-gray5: #d5d4e0;
  --color-gray6: #e9e9f7;
  --color-gray7: #aaa8bf;
  --color-gray8: #fafafc;
  --color-gray9: #f6f6f9;
  --color-green1: #00db16;
  --color-cyan1: #50b7ed;
  --color-cyan2: #50b7ed;
  --color-cyan3: #23dee2;
  --color-red1: #f55a00;
  --color-red2: #00c22d;
  --color-blue1: #9900ff;
  --color-alertError: #cc1c4e;
  --color-alertSuccess: #1dd890;
  --color-alertWarning: #f4e637;
  --color-warning: #f4be38;
  --color-alertInfo: #109df7;
  --color-alertNeutral: #7850dd;
  --color-socialFacebook: #3b5998;
  --color-socialTwitter: #00aced;
}

iframe {
  display: none;
}

* {
  box-sizing: border-box;
}

body {
  margin: 0;
}

#root {
  width: 100% !important;
  overflow-y: hidden;
}

.hidden {
  display: none;
}

.flex_1 {
  flex: 1;
}

.flex_wrap {
  flex-wrap: wrap;
}

.pos_relative {
  position: relative;
}

.row_center {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.flex_between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.center-align {
  display: flex;
  justify-content: center;
}

.align-middle {
  display: flex;
  justify-content: center;
  align-items: center;
}

.align-col-middle {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.align-col-start {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.align-start {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
}

.align-row-start {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
}

.align-end {
  display: flex;
  justify-content: flex-end;
}

.row-start {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.h100 {
  height: 100%;
}

.w100 {
  width: 100%;
}

.bg-cover {
  background-repeat: no-repeat !important;
  background-position: 50% !important;
  background-size: cover !important;
}

.bg-theme-color-light {
  background-color: rgba(66, 133, 244, 0.04) !important;
}

.overlay-dark {
  transition: all 0.2s ease;
}

.overlay-dark:after {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 0;
  /*opacity: .2;*/
  /*background-color: black;*/
}

.overlay-dark > *,
.overlay-light > * {
  z-index: 1;
  position: relative;
}

.btn-style {
  cursor: pointer;
}

.MuiDialog-root {
  z-index: 1000 !important;
}

.react-confirm-alert-body {
  font-family: Yellix-Medium, serif !important;
  width: 400px !important;
  max-width: calc(100vw - 40px) !important;
  text-align: center !important;
  color: var(--color-text) !important;
  padding: 16px 25px !important;
}

.react-confirm-alert-body > h1 {
  font-size: 18px;
}

.react-confirm-alert-overlay {
  z-index: 100000;
  background: rgba(0, 0, 0, 0.3) !important;
}

.react-confirm-alert-button-group > button {
  background: var(--color-cyan2) !important;
  font-family: Yellix-Medium, serif !important;
}

.react-confirm-alert-button-group {
  justify-content: center !important;
}

.react-confirm-alert-button-group > button.error-ok-btn {
  background: white !important;
  color: #50B7ED !important;
}

.error-ok-btn {
  font-size: 14px !important;
}

.lds-ellipsis div {
  background: #50B7ED !important;
  width: 9px !important;
  height: 9px !important;
}

.ptr__pull-down--pull-more div p {
  display: none;
}

.hide-sm {
  display: inherit;
}

.sm-100 {
  width: inherit;
}

.sm-flex-100 {
  flex: 1;
}

.md-flex-100 {
  flex: 1;
}

.max-lg {
  max-width: 1600px;
}

@media (max-width: 768px) {
  .md-flex-100 {
    flex: none;
    width: 100%;
  }
}

@media (max-width: 576px) {
  .hide-sm {
    display: none;
  }
  .sm-100 {
    width: 100%;
  }
  .sm-flex-100 {
    flex: none;
    width: 100%;
  }
}
