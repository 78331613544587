.address-item {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: var(--color-gray8);
  border-radius: 16px;
  margin-bottom: 16px;
  cursor: pointer;
  padding: 10px 16px 16px;
}

.address-item .address-type {
  font-size: 14px;
  font-family: Yellix-SemiBold, serif;
  color: var(--color-cyan2);
  padding: 6px 15px;
  border-radius: 5px;
  background-color: #23cbd833;
}

.address-item .primary {
  font-size: 14px;
  font-family: Yellix-SemiBold, serif;
  color: var(--color-cyan2);
}

.address-item .edit-link {
  font-size: 14px;
  font-family: Yellix-SemiBold, serif;
  color: var(--color-gray7);
  padding-right: 16px;
}

.address-item .radio {
  padding-left: 16px;
  border-left-width: 1px;
  border-left-style: solid;
  border-left-color: var(--color-gray5);
}

.address-item .info h3 {
  font-size: 16px;
  font-family: Yellix-SemiBold, serif;
  color: var(--color-text);
  margin-top: 7px;
  margin-bottom: 12px;
}

.address-item .info p {
  font-size: 16px;
  font-family: Yellix-Medium, serif;
  color: var(--color-text);
  margin-top: 9px;
  margin-bottom: 0;
}

.address-item .out_delivery_area_txt {
  color: var(--color-red1) !important;
}
