.blog-filter h1 {
  font-size: 24px;
  font-family: Yellix-ExtraBold, serif;
  color: var(--color-text);
}

.blog-filter .list-btn {
  background-color: var(--color-gray8) !important;
  color: var(--color-text);
  height: 48px;
  border-radius: 10px;
  margin-bottom: 15px;
  margin-top: 15px;
}

.blog-filter .list-btn span {
  font-size: 16px;
  font-family: Yellix-Bold, serif;
  color: var(--color-text);
}

.blog-filter .round-icon-btn {
  display: none !important;
}

@media (max-width: 767px) {
  .blog-filter .search-input {
    height: 48px !important;
  }

  .blog-filter h1 {
    display: none;
  }

  .blog-filter .round-icon-btn {
    display: flex !important;
    border-color : var(--color-gray5)
  }

  .blog-filter .filter-body {
    display: none !important;
  }
}