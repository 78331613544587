.snapfooders-group-modal {
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  z-index: 999;
  max-width: calc(100vw - 30px);
  width: 520px;
  position: fixed;
  bottom: 0;
  right: 15px;
  margin: 0 !important;
  box-shadow: 0px 11px 15px -7px rgb(0 0 0 / 20%), 0px 24px 38px 3px rgb(0 0 0 / 14%),
    0px 9px 46px 8px rgb(0 0 0 / 12%);
  background-color: white;
}

.snapfooders-group-modal .titleview {
  width: 100%;
  align-items: flex-start;
  position: relative;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
}

.snapfooders-group-modal .titleview img {
  width: 32px;
  height: 32px;
  object-fit: contain;
}

.snapfooders-group-modal .content {
  width: 100%;
  border-radius: 16px;
}

.snapfooders-group-modal .content .content-view {
  min-height: 380px;
  justify-content: flex-start;
}

.snapfooders-group-modal .content h2 {
  font-size: 22px;
  font-family: Yellix-SemiBold, serif;
  color: var(--color-text);
  margin: 0;
  flex: 1;
  text-align: center;
  padding: 6px 0;
}

.snapfooders-group-modal .content h3 {
  font-size: 22px;
  line-height: 24px;
  font-family: Yellix-SemiBold, serif;
  color: white;
  margin: 0;
  flex: 1;
}

.snapfooders-group-modal .scrollview {
  height: 48vh;
  justify-content: flex-start;
  overflow-y: auto;
  padding: 0px 20px;
  margin-bottom: 40px;
}
