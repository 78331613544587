.cart-delivery-info .vendor-address {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 15px;
  background-color: var(--color-gray8);
  padding: 12px 15px;
}

.cart-delivery-info .vendor-address p {
  color: var(--color-text);
  font-family: Yellix-Medium, serif;
  margin: 0;
}

.cart-delivery-info .handover-method .text-drop-btn {
  height: 46px;
}

.cart-delivery-info .map-view {
  width: 100%;
  height: 320px;
  border-radius: 12px;
  overflow: hidden;
  background-color: white;
}

.cart-delivery-info .address-view {
}

.cart-delivery-info .pickup-info .noti-text,
.cart-delivery-info .reservation-info .noti-text {
  font-family: Yellix-SemiBold, serif;
  text-align: center;
  color: var(--color-red1);
}

.checkout-screen .num_guest input {
  text-align: center;
}

.schedule-info-view .schedule-time-label {
  font-size: 15px;
  color: var(--color-text);
  font-family: Yellix-SemiBold, serif;
  text-decoration-line: underline;
  text-align: center;
}

@media (max-width: 420px) {
  .schedule-info-view {
    flex-direction: column;
    justify-content: center;
  }
  .schedule-info-view .subject-title {
    width: 100%;
    margin-bottom: 20px;
    margin-right: 0;
  }
}
