.auth-form h3 a {
  color: var(--color-red1);
}

.auth-bottom-link {
  margin-top: 40px;
}

.auth-bottom-link .text {
  font-size: 16px;
  font-family: Yellix-SemiBold;
  color: var(--color-text);
  margin-right: 4px;
}

.auth-bottom-link .btn {
  font-size: 16px;
  font-family: Yellix-SemiBold;
  color: var(--color-cyan2);
  cursor: pointer;
}

.text-btn {
  font-size: 16px;
  font-family: Yellix-SemiBold;
  color: var(--color-text);
  cursor: pointer;
}

.vcode-inputs .auth-input input,
textarea {
  text-align: center;
}

.view-editphone .auth-input {
  width: 100%;
}

@media (max-width: 576px) {
  .view-editphone .auth-input {
    height: 46px !important;
  }
}