
/* add card modal */
.snapfooder-profile-modal {
    width: 676px;
}

.snapfooder-profile-modal .MuiDialog-paper {
    border-radius: 16px;
    width: 560px;
    max-width: calc(100vw - 32px) !important;
}
 

.snapfooder-profile-modal .content {
    background-color: white;
    padding: 26px 18px;
}

.snapfooder-profile-modal .content h1.title {
    font-size: 32px;
    font-family: Yellix-Bold, serif;
    color: var(--color-text);
    margin: 0px;
    text-align: center;
}

.snapfooder-profile-modal .content img.close-btn {
    width: 40px;
    height: 40px;
    object-fit: contain;
    position: absolute;
    left: 0;
    top: 0;
    cursor: pointer;
}

.snapfooder-profile-modal .actions .save-btn {
    width: 100%;
    background-color: var(--color-cyan2) !important;
    color: white;
}

.snapfooder-profile-modal .row {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 8px 0px;
}

.snapfooder-profile-modal {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100vh;
}

.snapfooder-profile-modal  .header {
    height: 55px;
    display: flex;
    align-items: flex-end;
    padding-left: 20px;
    padding-right: 20px;
}

.snapfooder-profile-modal .scroll_view {
    flex: 1;
    width: 100%;
    background-color: white;
    margin-top: 20px;
}

.snapfooder-profile-modal .modal-content {
    width: 100%;
    padding: 20px 20px 30px;
    background-color: white;
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
}

.snapfooder-profile-modal .modal-title {
    width: 100%;
    text-align: center;
    font-size: 18px;
    font-family: Yellix-Bold, serif;
    color: var(--color-text);
    margin-bottom: 12px;
}


.snapfooder-profile-modal .subject-title {
    margin-top: 15px;
    font-size: 18px;
    font-family: Yellix-Bold, serif;
    color: var(--color-text);
}

.snapfooder-profile-modal .divider {
    width: 100%;
    height: 1px;
    background-color: var(--color-gray9);
}

.snapfooder-profile-modal .suggested-hlist {
    width: 100%;
    align-items: flex-start;
}

.snapfooder-profile-modal .suggested-hlist .scrollview_hider {
    width: 100%;
    margin-top: -18px;
    height: 18px;
    background-color: white;
    z-index: 1;
}

.snapfooder-profile-modal .remove-friend {
    padding-top: 12px;
    padding-bottom: 12px;
    font-size: 18px;
    font-family: Yellix-Bold, serif;
    color: var(--color-gray7);
    text-align: center;
}

.snapfooder-profile-modal .suggested-list {
    width: 100%;
    display: flex;
    margin-top: 16px;
    padding-bottom: 15px;
    overflow-x: auto;
}

.snapfooder-profile-modal .gray7_bg {
    background-color: var(--color-gray7) !important;
}

.snapfooder-profile-modal .cyan2_bg {
    background-color: var(--color-cyan2) !important;
}

.snapfooder-profile-modal .snapfooder-avatar .photoview {
    width: 100px !important;
    height: 100px !important;
    border-radius: 15px !important;
}

.snapfooder-profile-modal .snapfooder-avatar .avatarimg {
    width: 100px !important;
    height: 100px !important;
}