.user-item {
  display: flex;
  flex-direction: row;
  border-radius: 15px;
  background-color: var(--color-gray8);
  margin-bottom: 15px;
  cursor: pointer;
}

.user-item img.avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 10px;
}

.user-item .name {
  font-size: 18px;
  font-family: Yellix-SemiBold, serif;
  color: var(--color-text);
  margin-right: 6px;
}

.user-item img.zodiac {
  width: 14px;
  height: 14px;
  object-fit: contain;
  margin-top: 3px;
}

.user-item .invite_status {
  font-size: 16px;
  font-family: Yellix-SemiBold, serif;
  color: var(--color-cyan2);
  padding-top: 5px;
  padding-bottom: 5px;
}
