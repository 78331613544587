.country-dropdown {
  position: relative;
  margin: 0 auto;
  width: 96px;
  height: 54px;
  background: white;
  border: 1px solid var(--color-gray5);
  border-radius: 12px;
  z-index: 2;
}

.country-drop-btn {
  padding: 6px 10px;
  height: 48px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.country-drop-btn img {
  height: 24px;
  width: 22px;
  object-fit: contain;
}

.country-drop-btn span {
  color: var(--color-text);
  font-family: Yellix-SemiBold, serif;
  font-size: 14px !important;
  padding-left: 7px;
  padding-right: 14px;
}

.country-drop-btn:after {
  content: '';
  transition: all 0.3s;
  border: solid var(--color-text);
  border-width: 0 2px 2px 0;
  float: right;
  margin-top: 1px;
  margin-right: 6px;
  padding: 4px;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}

.country-dropdown.active .country-drop-btn:after {
  margin-top: 6px;
  transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg);
}

.country-dropdown-items {
  position: relative;
  visibility: hidden;
  opacity: 0;
  max-height: 0px;
  transition: max-height 0.6s ease;
  box-shadow: 0 2px 5px 1px rgb(64 60 67 / 16%);
  border-radius: 12px;
  background-color: white;
}

.country-dropdown.active .country-dropdown-items {
  visibility: visible;
  border: 1px solid var(--color-gray5);
  height: auto;
  max-height: 290px;
  opacity: 1;
  transition: max-height 0.7s, opacity 0.6s, visibility 1s ease;
}

.country-dropdown-item {
  cursor: pointer;
  padding: 3px 18px;
}

.country-dropdown-item img {
  height: 24px;
  width: 22px;
  object-fit: contain;
}

.country-dropdown-item h1 {
  font-family: Yellix-Medium, serif !important;
  font-size: 13px !important;
}

.country-dropdown-item:not(:last-child) {
  border-bottom: 1px solid var(--color-gray5);
}

.country-dropdown-item:hover {
  color: var(--color-text);
  font-family: Yellix-SemiBold, serif;
}
