.view-blog .header-bg {
  width: 100%;
  height: 390px;
  margin-top: -80px;
  background-image: url('../../assets/svgs/blog/blog_header_bg.svg');
  background-repeat: no-repeat;
}

.view-blog .main {
  margin-top: -195px;
}

.view-blog .main .bloglist-body {
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;
  background-color: white;
}


@media (max-width: 1400px) {
  .view-blog .blog-item-wrap {
    width: 50% !important;
    max-width: 50% !important;
    flex: none !important;
  }
}

@media (max-width: 992px) {
  .view-blog .blog-item-wrap {
    width: 100% !important;
    max-width: 100% !important;
    flex: none !important;
  }
}

@media (max-width: 576px) {
  .view-blog .main {
    padding-left: 0 !important;
    padding-right: 0 !important;
    margin-top: -295px;
  }

}