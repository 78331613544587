.snapfooder-screen {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 480px;
}

.snapfooder-screen .header {
    height: 65px;
    display: flex;
    align-items: flex-end;
    padding-left: 20px;
    padding-right: 20px;
}

.snapfooder-screen .scroll_view {
    width: 100%;
    background-color: white;
    padding-left: 20px;
    padding-right: 20px;
}

.snapfooder-screen .subject-title {
    margin-top: 15px;
    font-size: 18px;
    font-family: Yellix-Bold, serif;
    color: var(--color-text);
}

.snapfooder-screen .divider {
    width: 100%;
    height: 1px;
    background-color: var(--color-gray9);
}

.snapfooder-screen .suggested-hlist {
    width: 100%;
    align-items: flex-start;
}

.snapfooder-screen .suggested-hlist .scrollview_hider {
    width: 100%;
    margin-top: -18px;
    height: 18px;
    background-color: white;
    z-index: 1;
}

.snapfooder-screen .remove-friend {
    padding-top: 12px;
    padding-bottom: 12px;
    font-size: 18px;
    font-family: Yellix-Bold, serif;
    color: var(--color-gray7);
    text-align: center;
}

.snapfooder-screen .suggested-list {
    width: 100%;
    display: flex;
    margin-top: 16px;
    padding-bottom: 15px;
    overflow-x: auto;
}

.snapfooder-screen .gray7_bg {
    background-color: var(--color-gray7) !important;
}

.snapfooder-screen .cyan2_bg {
    background-color: var(--color-cyan2) !important;
}

@media (max-width: 576px) {
    .snapfooder-screen .snapfooder-avatar .photoview {
        width: 80px !important;
        height: 80px !important;
        border-radius: 16px !important;
    }
    .snapfooder-screen .snapfooder-avatar .avatarimg {
        width: 80px !important;
        height: 80px !important;
        border-radius: 6px !important;
    }
}
