.invitation-item {
  display: flex;
  flex-direction: row;
  padding: 20px;
  border-radius: 15px;
  background-color: var(--color-gray8);
  margin-bottom: 15px;
  cursor: pointer;
}

.invitation-item img.avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 10px;
}

.invitation-item .name {
  font-size: 14px;
  font-family: Yellix-SemiBold, serif;
  color: var(--color-text);
  margin-right: 6px;
}

.invitation-item .invite_date {
  font-size: 14px;
  font-family: Yellix-Medium, serif;
  color: var(--color-text);
  margin-top: 4px;
  margin-right: 6px;
}

.invitation-item .cancel-btn {
  font-size: 14px;
  font-family: Yellix-SemiBold, serif;
  color: var(--color-gray7);
  cursor: pointer;
}

.invitation-item img.btn {
  margin-left: 15px;
  cursor: pointer;
}
