.vendor-info {
  width: 100%;
  border-radius: 15px;
  align-items: flex-start;
}

.vendor-info p {
  font-size: 16px;
  font-family: Yellix-Medium, serif;
  color: var(--color-text);
  padding: 0;
  margin: 0 12px 0 6px;
}

.vendor-info a {
  font-size: 16px;
  font-family: Yellix-Medium, serif;
  text-decoration-line: none;
  color: var(--color-cyan2);
}

.vendor-info > div {
  justify-content: space-between;
  align-items: flex-start;
}

.vendor-info > div:nth-child(2),
.vendor-info > div:nth-child(3) {
  padding-left: 25px;
  margin-left: 25px;
  border-left-width: 2px;
  border-left-color: var(--color-gray7);
  border-left-style: solid;
}

.vendor-info .title {
  font-size: 16px;
  color: var(--color-text);
  font-family: Yellix-Medium, serif;
  display: flex;
}

.vendor-info .desc {
  font-size: 15px;
  color: var(--color-gray7);
  font-family: Yellix-Medium, serif;
  margin-right: 8px;
  margin-bottom: 4px;
}

.vendor-info .title .small-order-fee {
  padding-right: 4px;
  margin-top: -3px;
}

@media (max-width: 700px) {
  .vendor-info {
    flex-direction: column;
  }
  .vendor-info > div:nth-child(2),
  .vendor-info > div:nth-child(3) {
    padding-left: 0px;
    margin-left: 0px;
    border-left-width: 0px;
  }
}