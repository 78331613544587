
/* add card modal */
.blog-filter-modal {
    width: 676px;
}

.blog-filter-modal .MuiDialog-paper {
    border-radius: 16px;
    width: 560px;
    max-width: calc(100vw - 32px) !important;
}

.blog-filter-modal .content {
    background-color: white;
    padding: 26px 0px;
    overflow: hidden;
}

.blog-filter-modal .content h1.title {
    font-size: 26px;
    font-family: Yellix-Bold, serif;
    color: var(--color-text);
    margin: 0px;
    text-align: center;
}

.blog-filter-modal .content img.close-btn {
    width: 40px;
    height: 40px;
    object-fit: contain;
    position: absolute;
    left: 15px;
    top: 0;
    cursor: pointer;
}

.blog-filter-modal .save-btn {
    width: 100%;
    background-color: var(--color-cyan2) !important;
    color: white;
}

.blog-filter-modal {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100vh;
}

.blog-filter-modal  .header {
    height: 55px;
    display: flex;
    align-items: flex-end;
    padding-left: 20px;
    padding-right: 20px;
}

.blog-filter-modal .scroll_view {
    flex: 1;
    width: 100%;
    background-color: white;
    margin-top: 10px;
    overflow-y: auto;
}


.blog-filter-modal .list-btn {
    background-color: var(--color-gray8) !important;
    color: var(--color-text);
    height: 48px;
    border-radius: 10px;
    margin-bottom: 15px;
    margin-top: 15px;
}

.blog-filter-modal .MuiList-root {
    padding-bottom: 0 !important;
    padding-top: 0 !important;
}

.blog-filter-modal .list-btn span {
    font-size: 16px;
    font-family: Yellix-Bold, serif;
    color: var(--color-text);
}
