.credit-card-item {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  background: var(--color-gray8);
  border-width: 1px;
  border-style: solid;
  border-color: var(--color-gray8);
  border-radius: 15px;
  margin-bottom: 16px;
  cursor: pointer;
  padding: 12px 15px;
}

.credit-card-item .primarytxt {
  font-size: 14px;
  font-family: Yellix-Bold, serif;
  color: var(--color-gray7);
  margin-left: 8px;
}

.credit-card-item .edit-btn {
  font-size: 14px;
  font-family: Yellix-SemiBold, serif;
  color: var(--color-gray7);
}

.credit-card-item .user-name {
  margin-top: 12px;
  margin-bottom: 14px;
  font-size: 14px;
  color: var(--color-text);
  font-family: Yellix-SemiBold, serif;
}

.credit-card-item .card-num {
  font-size: 20px;
  font-family: Yellix-SemiBold, serif;
  color: var(--color-text);
}

.credit-card-item .cvv {
  flex: 1;
  font-size: 14px;
  font-family: Yellix-Medium, serif;
  color: var(--color-text);
}

.address-item .primary-text {
  margin-left: 8px;
  font-size: 15px;
  color: var(--color-red1);
  font-family: Yellix-Bold, serif;
}