.quiz-container {
  margin: 20px auto;
  padding: 10px 10px;
  border: 1px solid #ddd;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.quiz-synopsis {
  color: #555;
  margin-bottom: 20px;
}

.filter-dropdown-select {
  height: 40px !important;
  overflow-y: auto;
  width: 200px !important;
}

.react-quiz-container {
  max-width: unset !important;
}

.react-quiz-container .selection-tag,
.number-of-selection {
  display: none !important;
}

.questionBtnContainer {
  display: flex;
  justify-content: flex-end !important;
}

.questionWrapperBody .answerBtn {
  height: auto !important;
}

.react-quiz-container .questionWrapper .btn.correct,
.questionWrapperBody .incorrect .answerBtn .btn,
.react-quiz-container .incorrect {
  background-color: #78c43d !important;
  height: unset !important;
  border-image-source: linear-gradient(
    101.56deg,
    #240b3b 3.95%,
    #3d1e59 34%,
    #5d1c82 49%,
    #8319b4 67.14%,
    #bc14fe 94.28%
  ) !important;

  box-sizing: border-box !important;

  background: rgba(36, 11, 59, 0.2) !important;
  border-radius: 7px !important;

  color: linear-gradient(
      101.56deg,
      #240b3b 3.95%,
      #3d1e59 34%,
      #5d1c82 49%,
      #8319b4 67.14%,
      #bc14fe 94.28%
    ),
    linear-gradient(0deg, rgba(36, 11, 59, 0.2), rgba(36, 11, 59, 0.2)) !important;
  color: black !important;
  font-weight: 700 !important;
}

.react-quiz-container .questionWrapper .btn.correct {
  border: 3px #90dd40 solid !important;
}
.questionWrapperBody .incorrect .answerBtn .btn,
.react-quiz-container .incorrect {
  border: red 2px solid !important;
}

.questionWrapperBody h3 {
  font-size: 15px !important;
}

.quiz-title {
  font-family: 'Manrope' !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 25px !important;
  line-height: 54px !important;
  color: #240b3b !important;
  margin-bottom: 10px !important;
}

.react-quiz-container div :first-child:not(button):not(span),
.react-quiz-container div :nth-child(2):not(button):not(span) {
  font-family: 'Manrope' !important;
  font-style: normal !important;
  font-weight: 600 !important;
  margin-top: 10px !important;
}

.react-quiz-container div :first-child:not(button):not(span) {
  font-size: 22px;
}

.react-quiz-container div :nth-child(2):not(button):not(span) {
  font-size: 15px !important;
}

.quiz-synopsis {
  font-family: 'Manrope' !important;
  font-style: normal !important;
  font-weight: 700 !important;
  color: #565656 !important;
  margin-top: 0px !important;
}

.react-quiz-container .questionWrapper .btn {
  display: flex !important;
  align-items: center !important;
}

.react-quiz-container .tag-container {
  display: flex !important;
  flex-wrap: wrap !important;
  gap: 1px !important;
  margin-bottom: 15px !important;
  margin-top: 15px !important;
}
.startQuizWrapper button,
.questionBtnContainer button {
  width: 177.25px !important;
  height: 46.39px !important;
  border-radius: 6px !important;
  border: none !important;
  outline: none !important;
  background: rgba(80, 183, 237, 255) !important;
  color: white !important;
  background-color: rgba(80, 183, 237, 255) !important;
  text-transform: uppercase !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

.ctaTerms button {
  border-radius: 6px;
  outline: none;
  color: linear-gradient(101.56deg, #240b3b 3.95%, #3d1e59 34%, #bc14fe 94.28%);
  color: #240b3b;
  padding-block: 0px;
  margin-block: 0px;
  font-size: 15px;
  font-weight: 700;
  text-decoration: underline;
}

@media only screen and (max-width: 800px) {
  .react-quiz-container div :first-child:not(button):not(span) {
    padding-top: 20px !important;
    line-height: 1.2 !important;
  }

  .react-quiz-container div :nth-child(2):not(button):not(span) {
    font-size: 17px !important;
  }

  .modal-props {
    max-height: 400px;
  }
}
