.home-foods-menu h2 {
  font-family: Yellix-Bold, serif;
  color: var(--color-text);
}

.home-foods-menu .menu-item-view {
  width: calc(20% - 40px);
  min-width: 240px;
  height: 140px;
  cursor: pointer;
  border-radius: 20px;
  padding-top: 20px;
  padding-left: 20px;
  background-color: var(--color-gray8);
  margin-left: 20px;
  margin-right: 20px;
}

.home-foods-menu .menu-item {
  width: 100%;
  height: 100%;
  position: relative;
  cursor: pointer;
  border-radius: 20px;
}

.home-foods-menu .menu-item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 20px;
}

.home-foods-menu .menu-item h4 {
  font-size: 18px;
  font-family: Yellix-Bold, serif;
  color: var(--color-text);
  margin: 0 !important;
  position: absolute;
  top: 20px;
  left: 20px;
}

.home-foods-menu .list {
  flex-wrap: wrap;
}

.home-foods-menu .see_more_btn {
  height: 100%;
  background-color: var(--color-gray8);
  font-size: 18px;
  font-family: Yellix-Bold, serif;
  color: var(--color-text);
}


@media (max-width: 576px) {
  .home-foods-menu .menu-item-view  {
    width: 100%;
  }
}