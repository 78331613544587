.subscribe h1 {
  font-family: 'Yellix-Bold', sans-serif;
  font-size: 2rem;
  font-weight: bold;
  color: #2c3e50;
  margin-bottom: 20px;
  margin-bottom: 5px;
}

.sign-up-container {
  border-radius: 0.5rem;
  border: 1px solid #dee2e6;
  padding: 36px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
  height: 100%;
  margin-bottom: 20px;
}

.sign-up-header {
  color: #222222;
  font-weight: bold;
  font-size: 18px;
}

.sign-up-subtitle {
  font-family: 'Yellix-SemiBold';
  font-style: normal;
  font-weight: 700;
  color: #565656;
  margin-top: 0px;
}

.email-input-container {
  border: 1px solid #dee2e6;
  border-radius: 0.5rem;
  padding-left: 24px;
  display: flex;
  justify-content: space-between;
}

.email-input {
  border: none;
  font-weight: 600;
  font-size: 14px;
  width: 100%;
  height: 44px;
  outline: none;
}

.subscribe-button {
  background-color: rgba(80, 183, 237, 255);
  color: white;
  cursor: pointer;
  border-radius: 0.5rem;
  font-weight: 600;
  font-size: 14px;
  padding: 12px 20px;
  border: rgba(80, 183, 237, 255);
}
