.chat-channel-item {
  width: 100%;
  display: flex;
  flex-direction: row;
  border-radius: 15px !important;
  background-color: var(--color-gray8) !important;
  padding: 20px !important;
  margin-bottom: 15px !important;
}

.chat-channel-item img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: cover;
}

.chat-channel-item .name {
  font-size: 16px;
  font-family: Yellix-SemiBold, serif;
  color: var(--color-text);
}

.chat-channel-item .time {
  font-size: 14px;
  font-family: Yellix-SemiBold, serif;
  color: var(--color-gray7);
}

.chat-channel-item .message {
  font-size: 14px;
  font-family: Yellix-Medium, serif;
  color: var(--color-text);
  margin-right: 24px;
  text-align: left;
  word-break: break-word;
}

.chat-channel-item .unreadContainer {
  height: 15px;
  background-color: var(--color-red1);
  margin-left: 20px;
  padding-left: 5px;
  padding-right: 5px;
  border-radius: 15px;
}

.chat-channel-item .unread {
  text-align: center;
  font-size: 14px;
  line-height: 14px;
  color: white;
}
