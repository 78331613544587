.download-app-sticky {
  z-index: 998;
  width: 320px;
  padding: 10px;
  border-radius: 18px;
  position: fixed;
  bottom: 20px;
  right: 15px;
  box-shadow: 0px 11px 15px -7px rgb(0 0 0 / 20%), 0px 24px 38px 3px rgb(0 0 0 / 14%),
  0px 9px 46px 8px rgb(0 0 0 / 12%);
  background-color: white;
}

.download-app-sticky title {
  width: 50%;
  height: 160px;
  padding-right: 15px;
}

.download-app-sticky h2 {
  font-size: 22px;
  line-height: 28px;
  font-family: Yellix-Bold, serif;
  color: var(--color-text);
  margin: 0;
  padding-left: 20px;
}

@media (max-width: 576px) {
  .download-app-sticky {
    display: none !important;
    width: 200px;
    border-radius: 16px;
    right: calc((100vw - 200px) / 2);
  }
  
  .download-app-sticky title {
    width: 50%;
    height: 75px;
    padding-right: 12px;
  }
  
  .download-app-sticky h2 {
    font-size: 17px;
    line-height: 22px;
  }
}

