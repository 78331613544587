.order-review-modal .MuiDialog-paper {
  border-radius: 16px;
}

.order-review-modal .titleview {
  width: 100%;
  height: 60px;
  position: relative;
}

.order-review-modal .titleview img {
  position: absolute;
  left: 0;
  top: 10px;
}

.order-review-modal .content {
  max-width: calc(100vw - 30px);
  width: 600px;
  background-color: white;
  border-radius: 16px;
  padding: 32px 32px;
}

.order-review-modal .content h2 {
  font-size: 22px;
  font-family: Yellix-SemiBold, serif;
  color: var(--color-text);
  margin: 0;
}

.order-review-modal .content img.review-done-img {
  width: 200px;
  height: 200px;
  object-fit: contain;
}

.order-review-modal .content p.review-done {
  font-size: 19px;
  font-family: Yellix-SemiBold, serif;
  color: var(--color-text);
  text-align: center;
  line-height: 27px;
  margin: 20px 0px 40px 0;
}
