.settings-screen {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    background-color: white;
    border-top-width: 1px;
    border-top-style: solid;
    border-top-color: var(--color-gray6);
}

.settings-screen .header {
    padding-top: 20px;
}

.settings-screen h3 {
    font-size: 24px;
    font-family: Yellix-Bold, serif;
    color: var(--color-text);
    text-align: center;
}

.settings-screen .subject-title {
    font-size: 16px;
    font-family: Yellix-SemiBold, serif;
    color: var(--color-text);
}

.settings-screen .section-view {
    width: 100%;
    padding-top: 20px;
    padding-bottom: 20px;
    border-bottom-width: 1px;
    border-bottom-style: solid;
    border-bottom-color: var(--color-gray9);
}

.settings-screen .item-btn {
    margin-top: 20px;
    padding: 20px;
    background-color: var(--color-gray8);
    border-radius: 15px;
    font-size: 16px;
    font-family: Yellix-SemiBold, serif;
    color: var(--color-text);
}

.settings-screen .noti-text {
    font-size: 14px;
    font-family: Yellix-SemiBold, serif;
    color: var(--color-red1);
    text-align: center;
}

.settings-screen .lang-text {
    font-size: 16px;
    font-family: Yellix-Medium, serif;
    color: var(--color-text);
}

.settings-screen .lang-setting {
    width: 100%;
    padding: 20px;
    background-color: var(--color-gray8);
    border-radius: 15px;
}

.settings-screen .divider {
    width: 100%;
    height: 1px;
    background-color: var(--color-gray6);
}