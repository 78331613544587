.shop-feature-view {
    width: 100%;
    padding: 12px 0px;
    background-color: white;
    border-bottom-width: 1px;
    border-bottom-style: solid;
    border-bottom-color: var(--color-gray9);
}

.shop-feature-view .tags-view {
    width: 100%;
    flex-wrap: wrap;
}

.shop-feature-view .tags-view .tag {
    height: 25px;
    margin: 5px;
    padding: 5px 10px;
    border-radius: 7px;
    background-color: #23CBD826;
    font-size: 14px;
    line-height: 14px;
    font-family: Yellix-SemiBold, serif;
    color: var(--color-cyan2);
}

.shop-feature-view .feature-text {
    font-size: 16px;
    font-family: Yellix-Medium, serif;
    color: var(--color-gray7);
}

.shop-feature-view .delivery_type_txt {
    margin-top: 8px;
    margin-bottom: 8px;
    padding-left: 4px;
    font-size: 15px;
    font-family: Yellix-SemiBold, serif;
    color: var(--color-text);
    text-decoration-line: underline;
    text-decoration-color: var(--color-text);
}
