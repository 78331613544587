.chat-profile-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 0 solid #e9e9f7;
  border-bottom-width: 1px;
}

.chat-profile-item .user-info img {
  width: 42px;
  height: 42px;
  object-fit: cover;
  border-radius: 50%;
  margin-right: 8px;
}

.chat-profile-item .user-info h5 {
  font-size: 18px;
  font-family: Yellix-Bold, serif;
  margin: 0;
}

.chat-profile-item .user-info p {
  font-size: 14px;
  font-family: Yellix-SemiBold, serif;
  color: #aaa8bf;
  margin: 5px 0 0 0;
}

.new-chat-menu-items .item {
  padding: 6px;
  display: flex;
  align-items: center;
  color: var(--color-text);
  font-size: 16px;
  font-family: Yellix-SemiBold, serif;
}

.new-chat-menu-items .item div {
  margin-left: 8px;
}

.new-chat-menu-items ul li:first-child {
  border-bottom: 1px var(--color-gray6) solid;
}

.chat-profile-item .new-menu button {
  background: var(--color-text);
  padding: 6px 14px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  text-decoration: none;
}

.chat-profile-item .new-menu button img {
  margin-right: 0px;
}

.chat-profile-item .new-menu button p {
  color: #ffffff;
  font-size: 16px;
  font-family: Yellix-SemiBold, serif;
  margin: 0;
  text-transform: none;
  font-weight: normal;
}
