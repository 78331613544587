.earn-invitation-modal .MuiDialog-container {
}

.earn-invitation-modal .MuiDialog-paper {
    margin: 0 !important;
    border-radius: 30px;
}

.earn-invitation-modal .content {
}

.earn-invitation-modal .content h1{
    font-size: 18px;
    font-family: Yellix-Bold, serif;
    color: var(--color-text);
}

.earn-invitation-modal .name{
    width: 100%;
    margin: 22px 0px;
    padding: 16px;
    border-radius: 12px;
    background-color: var(--color-gray9);
    font-size: 16px;
    font-family: Yellix-Medium, serif;
    color: var(--color-text);
}