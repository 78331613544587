.view-social {
    border-top: 1px solid #e9e9f7;
}

.view-social .main .tabs .MuiTabs-flexContainer {
    border-top: 0 !important;
}

.view-social .main .tabs .MuiTabs-flexContainer {
    flex-direction: column;
}

.view-social .main .tabs .MuiButtonBase-root {
    padding: 40px 0 !important;
    font-family: Yellix-SemiBold, serif;
    font-size: 16px !important;
    color: var(--color-gray7);
    text-transform: capitalize;
}

.view-social .main .tabs .Mui-selected {
    color: var(--color-cyan2);
}

.view-social .main .tabs .Mui-selected svg path {
    fill: var(--color-cyan2) !important;
}

.view-social .main .tabs .Mui-selected:after {
    display: none;
}

.view-social .main .tabs .MuiTabs-indicator {
    display: none;
}

.view-social .main {
    align-items: flex-start;
}

.view-social .main .tabs {
    width: 140px;
}

.view-social .main .content {
    width: calc(100vw - 140px);
    border-left: 1px solid #e9e9f7;
}

@media (max-width: 1200px) {
    .view-social .main .tabs {
        width: 120px;
    }
    .view-social .main .content {
        width: calc(100vw - 120px);
    }
}

@media (max-width: 576px) {
    .view-social .main .tabs {
        width: 100%;
    }

    .view-social .main {
        flex-direction: column;
    }

    .view-social .main .content {
        width: 100%;
    }

    .view-social .main .tabs .MuiButtonBase-root {
        padding: 10px 15px !important;
        font-size: 14px !important;
    }

    .view-social .main .tabs .MuiTabs-flexContainer {
        flex-direction: row;
        overflow-x: auto;
    }

    .view-social .main .tabs img {
        width: 24px;
        height: 24px;
        object-fit: contain;
    }
}

@media (max-width: 1199px) {
    .hide-md {
        display: none;
    }
}