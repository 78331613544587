.view-snapfood-map .map-view {
  flex: 1;
  height: calc(100vh - 80px);
  position: relative;
}

.view-snapfood-map .search-bar {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  padding: 20px 40px;
}

.view-snapfood-map .setting-icon {
  position: absolute;
  top: 120px;
  right: 30px;
  width: 45px;
  height: 45px;
  object-fit: contain;
}