.payment-method-item {
  align-items: flex-start;
  border-radius: 15px;
  background-color: var(--color-gray8);
  padding: 16px;
}

.payment-method-item .title {
  font-size: 16px;
  color: var(--color-text);
  font-family: Yellix-Medium, serif;
}
