.addresses-screen {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.addresses-screen .scrollview {
    width: 100%;
    background-color: white;
    overflow-y: auto;
}

.addresses-screen .rc-swipeout-actions {
    padding: 2px;
}