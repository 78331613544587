.view-categories .header-bg {
    width: 100%;
    height: 390px;
    margin-top: -80px;
    background-image: url('../../assets/svgs/blog/blog_header_bg.svg');
    background-repeat: no-repeat;
    background-size: cover;
}

.view-categories .header-bg h4 {
    font-family: Yellix-Bold, serif;
    color: white;
    font-weight: normal;
    margin: 0;
    text-align: center;
}

.view-categories .main {
    flex-wrap: wrap;
    padding-left: 84px;
    padding-right: 84px;
}

.view-categories .main .list{
    flex-wrap: wrap;
    display: flex;
    flex-direction: row;
}

.view-categories .search-view {
    margin-top: -50px;
    padding-left: 104px;
    padding-right: 104px;
    padding-bottom: 20px;
}

.view-categories .subject-title {
    padding: 0 20px;
    font-size: 26px;
    font-family: Yellix-Bold, serif;
    color: var(--color-text);
}

.view-categories .subject-title span{
    font-size: 18px;
    font-family: Yellix-SemiBold, serif;
    color: var(--color-text);
}


@media (max-width: 1600px) {
    .view-categories .food-category-item {
        width: calc(25% - 40px);
    }
}

@media (max-width: 1400px) {
    .view-categories .vendor-item-wrap {
        width: 50% !important;
        max-width: 50% !important;
        flex: none !important;
    }

    .view-categories .search-view {
        padding-left: 40px;
        padding-right: 40px;
    }

    .view-categories .main {
        padding-left: 40px;
        padding-right: 40px;
    }
}

@media (max-width: 1200px) {
    .view-categories .food-category-item {
        width: calc(33% - 40px);
    }
}

@media (max-width: 992px) {
    .view-categories .header-bg {
        height: 280px;
    }

    .view-categories .vendor-item-wrap {
        width: 100% !important;
        max-width: 100% !important;
        flex: none !important;
    }

    .view-categories .search-view {
        padding-left: 20px;
        padding-right: 20px;
    }

    .view-categories .main {
        padding-left: 20px;
        padding-right: 20px;
    }
}

@media (max-width: 880px) {
    .view-categories .food-category-item {
        width: calc(50% - 40px);
    }
}

@media (max-width: 600px) {
    .view-categories .food-category-item {
        width: calc(100% - 40px);
    }
}

@media screen and (max-width: 576px) {
    .view-categories .header-bg {
        padding: 0px 15% 0px 15%;
    }
    .view-categories .header-bg h4 {
        padding-bottom: 20px;
    }
}