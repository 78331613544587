.emoji-modal .MuiDialog-container {
}

.emoji-modal .MuiDialog-paper {
  margin: 0 !important;
  border-radius: 15px;
}

.emoji-modal .content {
  padding: 15px 0;
}

.emoji-modal .content h1 {
  font-size: 18px;
  font-family: Yellix-Bold, serif;
  color: var(--color-text);
}

.emoji-modal aside.emoji-picker-react {
  border: none;
  box-shadow: none;
  width: 100%;
}

.emoji-modal .emoji-scroll-wrapper {
  overflow-x: hidden;
}
