.create-group-screen {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: calc(100% - 100px);
    background-size: cover;
    background-position: center;
}

.create-group-screen .scrollview {
    flex: 1;
    width: 100%;
    background-color: white;
    overflow-y: auto;
}

.create-group-screen .avatar-view {
    width: 100px;
    height: 100px;
    border-radius: 50px;
    border-width: 1px;
    border-style: solid;
    border-color: var(--color-gray9);
}

.create-group-screen img.avartar {
    width: 100px;
    height: 100px;
    border-radius: 50px;
    object-fit: cover;
}

.create-group-screen .upload-btn p{
    margin-top: 16px;
    margin-bottom: 0;
    font-size: 11px;
    color: var(--color-gray5);
    font-family: Yellix-SemiBold, serif;
}

.create-group-screen .permission-view {
    display: flex;
    border-top-width: 1px;
    border-top-style: solid;
    border-top-color: var(--color-gray9);
    border-bottom-width: 1px;
    border-bottom-style: solid;
    border-bottom-color: var(--color-gray9);
    padding-top: 20px;
    padding-bottom: 20px;
    margin-top: 10px;
}

.create-group-screen .permission-view p {
    font-size: 16px;
    font-family: Yellix-SemiBold, serif;
    color: var(--color-text);
    margin: 0
}

.create-group-screen .subject-title {
    font-size: 18px;
    font-family: Yellix-Bold, serif;
    color: var(--color-text);
}

.camera-view {
    position: absolute !important;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    background-color: #000000;
}
