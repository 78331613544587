.checkout-screen {
}

.checkout-screen .subject-title {
  font-family: Yellix-SemiBold, serif;
  color: var(--color-text);
}

.checkout-screen .proceed-btn {
  background-color: var(--color-cyan2) !important;
}

.checkout-screen .handover-view .h2 {
  font-size: 32px !important;
  font-family: normal;
  font-family: Yellix-Bold, serif;
  color: var(--color-text);
  margin: 0px;
  text-align: center;
}

.checkout-screen .cashback-row .balance {
  margin-top: 4px;
  font-family: Yellix-SemiBold;
  color: var(--color-gray7);
}

.checkout-screen .cashback-row input,
.checkout-screen .rider-tip-view input {
  text-align: center;
}
