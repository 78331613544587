.cart-counter {
    width: 128px;
    height: 50px;
    border-radius: 12px;
    background-color: var(--color-gray9);
    padding: 10px;
    display: flex;
    justify-content: space-between;
}

.cart-counter .text {
    font-size: 18px;
    font-family: Yellix-SemiBold, serif;
    color: var(--color-text);
    flex: 1;
    text-align: center;
}