.camera-modal .MuiDialog-container {
  width: 100vw;
  max-width: 520px;
}

.camera-modal .MuiDialog-paper {
  margin: 0 !important;
  border-radius: 8px;
}

.camera-modal .content {
  width: 100vw;
  max-width: 520px;
  height: 600px;
}
