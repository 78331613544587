.snapfooder-detail-map-view {
  width: 100%;
  height: 214px;
  border-radius: 18px;
  overflow: hidden;
  background-color: white;
}

/* map marker */
.map-marker {
  position: relative;
}

.map-marker .marker-body {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.map-marker img {
  cursor: pointer;
}

.map-marker .popup {
  position: absolute;
  background-color: white;
  text-align: center;
  z-index: 2;
  opacity: 1;
  transition: opacity 0.6s;
  box-shadow: 0 0 17px 2px rgb(2 2 2 / 15%) !important;
}

.map-marker .popup::after {
  content: '';
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -15px;
  border-width: 15px;
  border-style: solid;
  border-color: white transparent transparent transparent;
}

/* user marker */
.user-marker .popup {
  top: -60px;
  border-radius: 8px;
  width: 75px;
  height: 45px;
}

.user-marker .user-info p {
  font-size: 16px;
  font-family: Yellix-Bold, serif;
  color: var(--color-text);
  flex-wrap: nowrap;
  overflow-wrap: unset;
  margin: 0 !important;
}

/* end user marker */
