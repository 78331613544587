.map-setting-modal {
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
    z-index: 999;
    max-width: calc(100vw - 30px);
    max-height: calc(100vh - 100px);
    width: 520px;
    position: fixed;
    bottom: 0;
    right: 15px;
    margin: 0 !important;
    box-shadow: 0px 11px 15px -7px rgb(0 0 0 / 20%), 0px 24px 38px 3px rgb(0 0 0 / 14%),
    0px 9px 46px 8px rgb(0 0 0 / 12%);
    background-color: white;
}

.map-setting-modal .titleview {
    width: 100%;
    align-items: flex-start;
    position: relative;
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
}

.map-setting-modal .titleview img {
    width: 32px;
    height: 32px;
    object-fit: contain;
}

.map-setting-modal .content {
    width: 100%;
    max-height: calc(100vh - 100px);
    border-radius: 16px;
}

.map-setting-modal .content .content-view {
    min-height: 380px;
    justify-content: flex-start;
}

.map-setting-modal .content h2 {
    font-size: 22px;
    font-family: Yellix-SemiBold, serif;
    color: var(--color-text);
    margin: 0;
    flex: 1;
    text-align: center;
    padding: 6px 0;
}


.map-setting-modal .scrollview {
    justify-content: flex-start;
    overflow-y: auto;
    padding: 0px 20px;
    margin-bottom: 40px;
}

.map-setting-modal .section {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 0 8px;
}

.map-setting-modal .header {
    margin-bottom: 16px;
}

.map-setting-modal .headerTitle {
    color: var(--color-text);
    font-family: Yellix-Bold, serif;
    font-size: 20px;
}

.map-setting-modal .headerSubTitle {
    color: var(--color-text);
    font-family: Yellix-SemiBold, serif;
    font-size: 16px;
}

.map-setting-modal .sectionContent {
    background-color: var(--color-gray8);
    border-radius: 12px;
    display: flex;
    flex-direction: row;
    margin-top: 16px;
    align-items: center;
    min-height: 56px;
    padding: 8px 20px;
}

.map-setting-modal .sectionCol {
    display: flex;
    flex-direction: column;
    flex: 1;
}

.map-setting-modal .sectionTitle {
    margin-bottom: 8px;
    color: var(--color-text);
    font-family: Yellix-Bold, serif;
    font-size: 16px;
}

.map-setting-modal .sectionSubTitle {
    color: var(--color-text);
    font-family: Yellix-Medium, serif;
    font-size: 16px;
}

.map-setting-modal .sectionRadioTitle {
    color: var(--color-text);
    font-family: Yellix-SemiBold, serif;
    font-size: 16px;
}

.map-setting-modal .selectedFriends {
    color: var(--color-text);
    font-family: Yellix-Medium, serif;
    font-size: 15px;
}