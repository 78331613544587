:root {
    --size-unit: 10px;
}

@media (max-width: 1200px) {
    :root {
        --size-unit: 8px;
    }
}

@media (max-width: 992px) {
    :root {
        --size-unit: 6px;
    }

    .mt10 {
        /* on Small devices mt10 is same as mt5 */
        margin-top: calc(var(--size-unit) * 5);
    }
}

/*@media (max-width: 768px) {*/
/*  :root {*/
/*    --size-unit: 4px;*/
/*  }*/
/*}*/

/*@media (max-width: 576px) {*/
/*  :root {*/
/*    --size-unit: 2px;*/
/*  }*/
/*}*/

.mt0 {
    margin-top: 0px;
}

.mt1 {
    margin-top: calc(var(--size-unit) * 1);
}

.mt2 {
    margin-top: calc(var(--size-unit) * 2);
}

.mt3 {
    margin-top: calc(var(--size-unit) * 3);
}

.mt4 {
    margin-top: calc(var(--size-unit) * 4);
}

.mt5 {
    margin-top: calc(var(--size-unit) * 5);
}

.mt6 {
    margin-top: calc(var(--size-unit) * 6);
}

.mt7 {
    margin-top: calc(var(--size-unit) * 7);
}

.mt8 {
    margin-top: calc(var(--size-unit) * 8);
}

.mt9 {
    margin-top: calc(var(--size-unit) * 9);
}

.mt10 {
    margin-top: calc(var(--size-unit) * 10);
}

.mb0 {
    margin-bottom: 0px;
}

.mb1 {
    margin-bottom: calc(var(--size-unit) * 1);
}

.mb2 {
    margin-bottom: calc(var(--size-unit) * 2);
}

.mb3 {
    margin-bottom: calc(var(--size-unit) * 3);
}

.mb4 {
    margin-bottom: calc(var(--size-unit) * 4);
}

.mb5 {
    margin-bottom: calc(var(--size-unit) * 5);
}

.mb6 {
    margin-bottom: calc(var(--size-unit) * 6);
}

.mb7 {
    margin-bottom: calc(var(--size-unit) * 7);
}

.mb8 {
    margin-bottom: calc(var(--size-unit) * 8);
}

.mb9 {
    margin-bottom: calc(var(--size-unit) * 9);
}

.mb10 {
    margin-bottom: calc(var(--size-unit) * 10);
}

.ml0 {
    margin-left: 0px;
}

.ml1 {
    margin-left: calc(var(--size-unit) * 1);
}

.ml2 {
    margin-left: calc(var(--size-unit) * 2);
}

.ml3 {
    margin-left: calc(var(--size-unit) * 3);
}

.ml4 {
    margin-left: calc(var(--size-unit) * 4);
}

.ml5 {
    margin-left: calc(var(--size-unit) * 5);
}

.ml6 {
    margin-left: calc(var(--size-unit) * 6);
}

.ml7 {
    margin-left: calc(var(--size-unit) * 7);
}

.ml8 {
    margin-left: calc(var(--size-unit) * 8);
}

.ml9 {
    margin-left: calc(var(--size-unit) * 9);
}

.ml10 {
    margin-left: calc(var(--size-unit) * 10);
}

.mr0 {
    margin-right: 0px;
}

.mr1 {
    margin-right: calc(var(--size-unit) * 1);
}

.mr2 {
    margin-right: calc(var(--size-unit) * 2);
}

.mr3 {
    margin-right: calc(var(--size-unit) * 3);
}

.mr4 {
    margin-right: calc(var(--size-unit) * 4);
}

.mr5 {
    margin-right: calc(var(--size-unit) * 5);
}

.mr6 {
    margin-right: calc(var(--size-unit) * 6);
}

.mr7 {
    margin-right: calc(var(--size-unit) * 7);
}

.mr8 {
    margin-right: calc(var(--size-unit) * 8);
}

.mr9 {
    margin-right: calc(var(--size-unit) * 9);
}

.mr10 {
    margin-right: calc(var(--size-unit) * 10);
}

.mh0 {
    margin-left: 0;
    margin-right: 0;
}

.mh1 {
    margin-left: calc(var(--size-unit) * 1);
    margin-right: calc(var(--size-unit) * 1);
}

.mh2 {
    margin-left: calc(var(--size-unit) * 2);
    margin-right: calc(var(--size-unit) * 2);
}

.mh3 {
    margin-left: calc(var(--size-unit) * 3);
    margin-right: calc(var(--size-unit) * 3);
}

.mh4 {
    margin-left: calc(var(--size-unit) * 4);
    margin-right: calc(var(--size-unit) * 4);
}

.mh5 {
    margin-left: calc(var(--size-unit) * 5);
    margin-right: calc(var(--size-unit) * 5);
}

.mh6 {
    margin-left: calc(var(--size-unit) * 6);
    margin-right: calc(var(--size-unit) * 6);
}

.mh7 {
    margin-left: calc(var(--size-unit) * 7);
    margin-right: calc(var(--size-unit) * 7);
}

.mh8 {
    margin-left: calc(var(--size-unit) * 8);
    margin-right: calc(var(--size-unit) * 8);
}

.mh9 {
    margin-left: calc(var(--size-unit) * 9);
    margin-right: calc(var(--size-unit) * 9);
}

.mh10 {
    margin-left: calc(var(--size-unit) * 10);
    margin-right: calc(var(--size-unit) * 10);
}

.mv0 {
    margin-top: 0;
    margin-bottom: 0;
}

.mv1 {
    margin-top: calc(var(--size-unit) * 1);
    margin-bottom: calc(var(--size-unit) * 1);
}

.mv2 {
    margin-top: calc(var(--size-unit) * 2);
    margin-bottom: calc(var(--size-unit) * 2);
}

.mv3 {
    margin-top: calc(var(--size-unit) * 3);
    margin-bottom: calc(var(--size-unit) * 3);
}

.mv4 {
    margin-top: calc(var(--size-unit) * 4);
    margin-bottom: calc(var(--size-unit) * 4);
}

.mv5 {
    margin-top: calc(var(--size-unit) * 5);
    margin-bottom: calc(var(--size-unit) * 5);
}

.mv6 {
    margin-top: calc(var(--size-unit) * 6);
    margin-bottom: calc(var(--size-unit) * 6);
}

.mv7 {
    margin-top: calc(var(--size-unit) * 7);
    margin-bottom: calc(var(--size-unit) * 7);
}

.mv8 {
    margin-top: calc(var(--size-unit) * 8);
    margin-bottom: calc(var(--size-unit) * 8);
}

.mv9 {
    margin-top: calc(var(--size-unit) * 9);
    margin-bottom: calc(var(--size-unit) * 9);
}

.mv10 {
    margin-top: calc(var(--size-unit) * 10);
    margin-bottom: calc(var(--size-unit) * 10);
}

.pt0 {
    padding-top: 0px;
}

.pt1 {
    padding-top: calc(var(--size-unit) * 1);
}

.pt2 {
    padding-top: calc(var(--size-unit) * 2);
}

.pt3 {
    padding-top: calc(var(--size-unit) * 3);
}

.pt4 {
    padding-top: calc(var(--size-unit) * 4);
}

.pt5 {
    padding-top: calc(var(--size-unit) * 5);
}

.pt6 {
    padding-top: calc(var(--size-unit) * 6);
}

.pt7 {
    padding-top: calc(var(--size-unit) * 7);
}

.pt8 {
    padding-top: calc(var(--size-unit) * 8);
}

.pt9 {
    padding-top: calc(var(--size-unit) * 9);
}

.pt10 {
    padding-top: calc(var(--size-unit) * 10);
}

.pb0 {
    padding-bottom: 0px;
}

.pb1 {
    padding-bottom: calc(var(--size-unit) * 1);
}

.pb2 {
    padding-bottom: calc(var(--size-unit) * 2);
}

.pb3 {
    padding-bottom: calc(var(--size-unit) * 3);
}

.pb4 {
    padding-bottom: calc(var(--size-unit) * 4);
}

.pb5 {
    padding-bottom: calc(var(--size-unit) * 5);
}

.pb6 {
    padding-bottom: calc(var(--size-unit) * 6);
}

.pb7 {
    padding-bottom: calc(var(--size-unit) * 7);
}

.pb8 {
    padding-bottom: calc(var(--size-unit) * 8);
}

.pb9 {
    padding-bottom: calc(var(--size-unit) * 9);
}

.pb10 {
    padding-bottom: calc(var(--size-unit) * 10);
}

.pl0 {
    padding-left: 0px;
}

.pl1 {
    padding-left: calc(var(--size-unit) * 1);
}

.pl2 {
    padding-left: calc(var(--size-unit) * 2);
}

.pl3 {
    padding-left: calc(var(--size-unit) * 3);
}

.pl4 {
    padding-left: calc(var(--size-unit) * 4);
}

.pl5 {
    padding-left: calc(var(--size-unit) * 5);
}

.pl6 {
    padding-left: calc(var(--size-unit) * 6);
}

.pl7 {
    padding-left: calc(var(--size-unit) * 7);
}

.pl8 {
    padding-left: calc(var(--size-unit) * 8);
}

.pl9 {
    padding-left: calc(var(--size-unit) * 9);
}

.pl10 {
    padding-left: calc(var(--size-unit) * 10);
}

.pr0 {
    padding-right: 0px;
}

.pr1 {
    padding-right: calc(var(--size-unit) * 1);
}

.pr2 {
    padding-right: calc(var(--size-unit) * 2);
}

.pr3 {
    padding-right: calc(var(--size-unit) * 3);
}

.pr4 {
    padding-right: calc(var(--size-unit) * 4);
}

.pr5 {
    padding-right: calc(var(--size-unit) * 5);
}

.pr6 {
    padding-right: calc(var(--size-unit) * 6);
}

.pr7 {
    padding-right: calc(var(--size-unit) * 7);
}

.pr8 {
    padding-right: calc(var(--size-unit) * 8);
}

.pr9 {
    padding-right: calc(var(--size-unit) * 9);
}

.pr10 {
    padding-right: calc(var(--size-unit) * 10);
}

.ph0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
}

.ph05 {
    padding-left: calc(var(--size-unit) * 0.5);
    padding-right: calc(var(--size-unit) * 0.5);
}

.ph1 {
    padding-left: calc(var(--size-unit) * 1);
    padding-right: calc(var(--size-unit) * 1);
}

.ph2 {
    padding-left: calc(var(--size-unit) * 2);
    padding-right: calc(var(--size-unit) * 2);
}

.ph3 {
    padding-left: calc(var(--size-unit) * 3) !important;
    padding-right: calc(var(--size-unit) * 3) !important;
}

.ph4 {
    padding-left: calc(var(--size-unit) * 4) !important;
    padding-right: calc(var(--size-unit) * 4) !important;
}

.ph5 {
    padding-left: calc(var(--size-unit) * 5) !important;
    padding-right: calc(var(--size-unit) * 5) !important;
}

.ph6 {
    padding-left: calc(var(--size-unit) * 6) !important;
    padding-right: calc(var(--size-unit) * 6) !important;
}

.ph7 {
    padding-left: calc(var(--size-unit) * 7) !important;
    padding-right: calc(var(--size-unit) * 7) !important;
}

.ph8 {
    padding-left: calc(var(--size-unit) * 8) !important;
    padding-right: calc(var(--size-unit) * 8) !important;
}

.ph9 {
    padding-left: calc(var(--size-unit) * 9) !important;
    padding-right: calc(var(--size-unit) * 9) !important;
}

.ph10 {
    padding-left: calc(var(--size-unit) * 10);
    padding-right: calc(var(--size-unit) * 10);
}

.pv0 {
    padding-top: 0;
    padding-bottom: 0;
}

.pv05 {
    padding-top: calc(var(--size-unit) * 0.5);
    padding-bottom: calc(var(--size-unit) * 0.5);
}

.pv1 {
    padding-top: calc(var(--size-unit) * 1);
    padding-bottom: calc(var(--size-unit) * 1);
}

.pv2 {
    padding-top: calc(var(--size-unit) * 2);
    padding-bottom: calc(var(--size-unit) * 2);
}

.pv3 {
    padding-top: calc(var(--size-unit) * 3);
    padding-bottom: calc(var(--size-unit) * 3);
}

.pv4 {
    padding-top: calc(var(--size-unit) * 4);
    padding-bottom: calc(var(--size-unit) * 4);
}

.pv5 {
    padding-top: calc(var(--size-unit) * 5);
    padding-bottom: calc(var(--size-unit) * 5);
}

.pv6 {
    padding-top: calc(var(--size-unit) * 6);
    padding-bottom: calc(var(--size-unit) * 6);
}

.pv7 {
    padding-top: calc(var(--size-unit) * 7);
    padding-bottom: calc(var(--size-unit) * 7);
}

.pv8 {
    padding-top: calc(var(--size-unit) * 8);
    padding-bottom: calc(var(--size-unit) * 8);
}

.pv9 {
    padding-top: calc(var(--size-unit) * 9);
    padding-bottom: calc(var(--size-unit) * 9);
}

.pv10 {
    padding-top: calc(var(--size-unit) * 10);
    padding-bottom: calc(var(--size-unit) * 10);
}
