.our-app-intro-last {

    position: relative;
    width: 100%;
    height: 100vh;
    margin-bottom: -100px;
    padding: 0px 100px 0px;
    /* background-color: #f9f9f9; */
}

.our-app-intro-last::after {

    content: '';
    position: absolute;
    top: 0px;
    right: 0px;
    left: 0px;
    width: 100%;
    height: 100%;

    background-size: cover;
    background-repeat: no-repeat;
    background-position-x: right;
    background-position-y: center;
    background-image: url('/src/assets/images/ourapp/bg.png');
    /* background-color: #f9f9f9; */

    clip-path: polygon(0 0, 100% 0%, 100% 100%, 0 91%);

}

.our-app-intro-last .content {
    width: 50%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;


    position: relative;
    z-index: 1;
}

.our-app-intro-last .content h2 {
    letter-spacing: 0px;
    font-family: Yellix-Bold, serif;
    color: var(--color-text);
    margin-top: 0;
    margin-left: 0;
    margin-right: 0;
}


.our-app-intro-last .content .craving-something {
    color: var(--color-cyan2);
    padding-right: 10px;
}


.our-app-intro-last .content img {
    width: 240px;
    height: 80px;
    object-fit: contain;
}

@media screen and (max-width: 1200px) {

    .our-app-intro-last {
        padding: 0px 100px;
        height: 80vh;
    }

    .our-app-intro-last img {
        width: 200px;
    }

    .our-app-intro-last .content img {
        width: 210px;
        height: 70px;
        object-fit: contain;
    }
}


@media screen and (max-width: 872px) {

    .our-app-intro-last {
        height: 50vh;
        background-color: #fff;
        background-position: 100%;
    }

    .our-app-intro-last::after {

        background-size: auto;
        background-position-x: 1%;
        background-position-y: center;

    }

    .our-app-intro-last .content {
        width: 100%;
        text-align: center;
        align-items: center;
        justify-content: flex-start;
        padding-top: 50px;
    }

    .our-app-intro-last .align-middle {
        width: 100%;
    }

    .our-app-intro-last .content a {
        margin-bottom: 18px;
    }

    .our-app-intro-last .content img {
        width: 180px;
        height: 60px;
        object-fit: contain;
    }
}

@media screen and (max-width: 768px) {


    .our-app-intro-last::after {
        background-position-x: 1%;
        background-position-y: center;
    }

    .our-app-intro-last .align-middle {
        flex-direction: column;
    }

    .our-app-intro-last {
        padding-left: 20px;
        padding-right: 20px;
        margin-bottom: -60px;
    }

    .our-app-intro-last {
        padding: 0px 20px;
        background-position-y: -93px;
        background-size: contain;
        margin-top: 90px;
    }

    .our-app-intro-last .content {
        width: 100%;
        text-align: left;
        justify-content: flex-start;
        padding-top: 50px;
    }

    .our-app-intro-last .content a {
        display: block;
        width: 70%;
    }

    .our-app-intro-last br {
        display: none;
    }

    .our-app-intro-last .content img {
        width: 150px;
        height: 50px;
    }

    .our-app-intro-last .content h2 {
        text-align: center;
        margin-bottom: 20px;
    }
}