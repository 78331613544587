:root {
  --base-font: 12px;
}

@media (max-width: 1440px) {
  :root {
    --base-font: 11px;
  }
}

@media (max-width: 1200px) {
  :root {
    --base-font: 10px;
  }
}

/*@media (max-width: 992px) {*/
/*  :root {*/
/*    --base-font: 6px;*/
/*  }*/
/*}*/

/*@media (max-width: 768px) {*/
/*  :root {*/
/*    --base-font: 4px;*/
/*  }*/
/*}*/

/*@media (max-width: 576px) {*/
/*  :root {*/
/*    --base-font: 2px;*/
/*  }*/
/*}*/

.fs0 {
  font-size: var(--base-font);
}

.fs1 {
  font-size: calc(var(--base-font) + 1px);
}

.fs2 {
  font-size: calc(var(--base-font) + 2px);
}

.fs3 {
  font-size: calc(var(--base-font) + 3px);
}

.fs4 {
  font-size: calc(var(--base-font) + 4px);
}

.fs5 {
  font-size: calc(var(--base-font) + 5px);
}

.fs6 {
  font-size: calc(var(--base-font) + 6px);
}

.fs7 {
  font-size: calc(var(--base-font) + 7px);
}

.fs8 {
  font-size: calc(var(--base-font) + 8px);
}

.fs9 {
  font-size: calc(var(--base-font) + 9px);
}

.fs10 {
  font-size: calc(var(--base-font) + 10px);
}

.fs11 {
  font-size: calc(var(--base-font) + 11px);
}

.fs12 {
  font-size: calc(var(--base-font) + 12px);
}

.fs13 {
  font-size: calc(var(--base-font) + 13px);
}

.fs14 {
  font-size: calc(var(--base-font) + 14px);
}

.fs15 {
  font-size: calc(var(--base-font) + 15px);
}

.fs16 {
  font-size: calc(var(--base-font) + 16px);
}

.fs17 {
  font-size: calc(var(--base-font) + 17px);
}

.fs18 {
  font-size: calc(var(--base-font) + 18px);
}

.fs19 {
  font-size: calc(var(--base-font) + 19px);
}

.fs20 {
  font-size: calc(var(--base-font) + 20px);
}


h1 {
  font-size: 40px ;
}
h2 {
  font-size: 36px ;
}
h3 {
  font-size: 32px ;
}
h4 {
  font-size: 28px ;
}
h5 {
  font-size: 24px ;
}
h6 {
  font-size: 22px ;
}


@media (max-width: 1440px) {
  h1 {
    font-size: 36px ;
  }
  h2 {
    font-size: 32px ;
  }
  h3 {
    font-size: 28px ;
  }
  h4 {
    font-size: 24px ;
  }
  h5 {
    font-size: 22px ;
  }
  h6 {
    font-size: 20px ;
  }
}

@media (max-width: 1200px) {
  h1 {
    font-size: 32px ;
  }
  h2 {
    font-size: 28px ;
  }
  h3 {
    font-size: 24px ;
  }
  h4 {
    font-size: 22px ;
  }
  h5 {
    font-size: 20px ;
  }
  h6 {
    font-size: 18px ;
  }
}

@media (max-width: 992px) {
  h1 {
    font-size: 28px ;
  }
  h2 {
    font-size: 24px ;
  }
  h3 {
    font-size: 22px ;
  }
  h4 {
    font-size: 20px ;
  }
  h5 {
    font-size: 18px ;
  }
  h6 {
    font-size: 17px ;
  }
}

@media (max-width: 768px) {
  h1 {
    font-size: 24px ;
  }
  h2 {
    font-size: 22px ;
  }
  h3 {
    font-size: 20px ;
  }
  h4 {
    font-size: 18px ;
  }
  h5 {
    font-size: 17px ;
  }
  h6 {
    font-size: 16px ;
  }
}

@media (max-width: 576px) {
  h1 {
    font-size: 22px ;
  }
  h2 {
    font-size: 20px ;
  }
  h3 {
    font-size: 19px ;
  }
  h4 {
    font-size: 17px ;
  }
  h5 {
    font-size: 16px ;
  }
  h6 {
    font-size: 15px ;
  }
}

@media (max-width: 420px) {
  h1 {
    font-size: 20px ;
  }
  h2 {
    font-size: 19px ;
  }
  h3 {
    font-size: 18px ;
  }
  h4 {
    font-size: 17px ;
  }
  h5 {
    font-size: 16px ;
  }
  h6 {
    font-size: 15px ;
  }
}