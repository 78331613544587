.food-category-item {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: calc(20% - 40px);
  min-width: 240px;
  height: 120px;
  cursor: pointer;
  border-radius: 20px;
  padding-top: 10px;
  padding-left: 20px;
  padding-right: 20px;
  background-color: var(--color-gray8);
  margin-left: 20px;
  margin-right: 20px;
}

.food-category-item .img-wrapper {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.food-category-item img {
  height: 70px;
  object-fit: contain;
  padding-bottom: 10px;
}

.food-category-item h4 {
  padding-bottom: 10px;
  font-size: 22px;
  font-family: Yellix-Bold, serif;
  color: var(--color-text);
  margin: 0 !important;
}

@media (max-width: 576px) {
  .food-category-item {
    width: 100%;
  }
  .food-category-item h4 {
    font-size: 19px;
  }
}