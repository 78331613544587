.card-payment-method-item {
    width: 100%;
    align-items: flex-start;
    margin-bottom: 16px;
    border-radius: 15px;
    background-color: var(--color-gray8);
    padding: 16px;
}

.card-payment-method-item .title {
    font-size: 16px;
    color: var(--color-text);
    font-family: Yellix-Medium, serif;
}

.card-payment-method-item .card_number {
    font-size: 16px;
    color: var(--color-gray7);
    font-family: Yellix-Medium, serif;
}

.card-payment-method-item .add_card_btn {
    font-size: 16px;
    color: var(--color-cyan2);
    font-family: Yellix-SemiBold, serif;
    cursor: pointer;
}
