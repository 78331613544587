.food-item {
    background: var(--color-gray8);
    border-radius: 16px;
    margin-bottom: 32px;
    cursor: pointer;
    padding: 12px;
}

.food-item .food-title {
    font-size: 18px;
    font-family: Yellix-Bold, serif;
    color: var(--color-text);
    margin: 0 !important;
}

.food-item .cart-cnt {
    font-size: 17px;
    font-family: Yellix-SemiBold, serif;
    color: var(--color-cyan2);
    margin-right: 5px;
}

.food-item .discount {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 8px;
}

.food-item .discount span {
    font-size: 14px;
    font-family: Yellix-SemiBold, serif;
    color: var(--color-cyan2);
    padding-left: 2px;
}

.food-item .food-desc {
    font-size: 17px;
    font-family: Yellix-Medium, serif;
    color: var(--color-gray2);
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3; /* number of lines to show */
    line-clamp: 3;
    -webkit-box-orient: vertical;
}


.food-item .info {
    display: flex;
    justify-content: space-between;
}

.food-item .info div:first-child {
    margin-right: 2px;
}

.food-item .food-img {
    width: 108px;
    height: 108px;
    background-color: white;
    border-radius: 12px;
}

.food-item .food-img img {
    width: 108px;
    height: 108px;
    object-fit: contain;
    border-radius: 12px;
}

.food-item .indicator {
    font-size: 12px;
    font-family: Yellix-Medium;
    display: flex;
    align-items: center;
}

.food-item .indicator > div {
    width: 10px;
    height: 10px;
    border-radius: 5px;
    margin-right: 5px;
}

.food-item .like-btns span {
    color: var(--color-gray2);
    font-size: 12px;
    font-family: Yellix-Medium, serif;
    padding-left: 3px;
}

.food-item .like-btns span.divider {
    padding-left: 10px;
    padding-right: 10px;
}

.food-item .like-btns .active span,
.food-item .like-btns .active svg {
    color: var(--color-red1);
}

.food-item .price-item span.default-price {
    font-size: 19px;
    font-family: Yellix-Bold, serif;
    color: var(--color-text);
    padding-right: 6px;
}

.food-item .price-item span.discount-price {
    font-size: 16px;
    font-family: Yellix-Medium, serif;
    color: var(--color-gray7);
    text-decoration-line: line-through;
    text-decoration-color: var(--color-gray7);
    padding-top: 1px;
}

.food-item .fav-btn {
    padding: 4px;
    position: absolute;
    top: 4px;
    right: 4px;
}

.food-item-cart-border {
    border-left : 8px solid var(--color-cyan2);
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
}

.food-item .img-view {
    position: relative;
    width: 108px;
    height: 108px;
}