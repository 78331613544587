.contact_wrapper {
  display: flex;
  max-width: 80%;
  margin: auto;
  /* padding-left: 20px;
    padding-right: 20px; */
  padding-top: 83px;
}

.find_us a {
  text-decoration: none;
  color: inherit;
}
.contact_wrapper_left {
  width: 60%;
  padding-left: 0px;
  padding-right: 0px;
  height: inherit;

  box-shadow: 0px 30px 60px #0e0d1b0f;
  border-radius: 20px;
}

.checkbox-wrapper {
  display: flex;
  width: 100%;
  gap: 2px;
  padding-block: 16px;
}

.contact_wrapper_right {
  width: 40%;
  padding-left: 40px;
  padding-right: 0px;
}

.contact_form {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 50px #0e0d1b0f;
  border-radius: 20px;
  opacity: 1;
  padding: 40px;

  text-align: center;
}

.contact_form p {
  text-align: center;
  font: normal normal 800 24px/29px Yellix-Bold;
  letter-spacing: 0px;
  color: #222222;
  opacity: 1;
  margin-bottom: 40px;
  margin-top: 0px;
}

.contact_form .input,
.contact_form textarea {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #d5d4e0 !important;
  border-radius: 16px;
  opacity: 1;
  display: block;
  height: 60px;
  margin-bottom: 20px;
  padding-left: 20px;
  width: 100%;
  padding-top: 20px;
  padding-bottom: 20px;
}

.contact_form textarea {
  height: 169px;
  text-align: left;
}

.contact_form input:focus {
  outline: none;
}

.contact_form ::placeholder {
  font: normal normal 600 16px/19px Yellix-SemiBold;
  letter-spacing: 0px;
  color: #aaa8bf;
}

.contact_form button {
  border: none;
  background: #222222 0% 0% no-repeat padding-box;
  border-radius: 16px;
  opacity: 1;

  text-align: center;
  font: normal normal 600 18px/22px Yellix-SemiBold;
  letter-spacing: 0px;
  color: #ffffff;

  appearance: none;
  height: 60px;
  padding-left: 60px;
  padding-right: 60px;

  margin: auto;
  cursor: pointer;
}

@media screen and (max-width: 1200px) {
  .contact_wrapper {
    max-width: 90%;
  }
}
@media screen and (max-width: 992px) {
  .contact_form {
    padding-left: 15px;
    padding-right: 15px;
  }

  .contact_wrapper {
    max-width: 95%;
    padding-top: 87px;
  }

  .contact_wrapper_left {
    width: 100%;
    height: 50vh;
    margin-bottom: 40px;
  }

  .contact_wrapper_right {
    width: 100%;
    padding-left: 0px;
  }

  .contact_wrapper {
    flex-wrap: wrap;
  }
}

.find_us {
  padding-top: 56px;
  display: block;
}

.find_us h2 {
  font: normal normal 800 28px/24px Yellix-Bold;
  letter-spacing: 0px;
  color: #50b7ed;
  opacity: 1;
  margin-top: 0px;
  margin-bottom: 35px;
}

.contact_address {
  display: block;
  margin-bottom: 32px;

  font: normal normal 600 20px/24px Yellix-SemiBold;
  letter-spacing: 0px;
  color: #222222;
  opacity: 1;
}

.contact_address img {
  margin-right: 20px;
  height: 24px;
  float: left;
}

.contact_address img {
  vertical-align: middle;
}
