.app-btn {
  width: 100%;
  height: 54px;
  background-color: var(--color-text) !important;
  font-size: 16px !important;
  font-family: Yellix-Medium, serif !important;
  border-radius: 12px !important;
  text-transform: none !important;
  box-shadow: none !important;
}

.Mui-disabled {
  color: var(--color-gray6) !important;
}

.auth-btn {
  background-color: var(--color-cyan2) !important;
  color: white;
}

.auth-btn svg {
  color: white;
}

.main-btn {
  background-color: var(--color-text) !important;
  color: white;
}

/* switch button */
.switch-btn {
  width: 100%;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
  border: 1px solid var(--color-gray5) !important;
}

.switch-btn button {
  flex: 1;
  font-size: 17px !important;
  padding: 0 !important;
}

.switch-btn button.active {
  background-color: var(--color-cyan2) !important;
  color: white;
}

.switch-btn button.inactive {
  background-color: white !important;
  color: var(--color-text);
}

/* end switch button */

/* city button */
.city-btn {
  height: 110px;
  background-color: white !important;
  margin-bottom: 25px !important;
  border-radius: 16px !important;
  padding-right: 25px !important;
  padding-left: 25px !important;
}
.city-btn .city-btn-title {
  font-size: 22x;
  font-family: Yellix-SemiBold, serif;
  color: var(--color-text);
}

/* end city button */

/* drawer item button */
.draweritem-btn {
  background-color: var(--color-gray8) !important;
  color: var(--color-text);
  height: 48px;
}
/* end drawer item button */

/* outline button */
.outline-btn {
  background-color: white !important;
  color: var(--color-text) !important;
  border: 1px solid var(--color-text) !important;
}
/* end outline button */

/* round-icon-btn button */
.round-icon-btn {
  width: 50px;
  height: 50px;
  background-color: white !important;
  border-radius: 13px;
  border-width: 1px;
  border-color: var(--color-gray6);
  border-style: solid;
}
/* end round-icon-btn button */

/* dot-border-btn  button */
.dot-border-btn {
  background-color: white !important;
  color: var(--color-cyan2) !important;
  border: 1px dashed var(--color-cyan2) !important;
}
/* end dot-border-btn  button */

/* list button */
.list-btn {
  padding: 20px;
  background-color: var(--color-gray8) !important;
  border-radius: 8px !important;
}

.list-btn .btn-title {
  font-size: 17px;
  font-family: Yellix-SemiBold, serif;
  color: var(--color-text);
}

/* end city button */
