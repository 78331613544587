.home-featured-card-item {
  border-radius: 20px;
  padding: 25px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}

.home-featured-card-item img {
  width: 30px;
  height: 30px;
}

.home-featured-card-item .title {
  font-size: 18px;
  font-family: Yellix-SemiBold;
  color: var(--color-cyan2);
}

.home-featured-card-item .desc {
  font-size: 16px;
  font-family: Yellix-SemiBold;
  color: var(--color-text);
}

@media (max-width: 380px) {
  .home-featured-card-item img {
    width: 30px;
    height: 30px;
  }

  .home-featured-card-item {
    border-radius: 16px;
    padding: 15px;
  }
  .home-featured-card-item .title {
    font-size: 18px;
  }

  .home-featured-card-item .desc {
    font-size: 16px;
    margin-top: 4px;
  }
}