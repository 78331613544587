.price-board {
  background-color: var(--color-gray9);
  border-radius: 12px;
}

.price-board .key-text {
  font-size: 16px;
  color: var(--color-text);
  font-family: Yellix-Medium, serif;
}

.price-board .value-text {
  font-size: 16px;
  color: var(--color-text);
  font-family: Yellix-Medium, serif;
}

.price-board .top-border {
  border-top-width: 1px;
  border-top-style: solid;
  border-top-color: var(--color-gray7);
  padding-top: 12px;
}
