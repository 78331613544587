.partner-screen .mobile-intro {
  background-image: url('../../assets/images/partner/bg.png');
  height: 78vh;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  -webkit-clip-path: polygon(0 10%, 100% 0%, 100% 100%, 0 90%);
  clip-path: polygon(0 10%, 100% 0%, 100% 100%, 0 90%);
  position: relative;
}

.partner-screen .mobile-intro {
  background-position: left !important;
}

@media (max-width: 768px) {
  .partner-screen .mobile-intro {
    height: 56vh;
  }
}

@media (max-width: 576px) {
  .partner-screen .mobile-intro {
    height: 44vh;
  }
}

.partner-screen .mobile-intro .title-view {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  padding-right: 20%;
}

.partner-screen .mobile-intro h2 {
  font-family: Yellix-ExtraBold, serif;
  color: var(--color-cyan2);
}

.partner-screen .mobile-intro h5 {
  margin-top: 0 !important;
  font-family: Yellix-SemiBold, serif;
  color: var(--color-text);
}
