.home-banner {
  width: 100%;
  /*height: 80vh;*/
  background-size: contain;
  background-repeat: no-repeat;
  background-position-x: right;
  background-position-y: top;

  background-image: url('../../../assets/images/home/landing_bg.png');
  position: relative;
}


.home-banner .content {
  z-index: 1;
  width: 50%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.home-banner .content h2 {
  font-family: Yellix-Bold, serif;
  color: var(--color-text);
  margin: 0;
}

.home-banner .content h4 {
  font-family: Yellix-SemiBold, serif;
  color: var(--color-text);
  margin-top: 20px;
  margin-bottom: 20px;
}

.wanna-work-description {
  font-size: 17px;
  font-family: Yellix-SemiBold, serif;
  color: var(--color-text);
  margin-top: 20px;
  margin-bottom: 20px;
  z-index: 10;
}

.wanna-work-description a {
  color: var(--color-red1);
  padding-left: 8px;
  padding-right: 8px;
}

.bg-wrap {
  position: absolute;
  top: 0;
  right: 0;
  height: 80vh;
  display: block;
}

.bg-wrap img {
  object-fit: contain;
  height: 100%;
  overscroll-behavior-y: unset;
  overflow-y: hidden;
}

@media screen and (max-width: 576px) {
  .home-banner .content h2 {
    font-size: 23px;
  }

  .home-banner .content h4 {
    font-size: 18px;
  }

  .home-banner .content {
    width: 100%;
  }
}

@media (max-width: 1200px) {
  .home-banner .content {
    width: 100%;
  }
  .home-banner {
    /*height: 76vh;*/
  }
}

@media (max-width: 992px) {
  .home-banner {
    /*height: 72vh;*/
  }
}

@media (max-width: 768px) {
  .home-banner {
    /*height: 68vh;*/
  }
}

@media (max-width: 576px) {
  .home-banner {
    /*height: 60vh;*/
  }
  .home-banner .content p {
    text-align: center;
  }
}