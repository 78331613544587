.small-order-fee .tooltip {
  background-color: white;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
}

.small-order-fee .tooltip p {
  font-size: 14px;
  font-family: Yellix-Medium, serif;
  color: var(--color-text);
  margin: 0;
}
