.vendor-dropdown {
  position: relative;
  margin: 0 auto;
  height: 54px;
  background: white;
  border-radius: 12px;
  z-index: 3;
}

.vendor-dropdown-items {
  position: relative;
  visibility: hidden;
  opacity: 0;
  max-height: 0px;
  transition: max-height 0.6s ease;
  box-shadow: 0 2px 5px 1px rgb(64 60 67 / 16%);
  border-radius: 12px;
  background-color: white;
  padding-left: 12px;
  padding-right: 12px;
}

.vendor-dropdown.active .vendor-dropdown-items {
  visibility: visible;
  border: 1px solid var(--color-gray5);
  height: auto;
  max-height: 390px;
  opacity: 1;
  transition: max-height 0.7s, opacity 0.6s, visibility 1s ease;
}

.vendor-dropdown-item {
  cursor: pointer;
  padding: 12px 0px;
}


.vendor-dropdown-item .count {
  font-family: Yellix-Regular, serif;
  font-size: 14px;
  color: var(--color-text);
}

.vendor-dropdown-item .suggested {
  font-family: Yellix-Medium, serif;
  font-size: 17px;
  line-height: 19px;
  color: var(--color-text);
  padding-left: 2px;
}

.vendor-dropdown-item .suggested span {
  font-family: Yellix-Bold, serif;
}

.vendor-dropdown-item:not(:last-child) {
  border-bottom: 1px solid var(--color-gray5);
}

.vendor-dropdown-item:hover {
  color: var(--color-text);
  font-family: Yellix-SemiBold, serif;
}
