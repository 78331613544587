.order-support-modal {
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  z-index: 999;
  max-width: calc(100vw - 30px);
  width: 520px;
  position: fixed;
  bottom: 0;
  right: 15px;
  margin: 0 !important;
  box-shadow: 0px 11px 15px -7px rgb(0 0 0 / 20%), 0px 24px 38px 3px rgb(0 0 0 / 14%),
    0px 9px 46px 8px rgb(0 0 0 / 12%);
  background-color: white;
}

.order-support-modal .titleview {
  width: 100%;
  height: 160px;
  align-items: flex-start;
  position: relative;
  background: url('../../../assets/images/orders/help_bg.png');
  background-size: cover;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
}

.order-support-modal .titleview img {
  width: 32px;
  height: 32px;
  object-fit: contain;
}

.order-support-modal .content {
  width: 100%;
  border-radius: 16px;
}

.order-support-modal .content .content-view {
  min-height: 380px;
  justify-content: flex-start;
}

.order-support-modal .content h2 {
  font-size: 22px;
  font-family: Yellix-SemiBold, serif;
  color: white;
  margin: 0;
  flex: 1;
  text-align: center;
  padding: 6px 0;
}

.order-support-modal .content h3 {
  font-size: 22px;
  line-height: 24px;
  font-family: Yellix-SemiBold, serif;
  color: white;
  margin: 0;
  flex: 1;
}

.order-support-modal .content p.members {
  font-size: 16px;
  line-height: 22px;
  font-family: Yellix-Medium, serif;
  color: var(--color-red1);
  margin: 0;
}

.order-support-modal .content .logo-view {
  width: 84px;
  height: 84px;
  margin-top: -46px;
  background-color: white;
  z-index: 1;
  border-radius: 8px;
  border-width: 1px;
  border-style: solid;
  border-color: var(--color-gray9);
}

.order-support-modal .content .logo-view img {
  width: 80px;
  height: 80px;
  object-fit: contain;
}

.order-support-modal .content p.need-help {
  font-size: 16px;
  font-family: Yellix-SemiBold, serif;
  color: var(--color-text);
  text-align: center;
  line-height: 22px;
  margin: 20px 0px 30px 0;
}

.order-support-modal .content p.call-vendor {
  font-size: 15px;
  font-family: Yellix-Medium, serif;
  color: var(--color-text);
  text-align: center;
  line-height: 19px;
  margin: 0;
}

.order-support-modal .content .or-divider {
  width: 100%;
  margin: 28px 0;
}

.order-support-modal .content .or-divider div:first-child {
  width: 100%;
  border-width: 1px;
  border-color: var(--color-gray6);
  border-style: solid;
}

.order-support-modal .content .or-divider div:nth-child(2) {
  margin-top: -12px;
  background-color: white;
  padding: 0 8px;
  text-align: center;
  font-size: 15px;
  font-family: Yellix-Medium, serif;
  color: var(--color-text);
}

.order-support-modal .order-support-chat .messages-screen {
  max-height: calc(100vh - 364px);
}
